import React, { useState } from "react";
import UserPool from "../events/UserPool";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import Logo from "../assets/sentinen-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState();
  const [region, setRegion] = useState();
  const [loading, setLoading] = useState(false);
  const [isModal, setisModal] = useState(false);
  const [signupControl, setSignupControl] = useState("");
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  var userLang = navigator.language || navigator.userLanguage;

  const onSubmit = (event) => {
    event.preventDefault();
    var signuperr;
    UserPool.signUp(
      email,
      password,
      [
        { Name: "custom:name", Value: name },
      ],
      null,
      (err, data) => {
        if (err) {
          switch (err.code) {
            case "UsernameExistsException":
              console.log(err.code, err.message);
              signuperr = "UsernameExistsException";
              setSignupControl("UsernameExistsException");

              break;
          }
        }

        console.log(data);
        setLoading(false);
        if (signuperr == "UsernameExistsException") {
          setSignupControl("UsernameExistsException");
          signuperr = "s";
        } else {
          setisModal(true);
        }
      }
    );
  };

 
  const [letterValidated, setLetterValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);
  const handlePasswordChange = (value) => {
    setPassword(value);
    const letter = new RegExp("(?=.*[A-z])");
    const number = new RegExp("(?=.*[0-9])");
    const length = new RegExp("(?=.{8,})");
    if (letter.test(value)) {
      setLetterValidated(true);
    } else {
      setLetterValidated(false);
    }

    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
  };

  function ModalVerifyClick() {
    setisModal(false);
    navigate("/");
  }
  function Modal() {
    return (
      <div
        class="min-w-screen h-screen animated fadeIn faster p-4  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
        id="modal-id"
      >
        <div class="absolute bg-black opacity-80 inset-0 z-0"></div>
        <div class="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
          <div class="">
            <div class="text-center p-5 flex-auto justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-16 h-16 flex items-center text-green-500  mx-auto"
                viewBox="0 0 25 25"
                fill="currentColor"
              >
                <path
                  id="Send_Mail_2"
                  data-name="Send Mail 2"
                  d="M23.75,5H7.25A1.25,1.25,0,0,0,6,6.25v13.5A1.25,1.25,0,0,0,7.25,21h16.5A1.25,1.25,0,0,0,25,19.75V6.25A1.25,1.25,0,0,0,23.75,5ZM7.25,6h16.5a.25.25,0,0,1,.25.25h0l-8.5,8L7,6.28H7A.25.25,0,0,1,7.22,6Zm16.5,14H7.25A.25.25,0,0,1,7,19.75V7.66l8.16,7.7a.5.5,0,0,0,.69,0L24,7.66V19.75A.25.25,0,0,1,23.75,20ZM5,11H0V10H5Zm0,3H1V13H5Zm0,3H2V16H5Z"
                  fill="#22c55e"
                />
              </svg>
              <h2 class="text-xl font-bold py-4 ">{t("almostDone")}</h2>
              <p class="text-sm text-gray-500 px-8">{t("modalText")}</p>
            </div>

            <div class="p-3  mt-2 text-center space-x-4 md:block">
              <button
                onClick={ModalVerifyClick}
                class="mb-2 md:mb-0 bg-green-500 border border-green-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-green-600"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-5 h-screen overflow-scroll w-screen relative flex flex-col bg-[#F8F9FA] ">
      <div className="absolute p-5 z-0 top-0 h-[60vh] w-full  left-0  justify-center  lg:block  bg-cover bg-no-repeat  rounded-2xl ">
        <div className="w-full h-full bg-bgoverview2 brightness-[.5] contrast-[.75] bg-bottom bg-cover rounded-2xl " />
      </div>
      <div className="relative z-10 w-full h-80 px-6 py-10 flex flex-col items-center justify-center">
        <p className="text-4xl font-semibold text-white text-center mb-4 ">
          {t("registerAccount")}!
        </p>
        <p className="text-sm  text-white text-center ">
        Cihazlarınızı verimli bir şekilde yönetin ve takip edin.
        </p>
      </div>
      <div className=" relative z-10 w-[87%] mx-auto shadow-myShadow1   lg:w-4/12 bg-white/90 backdrop-blur-lg  rounded-2xl ">
        <img src={Logo} className=" mx-auto my-8 w-28 " />

        
        <form
          onSubmit={(e) => {
            setLoading(true);
            onSubmit(e);
          }}
          className="px-5  pb-8 mb-4  rounded"
        >
          <div className="mb-4 md:flex md:justify-between">
            <div className="mb-4 md:mr-2 md:mb-0">
              <label
                className="block mb-2 text-sm font-bold text-gray-700"
                htmlFor="firstName"
              >
                {t("name")}
              </label>
              <input
                className="w-full px-3 py-3 text-sm  text-textDark border border-textLight/50 rounded-lg  appearance-none focus:outline-none "
                id="firstName"
                type="text"
                placeholder={`${t("name")}`}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-bold text-gray-700"
              htmlFor="email"
            >
              {t("email")}
            </label>
            <input
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className="w-full px-3 py-3 text-sm  text-textDark border border-textLight/50 rounded-lg  appearance-none focus:outline-none "
              type="email"
              placeholder={`${t("email")}`}
            />
          </div>

          <div className="mb-4 ">
            <label
              className="block mb-2 text-sm font-bold text-gray-700"
              htmlFor="password"
            >
              {t("password")}
            </label>
            <input
              value={password}
              onChange={(e) => handlePasswordChange(e.target.value)}
              className="w-full mb-1 px-3 py-3 text-sm  text-textDark border border-textLight/50 rounded-lg  appearance-none focus:outline-none "
              type="password"
              placeholder="********"
            />
            <div className="flex flex-row flex-wrap text-xs text-gray-500">
              <p className={letterValidated ? `text-green-500 font-bold` : ``}>
                {" "}
                *{t("containALetter")}&nbsp;
              </p>
              <p className={numberValidated ? `text-green-500 font-bold` : ``}>
                {t("andANumber")}&nbsp;
              </p>
              <p className={lengthValidated ? `text-green-500 font-bold` : ``}>
                {t("least8Characters")}
              </p>
            </div>
          </div>
          
          <div className="mb-6 text-center ">
            {signupControl == "UsernameExistsException" && (
              <p className="text-sm text-red-600">{t("exist")}</p>
            )}
            <button
              className="w-full px-4 py-2 font-bold text-white bg-gradient-to-r from-[#62CBF8] to-[#3157F5] rounded-lg hover:bg-blue-700 focus:outline-none focus:shadow-outline disabled:opacity-10"
              disabled={
                loading ||
                name == null ||
                name == "" ||
                email == null ||
                email == "" ||
                !numberValidated ||
                !letterValidated ||
                !lengthValidated 
              
                  ? true
                  : false
              }
              type="submit"
            >
              {loading == true ? (
                <div className="  bg-opacity-5 ">
                  <div className="m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-6 w-6"></div>
                </div>
              ) : (
                `${t("registerAccount")}`
              )}
            </button>
          </div>
          <hr className="mb-6 border-t" />
          <div className="text-center">
            <Link
              className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
              to="/forgotPassword"
            >
              {t("forgotPassword")}
            </Link>
          </div>
          <div className="text-center">
            <Link
              className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
              to="/"
            >
              {t("alreadyHave")}
            </Link>
          </div>
        </form>
      </div>
    </div>
    // <div className='font-mono bg-gray-100 my-auto '>
    //   <div className='container mx-auto'>
    //     <div className='flex justify-center px-6 my-12'>
    //       <div className='w-full xl:w-3/4 lg:w-11/12 flex'>
    //         <div className='bg-hero w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg '>
    //           <img src={Logo} className=' block w-40 h-40 mx-auto my-9' />
    //         </div>

    //         <div className='w-full lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none'>
    //           <img src={Logo} className=' mx-auto my-4 w-32 lg:hidden ' />

    //           <h3 className='pt-4 text-2xl text-center clear-both'>
    //             {t('createAnAccount')}
    //           </h3>
    //           <form
    //             onSubmit={(e) => {
    //               setLoading(true);
    //               onSubmit(e);
    //             }}
    //             className='lg:px-16 sm:px-0  pt-6 pb-3 mb-4 bg-white rounded'
    //           >
    //             <div className='mb-4 md:flex md:justify-between'>
    //               <div className='mb-4 md:mr-2 md:mb-0'>
    //                 <label
    //                   className='block mb-2 text-sm font-bold text-gray-700'
    //                   htmlFor='firstName'
    //                 >
    //                   {t('name')}
    //                 </label>
    //                 <input
    //                   className='w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline'
    //                   id='firstName'
    //                   type='text'
    //                   placeholder={`${t('name')}`}
    //                   onChange={(event) => setName(event.target.value)}
    //                 />
    //               </div>
    //             </div>
    //             <div className='mb-4'>
    //               <label
    //                 className='block mb-2 text-sm font-bold text-gray-700'
    //                 htmlFor='email'
    //               >
    //                 {t('email')}
    //               </label>
    //               <input
    //                 value={email}
    //                 onChange={(event) => setEmail(event.target.value)}
    //                 className='w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline'
    //                 type='email'
    //                 placeholder={`${t('email')}`}
    //               />
    //             </div>

    //             <div className='mb-4 '>
    //               <label
    //                 className='block mb-2 text-sm font-bold text-gray-700'
    //                 htmlFor='password'
    //               >
    //                 {t('password')}
    //               </label>
    //               <input
    //                 value={password}
    //                 onChange={(e) => handlePasswordChange(e.target.value)}
    //                 className='w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border  rounded shadow appearance-none focus:outline-none focus:shadow-outline'
    //                 type='password'
    //                 placeholder='********'
    //               />
    //               <div className='flex flex-row flex-wrap text-xs text-gray-500'>
    //                 <p
    //                   className={
    //                     letterValidated ? `text-green-500 font-bold` : ``
    //                   }
    //                 >
    //                   {' '}
    //                   *{t('containALetter')}&nbsp;
    //                 </p>
    //                 <p
    //                   className={
    //                     numberValidated ? `text-green-500 font-bold` : ``
    //                   }
    //                 >
    //                   {t('andANumber')}&nbsp;
    //                 </p>
    //                 <p
    //                   className={
    //                     lengthValidated ? `text-green-500 font-bold` : ``
    //                   }
    //                 >
    //                   {t('least8Characters')}
    //                 </p>
    //               </div>
    //             </div>
    //             <div className='mb-4 md:flex md:justify-between'>
    //               <div className='w-1/2 mb-4 md:mr-2 md:mb-0'>
    //                 <label
    //                   className=' text-sm font-bold text-gray-700'
    //                   htmlFor='text'
    //                 >
    //                   {t('country')}
    //                 </label>
    //                 <div className=' py-2 text-sm md:flex md:justify-between '>
    //                   <CountryDropdown
    //                     className='w-full  px-3 py-2 text-sm font-bold leading-tight text-gray-700 border rounded shadow focus:outline-none focus:shadow-outline'
    //                     value={country}
    //                     onChange={selectCountry}
    //                   />
    //                 </div>
    //               </div>
    //               <div className='w-1/2 mb-4 md:mr-2 md:mb-0'>
    //                 <label
    //                   className=' text-sm font-bold text-gray-700'
    //                   htmlFor='text'
    //                 >
    //                   {t('region')}
    //                 </label>
    //                 <div className='w-full py-2 text-sm md:flex md:justify-between '>
    //                   <RegionDropdown
    //                     className='w-full px-3 py-2 text-sm font-bold leading-tight text-gray-700 border rounded shadow focus:outline-none focus:shadow-outline'
    //                     disableWhenEmpty={true}
    //                     country={country}
    //                     value={region}
    //                     onChange={selectRegion}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //             <div className='mb-6 text-center '>
    //               {signupControl == 'UsernameExistsException' && (
    //                 <p className='text-sm text-red-600'>{t('exist')}</p>
    //               )}
    //               <button
    //                 className='w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline disabled:opacity-10'
    //                 disabled={
    //                   loading ||
    //                   name == null ||
    //                   name == '' ||
    //                   email == null ||
    //                   email == '' ||
    //                   !numberValidated ||
    //                   !letterValidated ||
    //                   !lengthValidated ||
    //                   country == null ||
    //                   country == 'Select Country' ||
    //                   region == null ||
    //                   region == ''
    //                     ? true
    //                     : false
    //                 }
    //                 type='submit'
    //               >
    //                 {loading == true ? (
    //                   <div className='  bg-opacity-5 '>
    //                     <div className='m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-6 w-6'></div>
    //                   </div>
    //                 ) : (
    //                   `${t('registerAccount')}`
    //                 )}
    //               </button>
    //             </div>
    //             <hr className='mb-6 border-t' />
    //             <div className='text-center'>
    //               <Link
    //                 className='inline-block text-sm text-blue-500 align-baseline hover:text-blue-800'
    //                 to='/forgotPassword'
    //               >
    //                 {t('forgotPassword')}
    //               </Link>
    //             </div>
    //             <div className='text-center'>
    //               <Link
    //                 className='inline-block text-sm text-blue-500 align-baseline hover:text-blue-800'
    //                 to='/'
    //               >
    //                 {t('alreadyHave')}
    //               </Link>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {isModal && Modal()}
    // </div>
  );
};

export default Signup;
