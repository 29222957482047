import React from "react";
import PropTypes from "prop-types";

const SVGComponent = ({
    color,
    image,
    width,
    height,
    viewBox
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox? viewBox :'0 -3 38 37' }
            xmlns="http://www.w3.org/2000/svg"
            fill={color}
            style={{"filter": "drop-shadow(3px 2px 1px rgb(0 0 0 / 0.4))"}}
        >
            
            
            <path
                d={image}
            />
        </svg>

    );
};

SVGComponent.propTypes = {
    viewBox: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
};

SVGComponent.defaultProps = {
    color: '#ffffff',
    with: "100%",
    size: 40,
    viewBox: '0 -3 38 37',

};

export default SVGComponent;


