// // file = Html5QrcodePlugin.jsx

// import { Html5QrcodeScanner } from 'html5-qrcode';
// import React from 'react';

// const qrcodeRegionId = 'html5qr-code-full-region';

// class Html5QrcodePlugin extends React.Component {
//   render() {
//     return <div id={qrcodeRegionId} />;
//   }

//   componentWillUnmount() {
//     // TODO(mebjas): See if there is a better way to handle
//     //  promise in `componentWillUnmount`.
//     this.html5QrcodeScanner.clear().catch((error) => {
//       console.error('Failed to clear html5QrcodeScanner. ', error);
//     });
//   }

//   componentDidMount() {
//     // Creates the configuration object for Html5QrcodeScanner.
//     function createConfig(props) {
//       var config = {};
//       if (props.fps) {
//         config.fps = props.fps;
//       }
//       if (props.qrbox) {
//         config.qrbox = props.qrbox;
//       }
//       if (props.aspectRatio) {
//         config.aspectRatio = props.aspectRatio;
//       }
//       if (props.disableFlip !== undefined) {
//         config.disableFlip = props.disableFlip;
//       }
//       return config;
//     }

//     var config = createConfig(this.props);
//     var verbose = this.props.verbose === true;

//     // Suceess callback is required.
//     if (!this.props.qrCodeSuccessCallback) {
//       throw 'qrCodeSuccessCallback is required callback.';
//     }

//     this.html5QrcodeScanner = new Html5QrcodeScanner(
//       qrcodeRegionId,
//       config,
//       verbose
//     );
//     this.html5QrcodeScanner.render(
//       this.props.qrCodeSuccessCallback,
//       this.props.qrCodeErrorCallback
//     );
//   }
// }

// export default Html5QrcodePlugin;
import React, { useEffect, useReducer, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
const height = document.body.offsetHeight;
const width = document.body.offsetWidth;
const minWidth = Math.min(height, width);

const qrConfig = {
  fps: 10,
  qrbox: { width: width * 0.667, height: width * 0.667 },
  aspectRatio: Math.ceil((height / width) * 100) / 100,
};
let html5QrCode;

const Scanner = (props) => {
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [data, setdata] = useState('selam');
  
  useEffect(() => {
    forceUpdate();
    html5QrCode = new Html5Qrcode('reader');
    handleClickAdvanced();
    setTimeout(() => {
      handleStop();
    }, 180000);
  }, []);

  const handleClickAdvanced = () => {
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      props.onResult(decodedText);

      setdata(decodedText);
      handleStop();
    };
    html5QrCode.start(
      { facingMode: 'environment' },
      qrConfig,
      qrCodeSuccessCallback
    );
    return handleStop();
  };

  const handleStop = () => {
    try {
      html5QrCode
        .stop()
        .then((res) => {
          html5QrCode.clear();
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (err) {
      console.log(err); 
    }
  };

  return (
    <div className='relative    bg-orange-400 '>
      <div id='reader' className=' w-full h-auto aspect-square ' />
      {props.type}

      {/* <button onClick={() => handleClickAdvanced()}>
        click pro {props.type}
      </button>
      <button onClick={() => handleStop()}>stop pro</button> */}
    </div>
  );
};
export default Scanner;
