import React, {
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {MaterialReactTable} from "material-react-table";
import QRCode from "react-qr-code";
import { AccountContext } from "../events/Account";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  ListItemIcon,
  Modal,
} from "@mui/material";
import {
  Delete,
  Edit,
  OpenInNew,
  Download,
  Group,
  Done,
} from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  Popup,
  Polygon,
  useMapEvent,
  FeatureGroup,
  Circle,
  useMapEvents,
  AttributionControl,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { map, marker } from 'leaflet';
const markerIcon = new L.Icon({
  iconUrl: require('../assets/marker-w.png'),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

const Home = () => {
  const { apiKey, user_id, userMail } = useContext(AccountContext);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [lastData, setLastData] = useState([]);

  const [validationErrors, setValidationErrors] = useState({});
  const [postdata, setpostdata] = useState(false);
  const [loading, setLoading] = useState(true);
  const [groupName, setGroupName] = useState("");
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState(false);
  var demolast;
  const navigate = useNavigate();
  
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    function Fetching() {
      fetch(
        "https://x7el29cfwg.execute-api.eu-west-1.amazonaws.com/v1/izci",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("first fetch", result);
          console.log(result.body.filter((obj) => !obj.deleted_at));
          var resultfilter = result.body.filter(
            (obj) => !obj.deleted_at && obj.type == "izci"
          );
          //  resultfilter.map((e)=>{delete e.led_status ; delete e.last_measure;delete e.motor_status  ; delete e.green_led_status; delete e.red_led_status})
          console.log("resultfilter", resultfilter);
          function isBigEnough(value) {
            return value.kurulum_tarihi != null ? value : "" ;
          }
          
          
          
          var resultsort = resultfilter.filter((item)=>item.kurulum_tarihi != "");

          var tempTable;
          var asd = [];
          
          setTableData([...resultsort]);
          console.log("resultsotr", resultsort);
        })
        .then(() => setLoading(false))
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    }
    Fetching();
  }, [postdata]);

  const handleCreateNewRow = (values) => {
    console.log("values: ", values);
    values.type = "izci";
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(values),
    };

    fetch(
      "https://x7el29cfwg.execute-api.eu-west-1.amazonaws.com/v1/izci",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .then(setpostdata(!postdata))
      .catch((error) => console.log("error", error));
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var updatedValues = values;
      var id = values.id;
      delete updatedValues.id;
      delete updatedValues.produced_at;

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(values),
      };
      console.log(values);
      fetch(
        `https://x7el29cfwg.execute-api.eu-west-1.amazonaws.com/v1/izci/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));

      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
      //
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue("id")}`)
      ) {
        return;
      }
      console.log("deleted");
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var deletebody = { deleted_at: "12" };
      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: JSON.stringify(deletebody),
      };

      fetch(
        `https://x7el29cfwg.execute-api.eu-west-1.amazonaws.com/v1/izci/${row.getValue(
          "id"
        )}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "group_name",
      //   header: "group_name",
      //   enableEditing: false,
      //   size: 80,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //   }),
      // },
      {
        accessorKey: "id",
        header: "ID",
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
        
      },
      {
        accessorKey: "model",
        header: "model",
        size: 80,
      },
      {
        accessorKey: "malzeme",
        header: "malzeme",
        size: 80,
      },
      {
        accessorKey: "üretim_tarihi",
        header: "üretim_tarihi",
        enableEditing: true, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "kurulum_tarihi",
        header: "kurulum_tarihi",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "direk_no",
        header: "direk_no",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "cikis_adi",
        header: "çıkış_adı",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "sebeke_unsuru",
        header: "şebeke_unsuru",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "iletken_kesiti",
        header: "iletken_kesiti",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "direk_tipi",
        header: "direk_tipi",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "konum",
        header: "konum",
        enableEditing: false,
        size: 80,
        Cell: ({ cell }) => (
          <>
            
            <div>
            <span>{cell.row.original.konum?.lat}{cell.row.original.konum?.lat ? "," : ""}</span>
            <span>{cell.row.original.konum?.lon}</span>
            </div>
          </>
        ),
      },
      {
        accessorKey: "kuran",
        header: "montajı_yapan",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "operator_notu",
        header: "operatör_notu",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "kaldırma_tarihi",
        header: "kaldırma_tarihi",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "kaldıran",
        header: "montajı_kaldıran",
        enableEditing: false,
        size: 80,
      },
      
    ],
    [getCommonEditTextFieldProps]
  );

  const downloadQRCode = (row) => {
    console.log("row : ", row.original.id);
    var svg = document.getElementById(row.original.id);

    //get svg source.
    var serializer = new XMLSerializer();
    var source = serializer.serializeToString(svg);

    //add name spaces.
    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"'
      );
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
      );
    }

    //add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    //convert svg source to URI data scheme.
    var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);

    //set url value to a element's href attribute.

    //you can download svg file by right click menu.
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${row.original.id}.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  function handleOpenInNew(row) {
    console.log(row.original.id);
    // navigate(row.original.id, '_blank');
    window.open(`/izci/${row.original.id}`, "_blank");
  }
  function handleOpenCalibration(row) {
    navigate(`${row.original.id}/calibration`);
  }
  function formatDate(date) {
    var mdate = new Date(date);
    mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
    return `       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }
     ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    `;
  }
  function formatDateCSV(date) {
    var mdate = new Date(date);
    mdate.setMinutes(mdate.getMinutes());
    return `${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}`;
  }
  function fetchLast(data) {
    fetch(
      `https://g0vud29n03.execute-api.eu-west-1.amazonaws.com/Stage1?id=${data.id}&type=last&izcilength=${data.izci_length}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        const resultfilter = JSON.parse(result.body);
        Object.keys(resultfilter).map((e, i) => {
          data[e] = resultfilter[e];
        });
      })
      .catch((err) => {
        console.log("lasdatafetcherr : ", err);
      });
    const original = [
      "coords",
      "izci_hw_group",
      "izci_length",
      "qr",
      "created_at",
      "izci_packet_ver",
      "izci_hw_cfg",
      "izci_hw_rev",
      "izci_hw_ver",
      "owner_id",
      "produced_at",
      "izci_sw_ver",
      "id",
      "type",
      "time",
    ];
    return (
      <div className="flex flex-row  ">
        <p className="text-xl font-bold text-blue-600 mr-5 items-center">
          <p>last measure </p> <p>{formatDate(data.time)}</p>
        </p>
        {Object.keys(data).map((e, i) => {
          if (!original.includes(e)) {
            return (
              <div
                key={Math.random()}
                className="flex flex-col justify-center items-center bg-slate-200 "
              >
                <p className="text-lg font-bold text-gray-600 px-5">{e}</p>
                <p className="text-xl font-bold bg-white w-full px-5">
                  {Number(data[e]).toFixed(1)}
                </p>
              </div>
            );
          }
        })}
      </div>
    );
  }

  const handleSetGroup = async (data, groupName) => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({ group_name: groupName }),
    };

    data.map((e) => {
      fetch(
        `https://x7el29cfwg.execute-api.eu-west-1.amazonaws.com/v1/izci/${e.original.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    });
    setIsGroupModal(false);

    // fetch(
    //   `https://x7el29cfwg.execute-api.eu-west-1.amazonaws.com/v1/izci/${id}`,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => console.log(result))
    //   .then(setpostdata(!postdata))
    //   .catch((error) => console.log("error", error));
  };
  function getAll() {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      "https://x7el29cfwg.execute-api.eu-west-1.amazonaws.com/v1/izci",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("noldubize: ", result);
        console.log(result.body.filter((obj) => !obj.deleted_at));
        const resultfilter = result.body.filter(
          (obj) => !obj.deleted_at && obj.type == "izci"
        );

        const resultsort = resultfilter.sort(function (a, b) {
          return b.produced_at - a.produced_at;
        });

        console.log("resultsort : ", resultsort);
        setTableData(resultsort);
      })
      .then(() => setLoading(false))

      .catch((error) => console.log("error", error));
  }

  var csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    title: 'Sentinen İzolator',
    filename:`Sentinen ${formatDateCSV(new Date())} Rapor`,
    useKeysAsHeaders: false,
    headers: tableData[0]
      ? columns.map((c) => c.header)
      : "",
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportRows = (rows) => {
    csvExporter.generateCsv(
      rows.map((row) => {
        let formattedValue = String(row.original.id).padStart(8, '0');
        var ordered = {
          "ocak_id":`SNT${row.original.id}`,
          "model":row.original.model,
          "malzeme":row.original.malzeme,
          "üretim_tarihi":row.original.üretim_tarihi,
          "kurulum_tarihi":row.original.kurulum_tarihi,
          "direk_no":row.original.direk_no,
          "çıkış_adı":row.original.cikis_adi,
          "şebeke_unsuru":row.original.sebeke_unsuru,
          "iletken_kesiti":row.original.iletken_kesiti,
          "direk_tipi":row.original.direk_tipi,
          "konum":`${row.original.konum.lat},${row.original.konum.lon}`,
          "montajı_yapan":row.original.kuran,
          "operator_notu":row.original.operator_notu,
          "kaldırma_tarihi":row.original.kaldırma_tarihi,
          "montajı_kaldıran":row.original.kaldıran

        };
        // columns.map((c) => (ordered[c.header] = row.original[c.header]));
        return ordered;
      })
    );
  };
  return (
    <>
      {!loading ? (
        <>
        <MaterialReactTable
          columns={columns}
          data={tableData}
          enableEditing={false}
          enableColumnOrdering
          enableDensityToggle={false}
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          // renderDetailPanel={({ row }) => fetchLast(row.original)}
          // enableRowNumbers={userMail.includes('@yt.com.tr') ? true :false}
          // enableRowActions={userMail.includes('@yt.com.tr') ? true :false}
          // enableRowSelection={userMail.includes('@yt.com.tr') ? true :false}
          // enableTopToolbar={userMail.includes('@yt.com.tr') ? true :false}
          enableTopToolbar={true}
          enableGrouping
          enableStickyHeader
          positionToolbarAlertBanner="bottom"
          initialState={{
            density: "compact",
            expanded: true,
            // grouping: ["group_name"],
            pagination: { pageSize: 100, pageIndex: 0 },
            sorting: [
              {
                id: 'kurulum_tarihi', //sort by age by default on page load
                desc: true,
              },
            ],
          }}
          state={{
            isLoading: loading,
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                position: "relative",
                left:"100%",
                marginLeft:"-8rem",
                marginTop:"2.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                disabled={
                  table.getPrePaginationRowModel().rows.length === 0
                }
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                
                variant="contained"
              >
                CSV Kaydet
              </Button>

              
            </Box>
          )}
        />
        <div>
        <div className="w-[100% -10rem] max-sm:w-full h-[90vh] max-sm:h-[80vh]">
        <MapContainer
                  center={{lat:37.858705, lon:32.503496}}
                  zoom={8}
                  className='w-full h-full  z-50'
                  scrollWheelZoom={true}
                  dragging={true}
                  zoomControl={true}
                  
                  attributionControl={false}
                  
                >
                  <TileLayer
                
                url='https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}'
              />
              <AttributionControl 
              
                position='bottomright'
                prefix={"Leaflet"}
              />
                 {tableData.map((e)=>{
                  
                  return e.kaldıran =="" ? e.konum?.lat ? <Marker position={{lat:e.konum.lat, lon:e.konum.lon}} icon={markerIcon}>
                  <Popup autoClose={false} >
                    id: {e.id}<br/>
                    mode: {e.model}<br/>
                    malzeme: {e.malzeme}<br/>
                    kurulum tarihi: {e.kurulum_tarihi}<br/>
                    direk no: {e.direk_no}<br/>
                    çıkış adı: {e.cikis_adi}<br/>
                    şebeke unsuru: {e.sebeke_unsuru}<br/>
                    iletken kesiti {e.iletken_kesiti}<br/>
                    direk tipi: {e.direk_tipi}<br/>
                    konum: {e.konum.lat},
                    {e.konum.lon}<br/>
                    kurulum yapan : {e.kuran}<br/>
                    operator notu : {e.operator_notu}
                  </Popup>
                </Marker>:"":""
                 })}
                
                  
                 
                </MapContainer>
        </div>
        </div></>
      ) : (
        <div className=" flex flex-row flex-wrap gap-5 ml-5 mr-5 ">
          <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
        </div>
      )}

      
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey] = "";
      return acc;
    }, {})
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New İzci</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            {columns.map((column) =>
              column.enableEditing == false || column.header == "length" ? (
                ""
              ) : (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      izci_length: 60,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              )
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Create New İzci
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;

export default Home;
