import React, { useEffect, useRef, useState } from "react";
import ReactEcharts from "echarts-for-react";
import Drop from "../assets/drop.svg";
import Temp from "../assets/temp.svg";
import Particle from "../assets/particle.svg";
import { useParams, useLocation } from "react-router-dom";
import ReactWeather, { useOpenWeather } from "react-open-weather";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DeviceCard from "./DeviceCard";
import SettingsIcon from "../assets/settingsIcon.svg"
import WeatherCard from "./WeatherCard/WeatherCard";
import { useTranslation } from "react-i18next";
function SelectedIzci() {
  const {t,i18n} = useTranslation()
  useEffect(() => {
    executeScroll()
  }, []);
  const { deviceid } = useParams();
  const location = useLocation();
  // console.log('seleceddevice : ', deviceid, 'locaiton :', location);
  const [fieldData, setFieldData] = useState(location.state && location.state);
  const [full, setfull] = useState(false);
  const [lastMeasureDate, setLastMeasureDate] = useState();
  const [lastData, setLastData] = useState();
  const [fieldTown, setFieldTown] = useState();

  var position = [];
  const tempcurrent = [
    lastData?.at
      ? {
          at: Number(lastData.at).toFixed(2),
        }
      : null,
    lastData?.sm5
      ? {
          mois: Number(lastData.sm5).toFixed(2),
          temp: Number(lastData.st5).toFixed(0),
          cm: "05",
        }
      : null,
    lastData?.sm15
      ? {
          mois: Number(lastData.sm15).toFixed(2),
          temp: Number(lastData.st15).toFixed(0),
          cm: "15",
        }
      : null,
    lastData?.sm25
      ? {
          mois: Number(lastData.sm25).toFixed(2),
          temp: Number(lastData.st25).toFixed(0),
          cm: "25",
        }
      : null,
    lastData?.sm35
      ? {
          mois: Number(lastData.sm35).toFixed(2),
          temp: Number(lastData.st35).toFixed(0),
          cm: "35",
        }
      : null,
    lastData?.sm45
      ? {
          mois: Number(lastData.sm45).toFixed(2),
          temp: Number(lastData.st45).toFixed(0),
          cm: "45",
        }
      : null,
    lastData?.sm55
      ? {
          mois: Number(lastData.sm55).toFixed(2),
          temp: lastData?.st55 && Number(lastData.st55).toFixed(0),
          cm: "55",
        }
      : null,
    lastData?.sm65
      ? {
          mois: Number(lastData.sm65).toFixed(2),
          temp: lastData?.st65 && Number(lastData.st65).toFixed(0),
          cm: "65",
        }
      : null,
    lastData?.sm75
      ? {
          mois: Number(lastData.sm75).toFixed(2),
          temp: lastData?.st75 && Number(lastData.st75).toFixed(0),
          cm: "75",
        }
      : null,
    lastData?.sm85
      ? {
          mois: Number(lastData.sm85).toFixed(2),
          temp: lastData?.st85 && Number(lastData.st85).toFixed(0),
          cm: "85",
        }
      : null,
    // { mois: 47, temp: 23, cm: '65' },
    // { mois: 45, temp: 23, cm: '75' },
    // { mois: 40, temp: 22, cm: '85' },
  ];
  const { data, isLoading, errorMessage, locationLabel } = useOpenWeather({
    key: "649aab4a6e31bce8122c212ee9b978bf",
    // lat: 36.924754,
    // lon: 30.749551,
    lat: fieldData?.coords?.lat,
    lon: fieldData?.coords?.lon,
    lang: "en",
    unit: "metric", // values are (metric, standard, imperial)
  });
  useEffect(() => {
    const requestOptionsnom = {
      method: "GET",
    };
    fetch(
      `https://nominatim.openstreetmap.org/search?q=${fieldData?.coords?.lat},${fieldData?.coords?.lon}&addressdetails=1&format=json`,
      requestOptionsnom
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(("coord nomi:", result));
        setFieldTown(result[0].address.town);
      })
      .catch((err) => console.log("err : ", err));
  }, []);

  useEffect(() => {
    fetch(
      `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=last&izcilength=${fieldData.izci_length}&detail=nodetail`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        var parsedlastdata = JSON.parse(result.body);
        // console.log('lastdata parse :', parsedlastdata);
        setLastData(parsedlastdata);
        var templasttime = new Date(parsedlastdata.time);

        setLastMeasureDate(formatDate(templasttime));
      })
      .catch((err) => {
        // console.log('lasdatafetcherr : ', err);
      });
    fetch(
      `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=all&izcilength=${fieldData.izci_length}&timeResolution=1h&detail=nodetail`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        var asd = JSON.stringify(result.body);
        var obj = [];
        result.body.map((e) => {
          obj.push(JSON.parse(e));
        });
        // console.log('parse all:', obj[0]['sm5']);
        fieldData.tsdata = obj;
      })
      .then(() => setfull(true))
      .catch((err) => {
        // console.log('devicecard err :', err);
      });
    // const interval = setInterval(() => {
    //   console.log("This will run every 5 second!");
    //   fetch(
    //     `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=last&izcilength=${fieldData.izci_length}&detail=nodetail`,
    //     {
    //       method: "GET",
    //     }
    //   )
    //     .then((response) => response.json())
    //     .then((result) => {
    //       console.log(result);
    //       var parsedlastdata = JSON.parse(result.body);
    //       console.log("lastdata parse :", parsedlastdata);
    //       setLastData(parsedlastdata);
    //       var templasttime = new Date(parsedlastdata.time);

    //       setLastMeasureDate(formatDate(templasttime));
    //     })
    //     .catch((err) => {
    //       console.log("lasdatafetcherr : ", err);
    //     });
    //   fetch(
    //     `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=all&izcilength=${fieldData.izci_length}&timeResolution=1h&detail=nodetail`,
    //     {
    //       method: "GET",
    //     }
    //   )
    //     .then((response) => response.json())
    //     .then((result) => {
    //       console.log(result);
    //       var asd = JSON.stringify(result.body);
    //       var obj = [];
    //       result.body.map((e) => {
    //         obj.push(JSON.parse(e));
    //       });
    //       console.log("parse all:", obj[0]["sm5"]);
    //       fieldData.tsdata = obj;
    //     })
    //     .then(() => setfull(true))
    //     .catch((err) => {
    //       console.log("devicecard err :", err);
    //     });
    // }, 60000);
    // return () => clearInterval(interval);
  }, []);

  function formatDate(date) {
    var mdate = new Date(date);
    mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
    return `       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }
     ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    `;
  }
  function yaz(attrname) {
    var time = [];
    var value = [];
    var lasttime;
    fieldData.tsdata?.map((data) => {
      if (Date.parse(data.time) - lasttime < -7200000) {
        value.push([Date.parse(data.time) + 3200000, null]);
      }
      var mdate = new Date(data.time);
      mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
      value.push([mdate, Number(data[attrname]).toFixed(2)]);

      lasttime = Date.parse(data.time);
    });
    return value;
  }

  const DrawStackedTSSM = (data) => {
    switch (fieldData.izci_length) {
      case "60":
        var graphData1 = yaz("sm5");
        var graphData2 = yaz("sm15");
        var graphData3 = yaz("sm25");
        var graphData4 = yaz("sm35");
        var graphData5 = yaz("sm45");
        var graphData6 = yaz("sm55");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
          ],
          ["sm05", "sm15", "sm25", "sm35", "sm45", "sm55"]
        );
      case "90":
        var graphData1 = yaz("sm5");
        var graphData2 = yaz("sm15");
        var graphData3 = yaz("sm25");
        var graphData4 = yaz("sm35");
        var graphData5 = yaz("sm45");
        var graphData6 = yaz("sm55");
        var graphData7 = yaz("sm65");
        var graphData8 = yaz("sm75");
        var graphData9 = yaz("sm85");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
            graphData7,
            graphData8,
            graphData9,
          ],
          [
            "sm05",
            "sm15",
            "sm25",
            "sm35",
            "sm45",
            "sm55",
            "sm65",
            "sm75",
            "sm85",
          ]
        );
      default:
        var graphData1 = yaz("sm5");
        var graphData2 = yaz("sm15");
        var graphData3 = yaz("sm25");
        return StackedLine(
          [graphData1, graphData2, graphData3],
          ["sm05", "sm15", "sm25"]
        );
    }
  };
  const DrawStackedTSST = (data) => {
    switch (fieldData.izci_length) {
      case "60":
        var graphData1 = yaz("st5");
        var graphData2 = yaz("st15");
        var graphData3 = yaz("st25");
        var graphData4 = yaz("st35");
        var graphData5 = yaz("st45");
        var graphData6 = yaz("st55");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
          ],
          ["st05", "st15", "st25", "st35", "st45", "st55"]
        );
      case "90":
        var graphData1 = yaz("st5");
        var graphData2 = yaz("st15");
        var graphData3 = yaz("st25");
        var graphData4 = yaz("st35");
        var graphData5 = yaz("st45");
        var graphData6 = yaz("st55");
        var graphData7 = yaz("st65");
        var graphData8 = yaz("st75");
        var graphData9 = yaz("st85");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
            graphData7,
            graphData8,
            graphData9,
          ],
          [
            "st05",
            "st15",
            "st25",
            "st35",
            "st45",
            "st55",
            "st65",
            "st75",
            "st85",
          ]
        );
      default:
        var graphData1 = yaz("st5");
        var graphData2 = yaz("st15");
        var graphData3 = yaz("st25");
        return StackedLine(
          [graphData1, graphData2, graphData3],
          ["st05", "st15", "st25"]
        );
    }
  };
  const DrawStackedTSAT = (data) => {
    var graphData1 = yaz("at");
    return StackedLine([graphData1], ["at"]);
  };

  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start' })
  }

  return (
    <div ref={myRef} className="flex flex-col gap-3   mx-auto max-w-8xl  sm:px-6  max-sm:justify-center  lg:px-8  mb-64">
      <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fff">
        
        <div  onClick={()=>{
        var navi = document.getElementById("mobilBody");
        
        if(navi.classList.contains("mobilSmallBody")){
          return
        }else{
          executeScroll()
        }
      }} className="lg:top-3 lg:mb-0 lg:w-full sticky w-11/12 mx-auto top-10  z-50  mt-6 -mb-9 flex flex-row justify-between bg-white  bg-opacity-80 shadow-myShadow1 backdrop-blur-[2rem] rounded-2xl items-center h-16 py-11 px-4">
        <div>
          <p className="t font-medium  text-lg text-textDark ">
          {deviceid}
          </p>
          <p className=" font-medium  text-sm text-textLight -mt-1 ">
            Haymana Kalecik Field
          </p>
        </div>
        <div
          className="mt-4 w-10 hover:cursor-pointer h-10 max-sm:right-1  max-sm:pt-0 max-sm:mt-2 flex  items-center  "
          
        >
          <img
            src={SettingsIcon}
            className="w-10 h-10 bg-white p-2 rounded-lg shadow-miniMap"
          />
        </div>
      </div>
        <div className="flex flex-row max-sm:flex-col gap-3 ">
          <div className="flex flex-row max-sm:flex-col max-sm:w-full gap-3  w-1/2 ">
            {!full ? (
              <div className=" w-full flex max-sm:flex-col flex-row gap-4">
                <div className="w-full ">
                  <Skeleton width={"100%"} height={370} />
                </div>
              </div>
            ) : (
              // <div className="devicecardcontainer w-2/3  max-sm:w-full h-full bg-white rounded-sm ">
              //   <div className="flex flex-row w-full justify-between p-3">
              //     <div>
              //       <p className="text-lg font-bold ">Son Durum</p>
              //       <p>
              //         Ölçüm tarihi:
              //         {lastMeasureDate}
              //       </p>
              //     </div>
              //     <p className="font-bold text-green-500">online</p>
              //   </div>
              //   <div className="cubukcontainer flex flex-row pb-5 ">
              //     <div className="w-14  h-auto bg-izci  bg-no-repeat bg-right-top mr-3 "></div>
              //     <div>
              //       <div className="flex flex-row center items-center  gap-10  mb-4">
              //         <p className="text-lg font-bold text-gray-500 ">
              //           Ambient Temp
              //         </p>
              //         <p className="text-4xl">
              //           {tempcurrent[0]?.at
              //             ? Number(tempcurrent[0]?.at).toFixed(1)
              //             : 1}{" "}
              //           °C
              //         </p>
              //       </div>
              //       <div>
              //         <div className="flex flex-row justify-between font-bold text-slate-500 ">
              //           <p className="invisible">"""""</p>
              //           <p>*Soil Mois</p>
              //           <p>*Soil Temp</p>
              //         </div>
              //         {tempcurrent.map((e) => {
              //           return (
              //             e &&
              //             !e.at && (
              //               <div
              //                 key={Math.random()}
              //                 className="flex flex-row justify-between font-bold  "
              //               >
              //                 <p className="text-slate-500">
              //                   {e?.cm ? e.cm : 1}cm
              //                 </p>
              //                 <p>{e?.mois ? e.mois : 1}%</p>
              //                 <p>{e?.temp ? e.temp : 1}°C</p>
              //               </div>
              //             )
              //           );
              //         })}
              //       </div>
              //     </div>
              //   </div>
              // </div>
              <div className=" w-1/2 max-sm:w-full [&_.deviceid]:hidden  [&_.devicecardcontainer]:p-0 ">
                <DeviceCard data={fieldData} selectedDevice={true} />
                </div>
              
            )}

            {!full ? (
              <div className=" w-full flex max-sm:flex-col flex-row gap-4">
                <div className="w-full ">
                  <Skeleton width={"100%"} height={370} />
                </div>
              </div>
            ) : (
              // <div className="w-1/2 max-sm:w-full  ">
              //   <ReactWeather
              //     isLoading={isLoading}
              //     errorMessage={errorMessage}
              //     data={data}
              //     lang="en"
              //     locationLabel={fieldTown}
              //     unitsLabels={{ temperature: "°", windSpeed: "Km/h" }}
              //     showForecast={false}
              //   />
              // </div>
              <div className=" w-full   h-[31rem] max-sm:h-[34rem] -mb-52 ">
            <WeatherCard
              lat={fieldData?.coords?.lat}
              lon={fieldData?.coords?.lon}
              lang={i18n.language}
            />
          </div>
            )}
          </div>
          {!full ? (
            <div className=" w-1/2 max-sm:w-full flex max-sm:flex-col flex-row gap-4">
              <div className="w-full ">
                <Skeleton width={"100%"} height={370} />
              </div>
            </div>
          ) : (
            <div className=" w-1/2 max-sm:w-full  bg-white">
              <p className="relative z-20 text-lg font-bold  p-3 ">
              {t('soilmoisture')} (%){" "}
              </p>
              {DrawStackedTSSM(fieldData.tsdata)}
            </div>
          )}
        </div>
        {full ? (
          <div className="flex flex-row max-sm:flex-col gap-3 h-1/2">
            <div className=" w-1/2 max-sm:w-full  bg-white">
              <p className="relative z-20 text-lg font-bold  p-3 ">
                {t('soiltemperature')} (°C){" "}
              </p>
              {DrawStackedTSST(fieldData.tsdata)}
            </div>
            <div className=" w-1/2 max-sm:w-full  bg-white">
              <p className="relative z-20 text-lg font-bold  p-3 ">
              {t('airtemp')} (°C){" "}
              </p>
              {DrawStackedTSAT(fieldData.tsdata)}
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-row max-sm:flex-col gap-3">
            <div className="w-1/2 ">
              <Skeleton width={"100%"} height={370} />
            </div>
            <div className="w-1/2 ">
              <Skeleton width={"100%"} height={370} />
            </div>
          </div>
        )}
      </SkeletonTheme>
    </div>
  );
}

export default SelectedIzci;
function StackedLine(rawdatas, names) {
  let option = {
    // title: {
    //   show: false,
    //   text: ' asd',
    //   display: 'none',
    // },

    tooltip: {
      trigger: "axis",
    },
    legend: {
      type: "scroll",
      top: "0%",
    },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: false, readOnly: false },
        saveAsImage: {},
      },
      top: "12%",
      z: 100000,
    },
    grid: [
      {
        left: "3%",
        right: "5%",
        bottom: "8%",
        top: "10%",
        height: "75%",
        containLabel: true,
      },
    ],
    axisLine: {
      show: false,
    },
    xAxis: {
      type: "time",
    },
    yAxis: {
      splitLine: { show: false },
      splitArea: {
        show: true,
      },
      axisLine: { show: true },
      type: "value",
      max: function (value) {
        return value.max + 10;
      },
      min: function (value) {
        return value.min - 0;
      },
    },
    dataZoom: [
      {
        type: "slider",
        show: true,
        brushSelect: false,
        handleStyle: {
          borderColor: "rgba(0, 0, 0, 1)",
        },
        handleIcon: "roundRect",
        handleSize: "61%",
      },
    ],
    series: [],
    seriesCnt: "6",
    backgroundColor: "rgba(255,255,255,1)",
    titleColor: "#000000",
    subtitleColor: "#505050",
    textColorShow: false,
    textColor: "#333",
    markTextColor: "#ffffff",
    color: [
      "#0015FF",
      "#9900FF",
      "#00BBFF",
      "#FF8400",
      "#FF00BF",
      "#04C039",
      "#00E0A5",
      "#9983A7",
      "#CC0000",
    ],
    borderColor: "#ccc",
    borderWidth: "0",
    visualMapColor: ["#8a7ca8", "#e098c7", "#cceffa"],
    legendTextColor: "#000000",
    kColor: "#e098c7",
    kColor0: "transparent",
    kBorderColor: "#e098c7",
    kBorderColor0: "#8fd3e8",
    kBorderWidth: "2",
    lineWidth: "1",
    symbolSize: "0",
    symbol: "emptyRoundRect",
    symbolBorderWidth: "0",
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: "#aaaaaa",
    mapLabelColor: "#000",
    mapLabelColorE: "#ffffff",
    mapBorderColor: "#444",
    mapBorderColorE: "#444",
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: "#eee",
    mapAreaColorE: "#e098c7",
    axes: [
      {
        type: "all",
        name: "通用坐标轴",
        axisLineShow: true,
        axisLineColor: "#000000",
        axisTickShow: false,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#000000",
        splitLineShow: false,
        splitLineColor: ["#eeeeee", "#333333"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
      {
        type: "category",
        name: "类目坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: false,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "value",
        name: "数值坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "log",
        name: "对数坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "time",
        name: "时间坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: "#4100ff",
    toolboxEmphasisColor: "#000000",
    tooltipAxisColor: "#0013ff",
    tooltipAxisWidth: "3",
    timelineLineColor: "#8fd3e8",
    timelineLineWidth: 1,
    timelineItemColor: "#ff0000",
    timelineItemColorE: "#8fd3e8",
    timelineCheckColor: "#8fd3e8",
    timelineCheckBorderColor: "#8a7ca8",
    timelineItemBorderWidth: 1,
    timelineControlColor: "#8fd3e8",
    timelineControlBorderColor: "#8fd3e8",
    timelineControlBorderWidth: 0.5,
    timelineLabelColor: "#8fd3e8",
    datazoomBackgroundColor: "rgba(0,0,0,0)",
    datazoomDataColor: "rgba(255,255,255,0.3)",
    datazoomFillColor: "rgba(167,183,204,0.4)",
    datazoomHandleColor: "#a7b7cc",
    datazoomHandleWidth: "100",
    datazoomLabelColor: "#333",
  };
  rawdatas.map((e, index) => {
    option.series.push({
      name: names[index],
      symbol: "roundRect",
      type: "line",
      data: e,
      lineStyle: {
        width: 1,
      },
    });
  });
  return <ReactEcharts option={option} className=" z-10" opts={{ renderer: "canvas" }} lazyUpdate={true}/>;
}
