import { useState, useEffect } from "react";
import axios from 'axios';
import './style.css';
import SVGComponent from "./WeatherIcon";
import svgIcons from "./svgIcons";
import Damla from "./Asset/Frame.svg";
import Nem from "./Asset/NemB.svg";
import Ruzgar from "./Asset/RuzgarB.svg";
import Yagmur from "./Asset/YagmurB.svg";
//import LangData from "./Lang";
// https://api.openweathermap.org/data/2.5/forecast?lat=39.794305&lon=32.806588&appid=e44a00cd310ed9122a3d9c40b6817e98&units=metric&lang=tr
//e44a00cd310ed9122a3d9c40b6817e98

function App({ lat, lon, lang, unit }) {
  const [weatherData, setWeatherData] = useState(null);
  const [imagePath, setImagePath] = useState('');
  const [groupedWeatherData, setGroupedWeatherData] = useState({});

  useEffect(() => {
    const fetchWeatherData = async () => {
      const apiUrl = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&appid=649aab4a6e31bce8122c212ee9b978bf&units=${unit}&lang=${lang}`;

      try {
        const response = await axios.get(apiUrl);
        setImagePath(getWeatherImage(response.data.list[0].weather[0].main));
        setWeatherData(response.data);

        console.log("api yaniti :", response.data);

      } catch (error) {
        console.error("Hava durumu verileri alinirken hata oluştu:", error);
      }
    };

    fetchWeatherData();
  }, [lat, lon, lang, unit]);

  const getWeatherImage = (weatherCondition) => {
    const dailyWeatherImages = {
      Clouds: svgIcons.cloudy,
      Clear: svgIcons.sunny,
      Cloudy: svgIcons.cloudy,
      Foggy: svgIcons.fog,
      Rain: svgIcons.rain,
      Snow: svgIcons.snow,
      Wind: svgIcons.windySnow,
      Storm: svgIcons.thunderstorms,
    };
    return dailyWeatherImages[weatherCondition];
  };

  const getWeatherImageWeek = (weatherCondition) => {
    const weeklyWeatherImages = {
      Clouds: svgIcons.cloudy,
      Clear: svgIcons.sunny,
      Cloudy: svgIcons.cloudy,
      Foggy: svgIcons.fog,
      Rain: svgIcons.rain,
      Snow: svgIcons.snow,
      Wind: svgIcons.windySnow,
      Storm: svgIcons.thunderstorms,
    };
    return weeklyWeatherImages[weatherCondition];
  };

  const groupForecastsByDay = (forecasts) => {
    const groupedForecasts = {};
    forecasts.forEach((forecast) => {
      const date = new Date(forecast.dt_txt);
      const dayKey = date.toLocaleDateString({ weekday: "long" });
      if (!groupedForecasts[dayKey]) {
        groupedForecasts[dayKey] = [];
      }
      groupedForecasts[dayKey].push(forecast);
    });
    return groupedForecasts;
  };

  useEffect(() => {
    if (weatherData) {
      const forecastKeys = Object.keys(groupForecastsByDay(weatherData.list));
      const startIndex = 1;
      const endIndex = startIndex + 5;
      const filteredKeys = forecastKeys.slice(startIndex, endIndex);
      const filteredForecasts = filteredKeys.reduce((filtered, key) => {
        filtered[key] = groupForecastsByDay(weatherData.list)[key];
        return filtered;
      }, {});

      setGroupedWeatherData(filteredForecasts);
    }
  }, [weatherData]);

  return (
    <section className="Appcik">
      {weatherData && (
        <div className="containercik">

          <div className="today">
            <div className="container-left">

              <SVGComponent
                
                
                image={imagePath}
                width={"100%"}
                height={"100%"}
                viewBox={"-10 -10 55 55"}
              />

            </div>

            <div className="container-right">

              <div className="location">
                <p>{weatherData.city.name},</p>
              </div>
              <div className="history">
                <p>{new Date().toLocaleDateString(lang === 'tr' ? "tr-TR" : "en-US", {
                  weekday: "long",
                  day: "numeric",
                  month: "long"
                })}
                </p>
              </div>

              <hr style={{}} />

              <div className="deneme">
                <div className="temp">
                  <p>{Math.round(weatherData.list[0].main.temp)}°</p>
                </div>
                <div className="description">
                  <p>{weatherData.list[0].weather[0].description}</p>
                </div>
              </div>

            </div>

          </div>

          <div className="todays-details">

            <div className="dayDetails">
              <div className="det">

                <img className="details-img"
                  src={Nem}
                  alt=""
                />
                <p>{lang === 'tr' ? 'Nem' : 'Humidity'} </p>
              </div>

              <div className="details-img">
                <p className="sonuc">
                  {weatherData.list[0].main.humidity}
                </p>
                <p>%</p>
              </div>

            </div>
            <div className="dayDetails">
              <div className="det">
                <img className="details-img "
                  src={Ruzgar}
                  alt=""
                />
                <p>{lang === 'tr' ? 'Rüzgar' : 'Wind'} </p>
              </div>

              <div className="details-img">
                <p className="sonuc">
                  {Math.round(weatherData.list[0].wind.speed)}
                </p>
                <p>{lang === 'tr' ? 'km/s' : 'km/h'} </p>
              </div>
            </div>
            <div className="dayDetails">
              <div className="det">

                <img className="details-img"
                  src={Yagmur}
                  alt=""
                />
                <p>{lang === 'tr' ? 'Yağmur' : 'Rain'} </p>
              </div>

              <div className="details-img">
                <p className="sonuc">
                  {weatherData.list[0].pop ? `${Math.round(weatherData.list[0].pop * 100)}` : "0"}
                </p>
                <p>%</p>
              </div>

            </div>

          </div>

          <h1 className="title">{lang === 'tr' ? 'Bugün' : 'Today'} </h1>

          <div className="hourly-panel">
            {weatherData && (
              weatherData.list.slice(1, 6).map((hourlyForecast) => {
                const date = new Date(hourlyForecast.dt_txt);
                const hour = date.getHours();
                const weatherIcon = getWeatherImage(hourlyForecast.weather[0].main);

                return (
                  <div key={hourlyForecast.dt} className="hourly">
                    <p>{hour}:00</p>
                    <div className="hourly-icon">
                      <SVGComponent
                        
                        image={weatherIcon}
                        viewBox={'0 -3 38 37'}
                        width={"100%"}
                        height={"100%"}
                      />

                    </div>
                    <p>{Math.round(hourlyForecast.main.temp)}°</p>
                    {/* <p>{hourlyForecast.weather[0].description}</p> */}
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}

      {/* <div className="days-panel hourly-panel">
        {groupedWeatherData &&
          Object.keys(groupedWeatherData).slice(0, 4).map((dayKey) => {
            const dayForecasts = groupedWeatherData[dayKey];
            const firstForecast = dayForecasts[0];
            const date = new Date(firstForecast.dt_txt);
            const dayOfWeekShort = date.toLocaleDateString(lang === 'tr' ? "tr-TR" : "en-US", {
              weekday: "short"
            });
            const dayOfMonth = date.getDate();
            const weatherIcon = getWeatherImageWeek(firstForecast.weather[0].main);
            let maxTemp = Number.MIN_SAFE_INTEGER;
            let minTemp = Number.MAX_SAFE_INTEGER;

            dayForecasts.forEach((forecast) => {
              const temp = forecast.main.temp;
              if (temp > maxTemp) {
                maxTemp = temp;
              }
              if (temp < minTemp) {
                minTemp = temp;
              }
            });

            return (
              <div key={firstForecast.dt} className="hourly">
                <div className="weekDay">
                  <p>{dayOfWeekShort},</p>
                  <p>{dayOfMonth}</p>
                </div>
                <div className="hourly-icon">
                  <SVGComponent
                    
                    image={weatherIcon}
                    viewBox={'0 -3 38 37'}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>

                <div className="weekImg">
                  <p style={{ color: 'black' }}> {Math.round(maxTemp)}°</p>
                  <p> {Math.round(minTemp)}°</p>
                </div>

                <div className="weekImg">

                  <img
                    className="w-img"
                    src={Damla}
                    alt=""
                  />
                  <p>
                    {firstForecast.pop ? `${Math.round(firstForecast.pop * 100)}%` : `0%`}
                  </p>
                </div>
                <p>{firstForecast.weather[0].description}</p>

              </div>
            );
          }
          )}

      </div> */}

    </section>
  );
}

export default App;