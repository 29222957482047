import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  Popup,
  useMapEvent,
  FeatureGroup,
  Circle,
  Polygon,
  Tooltip,
  AttributionControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { map } from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import SetupFieldImg from "../assets/setupFieldImg.png";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ReactWeather, { useOpenWeather } from "react-open-weather";
import Devices from "../components/Devices";
import SettingsImg from "../assets/settings.png";
import NoDrawn from "../assets/nodrawn.svg";
import SettingsIcon from "..//assets/settingsIcon.svg";
import WeatherCard from "../components/WeatherCard/WeatherCard";
import Carousel from "../components/Carousel";
import alertLowBat from "../assets/alertLowBat.png";
import alertNoConnection from "../assets/ alertNoConnection.png";
import alertVana from "../assets/alertVana.png";
import { useTranslation } from "react-i18next";
// const markerIzciIcon = new L.Icon({
//   iconUrl: require("../assets/marker-w.png"),
//   iconSize: [40, 40],
//   iconAnchor: [17, 46], //[left/right, top/bottom]
//   popupAnchor: [0, -46], //[left/right, top/bottom]
// });
// const markerOcakIcon = new L.Icon({
//   iconUrl: require("../assets/marker.png"),
//   iconSize: [40, 40],
//   iconAnchor: [17, 46], //[left/right, top/bottom]
//   popupAnchor: [0, -46], //[left/right, top/bottom]
// });
const markerIzciIcon = new L.Icon({
  iconUrl: require("../assets/softpinkmarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});
const markerOcakIcon = new L.Icon({
  iconUrl: require("../assets/softorangemarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});
const markerOptimal = new L.Icon({
  iconUrl: require("../assets/softwhitemarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});
function SelectedField() {
  const { t, i18n } = useTranslation();
console.log("i18n",i18n.language)
  const myRef = useRef(null);
  useEffect(() => {
    var navi = document.getElementById("mobilBody");

    if (navi.classList.contains("mobilSmallBody")) {
      return;
    } else {
      myRef.current.scrollIntoView({ behavior: "instant", inline: "start" });
    }
  }, []);
  const navigate = useNavigate();
  const { fieldname } = useParams();
  const location = useLocation();
  const [fieldTown, setFieldTown] = useState();
  console.log("selecedasd : ", fieldname, location);
  var fieldData = location.state && location.state;
  var position = [];
  var positionset =
    fieldData?.geometry?.length > 0 &&
    fieldData.geometry.map((e) => {
      position.push([e.lat, e.lng]);
    });
  useEffect(() => {
    console.log("fielddatadır bu :", fieldData);
    const requestOptionsnom = {
      method: "GET",
    };
    fetch(
      `https://nominatim.openstreetmap.org/search?q=${fieldData?.center?.lat},${fieldData?.center?.lng}&addressdetails=1&format=json`,
      requestOptionsnom
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("coord nomi:", result);
        setFieldTown(`${result[0].address.town}, ${result[0].address.province} `);
      })
      .catch((err) => console.log("err : ", err));
  }, []);

  const { data, isLoading, errorMessage, locationLabel } = useOpenWeather({
    key: "649aab4a6e31bce8122c212ee9b978bf",
    // lat: 36.924754,
    // lon: 30.749551,
    lat: fieldData?.center?.lat,
    lon: fieldData?.center?.lng,
    lang: "tr",
    unit: "metric", // values are (metric, standard, imperial)
  });

  const slides = ["3", "4", "5"];
  const datatmp = [
    {
      geometry_type: "polygon",
      devices: [
        {
          qr: "",
          produced_at: 1689254699119,
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          ocak_hw_cfg: "",
          created_at: 1689943576759,
          ocak_hw_no: "",
          ocak_sw_ver: "",
          id: "0080E11500142F45",
          type: "ocak",
          coords: {
            lat: 39.6132623,
            lon: 32.6993244,
          },
          ocak_packet_ver: "",
        },
        {
          qr: "",
          produced_at: 1689145064600,
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          boru40Dolu: "",
          created_at: 1689946970374,
          izci_packet_ver: "002",
          type: "izci",
          izci_hw_group: "00",
          suBos: {
            sf35: "3.961016E8",
            sf45: "3.89185024E8",
            sf15: "3.92247168E8",
            sf25: "3.8725184E8",
            sf5: "4.07067488E8",
            sf55: "3.9927248E8",
            st25: "25.6",
            at: "26.1",
            st15: "25.7",
            st45: "25.7",
            st35: "25.3",
            st5: "25.8",
            st55: "25.7",
          },
          boru40Bos: "",
          havaDolu: "",
          boru32Dolu: "",
          izci_length: "60",
          izci_sw_ver: "000",
          havaBos: {
            sf35: "4.25412864E8",
            sf45: "4.25218464E8",
            sf15: "4.29018688E8",
            sf25: "4.16923296E8",
            sf5: "4.2954416E8",
            sf55: "4.341552E8",
            st25: "25.8",
            at: "25.8",
            st15: "25.8",
            st45: "26.0",
            st35: "25.5",
            st5: "26.1",
            st55: "26.0",
          },
          suDolu: "",
          izci_hw_rev: "000",
          izci_hw_ver: "000",
          id: "0080E115051CF6AE",
          izci_hw_cfg: "001",
          coords: {
            lat: 39.6091983,
            lon: 32.6970026,
          },
          boru32Bos: "",
        },
        {
          qr: "",
          produced_at: 1689174725534,
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          created_at: 1689947470841,
          izci_packet_ver: "",
          type: "izci",
          izci_hw_group: "",
          suBos: {
            sf35: "3.88244032E8",
            sf45: "3.96371136E8",
            sf15: "3.80101568E8",
            sf25: "3.93427456E8",
            sf5: "3.97359968E8",
            sf55: "3.92937984E8",
            st25: "25.3",
            at: "26.5",
            st15: "26.5",
            st45: "25.1",
            st35: "25.3",
            st5: "26.1",
            st55: "25.2",
          },
          izci_length: "60",
          izci_sw_ver: "",
          havaBos: {
            sf35: "4.21897088E8",
            sf45: "4.28346272E8",
            sf15: "4.14918848E8",
            sf25: "4.29255200E8",
            sf5: "4.22244800E8",
            sf55: "4.22858368E8",
            st25: "26.0",
            at: "26.1",
            st15: "28.2",
            st45: "25.7",
            st35: "26.1",
            st5: "27.3",
            st55: "25.8",
          },
          izci_hw_rev: "",
          izci_hw_ver: "",
          id: "0080E115051CED22",
          izci_hw_cfg: "",
          coords: {
            lat: 39.6082487,
            lon: 32.698879,
          },
        },
        {
          qr: "",
          produced_at: 1689174777925,
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          created_at: 1689947813204,
          izci_packet_ver: "",
          type: "izci",
          izci_hw_group: "",
          suBos: {
            sf35: "3.86397984E8",
            sf45: "3.90926624E8",
            sf15: "3.88128128E8",
            sf25: "3.86852288E8",
            sf5: "3.94644608E8",
            sf55: "3.88531712E8",
            st25: "25.8",
            at: "26.1",
            st15: "25.8",
            st45: "25.6",
            st35: "25.6",
            st5: "25.3",
            st55: "25.5",
          },
          izci_length: "60",
          izci_sw_ver: "",
          havaBos: {
            sf35: "4.17219136E8",
            sf45: "4.24206976E8",
            sf15: "4.22156416E8",
            sf25: "4.26016032E8",
            sf5: "4.202232E8",
            sf55: "4.20847264E8",
            st25: "26.1",
            at: "25.8",
            st15: "26.0",
            st45: "25.7",
            st35: "25.6",
            st5: "25.5",
            st55: "25.6",
          },
          izci_hw_rev: "",
          izci_hw_ver: "",
          id: "0080E1150505028E",
          izci_hw_cfg: "",
          coords: {
            lat: 39.6077291,
            lon: 32.7006762,
          },
        },
        {
          qr: "",
          produced_at: 1689174741693,
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          created_at: 1689949082382,
          izci_packet_ver: "",
          type: "izci",
          izci_hw_group: "",
          suBos: {
            sf35: "3.85243872E8",
            sf45: "3.86186912E8",
            sf15: "3.8773632E8",
            sf25: "3.83558944E8",
            sf5: "3.98081056E8",
            sf55: "3.8409376E8",
            st25: "25.5",
            at: "25.8",
            st15: "25.8",
            st45: "25.1",
            st35: "25.2",
            st5: "37.6",
            st55: "25.5",
          },
          izci_length: "60",
          izci_sw_ver: "",
          havaBos: {
            sf35: "4.16307552E8",
            sf45: "4.14708256E8",
            sf15: "4.18142528E8",
            sf25: "4.23906272E8",
            sf5: "4.25198944E8",
            sf55: "4.13248192E8",
            st25: "25.6",
            at: "25.9",
            st15: "25.7",
            st45: "25.2",
            st35: "25.5",
            st5: "25.8",
            st55: "25.6",
          },
          izci_hw_rev: "",
          izci_hw_ver: "",
          id: "0080E115051CE6C3",
          izci_hw_cfg: "",
          coords: {
            lat: 39.609122,
            lon: 32.699518,
          },
        },
      ],
      center: {
        lng: 32.69920802851288,
        lat: 39.60842953347683,
      },
      name: "Haymana Mısır",
      created_at: 1689338433425,
      geometry: [
        {
          lng: 32.700097560882575,
          lat: 39.60618413842161,
        },
        {
          lng: 32.69898176193238,
          lat: 39.60608494668563,
        },
        {
          lng: 32.69819855690003,
          lat: 39.60623373423631,
        },
        {
          lng: 32.69756555557252,
          lat: 39.606564372093175,
        },
        {
          lng: 32.69731879234315,
          lat: 39.60691980602844,
        },
        {
          lng: 32.69673943519593,
          lat: 39.607159516256914,
        },
        {
          lng: 32.696363925933845,
          lat: 39.60764720002222,
        },
        {
          lng: 32.69622445106507,
          lat: 39.6084159149151,
        },
        {
          lng: 32.696331739425666,
          lat: 39.6091184963308,
        },
        {
          lng: 32.696610689163215,
          lat: 39.60968055633014,
        },
        {
          lng: 32.697383165359504,
          lat: 39.610317001114836,
        },
        {
          lng: 32.69789814949036,
          lat: 39.610548434131445,
        },
        {
          lng: 32.698595523834236,
          lat: 39.61070547752354,
        },
        {
          lng: 32.69971132278443,
          lat: 39.61068068122216,
        },
        {
          lng: 32.70053744316102,
          lat: 39.610498841407306,
        },
        {
          lng: 32.70104169845582,
          lat: 39.610184753329435,
        },
        {
          lng: 32.701631784439094,
          lat: 39.609754946282266,
        },
        {
          lng: 32.70205020904542,
          lat: 39.60914329319167,
        },
        {
          lng: 32.70218968391419,
          lat: 39.60844897772981,
        },
        {
          lng: 32.70205020904542,
          lat: 39.60770506057999,
        },
        {
          lng: 32.70165324211121,
          lat: 39.607043794181266,
        },
        {
          lng: 32.70095586776734,
          lat: 39.60649824464809,
        },
      ],
      zoom: 17,
      id: "d405691e-0cba-4876-8264-d1b97a6c254c",
    },
    {
      geometry_type: "polygon",
      devices: [
        {
          izci_hw_group: "",
          izci_length: "",
          qr: "",
          created_at: 1694518183734,
          izci_packet_ver: "",
          status: "off",
          izci_hw_cfg: "",
          izci_hw_rev: "",
          izci_hw_ver: "",
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          watering: "210",
          produced_at: 1694157774766,
          izci_sw_ver: "",
          user_deleted_at: 1694506895153,
          coords: {
            lat: 40.1114785,
            lon: 33.4295398,
          },
          id: "0080E1150504F89C",
          event_date: "1696953673135",
          type: "vana",
        },
        {
          izci_hw_group: "",
          izci_length: "",
          qr: "",
          created_at: 1694700391663,
          izci_packet_ver: "",
          izci_hw_cfg: "",
          izci_hw_rev: "",
          izci_hw_ver: "",
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          produced_at: 1692965173634,
          izci_sw_ver: "",
          user_deleted_at: 1694700364224,
          coords: {
            lat: 38,
            lon: 32,
          },
          id: "0080E115050525B7",
          type: "tof",
        },
        {
          izci_hw_group: "00",
          izci_length: "60",
          qr: "",
          created_at: 1691495401615,
          izci_packet_ver: "003",
          status: "",
          izci_hw_cfg: "001",
          izci_hw_rev: "0",
          izci_hw_ver: "2",
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          produced_at: 1691406749186,
          izci_sw_ver: "006",
          coords: {
            lat: 40.1104934,
            lon: 33.4278534,
          },
          havaBos: {
            sf15: "2.88909024E8",
            sf55: "2.97129792E8",
            vbus5: "0.0",
            sc35: "0.0",
            sm35: "35.43",
            scgb45: "10.121",
            vbusgb15: "3.9",
            st45: "28.3",
            sf_sd45: "23.0",
            event_date: "1.691483124E12",
            vbus15: "0.0",
            sm5: "33.76",
            vbusgb55: "3.9",
            vbus55: "0.0",
            sf_sd5: "22.0",
            sf45: "2.96775456E8",
            sc25: "0.0",
            sm25: "35.28",
            sf_sd25: "23.0",
            scgb35: "9.878",
            ocak_id: "0080E115050873E4",
            vbusgb5: "3.9",
            vbusgb25: "3.9",
            at: "27.5",
            sf_sd35: "12.0",
            st35: "28.2",
            vbus45: "0.0",
            sf35: "2.90624192E8",
            sc15: "0.0",
            sf5: "2.92010048E8",
            sc55: "0.0",
            tbv: "3.8",
            sf_sd15: "22.0",
            sm15: "35.87",
            error: "0.0",
            scgb25: "10.101",
            sm55: "33.76",
            st25: "28.0",
            received_date: "1.691483122019E12",
            vbus35: "0.0",
            vbusgb35: "3.9",
            seq: "1",
            rssi: "-70.0",
            sf25: "2.91197216E8",
            sc5: "0.0",
            sc45: "0.0",
            btv: "3.627",
            scgb5: "10.076",
            scgb55: "10.055",
            sm45: "33.85",
            scgb15: "10.016",
            tc: "0.08",
            st15: "27.5",
            st5: "27.7",
            sf_sd55: "14.0",
            snr: "8.0",
            st55: "28.0",
            vbus25: "0.0",
            time: "2023-08-08T08:25:22.020Z",
            vbusgb45: "3.9",
          },
          id: "0080E1150504FEAF",
          suBos: {
            sf15: "2.61369248E8",
            sf55: "2.675032E8",
            vbus5: "0.0",
            sc35: "0.0",
            sm35: "7.88",
            scgb45: "9.54",
            vbusgb15: "3.9",
            st45: "27.2",
            sf_sd45: "28.0",
            event_date: "1.691483367E12",
            vbus15: "0.0",
            sm5: "11.49",
            vbusgb55: "3.9",
            vbus55: "0.0",
            sf_sd5: "21.0",
            sf45: "2.6828184E8",
            sc25: "0.0",
            sm25: "10.49",
            sf_sd25: "16.0",
            scgb35: "9.501",
            ocak_id: "0080E115050873E4",
            vbusgb5: "3.9",
            vbusgb25: "3.9",
            at: "28.0",
            sf_sd35: "155.0",
            st35: "27.3",
            vbus45: "0.0",
            sf35: "2.70818784E8",
            sc15: "0.0",
            sf5: "2.6760992E8",
            sc55: "0.0",
            tbv: "3.8",
            sf_sd15: "48.0",
            sm15: "11.03",
            error: "0.0",
            scgb25: "9.631",
            sm55: "11.49",
            st25: "27.5",
            received_date: "1.691483321943E12",
            vbus35: "0.0",
            vbusgb35: "3.9",
            seq: "1",
            rssi: "-70.0",
            sf25: "2.64781216E8",
            sc5: "0.0",
            sc45: "0.0",
            btv: "3.598",
            scgb5: "9.56",
            scgb55: "9.451",
            sm45: "11.07",
            scgb15: "9.42",
            tc: "0.08",
            st15: "27.1",
            st5: "27.6",
            sf_sd55: "36.0",
            snr: "7.0",
            st55: "27.1",
            vbus25: "0.0",
            time: "2023-08-08T08:28:41.943Z",
            vbusgb45: "3.9",
          },
          group_name: "Kalecik Üzüm",
          type: "izci",
        },
        {
          izci_hw_group: "00",
          izci_length: "60",
          qr: "",
          created_at: 1691496353750,
          izci_packet_ver: "003",
          izci_hw_cfg: "001",
          izci_hw_rev: "0",
          izci_hw_ver: "2",
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          produced_at: 1691406768066,
          izci_sw_ver: "006",
          coords: {
            lat: 40.1102418,
            lon: 33.4273446,
          },
          havaBos: {
            sf15: "2.92979072E8",
            sf55: "2.90134752E8",
            vbus5: "0.0",
            sc35: "0.0",
            sm35: "34.57",
            scgb45: "10.023",
            vbusgb15: "3.9",
            st45: "27.8",
            sf_sd45: "15.0",
            event_date: "1.69148442E12",
            vbus15: "0.0",
            sm5: "35.55",
            vbusgb55: "3.9",
            vbus55: "0.0",
            sf_sd5: "23.0",
            sf45: "2.89556288E8",
            sc25: "0.0",
            sm25: "35.19",
            sf_sd25: "21.0",
            scgb35: "10.078",
            ocak_id: "0080E115050873E4",
            vbusgb5: "3.9",
            vbusgb25: "3.9",
            at: "27.5",
            sf_sd35: "22.0",
            st35: "27.6",
            vbus45: "0.0",
            sf35: "2.93961408E8",
            sc15: "0.0",
            sf5: "2.95476448E8",
            sc55: "0.0",
            tbv: "3.8",
            sf_sd15: "12.0",
            sm15: "34.82",
            error: "0.0",
            scgb25: "10.093",
            sm55: "35.55",
            st25: "28.1",
            received_date: "1.691484418754E12",
            vbus35: "0.0",
            vbusgb35: "3.9",
            seq: "1",
            rssi: "-73.0",
            sf25: "2.9155888E8",
            sc5: "0.0",
            sc45: "0.0",
            btv: "3.569",
            scgb5: "10.125",
            scgb55: "9.958",
            sm45: "35.7",
            scgb15: "10.12",
            tc: "0.073",
            st15: "28.4",
            st5: "28.1",
            sf_sd55: "22.0",
            snr: "6.0",
            st55: "28.1",
            vbus25: "0.0",
            time: "2023-08-08T08:46:58.754Z",
            vbusgb45: "3.9",
          },
          id: "0080E115051CE95E",
          suBos: {
            sf15: "2.6565664E8",
            sf55: "2.68342032E8",
            vbus5: "0.0",
            sc35: "0.0",
            sm35: "41.28",
            scgb45: "9.471",
            vbusgb15: "3.9",
            st45: "28.1",
            sf_sd45: "20.0",
            event_date: "1.691484477E12",
            vbus15: "0.0",
            sm5: "41.15",
            vbusgb55: "3.9",
            vbus55: "0.0",
            sf_sd5: "72.0",
            sf45: "2.63586928E8",
            sc25: "0.0",
            sm25: "42.65",
            sf_sd25: "37.0",
            scgb35: "9.598",
            ocak_id: "0080E115050873E4",
            vbusgb5: "3.9",
            vbusgb25: "3.9",
            at: "28.0",
            sf_sd35: "33.0",
            st35: "27.9",
            vbus45: "0.0",
            sf35: "2.67851472E8",
            sc15: "0.0",
            sf5: "2.70486944E8",
            sc55: "0.0",
            tbv: "3.8",
            sf_sd15: "85.0",
            sm15: "41.84",
            error: "0.0",
            scgb25: "9.453",
            sm55: "41.15",
            st25: "28.0",
            received_date: "1.691484430473E12",
            vbus35: "0.0",
            vbusgb35: "3.9",
            seq: "1",
            rssi: "-69.0",
            sf25: "2.6251744E8",
            sc5: "0.0",
            sc45: "0.0",
            btv: "3.561",
            scgb5: "9.635",
            scgb55: "9.528",
            sm45: "42.37",
            scgb15: "9.555",
            tc: "0.073",
            st15: "28.3",
            st5: "28.1",
            sf_sd55: "86.0",
            snr: "7.0",
            st55: "28.3",
            vbus25: "0.0",
            time: "2023-08-08T08:47:10.473Z",
            vbusgb45: "3.9",
          },
          group_name: "Kalecik Üzüm",
          type: "izci",
        },
        {
          izci_hw_group: "00",
          izci_length: "60",
          qr: "",
          created_at: 1691497286385,
          izci_packet_ver: "003",
          izci_hw_cfg: "001",
          izci_hw_rev: "0",
          izci_hw_ver: "2",
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          produced_at: 1691406784547,
          izci_sw_ver: "006",
          coords: {
            lat: 40.1096622,
            lon: 33.4292973,
          },
          havaBos: {
            sf15: "2.97331712E8",
            sf55: "2.90972608E8",
            vbus5: "0.0",
            sc35: "0.0",
            sm35: "35.17",
            scgb45: "10.085",
            vbusgb15: "3.9",
            st45: "28.5",
            sf_sd45: "23.0",
            event_date: "1.69148418E12",
            vbus15: "0.0",
            sm5: "35.34",
            vbusgb55: "3.9",
            vbus55: "0.0",
            sf_sd5: "22.0",
            sf45: "2.90727072E8",
            sc25: "0.0",
            sm25: "35.18",
            sf_sd25: "9.0",
            scgb35: "10.045",
            ocak_id: "0080E115050873E4",
            vbusgb5: "3.9",
            vbusgb25: "3.9",
            at: "27.5",
            sf_sd35: "22.0",
            st35: "28.1",
            vbus45: "0.0",
            sf35: "2.91617536E8",
            sc15: "0.0",
            sf5: "2.96212928E8",
            sc55: "0.0",
            tbv: "3.9",
            sf_sd15: "21.0",
            sm15: "33.7",
            error: "0.0",
            scgb25: "10.016",
            sm55: "35.34",
            st25: "27.8",
            received_date: "1.691484134031E12",
            vbus35: "0.0",
            vbusgb35: "3.9",
            seq: "1",
            rssi: "-68.0",
            sf25: "2.91595648E8",
            sc5: "0.0",
            sc45: "0.0",
            btv: "3.569",
            scgb5: "10.216",
            scgb55: "10.11",
            sm45: "35.4",
            scgb15: "10.168",
            tc: "0.081",
            st15: "27.9",
            st5: "28.0",
            sf_sd55: "17.0",
            snr: "7.0",
            st55: "28.6",
            vbus25: "0.0",
            time: "2023-08-08T08:42:14.031Z",
            vbusgb45: "3.9",
          },
          id: "0080E115051D00FC",
          suBos: {
            sf15: "2.6692984E8",
            sf55: "2.63069568E8",
            vbus5: "0.0",
            sc35: "0.0",
            sm35: "9.35",
            scgb45: "9.551",
            vbusgb15: "3.9",
            st45: "26.6",
            sf_sd45: "31.0",
            event_date: "1.691484305E12",
            vbus15: "0.0",
            sm5: "11.09",
            vbusgb55: "3.9",
            vbus55: "0.0",
            sf_sd5: "24.0",
            sf45: "2.6392216E8",
            sc25: "0.0",
            sm25: "9.96",
            sf_sd25: "112.0",
            scgb35: "9.505",
            ocak_id: "0080E115050873E4",
            vbusgb5: "3.9",
            vbusgb25: "3.9",
            at: "28.5",
            sf_sd35: "99.0",
            st35: "26.6",
            vbus45: "0.0",
            sf35: "2.68016928E8",
            sc15: "0.0",
            sf5: "2.69052832E8",
            sc55: "0.0",
            tbv: "3.9",
            sf_sd15: "37.0",
            sm15: "11.78",
            error: "0.0",
            scgb25: "9.49",
            sm55: "11.09",
            st25: "26.6",
            received_date: "1.691484258952E12",
            vbus35: "0.0",
            vbusgb35: "3.9",
            seq: "1",
            rssi: "-68.0",
            sf25: "2.66473984E8",
            sc5: "0.0",
            sc45: "0.0",
            btv: "3.561",
            scgb5: "9.615",
            scgb55: "9.418",
            sm45: "10.66",
            scgb15: "9.523",
            tc: "0.078",
            st15: "26.9",
            st5: "27.3",
            sf_sd55: "20.0",
            snr: "7.0",
            st55: "26.9",
            vbus25: "0.0",
            time: "2023-08-08T08:44:18.952Z",
            vbusgb45: "3.9",
          },
          group_name: "Kalecik Üzüm",
          type: "izci",
        },
        {
          izci_hw_group: "00",
          izci_length: "60",
          qr: "",
          created_at: 1691498162563,
          izci_packet_ver: "003",
          izci_hw_cfg: "001",
          izci_hw_rev: "0",
          izci_hw_ver: "2",
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          produced_at: 1691406814601,
          izci_sw_ver: "006",
          coords: {
            lat: 40.1104335,
            lon: 33.4291117,
          },
          id: "0080E11505053CAD",
          group_name: "Kalecik Üzüm",
          type: "izci",
        },
        {
          qr: "",
          created_at: 1691506655570,
          ocak_packet_ver: "",
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          produced_at: 1689348761391,
          user_deleted_at: 1694095900822,
          coords: {
            lat: 40.11154134002288,
            lon: 33.42796052387712,
          },
          ocak_hw_cfg: "",
          id: "0080E115050873E4",
          ocak_hw_no: "",
          ocak_sw_ver: "",
          type: "ocak",
        },
        {
          izci_hw_group: "",
          izci_length: "60",
          qr: "",
          created_at: 1694517870673,
          izci_packet_ver: "003",
          status: "ok",
          izci_hw_cfg: "001",
          izci_hw_rev: "0",
          izci_hw_ver: "2",
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          produced_at: 1692189946545,
          pendingConfiguration: "",
          izci_sw_ver: "",
          user_deleted_at: 1694506586046,
          coords: {
            lat: 40.1114365,
            lon: 33.4295462,
          },
          havaBos: {
            sf15: "2.87960096E8",
            sf55: "2.92167104E8",
            vbus5: "0.0",
            sc35: "0.0",
            sm35: "35.46",
            scgb45: "10.031",
            vbusgb15: "3.9",
            st45: "30.5",
            sf_sd45: "23.0",
            event_date: "1.692374091E12",
            vbus15: "0.0",
            sm5: "35.98",
            vbusgb55: "3.9",
            vbus55: "0.0",
            sf_sd5: "22.0",
            sf45: "2.92700192E8",
            sc25: "0.0",
            sm25: "33.98",
            sf_sd25: "23.0",
            scgb35: "10.0",
            ocak_id: "0080E115050864F1",
            vbusgb5: "3.9",
            vbusgb25: "3.9",
            at: "31.5",
            sf_sd35: "6.0",
            st35: "30.6",
            vbus45: "0.0",
            sf35: "2.90477952E8",
            sc15: "0.0",
            sf5: "2.8847424E8",
            sc55: "0.0",
            tbv: "3.8",
            sf_sd15: "22.0",
            sm15: "36.11",
            error: "0.0",
            scgb25: "10.043",
            sm55: "35.03",
            st25: "30.8",
            received_date: "1.6923740925E12",
            vbus35: "0.0",
            vbusgb35: "3.9",
            seq: "1",
            rssi: "-43.0",
            sf25: "2.96243616E8",
            sc5: "0.0",
            sc45: "0.0",
            btv: "3.529",
            scgb5: "10.073",
            scgb55: "10.026",
            sm45: "34.89",
            scgb15: "10.023",
            tc: "0.083",
            st15: "31.1",
            st5: "31.6",
            sf_sd55: "20.0",
            snr: "7.0",
            st55: "30.3",
            vbus25: "0.0",
            time: "2023-08-18T15:54:52.500Z",
            vbusgb45: "3.9",
          },
          id: "0080E115051D0322",
          suBos: {
            sf15: "2.55785168E8",
            sf55: "2.56790768E8",
            vbus5: "0.0",
            sc35: "0.0",
            sm35: "9.85",
            scgb45: "9.4",
            vbusgb15: "3.9",
            st45: "30.2",
            sf_sd45: "151.0",
            event_date: "1.692374135E12",
            vbus15: "0.0",
            sm5: "11.67",
            vbusgb55: "3.9",
            vbus55: "0.0",
            sf_sd5: "29.0",
            sf45: "2.57795632E8",
            sc25: "0.0",
            sm25: "14.93",
            sf_sd25: "36.0",
            scgb35: "9.455",
            ocak_id: "0080E115050864F1",
            vbusgb5: "3.9",
            vbusgb25: "3.9",
            at: "31.5",
            sf_sd35: "18.0",
            st35: "30.3",
            vbus45: "0.0",
            sf35: "2.65745296E8",
            sc15: "0.0",
            sf5: "2.59394624E8",
            sc55: "0.0",
            tbv: "3.8",
            sf_sd15: "91.0",
            sm15: "12.94",
            error: "0.0",
            scgb25: "9.255",
            sm55: "13.99",
            st25: "30.5",
            received_date: "1.692374137442E12",
            vbus35: "0.0",
            vbusgb35: "3.9",
            seq: "1",
            rssi: "-58.0",
            sf25: "2.57872128E8",
            sc5: "0.0",
            sc45: "0.0",
            btv: "3.549",
            scgb5: "9.46",
            scgb55: "9.26",
            sm45: "13.77",
            scgb15: "9.321",
            tc: "0.081",
            st15: "30.7",
            st5: "31.1",
            sf_sd55: "32.0",
            snr: "7.0",
            st55: "30.0",
            vbus25: "0.0",
            time: "2023-08-18T15:55:37.442Z",
            vbusgb45: "3.9",
          },
          group_name: "Kalecik Üzüm",
          type: "izci",
        },
        "0080E11500142F56",
        {
          izci_hw_group: "",
          izci_length: "60",
          qr: "",
          created_at: 1695030438952,
          izci_packet_ver: "",
          status: "",
          izci_hw_cfg: "001",
          izci_hw_rev: "0",
          izci_hw_ver: "2",
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          produced_at: 1691406945960,
          izci_sw_ver: "",
          user_deleted_at: 1694506591206,
          coords: {
            lat: 40.110106,
            lon: 33.4289521,
          },
          havaBos: {
            sf15: "2.91574848E8",
            sf55: "2.92462624E8",
            vbus5: "0.0",
            sc35: "0.0",
            sm35: "34.76",
            scgb45: "10.026",
            vbusgb15: "3.9",
            st45: "29.8",
            sf_sd45: "17.0",
            event_date: "1.692357928E12",
            vbus15: "0.0",
            sm5: "34.56",
            vbusgb55: "3.9",
            vbus55: "0.0",
            sf_sd5: "22.0",
            sf45: "2.90051552E8",
            sc25: "0.0",
            sm25: "35.65",
            sf_sd25: "18.0",
            scgb35: "10.083",
            ocak_id: "0080E115050864F1",
            vbusgb5: "3.9",
            vbusgb25: "3.9",
            at: "29.4",
            sf_sd35: "23.0",
            st35: "29.7",
            vbus45: "0.0",
            sf35: "2.932336E8",
            sc15: "0.0",
            sf5: "2.93988864E8",
            sc55: "0.0",
            tbv: "3.9",
            sf_sd15: "23.0",
            sm15: "35.18",
            error: "0.0",
            scgb25: "10.065",
            sm55: "34.95",
            st25: "29.8",
            received_date: "1.692357928342E12",
            vbus35: "0.0",
            vbusgb35: "3.9",
            seq: "1",
            rssi: "-45.0",
            sf25: "2.89744576E8",
            sc5: "0.0",
            sc45: "0.0",
            btv: "3.562",
            scgb5: "10.053",
            scgb55: "10.07",
            sm45: "35.57",
            scgb15: "10.021",
            tc: "0.085",
            st15: "29.7",
            st5: "29.6",
            sf_sd55: "9.0",
            snr: "6.0",
            st55: "29.9",
            vbus25: "0.0",
            time: "2023-08-18T11:25:28.342Z",
            vbusgb45: "3.9",
          },
          id: "0080E11505053653",
          suBos: {
            sf15: "2.58863344E8",
            sf55: "2.59658E8",
            vbus5: "0.0",
            sc35: "0.0",
            sm35: "9.42",
            scgb45: "9.688",
            vbusgb15: "3.9",
            st45: "27.9",
            sf_sd45: "43.0",
            event_date: "1.692358089E12",
            vbus15: "0.0",
            sm5: "13.67",
            vbusgb55: "3.9",
            vbus55: "0.0",
            sf_sd5: "30.0",
            sf45: "2.68637664E8",
            sc25: "0.0",
            sm25: "12.19",
            sf_sd25: "39.0",
            scgb35: "9.421",
            ocak_id: "0080E115050864F1",
            vbusgb5: "3.9",
            vbusgb25: "3.9",
            at: "31.6",
            sf_sd35: "65.0",
            st35: "27.9",
            vbus45: "0.0",
            sf35: "2.69307808E8",
            sc15: "0.0",
            sf5: "2.59168752E8",
            sc55: "0.0",
            tbv: "3.9",
            sf_sd15: "26.0",
            sm15: "12.97",
            error: "2.0",
            scgb25: "9.455",
            sm55: "12.96",
            st25: "28.0",
            received_date: "1.692358089786E12",
            vbus35: "0.0",
            vbusgb35: "3.9",
            seq: "1",
            rssi: "-52.0",
            sf25: "2.59200128E8",
            sc5: "0.0",
            sc45: "0.0",
            btv: "3.56",
            scgb5: "9.321",
            scgb55: "9.253",
            sm45: "8.54",
            scgb15: "9.295",
            tc: "0.085",
            st15: "27.9",
            st5: "28.1",
            sf_sd55: "192.0",
            snr: "7.0",
            st55: "27.2",
            vbus25: "0.0",
            time: "2023-08-18T11:28:09.786Z",
            vbusgb45: "3.9",
          },
          group_name: "Kalecik Üzüm",
          type: "izci",
        },
        {
          izci_hw_group: "",
          izci_length: "60",
          qr: "",
          created_at: 1695031172860,
          izci_packet_ver: "",
          status: "",
          izci_hw_cfg: "001",
          izci_hw_rev: "0",
          izci_hw_ver: "2",
          owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
          produced_at: 1692189910599,
          izci_sw_ver: "",
          user_deleted_at: 1694506588506,
          coords: {
            lat: 40.1094722,
            lon: 33.4292974,
          },
          havaBos: {
            sf15: "2.93789536E8",
            sf55: "2.8909808E8",
            vbus5: "0.0",
            sc35: "0.0",
            sm35: "34.17",
            scgb45: "9.946",
            vbusgb15: "3.5",
            st45: "31.3",
            sf_sd45: "22.0",
            event_date: "1.692361655E12",
            vbus15: "0.0",
            sm5: "34.09",
            vbusgb55: "3.5",
            vbus55: "0.0",
            sf_sd5: "23.0",
            sf45: "2.9471824E8",
            sc25: "0.0",
            sm25: "35.18",
            sf_sd25: "15.0",
            scgb35: "10.006",
            ocak_id: "0080E115050864F1",
            vbusgb5: "3.5",
            vbusgb25: "3.5",
            at: "31.4",
            sf_sd35: "9.0",
            st35: "31.9",
            vbus45: "0.0",
            sf35: "2.95498912E8",
            sc15: "0.0",
            sf5: "2.95812096E8",
            sc55: "0.0",
            tbv: "3.6",
            sf_sd15: "17.0",
            sm15: "34.61",
            error: "0.0",
            scgb25: "9.928",
            sm55: "35.82",
            st25: "32.4",
            received_date: "1.692361697516E12",
            vbus35: "0.0",
            vbusgb35: "3.5",
            seq: "1",
            rssi: "-80.0",
            sf25: "2.915832E8",
            sc5: "0.0",
            sc45: "0.0",
            btv: "3.618",
            scgb5: "10.041",
            scgb55: "9.808",
            sm45: "34.38",
            scgb15: "10.065",
            tc: "0.083",
            st15: "32.3",
            st5: "35.2",
            sf_sd55: "22.0",
            snr: "5.0",
            st55: "31.4",
            vbus25: "0.0",
            time: "2023-08-18T12:28:17.516Z",
            vbusgb45: "3.5",
          },
          id: "0080E115051CFA9F",
          suBos: {
            sf15: "2.576088E8",
            sf55: "2.55963552E8",
            vbus5: "0.0",
            sc35: "0.0",
            sm35: "12.42",
            scgb45: "9.273",
            vbusgb15: "3.5",
            st45: "31.0",
            sf_sd45: "100.0",
            event_date: "1.692361773E12",
            vbus15: "0.0",
            sm5: "12.3",
            vbusgb55: "3.5",
            vbus55: "0.0",
            sf_sd5: "165.0",
            sf45: "2.6135688E8",
            sc25: "0.0",
            sm25: "12.85",
            sf_sd25: "89.0",
            scgb35: "9.331",
            ocak_id: "0080E115050864F1",
            vbusgb5: "3.5",
            vbusgb25: "3.5",
            at: "31.6",
            sf_sd35: "137.0",
            st35: "31.3",
            vbus45: "0.0",
            sf35: "2.63685904E8",
            sc15: "0.0",
            sf5: "2.6425896E8",
            sc55: "0.0",
            tbv: "3.5",
            sf_sd15: "39.0",
            sm15: "14.22",
            error: "0.0",
            scgb25: "9.191",
            sm55: "13.26",
            st25: "31.7",
            received_date: "1.692361773637E12",
            vbus35: "0.0",
            vbusgb35: "3.5",
            seq: "1",
            rssi: "-88.0",
            sf25: "2.59159344E8",
            sc5: "0.0",
            sc45: "0.0",
            btv: "3.529",
            scgb5: "9.316",
            scgb55: "9.016",
            sm45: "13.06",
            scgb15: "9.283",
            tc: "0.081",
            st15: "31.8",
            st5: "33.2",
            sf_sd55: "107.0",
            snr: "7.0",
            st55: "30.9",
            vbus25: "0.0",
            time: "2023-08-18T12:29:33.637Z",
            vbusgb45: "3.5",
          },
          group_name: "Kalecik Üzüm",
          type: "izci",
        },
      ],
      center: {
        lng: 33.4269840923823,
        lat: 40.1115544463389,
      },
      name: "Kalecik Üzüm",
      created_at: 1691493591971,
      geometry: [
        {
          lng: 33.426916884927785,
          lat: 40.10886019001959,
        },
        {
          lng: 33.42654705047608,
          lat: 40.11152455927166,
        },
        {
          lng: 33.42463731765748,
          lat: 40.111721486813316,
        },
        {
          lng: 33.42292070388795,
          lat: 40.11186918209537,
        },
        {
          lng: 33.42337131500245,
          lat: 40.11285380911256,
        },
        {
          lng: 33.42257738113404,
          lat: 40.113231245689605,
        },
        {
          lng: 33.42338950373233,
          lat: 40.114646995799994,
        },
        {
          lng: 33.430709838867195,
          lat: 40.11132763115981,
        },
        {
          lng: 33.43079566955567,
          lat: 40.10952243023187,
        },
        {
          lng: 33.42910051345826,
          lat: 40.10898086061076,
        },
      ],
      zoom: 17,
      id: "1d06b461-9264-4e49-9f21-7e47acb78359",
    },
    {
      qr: "",
      produced_at: 1689145064600,
      owner_id: "a9c355f4-dcac-4a16-82ca-0a8ba8d906c4",
      boru40Dolu: "",
      created_at: 1689946970374,
      izci_packet_ver: "002",
      type: "izci",
      izci_hw_group: "00",
      suBos: {
        sf35: "3.961016E8",
        sf45: "3.89185024E8",
        sf15: "3.92247168E8",
        sf25: "3.8725184E8",
        sf5: "4.07067488E8",
        sf55: "3.9927248E8",
        st25: "25.6",
        at: "26.1",
        st15: "25.7",
        st45: "25.7",
        st35: "25.3",
        st5: "25.8",
        st55: "25.7",
      },
      boru40Bos: "",
      havaDolu: "",
      boru32Dolu: "",
      izci_length: "60",
      izci_sw_ver: "000",
      havaBos: {
        sf35: "4.25412864E8",
        sf45: "4.25218464E8",
        sf15: "4.29018688E8",
        sf25: "4.16923296E8",
        sf5: "4.2954416E8",
        sf55: "4.341552E8",
        st25: "25.8",
        at: "25.8",
        st15: "25.8",
        st45: "26.0",
        st35: "25.5",
        st5: "26.1",
        st55: "26.0",
      },
      suDolu: "",
      izci_hw_rev: "000",
      izci_hw_ver: "000",
      id: "0080E115051CF6AE",
      izci_hw_cfg: "001",
      coords: {
        lat: 39.6091983,
        lon: 32.6970026,
      },
      boru32Bos: "",
      tsdata: [
        {
          time: "2023-09-20 08:00:00.000000000",
          at: "20.6",
          sm5: "107.37",
          sm15: "79.97",
          sm25: "83.7",
          sm35: "111.63",
          sm45: "97.28",
          sm55: "106.71",
          st5: "14.1",
          st15: "12.6",
          st25: "13.6",
          st35: "14.7",
          st45: "14.8",
          st55: "15.7",
        },
        {
          time: "2023-09-20 07:00:00.000000000",
          at: "17.9",
          sm5: "112.62",
          sm15: "80.69",
          sm25: "84.11",
          sm35: "111.7",
          sm45: "97.32",
          sm55: "106.74",
          st5: "12.7",
          st15: "12.2",
          st25: "13.6",
          st35: "14.7",
          st45: "14.8",
          st55: "15.6",
        },
        {
          time: "2023-09-20 06:00:00.000000000",
          at: "14.0",
          sm5: "114.1",
          sm15: "81.2",
          sm25: "84.5",
          sm35: "111.73",
          sm45: "97.35",
          sm55: "106.76",
          st5: "11.2",
          st15: "12.2",
          st25: "13.7",
          st35: "14.8",
          st45: "14.8",
          st55: "15.7",
        },
        {
          time: "2023-09-20 05:00:00.000000000",
          at: "9.2",
          sm5: "113.96",
          sm15: "82.66",
          sm25: "84.83",
          sm35: "111.78",
          sm45: "97.4",
          sm55: "106.77",
          st5: "10.0",
          st15: "12.2",
          st25: "13.8",
          st35: "14.8",
          st45: "14.8",
          st55: "15.7",
        },
        {
          time: "2023-09-20 04:00:00.000000000",
          at: "5.1",
          sm5: "1911.07",
          sm15: "83.14",
          sm25: "85.09",
          sm35: "111.75",
          sm45: "97.45",
          sm55: "106.77",
          st5: "9.0",
          st15: "12.3",
          st25: "14.0",
          st35: "14.8",
          st45: "15.0",
          st55: "15.7",
        },
        {
          time: "2023-09-20 03:00:00.000000000",
          at: "4.1",
          sm5: "1911.07",
          sm15: "83.54",
          sm25: "85.23",
          sm35: "111.73",
          sm45: "97.46",
          sm55: "106.76",
          st5: "8.8",
          st15: "12.6",
          st25: "14.0",
          st35: "14.8",
          st45: "15.0",
          st55: "15.7",
        },
        {
          time: "2023-09-20 02:00:00.000000000",
          at: "4.3",
          sm5: "114.81",
          sm15: "83.79",
          sm25: "85.38",
          sm35: "111.73",
          sm45: "97.45",
          sm55: "106.77",
          st5: "9.2",
          st15: "12.8",
          st25: "14.1",
          st35: "15.0",
          st45: "15.0",
          st55: "15.7",
        },
        {
          time: "2023-09-20 01:00:00.000000000",
          at: "5.1",
          sm5: "114.44",
          sm15: "83.93",
          sm25: "85.52",
          sm35: "111.71",
          sm45: "97.44",
          sm55: "106.77",
          st5: "9.7",
          st15: "13.1",
          st25: "14.2",
          st35: "15.0",
          st45: "14.8",
          st55: "15.6",
        },
        {
          time: "2023-09-20 00:00:00.000000000",
          at: "5.8",
          sm5: "114.39",
          sm15: "83.97",
          sm25: "85.64",
          sm35: "111.69",
          sm45: "97.43",
          sm55: "106.77",
          st5: "10.1",
          st15: "13.2",
          st25: "14.3",
          st35: "15.0",
          st45: "14.8",
          st55: "15.7",
        },
        {
          time: "2023-09-19 23:00:00.000000000",
          at: "6.1",
          sm5: "114.21",
          sm15: "83.8",
          sm25: "85.73",
          sm35: "111.67",
          sm45: "97.42",
          sm55: "106.77",
          st5: "10.3",
          st15: "13.3",
          st25: "14.3",
          st35: "15.0",
          st45: "15.0",
          st55: "15.7",
        },
        {
          time: "2023-09-19 22:00:00.000000000",
          at: "6.6",
          sm5: "113.41",
          sm15: "83.36",
          sm25: "85.82",
          sm35: "111.64",
          sm45: "97.42",
          sm55: "106.77",
          st5: "10.8",
          st15: "13.6",
          st25: "14.3",
          st35: "15.0",
          st45: "14.8",
          st55: "15.7",
        },
        {
          time: "2023-09-19 21:00:00.000000000",
          at: "6.9",
          sm5: "111.63",
          sm15: "82.71",
          sm25: "85.87",
          sm35: "111.61",
          sm45: "97.42",
          sm55: "106.77",
          st5: "11.2",
          st15: "13.8",
          st25: "14.3",
          st35: "15.0",
          st45: "14.8",
          st55: "15.7",
        },
        {
          time: "2023-09-19 20:00:00.000000000",
          at: "8.1",
          sm5: "109.73",
          sm15: "81.97",
          sm25: "85.87",
          sm35: "111.61",
          sm45: "97.43",
          sm55: "106.78",
          st5: "11.7",
          st15: "14.1",
          st25: "14.5",
          st35: "15.0",
          st45: "14.8",
          st55: "15.7",
        },
        {
          time: "2023-09-19 19:00:00.000000000",
          at: "8.3",
          sm5: "106.63",
          sm15: "81.1",
          sm25: "85.82",
          sm35: "111.59",
          sm45: "97.43",
          sm55: "106.78",
          st5: "12.1",
          st15: "14.2",
          st25: "14.5",
          st35: "15.0",
          st45: "14.8",
          st55: "15.7",
        },
        {
          time: "2023-09-19 18:00:00.000000000",
          at: "9.5",
          sm5: "103.37",
          sm15: "80.43",
          sm25: "85.7",
          sm35: "111.56",
          sm45: "97.42",
          sm55: "106.78",
          st5: "12.7",
          st15: "14.3",
          st25: "14.5",
          st35: "15.0",
          st45: "15.0",
          st55: "15.7",
        },
        {
          time: "2023-09-19 17:00:00.000000000",
          at: "10.8",
          sm5: "99.2",
          sm15: "79.79",
          sm25: "85.53",
          sm35: "111.55",
          sm45: "97.42",
          sm55: "106.79",
          st5: "13.5",
          st15: "14.6",
          st25: "14.3",
          st35: "14.8",
          st45: "14.8",
          st55: "15.7",
        },
        {
          time: "2023-09-19 16:00:00.000000000",
          at: "12.5",
          sm5: "96.09",
          sm15: "79.04",
          sm25: "85.33",
          sm35: "111.58",
          sm45: "97.43",
          sm55: "106.79",
          st5: "14.3",
          st15: "14.6",
          st25: "14.2",
          st35: "14.8",
          st45: "15.0",
          st55: "15.7",
        },
        {
          time: "2023-09-19 15:00:00.000000000",
          at: "16.1",
          sm5: "96.04",
          sm15: "78.34",
          sm25: "85.14",
          sm35: "111.61",
          sm45: "97.42",
          sm55: "106.8",
          st5: "15.3",
          st15: "14.6",
          st25: "14.2",
          st35: "14.7",
          st45: "15.0",
          st55: "15.7",
        },
        {
          time: "2023-09-19 14:00:00.000000000",
          at: "19.2",
          sm5: "94.8",
          sm15: "78.11",
          sm25: "84.96",
          sm35: "111.71",
          sm45: "97.4",
          sm55: "106.8",
          st5: "16.1",
          st15: "14.3",
          st25: "14.1",
          st35: "14.8",
          st45: "15.0",
          st55: "15.8",
        },
        {
          time: "2023-09-19 13:00:00.000000000",
          at: "20.8",
          sm5: "93.93",
          sm15: "78.17",
          sm25: "84.84",
          sm35: "111.81",
          sm45: "97.39",
          sm55: "106.81",
          st5: "16.2",
          st15: "14.1",
          st25: "14.0",
          st35: "14.8",
          st45: "15.0",
          st55: "14.4",
        },
        {
          time: "2023-09-19 11:00:00.000000000",
          at: "21.3",
          sm5: "97.43",
          sm15: "79.1",
          sm25: "84.99",
          sm35: "112.01",
          sm45: "97.45",
          sm55: "106.86",
          st5: "15.7",
          st15: "13.6",
          st25: "13.8",
          st35: "14.8",
          st45: "15.0",
          st55: "14.4",
        },
        {
          time: "2023-09-19 08:00:00.000000000",
          at: "18.2",
          sm5: "110.73",
          sm15: "81.35",
          sm25: "85.89",
          sm35: "112.28",
          sm45: "97.55",
          sm55: "106.93",
          st5: "13.2",
          st15: "12.6",
          st25: "13.8",
          st35: "15.0",
          st45: "15.1",
          st55: "14.4",
        },
        {
          time: "2023-09-19 07:00:00.000000000",
          at: "15.5",
          sm5: "113.6",
          sm15: "82.06",
          sm25: "86.29",
          sm35: "112.31",
          sm45: "97.59",
          sm55: "106.95",
          st5: "12.1",
          st15: "12.5",
          st25: "13.8",
          st35: "15.0",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-19 06:00:00.000000000",
          at: "12.8",
          sm5: "114.0",
          sm15: "83.16",
          sm25: "86.72",
          sm35: "112.36",
          sm45: "97.64",
          sm55: "106.96",
          st5: "11.1",
          st15: "12.3",
          st25: "14.0",
          st35: "15.1",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-19 05:00:00.000000000",
          at: "9.0",
          sm5: "114.29",
          sm15: "83.94",
          sm25: "87.07",
          sm35: "112.39",
          sm45: "97.68",
          sm55: "106.97",
          st5: "10.0",
          st15: "12.3",
          st25: "14.1",
          st35: "15.1",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-19 04:00:00.000000000",
          at: "4.9",
          sm5: "113.95",
          sm15: "84.73",
          sm25: "87.33",
          sm35: "112.35",
          sm45: "97.73",
          sm55: "106.96",
          st5: "9.0",
          st15: "12.6",
          st25: "14.2",
          st35: "15.2",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-19 03:00:00.000000000",
          at: "4.2",
          sm5: "114.76",
          sm15: "85.23",
          sm25: "87.51",
          sm35: "112.34",
          sm45: "97.73",
          sm55: "106.96",
          st5: "9.0",
          st15: "12.7",
          st25: "14.3",
          st35: "15.1",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-19 02:00:00.000000000",
          at: "3.8",
          sm5: "115.19",
          sm15: "85.58",
          sm25: "87.66",
          sm35: "112.34",
          sm45: "97.72",
          sm55: "106.96",
          st5: "9.2",
          st15: "13.0",
          st25: "14.3",
          st35: "15.2",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-19 01:00:00.000000000",
          at: "4.0",
          sm5: "117.14",
          sm15: "85.96",
          sm25: "87.77",
          sm35: "112.33",
          sm45: "97.72",
          sm55: "106.95",
          st5: "9.5",
          st15: "13.3",
          st25: "14.5",
          st35: "15.2",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-19 00:00:00.000000000",
          at: "5.0",
          sm5: "117.59",
          sm15: "86.12",
          sm25: "87.89",
          sm35: "112.31",
          sm45: "97.72",
          sm55: "106.95",
          st5: "10.1",
          st15: "13.6",
          st25: "14.6",
          st35: "15.2",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-18 23:00:00.000000000",
          at: "5.9",
          sm5: "116.52",
          sm15: "85.94",
          sm25: "87.98",
          sm35: "112.27",
          sm45: "97.71",
          sm55: "106.96",
          st5: "10.6",
          st15: "13.8",
          st25: "14.6",
          st35: "15.2",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-18 22:00:00.000000000",
          at: "6.3",
          sm5: "114.74",
          sm15: "85.49",
          sm25: "88.07",
          sm35: "112.25",
          sm45: "97.7",
          sm55: "106.96",
          st5: "11.1",
          st15: "14.0",
          st25: "14.7",
          st35: "15.2",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-18 21:00:00.000000000",
          at: "7.7",
          sm5: "113.0",
          sm15: "84.89",
          sm25: "88.11",
          sm35: "112.22",
          sm45: "97.7",
          sm55: "106.96",
          st5: "11.6",
          st15: "14.2",
          st25: "14.7",
          st35: "15.2",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-18 20:00:00.000000000",
          at: "8.3",
          sm5: "110.29",
          sm15: "84.36",
          sm25: "88.13",
          sm35: "112.19",
          sm45: "97.7",
          sm55: "106.96",
          st5: "12.2",
          st15: "14.5",
          st25: "14.7",
          st35: "15.2",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-18 19:00:00.000000000",
          at: "10.6",
          sm5: "108.42",
          sm15: "83.59",
          sm25: "88.06",
          sm35: "112.17",
          sm45: "97.7",
          sm55: "106.97",
          st5: "13.0",
          st15: "14.6",
          st25: "14.7",
          st35: "15.1",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-18 18:00:00.000000000",
          at: "11.6",
          sm5: "105.81",
          sm15: "82.82",
          sm25: "87.98",
          sm35: "112.19",
          sm45: "97.69",
          sm55: "106.97",
          st5: "13.3",
          st15: "14.7",
          st25: "14.7",
          st35: "15.1",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-18 17:00:00.000000000",
          at: "10.5",
          sm5: "102.98",
          sm15: "82.29",
          sm25: "87.86",
          sm35: "112.17",
          sm45: "97.7",
          sm55: "106.97",
          st5: "13.6",
          st15: "14.8",
          st25: "14.7",
          st35: "15.1",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-18 16:00:00.000000000",
          at: "12.9",
          sm5: "100.49",
          sm15: "81.57",
          sm25: "87.69",
          sm35: "112.18",
          sm45: "97.7",
          sm55: "106.98",
          st5: "14.6",
          st15: "15.0",
          st25: "14.6",
          st35: "15.1",
          st45: "15.1",
          st55: "16.0",
        },
        {
          time: "2023-09-18 15:00:00.000000000",
          at: "17.3",
          sm5: "101.53",
          sm15: "81.02",
          sm25: "87.52",
          sm35: "112.23",
          sm45: "97.68",
          sm55: "106.99",
          st5: "15.6",
          st15: "14.8",
          st25: "14.6",
          st35: "15.1",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-18 14:00:00.000000000",
          at: "19.5",
          sm5: "100.5",
          sm15: "80.88",
          sm25: "87.38",
          sm35: "112.33",
          sm45: "97.67",
          sm55: "106.99",
          st5: "16.1",
          st15: "14.8",
          st25: "14.5",
          st35: "15.1",
          st45: "15.1",
          st55: "16.0",
        },
        {
          time: "2023-09-18 13:00:00.000000000",
          at: "20.9",
          sm5: "100.06",
          sm15: "81.03",
          sm25: "87.37",
          sm35: "112.41",
          sm45: "97.68",
          sm55: "107.0",
          st5: "16.3",
          st15: "14.7",
          st25: "14.3",
          st35: "15.1",
          st45: "15.1",
          st55: "16.0",
        },
        {
          time: "2023-09-18 12:00:00.000000000",
          at: "21.6",
          sm5: "102.53",
          sm15: "81.66",
          sm25: "87.45",
          sm35: "112.49",
          sm45: "97.7",
          sm55: "107.02",
          st5: "16.3",
          st15: "14.3",
          st25: "14.3",
          st35: "15.1",
          st45: "15.1",
          st55: "15.8",
        },
        {
          time: "2023-09-18 11:00:00.000000000",
          at: "21.6",
          sm5: "108.66",
          sm15: "82.79",
          sm25: "87.65",
          sm35: "112.58",
          sm45: "97.72",
          sm55: "107.04",
          st5: "16.2",
          st15: "14.1",
          st25: "14.2",
          st35: "15.1",
          st45: "15.1",
          st55: "16.0",
        },
        {
          time: "2023-09-18 10:00:00.000000000",
          at: "21.0",
          sm5: "111.34",
          sm15: "84.48",
          sm25: "87.95",
          sm35: "112.7",
          sm45: "97.75",
          sm55: "107.06",
          st5: "15.6",
          st15: "13.8",
          st25: "14.2",
          st35: "15.1",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-18 09:00:00.000000000",
          at: "20.0",
          sm5: "114.37",
          sm15: "85.49",
          sm25: "88.29",
          sm35: "112.75",
          sm45: "97.79",
          sm55: "107.08",
          st5: "15.0",
          st15: "13.6",
          st25: "14.2",
          st35: "15.2",
          st45: "15.1",
          st55: "16.0",
        },
        {
          time: "2023-09-18 08:00:00.000000000",
          at: "18.1",
          sm5: "118.77",
          sm15: "85.93",
          sm25: "88.62",
          sm35: "112.79",
          sm45: "97.81",
          sm55: "107.1",
          st5: "14.0",
          st15: "13.3",
          st25: "14.2",
          st35: "15.2",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-18 07:00:00.000000000",
          at: "16.3",
          sm5: "120.48",
          sm15: "86.46",
          sm25: "88.96",
          sm35: "112.78",
          sm45: "97.85",
          sm55: "107.11",
          st5: "13.1",
          st15: "13.2",
          st25: "14.2",
          st35: "15.2",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-18 06:00:00.000000000",
          at: "14.0",
          sm5: "121.0",
          sm15: "86.91",
          sm25: "89.23",
          sm35: "112.79",
          sm45: "97.88",
          sm55: "107.12",
          st5: "12.1",
          st15: "13.1",
          st25: "14.3",
          st35: "15.2",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-18 05:00:00.000000000",
          at: "10.4",
          sm5: "120.4",
          sm15: "87.27",
          sm25: "89.71",
          sm35: "112.79",
          sm45: "97.91",
          sm55: "107.12",
          st5: "11.1",
          st15: "13.1",
          st25: "14.5",
          st35: "15.2",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-18 04:00:00.000000000",
          at: "6.7",
          sm5: "118.94",
          sm15: "87.88",
          sm25: "90.17",
          sm35: "112.73",
          sm45: "97.94",
          sm55: "107.11",
          st5: "10.3",
          st15: "13.3",
          st25: "14.5",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-18 03:00:00.000000000",
          at: "6.3",
          sm5: "118.99",
          sm15: "88.28",
          sm25: "90.34",
          sm35: "112.72",
          sm45: "97.94",
          sm55: "107.11",
          st5: "10.5",
          st15: "13.5",
          st25: "14.6",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-18 02:00:00.000000000",
          at: "6.7",
          sm5: "118.53",
          sm15: "88.45",
          sm25: "90.52",
          sm35: "112.7",
          sm45: "97.94",
          sm55: "107.1",
          st5: "10.7",
          st15: "13.6",
          st25: "14.7",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-18 01:00:00.000000000",
          at: "7.1",
          sm5: "118.42",
          sm15: "88.55",
          sm25: "90.64",
          sm35: "112.68",
          sm45: "97.93",
          sm55: "107.1",
          st5: "11.0",
          st15: "13.8",
          st25: "14.7",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-18 00:00:00.000000000",
          at: "7.3",
          sm5: "117.66",
          sm15: "88.46",
          sm25: "90.75",
          sm35: "112.66",
          sm45: "97.93",
          sm55: "107.1",
          st5: "11.3",
          st15: "14.0",
          st25: "14.8",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 23:00:00.000000000",
          at: "7.8",
          sm5: "116.93",
          sm15: "88.17",
          sm25: "90.86",
          sm35: "112.64",
          sm45: "97.93",
          sm55: "107.1",
          st5: "11.6",
          st15: "14.2",
          st25: "14.8",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 22:00:00.000000000",
          at: "8.0",
          sm5: "115.56",
          sm15: "87.62",
          sm25: "90.95",
          sm35: "112.61",
          sm45: "97.92",
          sm55: "107.1",
          st5: "11.8",
          st15: "14.5",
          st25: "15.0",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 21:00:00.000000000",
          at: "8.5",
          sm5: "113.78",
          sm15: "86.93",
          sm25: "90.97",
          sm35: "112.58",
          sm45: "97.92",
          sm55: "107.1",
          st5: "12.3",
          st15: "14.6",
          st25: "15.0",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 20:00:00.000000000",
          at: "9.6",
          sm5: "110.36",
          sm15: "86.11",
          sm25: "90.95",
          sm35: "112.56",
          sm45: "97.92",
          sm55: "107.11",
          st5: "13.0",
          st15: "14.8",
          st25: "15.0",
          st35: "15.3",
          st45: "15.1",
          st55: "16.0",
        },
        {
          time: "2023-09-17 19:00:00.000000000",
          at: "10.6",
          sm5: "107.54",
          sm15: "85.32",
          sm25: "90.89",
          sm35: "112.54",
          sm45: "97.92",
          sm55: "107.11",
          st5: "13.3",
          st15: "15.1",
          st25: "15.0",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 18:00:00.000000000",
          at: "11.3",
          sm5: "106.08",
          sm15: "84.66",
          sm25: "90.78",
          sm35: "112.51",
          sm45: "97.92",
          sm55: "107.11",
          st5: "14.0",
          st15: "15.2",
          st25: "15.0",
          st35: "15.2",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 17:00:00.000000000",
          at: "12.5",
          sm5: "103.65",
          sm15: "83.78",
          sm25: "90.62",
          sm35: "112.51",
          sm45: "97.92",
          sm55: "107.11",
          st5: "14.6",
          st15: "15.3",
          st25: "14.8",
          st35: "15.2",
          st45: "15.1",
          st55: "16.0",
        },
        {
          time: "2023-09-17 16:00:00.000000000",
          at: "14.8",
          sm5: "102.33",
          sm15: "82.98",
          sm25: "90.43",
          sm35: "112.52",
          sm45: "97.91",
          sm55: "107.12",
          st5: "15.5",
          st15: "15.3",
          st25: "14.8",
          st35: "15.1",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 15:00:00.000000000",
          at: "18.6",
          sm5: "100.35",
          sm15: "82.37",
          sm25: "90.21",
          sm35: "112.57",
          sm45: "97.89",
          sm55: "107.13",
          st5: "16.3",
          st15: "15.2",
          st25: "14.7",
          st35: "15.2",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 14:00:00.000000000",
          at: "20.7",
          sm5: "99.54",
          sm15: "82.18",
          sm25: "90.11",
          sm35: "112.66",
          sm45: "97.87",
          sm55: "107.13",
          st5: "16.8",
          st15: "15.1",
          st25: "14.6",
          st35: "15.1",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 13:00:00.000000000",
          at: "21.9",
          sm5: "99.28",
          sm15: "82.21",
          sm25: "90.19",
          sm35: "112.73",
          sm45: "97.88",
          sm55: "107.14",
          st5: "17.1",
          st15: "15.0",
          st25: "14.5",
          st35: "15.1",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 12:00:00.000000000",
          at: "22.5",
          sm5: "99.5",
          sm15: "82.46",
          sm25: "90.4",
          sm35: "112.78",
          sm45: "97.9",
          sm55: "107.15",
          st5: "17.1",
          st15: "14.7",
          st25: "14.3",
          st35: "15.1",
          st45: "15.1",
          st55: "16.0",
        },
        {
          time: "2023-09-17 11:00:00.000000000",
          at: "22.7",
          sm5: "100.16",
          sm15: "83.07",
          sm25: "90.68",
          sm35: "112.82",
          sm45: "97.92",
          sm55: "107.17",
          st5: "16.8",
          st15: "14.5",
          st25: "14.2",
          st35: "15.1",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 10:00:00.000000000",
          at: "22.7",
          sm5: "105.56",
          sm15: "83.83",
          sm25: "90.89",
          sm35: "112.89",
          sm45: "97.95",
          sm55: "107.19",
          st5: "16.5",
          st15: "14.1",
          st25: "14.2",
          st35: "15.1",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 09:00:00.000000000",
          at: "21.6",
          sm5: "111.33",
          sm15: "85.72",
          sm25: "90.57",
          sm35: "113.01",
          sm45: "97.99",
          sm55: "107.21",
          st5: "15.7",
          st15: "13.7",
          st25: "14.2",
          st35: "15.1",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-17 08:00:00.000000000",
          at: "20.6",
          sm5: "113.91",
          sm15: "86.86",
          sm25: "90.85",
          sm35: "113.03",
          sm45: "98.02",
          sm55: "107.22",
          st5: "14.8",
          st15: "13.5",
          st25: "14.2",
          st35: "15.2",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 07:00:00.000000000",
          at: "18.5",
          sm5: "117.29",
          sm15: "87.78",
          sm25: "91.18",
          sm35: "113.01",
          sm45: "98.06",
          sm55: "107.24",
          st5: "13.8",
          st15: "13.3",
          st25: "14.2",
          st35: "15.2",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-17 06:00:00.000000000",
          at: "15.2",
          sm5: "118.63",
          sm15: "88.39",
          sm25: "91.49",
          sm35: "112.99",
          sm45: "98.1",
          sm55: "107.25",
          st5: "12.6",
          st15: "13.2",
          st25: "14.3",
          st35: "15.2",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-17 05:00:00.000000000",
          at: "11.1",
          sm5: "117.94",
          sm15: "88.72",
          sm25: "91.76",
          sm35: "112.99",
          sm45: "98.12",
          sm55: "107.23",
          st5: "11.5",
          st15: "13.2",
          st25: "14.3",
          st35: "15.2",
          st45: "15.3",
          st55: "16.0",
        },
        {
          time: "2023-09-17 04:00:00.000000000",
          at: "7.9",
          sm5: "117.28",
          sm15: "88.96",
          sm25: "91.96",
          sm35: "112.94",
          sm45: "98.16",
          sm55: "107.22",
          st5: "10.7",
          st15: "13.3",
          st25: "14.5",
          st35: "15.3",
          st45: "15.3",
          st55: "16.1",
        },
        {
          time: "2023-09-17 03:00:00.000000000",
          at: "7.1",
          sm5: "117.43",
          sm15: "89.22",
          sm25: "92.1",
          sm35: "112.93",
          sm45: "98.17",
          sm55: "107.22",
          st5: "10.7",
          st15: "13.5",
          st25: "14.6",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 02:00:00.000000000",
          at: "7.1",
          sm5: "116.58",
          sm15: "89.41",
          sm25: "92.23",
          sm35: "112.91",
          sm45: "98.16",
          sm55: "107.22",
          st5: "10.8",
          st15: "13.6",
          st25: "14.6",
          st35: "15.3",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-17 01:00:00.000000000",
          at: "7.3",
          sm5: "116.5",
          sm15: "89.95",
          sm25: "92.3",
          sm35: "112.9",
          sm45: "98.16",
          sm55: "107.22",
          st5: "11.2",
          st15: "13.8",
          st25: "14.7",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-17 00:00:00.000000000",
          at: "7.6",
          sm5: "116.87",
          sm15: "90.02",
          sm25: "92.38",
          sm35: "112.87",
          sm45: "98.15",
          sm55: "107.22",
          st5: "11.5",
          st15: "14.0",
          st25: "14.7",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-16 23:00:00.000000000",
          at: "8.3",
          sm5: "116.52",
          sm15: "89.89",
          sm25: "92.43",
          sm35: "112.85",
          sm45: "98.15",
          sm55: "107.22",
          st5: "11.8",
          st15: "14.2",
          st25: "14.8",
          st35: "15.3",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-16 22:00:00.000000000",
          at: "9.0",
          sm5: "115.06",
          sm15: "89.61",
          sm25: "92.44",
          sm35: "112.81",
          sm45: "98.14",
          sm55: "107.22",
          st5: "12.2",
          st15: "14.3",
          st25: "14.8",
          st35: "15.3",
          st45: "15.2",
          st55: "16.0",
        },
        {
          time: "2023-09-16 21:00:00.000000000",
          at: "9.9",
          sm5: "112.75",
          sm15: "89.16",
          sm25: "92.43",
          sm35: "112.8",
          sm45: "98.13",
          sm55: "107.23",
          st5: "12.6",
          st15: "14.6",
          st25: "14.8",
          st35: "15.2",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-16 20:00:00.000000000",
          at: "10.0",
          sm5: "114.09",
          sm15: "88.55",
          sm25: "92.42",
          sm35: "112.75",
          sm45: "98.14",
          sm55: "107.23",
          st5: "13.0",
          st15: "14.7",
          st25: "14.8",
          st35: "15.2",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-16 19:00:00.000000000",
          at: "10.8",
          sm5: "111.95",
          sm15: "87.78",
          sm25: "92.33",
          sm35: "112.73",
          sm45: "98.14",
          sm55: "107.23",
          st5: "13.3",
          st15: "14.8",
          st25: "14.8",
          st35: "15.2",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-16 18:00:00.000000000",
          at: "11.4",
          sm5: "109.12",
          sm15: "86.62",
          sm25: "92.19",
          sm35: "112.69",
          sm45: "98.13",
          sm55: "107.23",
          st5: "13.8",
          st15: "15.0",
          st25: "14.7",
          st35: "15.2",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-16 17:00:00.000000000",
          at: "12.9",
          sm5: "104.9",
          sm15: "85.61",
          sm25: "92.02",
          sm35: "112.68",
          sm45: "98.13",
          sm55: "107.24",
          st5: "14.5",
          st15: "15.1",
          st25: "14.7",
          st35: "15.2",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-16 16:00:00.000000000",
          at: "14.7",
          sm5: "102.78",
          sm15: "84.72",
          sm25: "91.82",
          sm35: "112.69",
          sm45: "98.12",
          sm55: "107.24",
          st5: "15.2",
          st15: "15.1",
          st25: "14.6",
          st35: "15.1",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-16 15:00:00.000000000",
          at: "18.9",
          sm5: "99.79",
          sm15: "83.96",
          sm25: "91.63",
          sm35: "112.75",
          sm45: "98.09",
          sm55: "107.26",
          st5: "16.2",
          st15: "15.0",
          st25: "14.5",
          st35: "15.1",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-16 14:00:00.000000000",
          at: "20.7",
          sm5: "100.51",
          sm15: "83.49",
          sm25: "91.49",
          sm35: "112.83",
          sm45: "98.08",
          sm55: "107.26",
          st5: "16.6",
          st15: "14.8",
          st25: "14.3",
          st35: "15.1",
          st45: "15.2",
          st55: "16.1",
        },
        {
          time: "2023-09-16 10:00:00.000000000",
          at: "22.3",
          sm5: "101.63",
          sm15: "84.73",
          sm25: "92.06",
          sm35: "112.96",
          sm45: "98.17",
          sm55: "107.31",
          st5: "15.8",
          st15: "13.7",
          st25: "14.1",
          st35: "15.1",
          st45: "15.3",
          st55: "16.1",
        },
        {
          time: "2023-09-16 09:00:00.000000000",
          at: "21.1",
          sm5: "112.81",
          sm15: "86.37",
          sm25: "92.43",
          sm35: "113.03",
          sm45: "98.21",
          sm55: "107.33",
          st5: "15.1",
          st15: "13.3",
          st25: "14.1",
          st35: "15.1",
          st45: "15.3",
          st55: "16.0",
        },
        {
          time: "2023-09-16 08:00:00.000000000",
          at: "20.1",
          sm5: "114.74",
          sm15: "87.52",
          sm25: "92.79",
          sm35: "113.04",
          sm45: "98.26",
          sm55: "107.35",
          st5: "14.1",
          st15: "13.1",
          st25: "14.1",
          st35: "15.2",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-16 07:00:00.000000000",
          at: "17.9",
          sm5: "112.05",
          sm15: "88.51",
          sm25: "93.16",
          sm35: "113.02",
          sm45: "98.31",
          sm55: "107.36",
          st5: "13.2",
          st15: "12.8",
          st25: "14.1",
          st35: "15.2",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-16 06:00:00.000000000",
          at: "14.1",
          sm5: "112.49",
          sm15: "89.38",
          sm25: "93.53",
          sm35: "113.02",
          sm45: "98.35",
          sm55: "107.36",
          st5: "11.7",
          st15: "12.8",
          st25: "14.2",
          st35: "15.3",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-16 05:00:00.000000000",
          at: "9.9",
          sm5: "112.18",
          sm15: "89.94",
          sm25: "93.81",
          sm35: "113.04",
          sm45: "98.39",
          sm55: "107.36",
          st5: "10.7",
          st15: "12.8",
          st25: "14.3",
          st35: "15.3",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-16 04:00:00.000000000",
          at: "6.0",
          sm5: "112.43",
          sm15: "90.34",
          sm25: "93.98",
          sm35: "111.17",
          sm45: "98.52",
          sm55: "107.33",
          st5: "9.8",
          st15: "13.0",
          st25: "14.5",
          st35: "15.3",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-16 03:00:00.000000000",
          at: "5.8",
          sm5: "113.51",
          sm15: "90.73",
          sm25: "94.1",
          sm35: "111.13",
          sm45: "98.52",
          sm55: "107.33",
          st5: "10.0",
          st15: "13.1",
          st25: "14.5",
          st35: "15.3",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-16 02:00:00.000000000",
          at: "6.3",
          sm5: "114.87",
          sm15: "91.05",
          sm25: "94.2",
          sm35: "111.12",
          sm45: "98.51",
          sm55: "107.33",
          st5: "10.2",
          st15: "13.3",
          st25: "14.6",
          st35: "15.3",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-16 01:00:00.000000000",
          at: "6.3",
          sm5: "115.27",
          sm15: "91.26",
          sm25: "94.3",
          sm35: "111.1",
          sm45: "98.5",
          sm55: "107.33",
          st5: "10.5",
          st15: "13.5",
          st25: "14.6",
          st35: "15.3",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-16 00:00:00.000000000",
          at: "6.5",
          sm5: "115.75",
          sm15: "91.41",
          sm25: "94.37",
          sm35: "111.08",
          sm45: "98.49",
          sm55: "107.33",
          st5: "10.7",
          st15: "13.7",
          st25: "14.7",
          st35: "15.3",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-15 23:00:00.000000000",
          at: "7.0",
          sm5: "115.62",
          sm15: "91.46",
          sm25: "94.45",
          sm35: "111.05",
          sm45: "98.49",
          sm55: "107.33",
          st5: "11.1",
          st15: "14.0",
          st25: "14.7",
          st35: "15.5",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-15 22:00:00.000000000",
          at: "7.5",
          sm5: "115.0",
          sm15: "91.42",
          sm25: "94.5",
          sm35: "111.02",
          sm45: "98.48",
          sm55: "107.33",
          st5: "11.5",
          st15: "14.1",
          st25: "14.8",
          st35: "15.3",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-15 21:00:00.000000000",
          at: "8.5",
          sm5: "113.88",
          sm15: "91.13",
          sm25: "94.51",
          sm35: "110.97",
          sm45: "98.47",
          sm55: "107.34",
          st5: "12.0",
          st15: "14.3",
          st25: "14.8",
          st35: "15.3",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-15 20:00:00.000000000",
          at: "9.0",
          sm5: "112.44",
          sm15: "90.67",
          sm25: "94.49",
          sm35: "110.93",
          sm45: "98.46",
          sm55: "107.34",
          st5: "12.5",
          st15: "14.6",
          st25: "14.8",
          st35: "15.3",
          st45: "15.3",
          st55: "16.3",
        },
        {
          time: "2023-09-15 19:00:00.000000000",
          at: "9.9",
          sm5: "112.14",
          sm15: "89.89",
          sm25: "94.42",
          sm35: "110.89",
          sm45: "98.45",
          sm55: "107.35",
          st5: "13.0",
          st15: "14.7",
          st25: "14.8",
          st35: "15.2",
          st45: "15.3",
          st55: "16.2",
        },
        {
          time: "2023-09-15 18:00:00.000000000",
          at: "10.6",
          sm5: "111.24",
          sm15: "88.44",
          sm25: "94.34",
          sm35: "110.82",
          sm45: "98.44",
          sm55: "107.36",
          st5: "13.5",
          st15: "14.8",
          st25: "14.7",
          st35: "15.2",
          st45: "15.3",
          st55: "16.3",
        },
        {
          time: "2023-09-15 17:00:00.000000000",
          at: "12.2",
          sm5: "109.77",
          sm15: "87.4",
          sm25: "94.22",
          sm35: "110.8",
          sm45: "98.44",
          sm55: "107.37",
          st5: "14.3",
          st15: "15.0",
          st25: "14.6",
          st35: "15.2",
          st45: "15.3",
          st55: "16.3",
        },
        {
          time: "2023-09-15 16:00:00.000000000",
          at: "14.5",
          sm5: "108.36",
          sm15: "86.55",
          sm25: "94.08",
          sm35: "110.79",
          sm45: "98.43",
          sm55: "107.38",
          st5: "15.2",
          st15: "15.0",
          st25: "14.6",
          st35: "15.2",
          st45: "15.3",
          st55: "16.3",
        },
        {
          time: "2023-09-15 15:00:00.000000000",
          at: "18.2",
          sm5: "105.56",
          sm15: "85.96",
          sm25: "93.93",
          sm35: "110.76",
          sm45: "98.4",
          sm55: "107.39",
          st5: "16.1",
          st15: "14.8",
          st25: "14.5",
          st35: "15.2",
          st45: "15.3",
          st55: "16.0",
        },
        {
          time: "2023-09-15 14:00:00.000000000",
          at: "20.6",
          sm5: "103.64",
          sm15: "85.72",
          sm25: "93.83",
          sm35: "110.68",
          sm45: "98.39",
          sm55: "107.4",
          st5: "16.5",
          st15: "14.7",
          st25: "14.3",
          st35: "15.2",
          st45: "15.3",
          st55: "16.0",
        },
        {
          time: "2023-09-15 12:00:00.000000000",
          at: "22.4",
          sm5: "104.26",
          sm15: "85.85",
          sm25: "93.99",
          sm35: "110.43",
          sm45: "98.42",
          sm55: "107.42",
          st5: "16.5",
          st15: "14.1",
          st25: "14.2",
          st35: "15.1",
          st45: "15.5",
          st55: "16.3",
        },
        {
          time: "2023-09-15 11:00:00.000000000",
          at: "22.5",
          sm5: "103.79",
          sm15: "86.25",
          sm25: "94.22",
          sm35: "110.48",
          sm45: "98.46",
          sm55: "107.43",
          st5: "16.1",
          st15: "13.8",
          st25: "14.1",
          st35: "15.2",
          st45: "15.5",
          st55: "16.0",
        },
        {
          time: "2023-09-15 10:00:00.000000000",
          at: "21.5",
          sm5: "103.93",
          sm15: "86.59",
          sm25: "94.51",
          sm35: "110.87",
          sm45: "98.48",
          sm55: "107.44",
          st5: "15.3",
          st15: "13.5",
          st25: "14.1",
          st35: "15.2",
          st45: "15.5",
          st55: "16.0",
        },
        {
          time: "2023-09-15 09:00:00.000000000",
          at: "20.2",
          sm5: "108.57",
          sm15: "87.26",
          sm25: "94.8",
          sm35: "111.42",
          sm45: "98.52",
          sm55: "107.45",
          st5: "14.5",
          st15: "13.2",
          st25: "14.1",
          st35: "15.3",
          st45: "15.6",
          st55: "16.0",
        },
        {
          time: "2023-09-15 07:00:00.000000000",
          at: "17.1",
          sm5: "110.42",
          sm15: "89.2",
          sm25: "95.39",
          sm35: "111.61",
          sm45: "98.61",
          sm55: "107.49",
          st5: "12.6",
          st15: "12.7",
          st25: "14.1",
          st35: "15.3",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-15 06:00:00.000000000",
          at: "12.8",
          sm5: "111.39",
          sm15: "89.94",
          sm25: "95.67",
          sm35: "111.61",
          sm45: "98.64",
          sm55: "107.49",
          st5: "11.1",
          st15: "12.6",
          st25: "14.2",
          st35: "15.5",
          st45: "15.5",
          st55: "16.5",
        },
        {
          time: "2023-09-15 05:00:00.000000000",
          at: "8.6",
          sm5: "110.25",
          sm15: "90.48",
          sm25: "95.93",
          sm35: "111.59",
          sm45: "98.67",
          sm55: "107.48",
          st5: "10.1",
          st15: "12.7",
          st25: "14.3",
          st35: "15.5",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-15 04:00:00.000000000",
          at: "5.2",
          sm5: "110.36",
          sm15: "91.03",
          sm25: "96.16",
          sm35: "112.76",
          sm45: "98.64",
          sm55: "107.38",
          st5: "9.3",
          st15: "12.8",
          st25: "14.5",
          st35: "15.5",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-15 03:00:00.000000000",
          at: "4.9",
          sm5: "111.1",
          sm15: "91.53",
          sm25: "96.3",
          sm35: "112.74",
          sm45: "98.64",
          sm55: "107.38",
          st5: "9.6",
          st15: "13.1",
          st25: "14.5",
          st35: "15.5",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-15 02:00:00.000000000",
          at: "5.5",
          sm5: "112.45",
          sm15: "91.99",
          sm25: "96.42",
          sm35: "112.73",
          sm45: "98.63",
          sm55: "107.38",
          st5: "9.8",
          st15: "13.2",
          st25: "14.6",
          st35: "15.5",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-15 01:00:00.000000000",
          at: "6.0",
          sm5: "114.0",
          sm15: "92.36",
          sm25: "96.52",
          sm35: "112.71",
          sm45: "98.63",
          sm55: "107.39",
          st5: "10.2",
          st15: "13.5",
          st25: "14.7",
          st35: "15.5",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-15 00:00:00.000000000",
          at: "5.8",
          sm5: "114.83",
          sm15: "92.65",
          sm25: "96.61",
          sm35: "112.7",
          sm45: "98.62",
          sm55: "107.39",
          st5: "10.5",
          st15: "13.6",
          st25: "14.7",
          st35: "15.6",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-14 23:00:00.000000000",
          at: "6.4",
          sm5: "115.79",
          sm15: "92.81",
          sm25: "96.67",
          sm35: "112.68",
          sm45: "98.61",
          sm55: "107.39",
          st5: "10.8",
          st15: "13.8",
          st25: "14.8",
          st35: "15.6",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-14 22:00:00.000000000",
          at: "7.1",
          sm5: "116.7",
          sm15: "92.93",
          sm25: "96.7",
          sm35: "112.64",
          sm45: "98.6",
          sm55: "107.4",
          st5: "11.2",
          st15: "14.1",
          st25: "14.8",
          st35: "15.5",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-14 21:00:00.000000000",
          at: "7.5",
          sm5: "115.81",
          sm15: "92.8",
          sm25: "96.73",
          sm35: "112.6",
          sm45: "98.6",
          sm55: "107.4",
          st5: "11.6",
          st15: "14.2",
          st25: "15.0",
          st35: "15.6",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-14 20:00:00.000000000",
          at: "8.2",
          sm5: "114.66",
          sm15: "92.46",
          sm25: "96.73",
          sm35: "112.57",
          sm45: "98.59",
          sm55: "107.41",
          st5: "12.1",
          st15: "14.5",
          st25: "14.8",
          st35: "15.5",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-14 19:00:00.000000000",
          at: "9.0",
          sm5: "113.84",
          sm15: "91.83",
          sm25: "96.69",
          sm35: "112.52",
          sm45: "98.58",
          sm55: "107.41",
          st5: "12.7",
          st15: "14.7",
          st25: "14.8",
          st35: "15.5",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-14 18:00:00.000000000",
          at: "9.8",
          sm5: "113.91",
          sm15: "90.51",
          sm25: "96.64",
          sm35: "112.47",
          sm45: "98.57",
          sm55: "107.42",
          st5: "13.2",
          st15: "14.8",
          st25: "14.8",
          st35: "15.5",
          st45: "15.6",
          st55: "16.6",
        },
        {
          time: "2023-09-14 17:00:00.000000000",
          at: "11.5",
          sm5: "114.53",
          sm15: "89.61",
          sm25: "96.53",
          sm35: "112.44",
          sm45: "98.57",
          sm55: "107.43",
          st5: "14.0",
          st15: "14.8",
          st25: "14.8",
          st35: "15.5",
          st45: "15.6",
          st55: "16.5",
        },
        {
          time: "2023-09-14 16:00:00.000000000",
          at: "14.1",
          sm5: "110.3",
          sm15: "88.78",
          sm25: "96.45",
          sm35: "112.45",
          sm45: "98.56",
          sm55: "107.45",
          st5: "15.0",
          st15: "14.8",
          st25: "14.7",
          st35: "15.5",
          st45: "15.7",
          st55: "16.0",
        },
        {
          time: "2023-09-14 15:00:00.000000000",
          at: "17.6",
          sm5: "107.28",
          sm15: "88.2",
          sm25: "96.36",
          sm35: "112.71",
          sm45: "98.52",
          sm55: "107.47",
          st5: "15.7",
          st15: "14.7",
          st25: "14.6",
          st35: "15.5",
          st45: "15.6",
          st55: "16.6",
        },
        {
          time: "2023-09-14 14:00:00.000000000",
          at: "19.8",
          sm5: "106.29",
          sm15: "87.96",
          sm25: "96.28",
          sm35: "112.79",
          sm45: "98.49",
          sm55: "107.48",
          st5: "16.1",
          st15: "14.6",
          st25: "14.5",
          st35: "15.5",
          st45: "15.7",
          st55: "16.6",
        },
        {
          time: "2023-09-14 13:00:00.000000000",
          at: "21.1",
          sm5: "105.9",
          sm15: "87.72",
          sm25: "96.24",
          sm35: "112.84",
          sm45: "98.5",
          sm55: "107.49",
          st5: "16.1",
          st15: "14.3",
          st25: "14.5",
          st35: "15.5",
          st45: "15.7",
          st55: "16.7",
        },
        {
          time: "2023-09-14 12:00:00.000000000",
          at: "21.3",
          sm5: "105.76",
          sm15: "87.65",
          sm25: "96.27",
          sm35: "112.88",
          sm45: "98.52",
          sm55: "107.51",
          st5: "16.1",
          st15: "14.1",
          st25: "14.3",
          st35: "15.5",
          st45: "15.7",
          st55: "16.7",
        },
        {
          time: "2023-09-14 11:00:00.000000000",
          at: "21.5",
          sm5: "105.61",
          sm15: "87.66",
          sm25: "96.35",
          sm35: "112.92",
          sm45: "98.54",
          sm55: "107.52",
          st5: "15.8",
          st15: "13.8",
          st25: "14.2",
          st35: "15.5",
          st45: "15.7",
          st55: "16.6",
        },
        {
          time: "2023-09-14 10:00:00.000000000",
          at: "20.6",
          sm5: "105.38",
          sm15: "87.84",
          sm25: "96.47",
          sm35: "112.98",
          sm45: "98.58",
          sm55: "107.53",
          st5: "15.1",
          st15: "13.5",
          st25: "14.2",
          st35: "15.5",
          st45: "15.8",
          st55: "16.7",
        },
        {
          time: "2023-09-14 09:00:00.000000000",
          at: "19.7",
          sm5: "105.54",
          sm15: "87.98",
          sm25: "96.63",
          sm35: "113.02",
          sm45: "98.62",
          sm55: "107.54",
          st5: "14.2",
          st15: "13.1",
          st25: "14.3",
          st35: "15.6",
          st45: "15.8",
          st55: "16.7",
        },
        {
          time: "2023-09-14 07:00:00.000000000",
          at: "16.5",
          sm5: "111.71",
          sm15: "88.81",
          sm25: "97.0",
          sm35: "113.19",
          sm45: "98.73",
          sm55: "107.58",
          st5: "12.1",
          st15: "12.7",
          st25: "14.3",
          st35: "15.7",
          st45: "15.8",
          st55: "16.7",
        },
        {
          time: "2023-09-14 06:00:00.000000000",
          at: "12.3",
          sm5: "113.17",
          sm15: "89.3",
          sm25: "97.22",
          sm35: "113.2",
          sm45: "98.73",
          sm55: "107.57",
          st5: "10.8",
          st15: "12.7",
          st25: "14.5",
          st35: "15.7",
          st45: "15.8",
          st55: "16.7",
        },
        {
          time: "2023-09-14 05:00:00.000000000",
          at: "8.2",
          sm5: "113.7",
          sm15: "90.22",
          sm25: "97.42",
          sm35: "113.25",
          sm45: "98.75",
          sm55: "107.56",
          st5: "9.8",
          st15: "12.8",
          st25: "14.6",
          st35: "15.7",
          st45: "16.0",
          st55: "16.7",
        },
        {
          time: "2023-09-14 04:00:00.000000000",
          at: "4.9",
          sm5: "113.76",
          sm15: "90.8",
          sm25: "97.65",
          sm35: "113.22",
          sm45: "98.79",
          sm55: "107.55",
          st5: "9.2",
          st15: "12.8",
          st25: "14.6",
          st35: "15.7",
          st45: "16.0",
          st55: "16.7",
        },
        {
          time: "2023-09-14 03:00:00.000000000",
          at: "4.7",
          sm5: "113.33",
          sm15: "91.4",
          sm25: "97.81",
          sm35: "113.23",
          sm45: "98.79",
          sm55: "107.55",
          st5: "9.3",
          st15: "13.1",
          st25: "14.7",
          st35: "15.8",
          st45: "15.8",
          st55: "16.7",
        },
        {
          time: "2023-09-14 02:00:00.000000000",
          at: "4.8",
          sm5: "108.65",
          sm15: "92.03",
          sm25: "97.97",
          sm35: "113.22",
          sm45: "98.79",
          sm55: "107.55",
          st5: "9.6",
          st15: "13.3",
          st25: "14.8",
          st35: "15.8",
          st45: "16.0",
          st55: "16.7",
        },
        {
          time: "2023-09-14 01:00:00.000000000",
          at: "5.1",
          sm5: "110.11",
          sm15: "92.5",
          sm25: "98.12",
          sm35: "113.21",
          sm45: "98.78",
          sm55: "107.56",
          st5: "10.0",
          st15: "13.6",
          st25: "15.0",
          st35: "15.8",
          st45: "16.0",
          st55: "16.8",
        },
        {
          time: "2023-09-14 00:00:00.000000000",
          at: "5.5",
          sm5: "111.95",
          sm15: "92.95",
          sm25: "98.24",
          sm35: "113.19",
          sm45: "98.77",
          sm55: "107.56",
          st5: "10.2",
          st15: "13.7",
          st25: "15.0",
          st35: "15.8",
          st45: "16.0",
          st55: "16.8",
        },
        {
          time: "2023-09-13 23:00:00.000000000",
          at: "5.8",
          sm5: "113.57",
          sm15: "93.28",
          sm25: "98.34",
          sm35: "113.18",
          sm45: "98.77",
          sm55: "107.56",
          st5: "10.6",
          st15: "14.0",
          st25: "15.1",
          st35: "16.0",
          st45: "16.0",
          st55: "16.8",
        },
        {
          time: "2023-09-13 22:00:00.000000000",
          at: "6.4",
          sm5: "115.08",
          sm15: "93.5",
          sm25: "98.4",
          sm35: "113.16",
          sm45: "98.76",
          sm55: "107.57",
          st5: "11.0",
          st15: "14.2",
          st25: "15.1",
          st35: "15.8",
          st45: "16.0",
          st55: "16.8",
        },
        {
          time: "2023-09-13 21:00:00.000000000",
          at: "6.9",
          sm5: "116.2",
          sm15: "93.61",
          sm25: "98.42",
          sm35: "113.13",
          sm45: "98.75",
          sm55: "107.57",
          st5: "11.3",
          st15: "14.3",
          st25: "15.2",
          st35: "15.8",
          st45: "16.0",
          st55: "16.8",
        },
        {
          time: "2023-09-13 20:00:00.000000000",
          at: "7.1",
          sm5: "116.98",
          sm15: "93.52",
          sm25: "98.41",
          sm35: "113.11",
          sm45: "98.75",
          sm55: "107.58",
          st5: "11.8",
          st15: "14.6",
          st25: "15.2",
          st35: "15.8",
          st45: "16.0",
          st55: "16.8",
        },
        {
          time: "2023-09-13 19:00:00.000000000",
          at: "8.1",
          sm5: "116.88",
          sm15: "93.17",
          sm25: "98.36",
          sm35: "113.08",
          sm45: "98.74",
          sm55: "107.59",
          st5: "12.5",
          st15: "14.8",
          st25: "15.2",
          st35: "15.8",
          st45: "16.0",
          st55: "16.8",
        },
        {
          time: "2023-09-13 18:00:00.000000000",
          at: "9.3",
          sm5: "114.99",
          sm15: "92.54",
          sm25: "98.28",
          sm35: "113.06",
          sm45: "98.74",
          sm55: "107.6",
          st5: "13.1",
          st15: "15.0",
          st25: "15.2",
          st35: "15.8",
          st45: "16.0",
          st55: "16.8",
        },
        {
          time: "2023-09-13 17:00:00.000000000",
          at: "10.6",
          sm5: "113.89",
          sm15: "91.65",
          sm25: "98.19",
          sm35: "113.04",
          sm45: "98.74",
          sm55: "107.61",
          st5: "14.0",
          st15: "15.1",
          st25: "15.2",
          st35: "15.8",
          st45: "16.0",
          st55: "16.8",
        },
        {
          time: "2023-09-13 16:00:00.000000000",
          at: "13.9",
          sm5: "115.7",
          sm15: "90.46",
          sm25: "98.13",
          sm35: "113.0",
          sm45: "98.74",
          sm55: "107.63",
          st5: "14.8",
          st15: "15.2",
          st25: "15.1",
          st35: "15.8",
          st45: "16.0",
          st55: "16.8",
        },
        {
          time: "2023-09-13 15:00:00.000000000",
          at: "17.2",
          sm5: "112.62",
          sm15: "89.9",
          sm25: "98.04",
          sm35: "113.04",
          sm45: "98.71",
          sm55: "107.64",
          st5: "15.7",
          st15: "15.1",
          st25: "15.0",
          st35: "15.8",
          st45: "16.0",
          st55: "17.0",
        },
        {
          time: "2023-09-13 14:00:00.000000000",
          at: "19.3",
          sm5: "110.57",
          sm15: "89.5",
          sm25: "97.91",
          sm35: "113.12",
          sm45: "98.7",
          sm55: "107.65",
          st5: "16.0",
          st15: "15.0",
          st25: "15.0",
          st35: "15.8",
          st45: "16.1",
          st55: "16.0",
        },
        {
          time: "2023-09-13 11:00:00.000000000",
          at: "20.9",
          sm5: "109.74",
          sm15: "89.28",
          sm25: "97.83",
          sm35: "113.26",
          sm45: "98.73",
          sm55: "107.69",
          st5: "15.6",
          st15: "14.2",
          st25: "14.7",
          st35: "15.8",
          st45: "16.1",
          st55: "17.0",
        },
        {
          time: "2023-09-13 10:00:00.000000000",
          at: "20.0",
          sm5: "109.66",
          sm15: "89.25",
          sm25: "97.89",
          sm35: "113.32",
          sm45: "98.75",
          sm55: "107.7",
          st5: "15.2",
          st15: "14.0",
          st25: "14.7",
          st35: "16.0",
          st45: "16.1",
          st55: "17.0",
        },
        {
          time: "2023-09-13 09:00:00.000000000",
          at: "19.1",
          sm5: "109.8",
          sm15: "89.35",
          sm25: "98.0",
          sm35: "113.36",
          sm45: "98.79",
          sm55: "107.71",
          st5: "14.6",
          st15: "13.7",
          st25: "14.7",
          st35: "16.0",
          st45: "16.2",
          st55: "16.0",
        },
        {
          time: "2023-09-13 08:00:00.000000000",
          at: "18.0",
          sm5: "110.58",
          sm15: "89.63",
          sm25: "98.13",
          sm35: "113.42",
          sm45: "98.83",
          sm55: "107.72",
          st5: "13.7",
          st15: "13.5",
          st25: "14.7",
          st35: "16.0",
          st45: "16.2",
          st55: "17.1",
        },
        {
          time: "2023-09-13 07:00:00.000000000",
          at: "16.2",
          sm5: "112.69",
          sm15: "90.36",
          sm25: "98.26",
          sm35: "113.5",
          sm45: "98.85",
          sm55: "107.73",
          st5: "12.7",
          st15: "13.3",
          st25: "14.7",
          st35: "16.1",
          st45: "16.2",
          st55: "17.1",
        },
        {
          time: "2023-09-13 06:00:00.000000000",
          at: "12.6",
          sm5: "113.67",
          sm15: "90.76",
          sm25: "98.46",
          sm35: "113.53",
          sm45: "98.88",
          sm55: "107.73",
          st5: "11.6",
          st15: "13.3",
          st25: "14.8",
          st35: "16.1",
          st45: "16.2",
          st55: "17.1",
        },
        {
          time: "2023-09-13 05:00:00.000000000",
          at: "9.0",
          sm5: "114.57",
          sm15: "91.18",
          sm25: "98.66",
          sm35: "113.55",
          sm45: "98.9",
          sm55: "107.66",
          st5: "10.7",
          st15: "13.3",
          st25: "15.0",
          st35: "16.1",
          st45: "16.2",
          st55: "17.1",
        },
        {
          time: "2023-09-13 04:00:00.000000000",
          at: "6.4",
          sm5: "115.41",
          sm15: "91.69",
          sm25: "98.86",
          sm35: "113.54",
          sm45: "98.94",
          sm55: "107.66",
          st5: "10.3",
          st15: "13.5",
          st25: "15.1",
          st35: "16.1",
          st45: "16.3",
          st55: "17.1",
        },
        {
          time: "2023-09-13 03:00:00.000000000",
          at: "6.7",
          sm5: "116.63",
          sm15: "92.25",
          sm25: "98.98",
          sm35: "113.55",
          sm45: "98.94",
          sm55: "107.66",
          st5: "10.5",
          st15: "13.7",
          st25: "15.1",
          st35: "16.2",
          st45: "16.3",
          st55: "17.1",
        },
        {
          time: "2023-09-13 02:00:00.000000000",
          at: "7.0",
          sm5: "117.63",
          sm15: "92.68",
          sm25: "99.11",
          sm35: "113.55",
          sm45: "98.93",
          sm55: "107.67",
          st5: "10.7",
          st15: "13.8",
          st25: "15.2",
          st35: "16.2",
          st45: "16.3",
          st55: "17.1",
        },
        {
          time: "2023-09-13 01:00:00.000000000",
          at: "7.2",
          sm5: "117.91",
          sm15: "93.12",
          sm25: "99.24",
          sm35: "113.55",
          sm45: "98.93",
          sm55: "107.67",
          st5: "10.8",
          st15: "14.0",
          st25: "15.2",
          st35: "16.2",
          st45: "16.3",
          st55: "17.1",
        },
        {
          time: "2023-09-13 00:00:00.000000000",
          at: "7.1",
          sm5: "116.59",
          sm15: "93.53",
          sm25: "99.36",
          sm35: "113.55",
          sm45: "98.93",
          sm55: "107.67",
          st5: "11.1",
          st15: "14.1",
          st25: "15.3",
          st35: "16.2",
          st45: "16.3",
          st55: "17.1",
        },
        {
          time: "2023-09-12 23:00:00.000000000",
          at: "7.1",
          sm5: "113.89",
          sm15: "93.87",
          sm25: "99.45",
          sm35: "113.54",
          sm45: "98.92",
          sm55: "107.68",
          st5: "11.2",
          st15: "14.3",
          st25: "15.3",
          st35: "16.2",
          st45: "16.3",
          st55: "17.1",
        },
        {
          time: "2023-09-12 22:00:00.000000000",
          at: "7.5",
          sm5: "115.5",
          sm15: "94.06",
          sm25: "99.51",
          sm35: "113.53",
          sm45: "98.92",
          sm55: "107.68",
          st5: "11.6",
          st15: "14.5",
          st25: "15.5",
          st35: "16.2",
          st45: "16.3",
          st55: "17.2",
        },
        {
          time: "2023-09-12 21:00:00.000000000",
          at: "8.0",
          sm5: "117.92",
          sm15: "94.18",
          sm25: "99.53",
          sm35: "113.51",
          sm45: "98.92",
          sm55: "107.69",
          st5: "12.0",
          st15: "14.7",
          st25: "15.5",
          st35: "16.2",
          st45: "16.3",
          st55: "17.2",
        },
        {
          time: "2023-09-12 20:00:00.000000000",
          at: "8.7",
          sm5: "120.79",
          sm15: "94.13",
          sm25: "99.5",
          sm35: "113.5",
          sm45: "98.91",
          sm55: "107.7",
          st5: "12.3",
          st15: "15.0",
          st25: "15.6",
          st35: "16.2",
          st45: "16.3",
          st55: "17.2",
        },
        {
          time: "2023-09-12 19:00:00.000000000",
          at: "9.3",
          sm5: "119.43",
          sm15: "93.87",
          sm25: "99.47",
          sm35: "113.48",
          sm45: "98.91",
          sm55: "107.71",
          st5: "13.0",
          st15: "15.1",
          st25: "15.5",
          st35: "16.3",
          st45: "16.3",
          st55: "17.2",
        },
        {
          time: "2023-09-12 18:00:00.000000000",
          at: "10.3",
          sm5: "119.66",
          sm15: "93.35",
          sm25: "99.41",
          sm35: "113.47",
          sm45: "98.92",
          sm55: "107.73",
          st5: "13.5",
          st15: "15.2",
          st25: "15.6",
          st35: "16.2",
          st45: "16.3",
          st55: "17.2",
        },
        {
          time: "2023-09-12 17:00:00.000000000",
          at: "11.8",
          sm5: "120.89",
          sm15: "92.66",
          sm25: "99.34",
          sm35: "113.47",
          sm45: "98.92",
          sm55: "107.74",
          st5: "14.1",
          st15: "15.3",
          st25: "15.5",
          st35: "16.2",
          st45: "16.5",
          st55: "17.3",
        },
        {
          time: "2023-09-12 16:00:00.000000000",
          at: "15.0",
          sm5: "118.19",
          sm15: "91.94",
          sm25: "99.23",
          sm35: "113.48",
          sm45: "98.92",
          sm55: "107.76",
          st5: "15.1",
          st15: "15.3",
          st25: "15.5",
          st35: "16.2",
          st45: "16.5",
          st55: "16.0",
        },
        {
          time: "2023-09-12 15:00:00.000000000",
          at: "17.8",
          sm5: "115.99",
          sm15: "91.37",
          sm25: "99.1",
          sm35: "113.54",
          sm45: "98.9",
          sm55: "107.77",
          st5: "15.6",
          st15: "15.2",
          st25: "15.3",
          st35: "16.2",
          st45: "16.5",
          st55: "17.3",
        },
        {
          time: "2023-09-12 14:00:00.000000000",
          at: "19.3",
          sm5: "114.88",
          sm15: "91.14",
          sm25: "98.95",
          sm35: "113.61",
          sm45: "98.89",
          sm55: "107.78",
          st5: "16.0",
          st15: "15.1",
          st25: "15.2",
          st35: "16.3",
          st45: "16.5",
          st55: "16.0",
        },
        {
          time: "2023-09-12 12:00:00.000000000",
          at: "20.1",
          sm5: "114.33",
          sm15: "90.97",
          sm25: "98.84",
          sm35: "113.67",
          sm45: "98.9",
          sm55: "107.79",
          st5: "16.0",
          st15: "14.8",
          st25: "15.2",
          st35: "16.2",
          st45: "16.5",
          st55: "16.0",
        },
        {
          time: "2023-09-12 11:00:00.000000000",
          at: "20.3",
          sm5: "114.01",
          sm15: "90.98",
          sm25: "98.74",
          sm35: "113.73",
          sm45: "98.91",
          sm55: "107.81",
          st5: "15.8",
          st15: "14.7",
          st25: "15.1",
          st35: "16.3",
          st45: "16.6",
          st55: "16.0",
        },
        {
          time: "2023-09-12 10:00:00.000000000",
          at: "20.8",
          sm5: "113.57",
          sm15: "90.88",
          sm25: "98.72",
          sm35: "113.78",
          sm45: "98.93",
          sm55: "107.82",
          st5: "15.6",
          st15: "14.5",
          st25: "15.1",
          st35: "16.3",
          st45: "16.6",
          st55: "16.0",
        },
        {
          time: "2023-09-12 08:00:00.000000000",
          at: "18.9",
          sm5: "113.29",
          sm15: "90.92",
          sm25: "98.84",
          sm35: "113.91",
          sm45: "98.98",
          sm55: "107.85",
          st5: "14.5",
          st15: "14.0",
          st25: "15.1",
          st35: "16.5",
          st45: "16.6",
          st55: "17.5",
        },
        {
          time: "2023-09-12 07:00:00.000000000",
          at: "18.0",
          sm5: "112.93",
          sm15: "91.03",
          sm25: "98.98",
          sm35: "113.96",
          sm45: "99.01",
          sm55: "107.86",
          st5: "13.6",
          st15: "13.7",
          st25: "15.2",
          st35: "16.5",
          st45: "16.7",
          st55: "17.5",
        },
        {
          time: "2023-09-12 06:00:00.000000000",
          at: "15.5",
          sm5: "111.77",
          sm15: "91.45",
          sm25: "99.17",
          sm35: "114.03",
          sm45: "99.03",
          sm55: "107.87",
          st5: "12.5",
          st15: "13.6",
          st25: "15.2",
          st35: "16.5",
          st45: "16.7",
          st55: "17.5",
        },
        {
          time: "2023-09-12 05:00:00.000000000",
          at: "12.0",
          sm5: "111.05",
          sm15: "92.22",
          sm25: "99.33",
          sm35: "114.08",
          sm45: "99.05",
          sm55: "107.87",
          st5: "11.5",
          st15: "13.6",
          st25: "15.3",
          st35: "16.6",
          st45: "16.7",
          st55: "16.0",
        },
        {
          time: "2023-09-12 04:00:00.000000000",
          at: "8.5",
          sm5: "111.31",
          sm15: "92.62",
          sm25: "99.55",
          sm35: "114.11",
          sm45: "99.09",
          sm55: "107.87",
          st5: "10.7",
          st15: "13.6",
          st25: "15.5",
          st35: "16.6",
          st45: "16.7",
          st55: "17.5",
        },
        {
          time: "2023-09-12 03:00:00.000000000",
          at: "6.2",
          sm5: "112.32",
          sm15: "92.95",
          sm25: "99.75",
          sm35: "114.1",
          sm45: "99.12",
          sm55: "107.88",
          st5: "10.3",
          st15: "13.8",
          st25: "15.6",
          st35: "16.7",
          st45: "16.8",
          st55: "17.5",
        },
        {
          time: "2023-09-12 02:00:00.000000000",
          at: "6.0",
          sm5: "113.93",
          sm15: "93.42",
          sm25: "99.89",
          sm35: "114.13",
          sm45: "99.12",
          sm55: "107.88",
          st5: "10.5",
          st15: "14.0",
          st25: "15.6",
          st35: "16.7",
          st45: "16.7",
          st55: "17.5",
        },
        {
          time: "2023-09-12 01:00:00.000000000",
          at: "6.0",
          sm5: "115.61",
          sm15: "93.88",
          sm25: "100.01",
          sm35: "114.15",
          sm45: "99.12",
          sm55: "107.89",
          st5: "10.6",
          st15: "14.2",
          st25: "15.7",
          st35: "16.7",
          st45: "16.8",
          st55: "17.6",
        },
        {
          time: "2023-09-12 00:00:00.000000000",
          at: "6.3",
          sm5: "117.08",
          sm15: "94.41",
          sm25: "100.13",
          sm35: "114.17",
          sm45: "99.12",
          sm55: "107.89",
          st5: "11.0",
          st15: "14.3",
          st25: "15.8",
          st35: "16.8",
          st45: "16.8",
          st55: "17.5",
        },
        {
          time: "2023-09-11 23:00:00.000000000",
          at: "6.9",
          sm5: "118.28",
          sm15: "94.87",
          sm25: "100.22",
          sm35: "114.19",
          sm45: "99.12",
          sm55: "107.9",
          st5: "11.2",
          st15: "14.6",
          st25: "15.8",
          st35: "16.8",
          st45: "16.8",
          st55: "17.5",
        },
        {
          time: "2023-09-11 22:00:00.000000000",
          at: "7.2",
          sm5: "119.17",
          sm15: "95.31",
          sm25: "100.31",
          sm35: "114.2",
          sm45: "99.12",
          sm55: "107.91",
          st5: "11.5",
          st15: "14.7",
          st25: "16.0",
          st35: "16.8",
          st45: "16.8",
          st55: "17.6",
        },
        {
          time: "2023-09-11 21:00:00.000000000",
          at: "7.6",
          sm5: "119.24",
          sm15: "95.65",
          sm25: "100.35",
          sm35: "114.21",
          sm45: "99.11",
          sm55: "107.91",
          st5: "11.8",
          st15: "15.0",
          st25: "16.1",
          st35: "16.8",
          st45: "16.8",
          st55: "17.6",
        },
        {
          time: "2023-09-11 20:00:00.000000000",
          at: "8.0",
          sm5: "116.53",
          sm15: "95.92",
          sm25: "100.36",
          sm35: "114.22",
          sm45: "99.11",
          sm55: "107.92",
          st5: "12.2",
          st15: "15.2",
          st25: "16.1",
          st35: "16.8",
          st45: "16.8",
          st55: "17.6",
        },
        {
          time: "2023-09-11 19:00:00.000000000",
          at: "8.9",
          sm5: "119.03",
          sm15: "96.01",
          sm25: "100.34",
          sm35: "114.22",
          sm45: "99.11",
          sm55: "107.93",
          st5: "12.7",
          st15: "15.3",
          st25: "16.1",
          st35: "16.8",
          st45: "16.8",
          st55: "17.6",
        },
        {
          time: "2023-09-11 18:00:00.000000000",
          at: "9.7",
          sm5: "121.98",
          sm15: "95.98",
          sm25: "100.29",
          sm35: "114.23",
          sm45: "99.12",
          sm55: "107.94",
          st5: "13.1",
          st15: "15.6",
          st25: "16.1",
          st35: "16.8",
          st45: "16.8",
          st55: "17.6",
        },
        {
          time: "2023-09-11 17:00:00.000000000",
          at: "10.6",
          sm5: "123.82",
          sm15: "95.81",
          sm25: "100.22",
          sm35: "114.24",
          sm45: "99.12",
          sm55: "107.95",
          st5: "13.7",
          st15: "15.7",
          st25: "16.1",
          st35: "16.8",
          st45: "17.0",
          st55: "17.7",
        },
        {
          time: "2023-09-11 16:00:00.000000000",
          at: "11.8",
          sm5: "121.52",
          sm15: "95.44",
          sm25: "100.16",
          sm35: "114.25",
          sm45: "99.12",
          sm55: "107.97",
          st5: "14.3",
          st15: "15.7",
          st25: "16.1",
          st35: "17.0",
          st45: "17.0",
          st55: "17.7",
        },
        {
          time: "2023-09-11 15:00:00.000000000",
          at: "15.3",
          sm5: "123.58",
          sm15: "94.94",
          sm25: "100.07",
          sm35: "114.29",
          sm45: "99.13",
          sm55: "107.98",
          st5: "15.1",
          st15: "15.8",
          st25: "16.1",
          st35: "16.8",
          st45: "17.0",
          st55: "17.7",
        },
        {
          time: "2023-09-11 11:00:00.000000000",
          at: "20.3",
          sm5: "121.08",
          sm15: "93.88",
          sm25: "99.64",
          sm35: "114.64",
          sm45: "99.12",
          sm55: "108.03",
          st5: "15.8",
          st15: "15.3",
          st25: "16.1",
          st35: "17.0",
          st45: "17.1",
          st55: "17.6",
        },
        {
          time: "2023-09-11 10:00:00.000000000",
          at: "20.7",
          sm5: "120.84",
          sm15: "93.86",
          sm25: "99.65",
          sm35: "114.71",
          sm45: "99.14",
          sm55: "108.04",
          st5: "15.6",
          st15: "15.2",
          st25: "16.0",
          st35: "17.0",
          st45: "17.1",
          st55: "17.8",
        },
        {
          time: "2023-09-11 09:00:00.000000000",
          at: "19.5",
          sm5: "120.78",
          sm15: "93.89",
          sm25: "99.73",
          sm35: "114.78",
          sm45: "99.15",
          sm55: "108.04",
          st5: "15.2",
          st15: "15.0",
          st25: "16.0",
          st35: "17.1",
          st45: "17.1",
          st55: "17.8",
        },
        {
          time: "2023-09-11 08:00:00.000000000",
          at: "18.7",
          sm5: "120.56",
          sm15: "94.05",
          sm25: "99.91",
          sm35: "114.83",
          sm45: "99.18",
          sm55: "108.06",
          st5: "14.8",
          st15: "14.7",
          st25: "16.1",
          st35: "17.1",
          st45: "17.2",
          st55: "17.8",
        },
        {
          time: "2023-09-11 07:00:00.000000000",
          at: "16.8",
          sm5: "119.58",
          sm15: "94.42",
          sm25: "100.14",
          sm35: "114.88",
          sm45: "99.19",
          sm55: "108.06",
          st5: "13.8",
          st15: "14.7",
          st25: "16.1",
          st35: "17.2",
          st45: "17.2",
          st55: "17.8",
        },
        {
          time: "2023-09-11 06:00:00.000000000",
          at: "14.0",
          sm5: "119.61",
          sm15: "94.88",
          sm25: "100.43",
          sm35: "114.91",
          sm45: "99.22",
          sm55: "108.06",
          st5: "12.8",
          st15: "14.6",
          st25: "16.2",
          st35: "17.3",
          st45: "17.2",
          st55: "17.8",
        },
        {
          time: "2023-09-11 05:00:00.000000000",
          at: "11.2",
          sm5: "120.59",
          sm15: "95.44",
          sm25: "100.72",
          sm35: "115.16",
          sm45: "99.26",
          sm55: "108.06",
          st5: "12.1",
          st15: "14.7",
          st25: "16.3",
          st35: "17.3",
          st45: "17.2",
          st55: "17.8",
        },
        {
          time: "2023-09-11 04:00:00.000000000",
          at: "8.2",
          sm5: "121.84",
          sm15: "96.02",
          sm25: "100.96",
          sm35: "115.17",
          sm45: "99.29",
          sm55: "108.07",
          st5: "11.5",
          st15: "14.8",
          st25: "16.3",
          st35: "17.3",
          st45: "17.2",
          st55: "17.8",
        },
        {
          time: "2023-09-11 03:00:00.000000000",
          at: "6.0",
          sm5: "122.67",
          sm15: "96.55",
          sm25: "101.15",
          sm35: "115.17",
          sm45: "99.31",
          sm55: "108.02",
          st5: "11.3",
          st15: "15.1",
          st25: "16.5",
          st35: "17.3",
          st45: "17.2",
          st55: "17.8",
        },
        {
          time: "2023-09-11 02:00:00.000000000",
          at: "6.3",
          sm5: "123.34",
          sm15: "97.03",
          sm25: "101.26",
          sm35: "115.18",
          sm45: "99.31",
          sm55: "108.02",
          st5: "11.7",
          st15: "15.3",
          st25: "16.6",
          st35: "17.3",
          st45: "17.2",
          st55: "17.8",
        },
        {
          time: "2023-09-11 01:00:00.000000000",
          at: "7.1",
          sm5: "123.84",
          sm15: "97.47",
          sm25: "101.33",
          sm35: "115.2",
          sm45: "99.31",
          sm55: "108.03",
          st5: "12.1",
          st15: "15.5",
          st25: "16.7",
          st35: "17.5",
          st45: "17.3",
          st55: "17.8",
        },
        {
          time: "2023-09-11 00:00:00.000000000",
          at: "7.7",
          sm5: "123.84",
          sm15: "97.93",
          sm25: "101.39",
          sm35: "115.21",
          sm45: "99.31",
          sm55: "108.03",
          st5: "12.6",
          st15: "15.7",
          st25: "16.7",
          st35: "17.5",
          st45: "17.3",
          st55: "17.8",
        },
        {
          time: "2023-09-10 23:00:00.000000000",
          at: "9.0",
          sm5: "123.32",
          sm15: "98.15",
          sm25: "101.43",
          sm35: "115.21",
          sm45: "99.32",
          sm55: "108.04",
          st5: "13.1",
          st15: "16.0",
          st25: "16.8",
          st35: "17.5",
          st45: "17.3",
          st55: "18.0",
        },
        {
          time: "2023-09-10 22:00:00.000000000",
          at: "10.4",
          sm5: "124.49",
          sm15: "98.4",
          sm25: "101.46",
          sm35: "115.22",
          sm45: "99.32",
          sm55: "108.05",
          st5: "13.6",
          st15: "16.0",
          st25: "17.0",
          st35: "17.5",
          st45: "17.3",
          st55: "18.0",
        },
        {
          time: "2023-09-10 21:00:00.000000000",
          at: "11.3",
          sm5: "127.17",
          sm15: "98.58",
          sm25: "101.47",
          sm35: "115.21",
          sm45: "99.32",
          sm55: "108.06",
          st5: "13.8",
          st15: "16.2",
          st25: "17.0",
          st35: "17.6",
          st45: "17.3",
          st55: "17.8",
        },
        {
          time: "2023-09-10 20:00:00.000000000",
          at: "12.0",
          sm5: "130.83",
          sm15: "98.7",
          sm25: "101.46",
          sm35: "115.21",
          sm45: "99.32",
          sm55: "108.06",
          st5: "14.2",
          st15: "16.3",
          st25: "17.0",
          st35: "17.6",
          st45: "17.3",
          st55: "18.0",
        },
        {
          time: "2023-09-10 19:00:00.000000000",
          at: "12.1",
          sm5: "131.16",
          sm15: "98.75",
          sm25: "101.44",
          sm35: "115.22",
          sm45: "99.32",
          sm55: "108.07",
          st5: "14.5",
          st15: "16.5",
          st25: "17.0",
          st35: "17.6",
          st45: "17.3",
          st55: "18.0",
        },
        {
          time: "2023-09-10 18:00:00.000000000",
          at: "12.0",
          sm5: "130.69",
          sm15: "98.65",
          sm25: "101.39",
          sm35: "115.21",
          sm45: "99.32",
          sm55: "108.07",
          st5: "14.8",
          st15: "16.6",
          st25: "17.1",
          st35: "17.6",
          st45: "17.3",
          st55: "18.0",
        },
        {
          time: "2023-09-10 17:00:00.000000000",
          at: "12.6",
          sm5: "132.93",
          sm15: "98.4",
          sm25: "101.35",
          sm35: "115.21",
          sm45: "99.33",
          sm55: "108.08",
          st5: "15.2",
          st15: "16.8",
          st25: "17.0",
          st35: "17.5",
          st45: "17.5",
          st55: "18.0",
        },
        {
          time: "2023-09-10 16:00:00.000000000",
          at: "14.1",
          sm5: "130.99",
          sm15: "98.05",
          sm25: "101.28",
          sm35: "115.23",
          sm45: "99.34",
          sm55: "108.08",
          st5: "15.8",
          st15: "16.8",
          st25: "17.1",
          st35: "17.5",
          st45: "17.3",
          st55: "18.0",
        },
        {
          time: "2023-09-10 15:00:00.000000000",
          at: "17.1",
          sm5: "132.08",
          sm15: "97.65",
          sm25: "101.22",
          sm35: "115.26",
          sm45: "99.35",
          sm55: "108.09",
          st5: "16.3",
          st15: "16.8",
          st25: "17.0",
          st35: "17.6",
          st45: "17.3",
          st55: "18.1",
        },
        {
          time: "2023-09-10 14:00:00.000000000",
          at: "19.0",
          sm5: "132.37",
          sm15: "97.35",
          sm25: "101.13",
          sm35: "115.33",
          sm45: "99.34",
          sm55: "108.1",
          st5: "16.7",
          st15: "16.8",
          st25: "17.0",
          st35: "17.6",
          st45: "17.5",
          st55: "17.6",
        },
        {
          time: "2023-09-10 13:00:00.000000000",
          at: "20.1",
          sm5: "132.12",
          sm15: "97.22",
          sm25: "101.05",
          sm35: "115.41",
          sm45: "99.33",
          sm55: "108.1",
          st5: "17.0",
          st15: "16.8",
          st25: "17.0",
          st35: "17.6",
          st45: "17.5",
          st55: "17.6",
        },
        {
          time: "2023-09-10 12:00:00.000000000",
          at: "20.8",
          sm5: "131.97",
          sm15: "97.24",
          sm25: "101.01",
          sm35: "115.47",
          sm45: "99.34",
          sm55: "108.11",
          st5: "17.0",
          st15: "16.6",
          st25: "17.0",
          st35: "17.6",
          st45: "17.5",
          st55: "18.1",
        },
        {
          time: "2023-09-10 11:00:00.000000000",
          at: "20.9",
          sm5: "132.31",
          sm15: "97.41",
          sm25: "101.03",
          sm35: "115.51",
          sm45: "99.35",
          sm55: "108.11",
          st5: "17.0",
          st15: "16.5",
          st25: "17.0",
          st35: "17.6",
          st45: "17.5",
          st55: "18.1",
        },
        {
          time: "2023-09-10 10:00:00.000000000",
          at: "21.6",
          sm5: "133.34",
          sm15: "97.68",
          sm25: "101.07",
          sm35: "115.57",
          sm45: "99.36",
          sm55: "108.12",
          st5: "16.8",
          st15: "16.3",
          st25: "17.0",
          st35: "17.7",
          st45: "17.6",
          st55: "17.6",
        },
        {
          time: "2023-09-10 09:00:00.000000000",
          at: "20.1",
          sm5: "134.17",
          sm15: "98.04",
          sm25: "101.18",
          sm35: "115.63",
          sm45: "99.37",
          sm55: "108.12",
          st5: "16.3",
          st15: "16.2",
          st25: "17.0",
          st35: "17.7",
          st45: "17.5",
          st55: "18.1",
        },
        {
          time: "2023-09-10 08:00:00.000000000",
          at: "19.6",
          sm5: "135.17",
          sm15: "98.45",
          sm25: "101.35",
          sm35: "115.66",
          sm45: "99.39",
          sm55: "108.13",
          st5: "16.0",
          st15: "16.1",
          st25: "17.0",
          st35: "17.7",
          st45: "17.6",
          st55: "18.1",
        },
        {
          time: "2023-09-10 07:00:00.000000000",
          at: "18.2",
          sm5: "133.23",
          sm15: "98.82",
          sm25: "101.6",
          sm35: "115.7",
          sm45: "99.41",
          sm55: "108.13",
          st5: "15.2",
          st15: "16.0",
          st25: "17.1",
          st35: "17.8",
          st45: "17.6",
          st55: "17.6",
        },
        {
          time: "2023-09-09 14:00:00.000000000",
          at: "21.3",
          sm5: "137.21",
          sm15: "96.97",
          sm25: "101.88",
          sm35: "115.71",
          sm45: "99.5",
          sm55: "108.15",
          st5: "18.8",
          st15: "17.8",
          st25: "17.5",
          st35: "18.0",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-09 13:00:00.000000000",
          at: "21.8",
          sm5: "135.8",
          sm15: "97.0",
          sm25: "101.77",
          sm35: "115.76",
          sm45: "99.51",
          sm55: "108.16",
          st5: "18.8",
          st15: "17.7",
          st25: "17.3",
          st35: "18.0",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-09 12:00:00.000000000",
          at: "22.3",
          sm5: "135.43",
          sm15: "97.1",
          sm25: "101.7",
          sm35: "115.81",
          sm45: "99.52",
          sm55: "108.16",
          st5: "18.8",
          st15: "17.6",
          st25: "17.3",
          st35: "18.0",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-09 11:00:00.000000000",
          at: "23.2",
          sm5: "135.33",
          sm15: "97.38",
          sm25: "101.66",
          sm35: "115.87",
          sm45: "99.53",
          sm55: "108.17",
          st5: "18.7",
          st15: "17.3",
          st25: "17.3",
          st35: "18.0",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-09 10:00:00.000000000",
          at: "22.7",
          sm5: "136.55",
          sm15: "97.82",
          sm25: "101.66",
          sm35: "115.94",
          sm45: "99.53",
          sm55: "108.17",
          st5: "18.2",
          st15: "17.1",
          st25: "17.3",
          st35: "18.0",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-09 09:00:00.000000000",
          at: "21.8",
          sm5: "138.33",
          sm15: "98.25",
          sm25: "101.71",
          sm35: "116.0",
          sm45: "99.55",
          sm55: "108.18",
          st5: "17.7",
          st15: "16.8",
          st25: "17.3",
          st35: "18.0",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-09 08:00:00.000000000",
          at: "21.4",
          sm5: "140.6",
          sm15: "98.74",
          sm25: "101.78",
          sm35: "116.05",
          sm45: "99.56",
          sm55: "108.19",
          st5: "17.1",
          st15: "16.6",
          st25: "17.3",
          st35: "18.1",
          st45: "17.7",
          st55: "17.6",
        },
        {
          time: "2023-09-09 07:00:00.000000000",
          at: "19.7",
          sm5: "141.11",
          sm15: "99.16",
          sm25: "101.95",
          sm35: "116.08",
          sm45: "99.57",
          sm55: "108.2",
          st5: "16.2",
          st15: "16.6",
          st25: "17.5",
          st35: "18.1",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-09 06:00:00.000000000",
          at: "17.0",
          sm5: "139.96",
          sm15: "99.56",
          sm25: "102.16",
          sm35: "116.1",
          sm45: "99.58",
          sm55: "108.21",
          st5: "15.3",
          st15: "16.5",
          st25: "17.5",
          st35: "18.2",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-09 05:00:00.000000000",
          at: "14.3",
          sm5: "138.97",
          sm15: "99.92",
          sm25: "102.34",
          sm35: "116.13",
          sm45: "99.6",
          sm55: "108.21",
          st5: "14.6",
          st15: "16.5",
          st25: "17.6",
          st35: "18.2",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-09 04:00:00.000000000",
          at: "11.4",
          sm5: "139.06",
          sm15: "100.25",
          sm25: "102.48",
          sm35: "116.13",
          sm45: "99.63",
          sm55: "108.21",
          st5: "14.1",
          st15: "16.6",
          st25: "17.7",
          st35: "18.2",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-09 03:00:00.000000000",
          at: "10.5",
          sm5: "139.82",
          sm15: "100.51",
          sm25: "102.56",
          sm35: "116.14",
          sm45: "99.64",
          sm55: "108.21",
          st5: "14.1",
          st15: "16.8",
          st25: "17.7",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-09 02:00:00.000000000",
          at: "10.8",
          sm5: "140.78",
          sm15: "100.78",
          sm25: "102.6",
          sm35: "116.14",
          sm45: "99.64",
          sm55: "108.21",
          st5: "14.3",
          st15: "17.0",
          st25: "17.8",
          st35: "18.3",
          st45: "18.0",
          st55: "18.2",
        },
        {
          time: "2023-09-09 01:00:00.000000000",
          at: "11.0",
          sm5: "141.88",
          sm15: "101.0",
          sm25: "102.63",
          sm35: "116.14",
          sm45: "99.64",
          sm55: "108.2",
          st5: "14.6",
          st15: "17.1",
          st25: "18.0",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-09 00:00:00.000000000",
          at: "11.4",
          sm5: "143.4",
          sm15: "101.2",
          sm25: "102.64",
          sm35: "116.14",
          sm45: "99.63",
          sm55: "108.2",
          st5: "15.0",
          st15: "17.3",
          st25: "18.0",
          st35: "18.3",
          st45: "17.8",
          st55: "18.3",
        },
        {
          time: "2023-09-08 23:00:00.000000000",
          at: "12.0",
          sm5: "145.68",
          sm15: "101.28",
          sm25: "102.64",
          sm35: "116.13",
          sm45: "99.64",
          sm55: "108.2",
          st5: "15.2",
          st15: "17.5",
          st25: "18.1",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-08 22:00:00.000000000",
          at: "12.4",
          sm5: "148.23",
          sm15: "101.33",
          sm25: "102.62",
          sm35: "116.11",
          sm45: "99.64",
          sm55: "108.2",
          st5: "15.5",
          st15: "17.7",
          st25: "18.1",
          st35: "18.5",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-08 21:00:00.000000000",
          at: "12.8",
          sm5: "151.24",
          sm15: "101.32",
          sm25: "102.6",
          sm35: "116.09",
          sm45: "99.64",
          sm55: "108.2",
          st5: "15.8",
          st15: "17.8",
          st25: "18.2",
          st35: "18.3",
          st45: "18.0",
          st55: "18.2",
        },
        {
          time: "2023-09-08 20:00:00.000000000",
          at: "13.4",
          sm5: "153.55",
          sm15: "101.2",
          sm25: "102.59",
          sm35: "116.07",
          sm45: "99.64",
          sm55: "108.19",
          st5: "16.2",
          st15: "18.0",
          st25: "18.2",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-08 19:00:00.000000000",
          at: "14.0",
          sm5: "155.27",
          sm15: "101.06",
          sm25: "102.53",
          sm35: "116.05",
          sm45: "99.64",
          sm55: "108.19",
          st5: "16.5",
          st15: "18.2",
          st25: "18.2",
          st35: "18.3",
          st45: "17.8",
          st55: "18.3",
        },
        {
          time: "2023-09-08 18:00:00.000000000",
          at: "14.5",
          sm5: "155.86",
          sm15: "100.88",
          sm25: "102.48",
          sm35: "116.04",
          sm45: "99.64",
          sm55: "108.19",
          st5: "16.8",
          st15: "18.3",
          st25: "18.2",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-08 17:00:00.000000000",
          at: "15.1",
          sm5: "156.25",
          sm15: "100.63",
          sm25: "102.44",
          sm35: "116.03",
          sm45: "99.65",
          sm55: "108.19",
          st5: "17.2",
          st15: "18.5",
          st25: "18.2",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-08 16:00:00.000000000",
          at: "17.2",
          sm5: "156.99",
          sm15: "100.34",
          sm25: "102.38",
          sm35: "116.04",
          sm45: "99.65",
          sm55: "108.19",
          st5: "17.7",
          st15: "18.5",
          st25: "18.2",
          st35: "18.3",
          st45: "17.8",
          st55: "18.3",
        },
        {
          time: "2023-09-08 15:00:00.000000000",
          at: "19.1",
          sm5: "156.67",
          sm15: "100.05",
          sm25: "102.3",
          sm35: "116.05",
          sm45: "99.65",
          sm55: "108.18",
          st5: "18.1",
          st15: "18.5",
          st25: "18.2",
          st35: "18.3",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-09-08 14:00:00.000000000",
          at: "20.3",
          sm5: "155.51",
          sm15: "99.85",
          sm25: "102.22",
          sm35: "116.1",
          sm45: "99.64",
          sm55: "108.18",
          st5: "18.3",
          st15: "18.5",
          st25: "18.2",
          st35: "18.3",
          st45: "18.0",
          st55: "17.6",
        },
        {
          time: "2023-09-08 13:00:00.000000000",
          at: "20.5",
          sm5: "153.93",
          sm15: "99.83",
          sm25: "102.2",
          sm35: "116.13",
          sm45: "99.65",
          sm55: "108.18",
          st5: "18.5",
          st15: "18.5",
          st25: "18.2",
          st35: "18.3",
          st45: "18.0",
          st55: "18.2",
        },
        {
          time: "2023-09-08 12:00:00.000000000",
          at: "20.8",
          sm5: "153.61",
          sm15: "100.06",
          sm25: "102.16",
          sm35: "116.16",
          sm45: "99.65",
          sm55: "108.18",
          st5: "18.8",
          st15: "18.5",
          st25: "18.1",
          st35: "18.3",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-09-08 11:00:00.000000000",
          at: "20.6",
          sm5: "151.86",
          sm15: "100.38",
          sm25: "102.15",
          sm35: "116.19",
          sm45: "99.65",
          sm55: "108.19",
          st5: "19.1",
          st15: "18.2",
          st25: "18.1",
          st35: "18.5",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-09-08 10:00:00.000000000",
          at: "23.1",
          sm5: "148.09",
          sm15: "100.94",
          sm25: "102.11",
          sm35: "116.28",
          sm45: "99.64",
          sm55: "108.18",
          st5: "19.1",
          st15: "18.1",
          st25: "18.1",
          st35: "18.5",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-09-08 08:00:00.000000000",
          at: "21.5",
          sm5: "149.17",
          sm15: "101.43",
          sm25: "102.34",
          sm35: "116.34",
          sm45: "99.65",
          sm55: "108.19",
          st5: "18.0",
          st15: "17.8",
          st25: "18.1",
          st35: "18.5",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-09-08 07:00:00.000000000",
          at: "20.0",
          sm5: "149.21",
          sm15: "101.58",
          sm25: "102.48",
          sm35: "116.35",
          sm45: "99.66",
          sm55: "108.2",
          st5: "17.3",
          st15: "17.7",
          st25: "18.2",
          st35: "18.5",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-09-08 06:00:00.000000000",
          at: "18.0",
          sm5: "148.98",
          sm15: "101.5",
          sm25: "102.6",
          sm35: "116.35",
          sm45: "99.67",
          sm55: "108.2",
          st5: "16.8",
          st15: "17.7",
          st25: "18.2",
          st35: "18.5",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-09-08 05:00:00.000000000",
          at: "16.3",
          sm5: "148.37",
          sm15: "101.52",
          sm25: "102.71",
          sm35: "116.34",
          sm45: "99.7",
          sm55: "108.21",
          st5: "16.5",
          st15: "17.8",
          st25: "18.3",
          st35: "18.5",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-09-08 04:00:00.000000000",
          at: "14.1",
          sm5: "147.84",
          sm15: "101.47",
          sm25: "102.74",
          sm35: "116.26",
          sm45: "99.74",
          sm55: "108.2",
          st5: "16.1",
          st15: "18.0",
          st25: "18.3",
          st35: "18.5",
          st45: "18.0",
          st55: "18.2",
        },
        {
          time: "2023-09-08 03:00:00.000000000",
          at: "13.4",
          sm5: "149.31",
          sm15: "101.51",
          sm25: "102.75",
          sm35: "116.22",
          sm45: "99.75",
          sm55: "108.2",
          st5: "16.2",
          st15: "18.1",
          st25: "18.5",
          st35: "18.5",
          st45: "18.0",
          st55: "18.2",
        },
        {
          time: "2023-09-08 02:00:00.000000000",
          at: "14.5",
          sm5: "150.53",
          sm15: "101.26",
          sm25: "102.76",
          sm35: "116.2",
          sm45: "99.75",
          sm55: "108.19",
          st5: "16.5",
          st15: "18.2",
          st25: "18.5",
          st35: "18.5",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-09-08 01:00:00.000000000",
          at: "14.8",
          sm5: "150.51",
          sm15: "100.87",
          sm25: "102.77",
          sm35: "116.17",
          sm45: "99.75",
          sm55: "108.19",
          st5: "16.7",
          st15: "18.3",
          st25: "18.5",
          st35: "18.6",
          st45: "18.0",
          st55: "18.2",
        },
        {
          time: "2023-09-08 00:00:00.000000000",
          at: "14.8",
          sm5: "150.26",
          sm15: "99.41",
          sm25: "102.94",
          sm35: "116.08",
          sm45: "99.75",
          sm55: "108.18",
          st5: "17.0",
          st15: "18.5",
          st25: "18.5",
          st35: "18.6",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 23:00:00.000000000",
          at: "15.6",
          sm5: "149.99",
          sm15: "97.97",
          sm25: "103.08",
          sm35: "116.01",
          sm45: "99.76",
          sm55: "108.17",
          st5: "17.2",
          st15: "18.6",
          st25: "18.6",
          st35: "18.5",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 22:00:00.000000000",
          at: "16.2",
          sm5: "149.28",
          sm15: "97.36",
          sm25: "103.12",
          sm35: "115.98",
          sm45: "99.76",
          sm55: "108.16",
          st5: "17.5",
          st15: "18.7",
          st25: "18.6",
          st35: "18.5",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 21:00:00.000000000",
          at: "16.9",
          sm5: "147.97",
          sm15: "96.76",
          sm25: "103.14",
          sm35: "115.99",
          sm45: "99.75",
          sm55: "108.16",
          st5: "17.7",
          st15: "19.0",
          st25: "18.6",
          st35: "18.5",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 20:00:00.000000000",
          at: "17.0",
          sm5: "146.34",
          sm15: "96.11",
          sm25: "103.09",
          sm35: "115.71",
          sm45: "99.78",
          sm55: "108.15",
          st5: "18.0",
          st15: "19.1",
          st25: "18.6",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 19:00:00.000000000",
          at: "16.5",
          sm5: "143.72",
          sm15: "95.48",
          sm25: "103.06",
          sm35: "115.27",
          sm45: "99.8",
          sm55: "108.14",
          st5: "18.2",
          st15: "19.2",
          st25: "18.6",
          st35: "18.5",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 18:00:00.000000000",
          at: "16.6",
          sm5: "141.96",
          sm15: "94.81",
          sm25: "103.04",
          sm35: "115.13",
          sm45: "99.82",
          sm55: "108.14",
          st5: "18.6",
          st15: "19.3",
          st25: "18.6",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 17:00:00.000000000",
          at: "17.1",
          sm5: "139.76",
          sm15: "94.4",
          sm25: "103.0",
          sm35: "115.45",
          sm45: "99.81",
          sm55: "108.14",
          st5: "19.1",
          st15: "19.5",
          st25: "18.6",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 16:00:00.000000000",
          at: "19.1",
          sm5: "140.48",
          sm15: "94.17",
          sm25: "102.94",
          sm35: "115.56",
          sm45: "99.8",
          sm55: "108.14",
          st5: "19.7",
          st15: "19.5",
          st25: "18.5",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 15:00:00.000000000",
          at: "21.5",
          sm5: "140.14",
          sm15: "94.09",
          sm25: "102.84",
          sm35: "115.58",
          sm45: "99.79",
          sm55: "108.13",
          st5: "20.3",
          st15: "19.5",
          st25: "18.3",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 14:00:00.000000000",
          at: "21.8",
          sm5: "137.32",
          sm15: "94.32",
          sm25: "102.75",
          sm35: "115.63",
          sm45: "99.78",
          sm55: "108.13",
          st5: "20.7",
          st15: "19.3",
          st25: "18.3",
          st35: "18.2",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 13:00:00.000000000",
          at: "22.8",
          sm5: "134.68",
          sm15: "95.71",
          sm25: "102.68",
          sm35: "115.71",
          sm45: "99.79",
          sm55: "108.13",
          st5: "20.8",
          st15: "19.1",
          st25: "18.2",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 12:00:00.000000000",
          at: "25.3",
          sm5: "131.45",
          sm15: "97.35",
          sm25: "102.61",
          sm35: "115.84",
          sm45: "99.77",
          sm55: "108.14",
          st5: "21.0",
          st15: "18.8",
          st25: "18.1",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-07 11:00:00.000000000",
          at: "25.3",
          sm5: "136.04",
          sm15: "98.79",
          sm25: "102.61",
          sm35: "115.88",
          sm45: "99.77",
          sm55: "108.15",
          st5: "20.8",
          st15: "18.7",
          st25: "18.1",
          st35: "18.3",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-06 10:00:00.000000000",
          at: "26.3",
          sm5: "134.0",
          sm15: "97.2",
          sm25: "102.96",
          sm35: "116.29",
          sm45: "99.76",
          sm55: "108.18",
          st5: "20.8",
          st15: "18.2",
          st25: "17.8",
          st35: "18.1",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-06 09:00:00.000000000",
          at: "25.4",
          sm5: "135.96",
          sm15: "98.5",
          sm25: "102.97",
          sm35: "116.35",
          sm45: "99.76",
          sm55: "108.19",
          st5: "20.2",
          st15: "18.0",
          st25: "17.7",
          st35: "18.2",
          st45: "17.8",
          st55: "18.2",
        },
        {
          time: "2023-09-06 08:00:00.000000000",
          at: "24.8",
          sm5: "140.76",
          sm15: "99.51",
          sm25: "102.97",
          sm35: "116.37",
          sm45: "99.75",
          sm55: "108.2",
          st5: "19.7",
          st15: "17.7",
          st25: "17.7",
          st35: "18.1",
          st45: "17.8",
          st55: "18.1",
        },
        {
          time: "2023-09-06 07:00:00.000000000",
          at: "23.1",
          sm5: "143.85",
          sm15: "100.16",
          sm25: "103.0",
          sm35: "116.39",
          sm45: "99.75",
          sm55: "108.21",
          st5: "18.5",
          st15: "17.5",
          st25: "17.7",
          st35: "18.1",
          st45: "17.8",
          st55: "17.6",
        },
        {
          time: "2023-09-06 06:00:00.000000000",
          at: "20.7",
          sm5: "142.3",
          sm15: "100.3",
          sm25: "103.19",
          sm35: "116.38",
          sm45: "99.75",
          sm55: "108.23",
          st5: "17.5",
          st15: "17.3",
          st25: "17.7",
          st35: "18.2",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-06 05:00:00.000000000",
          at: "18.0",
          sm5: "140.87",
          sm15: "100.5",
          sm25: "103.38",
          sm35: "116.4",
          sm45: "99.76",
          sm55: "108.23",
          st5: "16.6",
          st15: "17.3",
          st25: "17.8",
          st35: "18.2",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-06 04:00:00.000000000",
          at: "15.5",
          sm5: "140.38",
          sm15: "100.56",
          sm25: "103.54",
          sm35: "116.4",
          sm45: "99.79",
          sm55: "108.23",
          st5: "16.0",
          st15: "17.3",
          st25: "18.0",
          st35: "18.2",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-06 03:00:00.000000000",
          at: "13.1",
          sm5: "140.7",
          sm15: "100.55",
          sm25: "103.72",
          sm35: "116.34",
          sm45: "99.81",
          sm55: "108.23",
          st5: "15.5",
          st15: "17.5",
          st25: "18.0",
          st35: "18.2",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-06 02:00:00.000000000",
          at: "13.1",
          sm5: "142.47",
          sm15: "99.82",
          sm25: "103.92",
          sm35: "116.28",
          sm45: "99.82",
          sm55: "108.22",
          st5: "15.7",
          st15: "17.6",
          st25: "18.0",
          st35: "18.2",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-06 01:00:00.000000000",
          at: "13.3",
          sm5: "143.84",
          sm15: "99.36",
          sm25: "103.96",
          sm35: "116.24",
          sm45: "99.82",
          sm55: "108.22",
          st5: "15.8",
          st15: "17.7",
          st25: "18.1",
          st35: "18.2",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-06 00:00:00.000000000",
          at: "13.4",
          sm5: "145.37",
          sm15: "99.09",
          sm25: "103.96",
          sm35: "116.2",
          sm45: "99.82",
          sm55: "108.22",
          st5: "16.1",
          st15: "18.0",
          st25: "18.1",
          st35: "18.2",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-05 23:00:00.000000000",
          at: "13.8",
          sm5: "146.11",
          sm15: "98.46",
          sm25: "104.02",
          sm35: "116.16",
          sm45: "99.82",
          sm55: "108.21",
          st5: "16.5",
          st15: "18.1",
          st25: "18.2",
          st35: "18.2",
          st45: "17.7",
          st55: "18.1",
        },
        {
          time: "2023-09-05 22:00:00.000000000",
          at: "14.6",
          sm5: "143.07",
          sm15: "98.05",
          sm25: "104.06",
          sm35: "116.14",
          sm45: "99.82",
          sm55: "108.21",
          st5: "16.7",
          st15: "18.2",
          st25: "18.2",
          st35: "18.2",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-05 21:00:00.000000000",
          at: "14.5",
          sm5: "142.24",
          sm15: "97.65",
          sm25: "104.07",
          sm35: "116.13",
          sm45: "99.82",
          sm55: "108.2",
          st5: "17.0",
          st15: "18.3",
          st25: "18.2",
          st35: "18.2",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-05 20:00:00.000000000",
          at: "14.8",
          sm5: "141.73",
          sm15: "97.15",
          sm25: "104.06",
          sm35: "116.09",
          sm45: "99.82",
          sm55: "108.2",
          st5: "17.2",
          st15: "18.5",
          st25: "18.2",
          st35: "18.1",
          st45: "17.7",
          st55: "18.1",
        },
        {
          time: "2023-09-05 19:00:00.000000000",
          at: "15.2",
          sm5: "139.96",
          sm15: "96.56",
          sm25: "104.04",
          sm35: "116.08",
          sm45: "99.83",
          sm55: "108.2",
          st5: "17.6",
          st15: "18.6",
          st25: "18.2",
          st35: "18.1",
          st45: "17.6",
          st55: "18.2",
        },
        {
          time: "2023-09-05 18:00:00.000000000",
          at: "16.0",
          sm5: "138.82",
          sm15: "96.09",
          sm25: "103.54",
          sm35: "116.12",
          sm45: "99.83",
          sm55: "108.2",
          st5: "18.0",
          st15: "18.8",
          st25: "18.2",
          st35: "18.1",
          st45: "17.7",
          st55: "17.6",
        },
        {
          time: "2023-09-05 17:00:00.000000000",
          at: "16.6",
          sm5: "138.54",
          sm15: "95.58",
          sm25: "103.47",
          sm35: "116.11",
          sm45: "99.83",
          sm55: "108.2",
          st5: "18.5",
          st15: "18.8",
          st25: "18.1",
          st35: "18.1",
          st45: "17.7",
          st55: "18.2",
        },
        {
          time: "2023-09-04 13:00:00.000000000",
          at: "20.0",
          sm5: "148.42",
          sm15: "97.84",
          sm25: "104.05",
          sm35: "117.05",
          sm45: "99.78",
          sm55: "108.26",
          st5: "17.8",
          st15: "17.3",
          st25: "17.6",
          st35: "18.1",
          st45: "17.8",
          st55: "18.5",
        },
        {
          time: "2023-09-04 12:00:00.000000000",
          at: "19.8",
          sm5: "149.56",
          sm15: "98.28",
          sm25: "104.34",
          sm35: "117.15",
          sm45: "99.79",
          sm55: "108.27",
          st5: "17.5",
          st15: "17.2",
          st25: "17.6",
          st35: "18.1",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-09-04 11:00:00.000000000",
          at: "18.1",
          sm5: "150.8",
          sm15: "98.53",
          sm25: "104.84",
          sm35: "117.21",
          sm45: "99.82",
          sm55: "108.29",
          st5: "16.8",
          st15: "17.1",
          st25: "17.5",
          st35: "18.1",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-09-04 10:00:00.000000000",
          at: "17.0",
          sm5: "151.92",
          sm15: "98.89",
          sm25: "105.52",
          sm35: "117.25",
          sm45: "99.84",
          sm55: "108.3",
          st5: "16.5",
          st15: "17.0",
          st25: "17.6",
          st35: "18.2",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-09-04 09:00:00.000000000",
          at: "15.6",
          sm5: "153.36",
          sm15: "99.45",
          sm25: "106.19",
          sm35: "117.26",
          sm45: "99.86",
          sm55: "108.31",
          st5: "16.0",
          st15: "17.1",
          st25: "17.6",
          st35: "18.3",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-09-04 08:00:00.000000000",
          at: "14.8",
          sm5: "158.1",
          sm15: "101.02",
          sm25: "107.07",
          sm35: "117.26",
          sm45: "99.87",
          sm55: "108.31",
          st5: "15.8",
          st15: "17.1",
          st25: "17.6",
          st35: "18.2",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-09-04 07:00:00.000000000",
          at: "14.5",
          sm5: "166.4",
          sm15: "106.76",
          sm25: "109.3",
          sm35: "117.18",
          sm45: "99.88",
          sm55: "108.29",
          st5: "16.0",
          st15: "17.1",
          st25: "17.7",
          st35: "18.2",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-09-04 06:00:00.000000000",
          at: "15.1",
          sm5: "164.72",
          sm15: "104.18",
          sm25: "107.39",
          sm35: "117.1",
          sm45: "99.81",
          sm55: "108.26",
          st5: "16.2",
          st15: "17.3",
          st25: "17.8",
          st35: "18.3",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-09-04 05:00:00.000000000",
          at: "15.5",
          sm5: "158.73",
          sm15: "99.95",
          sm25: "104.18",
          sm35: "116.8",
          sm45: "99.78",
          sm55: "108.24",
          st5: "16.3",
          st15: "17.3",
          st25: "17.8",
          st35: "18.3",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-09-04 04:00:00.000000000",
          at: "15.3",
          sm5: "157.36",
          sm15: "99.19",
          sm25: "103.73",
          sm35: "116.69",
          sm45: "99.77",
          sm55: "108.23",
          st5: "16.3",
          st15: "17.5",
          st25: "18.0",
          st35: "18.3",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-09-04 03:00:00.000000000",
          at: "15.3",
          sm5: "153.17",
          sm15: "98.56",
          sm25: "103.4",
          sm35: "116.67",
          sm45: "99.77",
          sm55: "108.24",
          st5: "16.5",
          st15: "17.5",
          st25: "18.0",
          st35: "18.5",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-09-04 02:00:00.000000000",
          at: "15.3",
          sm5: "150.78",
          sm15: "98.58",
          sm25: "103.21",
          sm35: "116.7",
          sm45: "99.77",
          sm55: "108.24",
          st5: "16.5",
          st15: "17.6",
          st25: "18.0",
          st35: "18.5",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-09-04 01:00:00.000000000",
          at: "15.3",
          sm5: "151.03",
          sm15: "98.67",
          sm25: "103.27",
          sm35: "116.74",
          sm45: "99.77",
          sm55: "108.25",
          st5: "16.5",
          st15: "17.6",
          st25: "18.0",
          st35: "18.5",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-09-04 00:00:00.000000000",
          at: "15.3",
          sm5: "151.56",
          sm15: "98.73",
          sm25: "103.32",
          sm35: "116.77",
          sm45: "99.77",
          sm55: "108.25",
          st5: "16.5",
          st15: "17.6",
          st25: "18.0",
          st35: "18.5",
          st45: "18.1",
          st55: "18.6",
        },
        {
          time: "2023-09-03 23:00:00.000000000",
          at: "15.1",
          sm5: "151.96",
          sm15: "98.85",
          sm25: "103.42",
          sm35: "116.81",
          sm45: "99.77",
          sm55: "108.25",
          st5: "16.6",
          st15: "17.7",
          st25: "18.1",
          st35: "18.5",
          st45: "18.1",
          st55: "18.6",
        },
        {
          time: "2023-09-03 22:00:00.000000000",
          at: "15.1",
          sm5: "152.85",
          sm15: "98.92",
          sm25: "103.55",
          sm35: "116.85",
          sm45: "99.78",
          sm55: "108.26",
          st5: "16.6",
          st15: "17.7",
          st25: "18.1",
          st35: "18.5",
          st45: "18.1",
          st55: "18.6",
        },
        {
          time: "2023-09-03 21:00:00.000000000",
          at: "15.4",
          sm5: "153.89",
          sm15: "99.0",
          sm25: "103.68",
          sm35: "116.89",
          sm45: "99.78",
          sm55: "108.26",
          st5: "16.7",
          st15: "17.8",
          st25: "18.1",
          st35: "18.5",
          st45: "18.1",
          st55: "18.6",
        },
        {
          time: "2023-09-03 20:00:00.000000000",
          at: "15.6",
          sm5: "155.06",
          sm15: "99.15",
          sm25: "103.81",
          sm35: "116.93",
          sm45: "99.79",
          sm55: "108.27",
          st5: "16.7",
          st15: "18.0",
          st25: "18.2",
          st35: "18.6",
          st45: "18.1",
          st55: "18.6",
        },
        {
          time: "2023-09-03 19:00:00.000000000",
          at: "15.6",
          sm5: "157.0",
          sm15: "99.17",
          sm25: "103.98",
          sm35: "116.97",
          sm45: "99.79",
          sm55: "108.27",
          st5: "16.8",
          st15: "18.0",
          st25: "18.2",
          st35: "18.6",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 18:00:00.000000000",
          at: "15.6",
          sm5: "158.28",
          sm15: "99.23",
          sm25: "104.17",
          sm35: "117.04",
          sm45: "99.79",
          sm55: "108.28",
          st5: "17.0",
          st15: "18.0",
          st25: "18.2",
          st35: "18.6",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 17:00:00.000000000",
          at: "16.0",
          sm5: "159.45",
          sm15: "99.29",
          sm25: "104.43",
          sm35: "117.09",
          sm45: "99.79",
          sm55: "108.28",
          st5: "17.1",
          st15: "18.1",
          st25: "18.2",
          st35: "18.6",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 16:00:00.000000000",
          at: "16.2",
          sm5: "160.36",
          sm15: "99.33",
          sm25: "104.7",
          sm35: "117.12",
          sm45: "99.8",
          sm55: "108.28",
          st5: "17.2",
          st15: "18.1",
          st25: "18.2",
          st35: "18.6",
          st45: "18.2",
          st55: "18.5",
        },
        {
          time: "2023-09-03 15:00:00.000000000",
          at: "16.3",
          sm5: "159.57",
          sm15: "99.42",
          sm25: "105.23",
          sm35: "117.15",
          sm45: "99.8",
          sm55: "108.28",
          st5: "17.5",
          st15: "18.1",
          st25: "18.2",
          st35: "18.6",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 14:00:00.000000000",
          at: "16.5",
          sm5: "161.25",
          sm15: "99.51",
          sm25: "105.44",
          sm35: "117.11",
          sm45: "99.81",
          sm55: "108.27",
          st5: "17.6",
          st15: "18.1",
          st25: "18.2",
          st35: "18.7",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 13:00:00.000000000",
          at: "17.1",
          sm5: "160.67",
          sm15: "99.71",
          sm25: "106.07",
          sm35: "117.15",
          sm45: "99.8",
          sm55: "108.26",
          st5: "17.7",
          st15: "18.1",
          st25: "18.2",
          st35: "18.7",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 12:00:00.000000000",
          at: "17.6",
          sm5: "161.28",
          sm15: "100.52",
          sm25: "106.75",
          sm35: "117.21",
          sm45: "99.78",
          sm55: "108.21",
          st5: "17.6",
          st15: "18.0",
          st25: "18.3",
          st35: "18.7",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 11:00:00.000000000",
          at: "17.1",
          sm5: "163.15",
          sm15: "102.16",
          sm25: "107.42",
          sm35: "117.25",
          sm45: "99.7",
          sm55: "107.87",
          st5: "17.5",
          st15: "18.0",
          st25: "18.3",
          st35: "18.7",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 10:00:00.000000000",
          at: "16.6",
          sm5: "168.01",
          sm15: "104.79",
          sm25: "109.1",
          sm35: "117.24",
          sm45: "99.5",
          sm55: "107.83",
          st5: "17.2",
          st15: "18.0",
          st25: "18.3",
          st35: "18.8",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 09:00:00.000000000",
          at: "16.3",
          sm5: "168.51",
          sm15: "106.3",
          sm25: "108.8",
          sm35: "117.14",
          sm45: "98.58",
          sm55: "107.71",
          st5: "17.2",
          st15: "18.1",
          st25: "18.5",
          st35: "18.8",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 08:00:00.000000000",
          at: "16.1",
          sm5: "163.33",
          sm15: "103.31",
          sm25: "106.64",
          sm35: "115.67",
          sm45: "98.9",
          sm55: "107.65",
          st5: "17.3",
          st15: "18.2",
          st25: "18.6",
          st35: "18.8",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 07:00:00.000000000",
          at: "16.7",
          sm5: "158.16",
          sm15: "101.53",
          sm25: "104.93",
          sm35: "114.35",
          sm45: "98.94",
          sm55: "107.68",
          st5: "17.3",
          st15: "18.3",
          st25: "18.7",
          st35: "18.8",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 06:00:00.000000000",
          at: "16.0",
          sm5: "162.98",
          sm15: "103.88",
          sm25: "104.33",
          sm35: "114.65",
          sm45: "98.89",
          sm55: "107.68",
          st5: "17.2",
          st15: "18.3",
          st25: "18.7",
          st35: "18.8",
          st45: "18.2",
          st55: "17.6",
        },
        {
          time: "2023-09-03 05:00:00.000000000",
          at: "16.0",
          sm5: "157.34",
          sm15: "99.2",
          sm25: "94.83",
          sm35: "115.35",
          sm45: "98.76",
          sm55: "107.69",
          st5: "17.3",
          st15: "18.5",
          st25: "18.7",
          st35: "18.8",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 04:00:00.000000000",
          at: "16.1",
          sm5: "145.48",
          sm15: "95.65",
          sm25: "94.9",
          sm35: "115.26",
          sm45: "98.78",
          sm55: "107.69",
          st5: "17.6",
          st15: "18.7",
          st25: "18.7",
          st35: "18.8",
          st45: "18.2",
          st55: "18.5",
        },
        {
          time: "2023-09-03 03:00:00.000000000",
          at: "16.1",
          sm5: "145.28",
          sm15: "95.28",
          sm25: "94.93",
          sm35: "115.24",
          sm45: "98.79",
          sm55: "107.68",
          st5: "17.6",
          st15: "18.7",
          st25: "18.8",
          st35: "18.8",
          st45: "18.3",
          st55: "18.6",
        },
        {
          time: "2023-09-03 02:00:00.000000000",
          at: "16.5",
          sm5: "142.63",
          sm15: "95.21",
          sm25: "94.97",
          sm35: "115.23",
          sm45: "98.8",
          sm55: "107.68",
          st5: "17.7",
          st15: "18.8",
          st25: "18.8",
          st35: "18.8",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-03 01:00:00.000000000",
          at: "16.7",
          sm5: "143.62",
          sm15: "94.77",
          sm25: "94.96",
          sm35: "115.2",
          sm45: "98.81",
          sm55: "107.68",
          st5: "18.0",
          st15: "18.8",
          st25: "18.8",
          st35: "18.8",
          st45: "18.2",
          st55: "18.5",
        },
        {
          time: "2023-09-03 00:00:00.000000000",
          at: "16.7",
          sm5: "142.53",
          sm15: "94.38",
          sm25: "94.96",
          sm35: "115.18",
          sm45: "98.82",
          sm55: "107.68",
          st5: "18.0",
          st15: "19.0",
          st25: "18.8",
          st35: "18.8",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-02 23:00:00.000000000",
          at: "16.8",
          sm5: "141.37",
          sm15: "93.95",
          sm25: "94.94",
          sm35: "115.16",
          sm45: "98.83",
          sm55: "107.68",
          st5: "18.2",
          st15: "19.1",
          st25: "18.8",
          st35: "18.8",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-02 22:00:00.000000000",
          at: "17.0",
          sm5: "139.96",
          sm15: "93.68",
          sm25: "94.93",
          sm35: "115.17",
          sm45: "98.83",
          sm55: "107.68",
          st5: "18.3",
          st15: "19.2",
          st25: "19.0",
          st35: "18.8",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-02 21:00:00.000000000",
          at: "17.1",
          sm5: "138.2",
          sm15: "93.5",
          sm25: "94.89",
          sm35: "115.18",
          sm45: "98.84",
          sm55: "107.68",
          st5: "18.5",
          st15: "19.3",
          st25: "19.0",
          st35: "18.8",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-02 20:00:00.000000000",
          at: "18.1",
          sm5: "136.47",
          sm15: "93.61",
          sm25: "94.79",
          sm35: "115.19",
          sm45: "98.85",
          sm55: "107.67",
          st5: "18.8",
          st15: "19.3",
          st25: "19.0",
          st35: "18.8",
          st45: "18.2",
          st55: "18.5",
        },
        {
          time: "2023-09-02 19:00:00.000000000",
          at: "20.6",
          sm5: "135.35",
          sm15: "93.3",
          sm25: "94.72",
          sm35: "115.19",
          sm45: "98.86",
          sm55: "107.68",
          st5: "18.8",
          st15: "19.3",
          st25: "19.0",
          st35: "18.7",
          st45: "18.2",
          st55: "18.5",
        },
        {
          time: "2023-09-02 18:00:00.000000000",
          at: "21.1",
          sm5: "134.29",
          sm15: "93.0",
          sm25: "94.63",
          sm35: "115.19",
          sm45: "98.87",
          sm55: "107.68",
          st5: "19.2",
          st15: "19.5",
          st25: "19.0",
          st35: "18.7",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-09-02 17:00:00.000000000",
          at: "21.1",
          sm5: "133.05",
          sm15: "92.76",
          sm25: "94.54",
          sm35: "115.2",
          sm45: "98.88",
          sm55: "107.68",
          st5: "19.5",
          st15: "19.6",
          st25: "18.8",
          st35: "18.7",
          st45: "18.2",
          st55: "18.5",
        },
        {
          time: "2023-09-02 16:00:00.000000000",
          at: "22.0",
          sm5: "131.88",
          sm15: "92.39",
          sm25: "94.42",
          sm35: "115.23",
          sm45: "98.88",
          sm55: "107.68",
          st5: "19.7",
          st15: "19.6",
          st25: "18.7",
          st35: "18.7",
          st45: "18.2",
          st55: "18.5",
        },
        {
          time: "2023-09-02 15:00:00.000000000",
          at: "23.2",
          sm5: "130.72",
          sm15: "92.07",
          sm25: "94.28",
          sm35: "115.26",
          sm45: "98.87",
          sm55: "107.67",
          st5: "20.1",
          st15: "19.6",
          st25: "18.7",
          st35: "18.7",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-08-31 07:00:00.000000000",
          at: "21.3",
          sm5: "130.72",
          sm15: "89.17",
          sm25: "96.84",
          sm35: "114.22",
          sm45: "99.93",
          sm55: "107.95",
          st5: "18.2",
          st15: "17.1",
          st25: "17.7",
          st35: "18.3",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-08-31 06:00:00.000000000",
          at: "19.4",
          sm5: "126.59",
          sm15: "90.44",
          sm25: "96.99",
          sm35: "114.28",
          sm45: "99.97",
          sm55: "107.96",
          st5: "16.6",
          st15: "17.0",
          st25: "17.8",
          st35: "18.3",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-08-31 05:00:00.000000000",
          at: "15.6",
          sm5: "118.2",
          sm15: "90.92",
          sm25: "97.32",
          sm35: "114.28",
          sm45: "100.01",
          sm55: "107.96",
          st5: "15.3",
          st15: "17.0",
          st25: "17.8",
          st35: "18.3",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-08-31 04:00:00.000000000",
          at: "12.5",
          sm5: "117.46",
          sm15: "91.22",
          sm25: "97.56",
          sm35: "114.25",
          sm45: "100.05",
          sm55: "107.96",
          st5: "14.6",
          st15: "17.1",
          st25: "18.0",
          st35: "18.3",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-08-31 03:00:00.000000000",
          at: "11.6",
          sm5: "118.28",
          sm15: "91.48",
          sm25: "97.7",
          sm35: "114.22",
          sm45: "100.06",
          sm55: "107.96",
          st5: "14.6",
          st15: "17.3",
          st25: "18.1",
          st35: "18.3",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-08-31 02:00:00.000000000",
          at: "12.1",
          sm5: "119.55",
          sm15: "91.73",
          sm25: "97.79",
          sm35: "114.18",
          sm45: "100.06",
          sm55: "107.96",
          st5: "15.0",
          st15: "17.6",
          st25: "18.1",
          st35: "18.5",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-08-31 01:00:00.000000000",
          at: "12.3",
          sm5: "120.36",
          sm15: "91.84",
          sm25: "97.84",
          sm35: "114.14",
          sm45: "100.08",
          sm55: "107.96",
          st5: "15.2",
          st15: "17.7",
          st25: "18.2",
          st35: "18.5",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-08-31 00:00:00.000000000",
          at: "12.9",
          sm5: "121.14",
          sm15: "91.71",
          sm25: "97.88",
          sm35: "114.13",
          sm45: "100.1",
          sm55: "107.95",
          st5: "15.7",
          st15: "18.0",
          st25: "18.2",
          st35: "18.3",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-08-30 23:00:00.000000000",
          at: "14.1",
          sm5: "121.14",
          sm15: "91.37",
          sm25: "97.9",
          sm35: "114.06",
          sm45: "100.11",
          sm55: "107.96",
          st5: "16.1",
          st15: "18.1",
          st25: "18.3",
          st35: "18.3",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-08-30 22:00:00.000000000",
          at: "14.1",
          sm5: "120.94",
          sm15: "90.98",
          sm25: "97.92",
          sm35: "114.01",
          sm45: "100.13",
          sm55: "107.96",
          st5: "16.3",
          st15: "18.3",
          st25: "18.3",
          st35: "18.3",
          st45: "17.8",
          st55: "18.3",
        },
        {
          time: "2023-08-30 21:00:00.000000000",
          at: "14.5",
          sm5: "120.36",
          sm15: "90.54",
          sm25: "97.91",
          sm35: "114.08",
          sm45: "100.15",
          sm55: "107.95",
          st5: "16.7",
          st15: "18.6",
          st25: "18.3",
          st35: "18.3",
          st45: "18.0",
          st55: "18.3",
        },
        {
          time: "2023-08-30 20:00:00.000000000",
          at: "14.9",
          sm5: "121.22",
          sm15: "90.03",
          sm25: "97.87",
          sm35: "114.05",
          sm45: "100.16",
          sm55: "107.95",
          st5: "17.2",
          st15: "18.7",
          st25: "18.3",
          st35: "18.3",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-08-30 19:00:00.000000000",
          at: "15.8",
          sm5: "122.09",
          sm15: "89.48",
          sm25: "97.82",
          sm35: "114.02",
          sm45: "100.16",
          sm55: "107.95",
          st5: "17.7",
          st15: "18.8",
          st25: "18.3",
          st35: "18.2",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-08-30 18:00:00.000000000",
          at: "17.1",
          sm5: "119.79",
          sm15: "88.88",
          sm25: "97.75",
          sm35: "114.0",
          sm45: "100.18",
          sm55: "107.95",
          st5: "18.3",
          st15: "19.1",
          st25: "18.2",
          st35: "18.2",
          st45: "17.8",
          st55: "17.6",
        },
        {
          time: "2023-08-30 14:00:00.000000000",
          at: "24.9",
          sm5: "115.82",
          sm15: "87.77",
          sm25: "97.22",
          sm35: "114.09",
          sm45: "100.17",
          sm55: "107.95",
          st5: "20.6",
          st15: "18.7",
          st25: "17.8",
          st35: "18.1",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-08-30 13:00:00.000000000",
          at: "25.7",
          sm5: "115.81",
          sm15: "88.65",
          sm25: "97.09",
          sm35: "114.24",
          sm45: "100.18",
          sm55: "107.96",
          st5: "20.7",
          st15: "18.6",
          st25: "17.7",
          st35: "18.1",
          st45: "18.0",
          st55: "17.6",
        },
        {
          time: "2023-08-30 12:00:00.000000000",
          at: "26.5",
          sm5: "116.06",
          sm15: "88.69",
          sm25: "97.05",
          sm35: "114.3",
          sm45: "100.2",
          sm55: "107.98",
          st5: "20.7",
          st15: "18.2",
          st25: "17.6",
          st35: "18.2",
          st45: "18.0",
          st55: "17.6",
        },
        {
          time: "2023-08-30 11:00:00.000000000",
          at: "26.8",
          sm5: "116.82",
          sm15: "88.86",
          sm25: "97.09",
          sm35: "114.35",
          sm45: "100.23",
          sm55: "108.0",
          st5: "20.6",
          st15: "17.8",
          st25: "17.6",
          st35: "18.2",
          st45: "18.0",
          st55: "17.6",
        },
        {
          time: "2023-08-30 10:00:00.000000000",
          at: "26.8",
          sm5: "117.55",
          sm15: "89.04",
          sm25: "97.17",
          sm35: "114.38",
          sm45: "100.24",
          sm55: "108.01",
          st5: "20.1",
          st15: "17.6",
          st25: "17.5",
          st35: "18.1",
          st45: "18.0",
          st55: "17.6",
        },
        {
          time: "2023-08-30 09:00:00.000000000",
          at: "26.4",
          sm5: "118.21",
          sm15: "89.19",
          sm25: "97.35",
          sm35: "114.43",
          sm45: "100.28",
          sm55: "108.03",
          st5: "19.5",
          st15: "17.2",
          st25: "17.5",
          st35: "18.2",
          st45: "18.0",
          st55: "17.6",
        },
        {
          time: "2023-08-30 08:00:00.000000000",
          at: "25.3",
          sm5: "117.17",
          sm15: "89.26",
          sm25: "97.67",
          sm35: "114.42",
          sm45: "100.31",
          sm55: "108.05",
          st5: "18.6",
          st15: "16.8",
          st25: "17.5",
          st35: "18.2",
          st45: "18.0",
          st55: "17.6",
        },
        {
          time: "2023-08-30 07:00:00.000000000",
          at: "22.3",
          sm5: "121.06",
          sm15: "89.72",
          sm25: "97.87",
          sm35: "114.44",
          sm45: "100.34",
          sm55: "108.07",
          st5: "17.2",
          st15: "16.6",
          st25: "17.5",
          st35: "18.2",
          st45: "18.0",
          st55: "17.6",
        },
        {
          time: "2023-08-30 06:00:00.000000000",
          at: "18.5",
          sm5: "123.08",
          sm15: "90.23",
          sm25: "98.13",
          sm35: "114.47",
          sm45: "100.37",
          sm55: "108.08",
          st5: "15.6",
          st15: "16.5",
          st25: "17.6",
          st35: "18.3",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-08-30 05:00:00.000000000",
          at: "14.3",
          sm5: "122.63",
          sm15: "90.71",
          sm25: "98.45",
          sm35: "114.48",
          sm45: "100.4",
          sm55: "108.08",
          st5: "14.6",
          st15: "16.6",
          st25: "17.7",
          st35: "18.3",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-08-30 04:00:00.000000000",
          at: "10.7",
          sm5: "122.27",
          sm15: "91.19",
          sm25: "98.71",
          sm35: "114.44",
          sm45: "100.44",
          sm55: "108.08",
          st5: "13.7",
          st15: "16.7",
          st25: "17.8",
          st35: "18.3",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-08-30 03:00:00.000000000",
          at: "10.1",
          sm5: "121.09",
          sm15: "91.63",
          sm25: "98.88",
          sm35: "114.4",
          sm45: "100.44",
          sm55: "108.08",
          st5: "13.8",
          st15: "16.8",
          st25: "18.0",
          st35: "18.5",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-08-30 02:00:00.000000000",
          at: "10.4",
          sm5: "119.33",
          sm15: "92.02",
          sm25: "99.03",
          sm35: "114.35",
          sm45: "100.44",
          sm55: "108.06",
          st5: "14.1",
          st15: "17.1",
          st25: "18.0",
          st35: "18.5",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-08-30 01:00:00.000000000",
          at: "10.6",
          sm5: "119.45",
          sm15: "92.26",
          sm25: "99.17",
          sm35: "114.34",
          sm45: "100.45",
          sm55: "108.06",
          st5: "14.5",
          st15: "17.3",
          st25: "18.1",
          st35: "18.5",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-08-30 00:00:00.000000000",
          at: "11.3",
          sm5: "119.97",
          sm15: "92.49",
          sm25: "99.26",
          sm35: "114.32",
          sm45: "100.46",
          sm55: "108.06",
          st5: "14.7",
          st15: "17.6",
          st25: "18.2",
          st35: "18.5",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-08-29 23:00:00.000000000",
          at: "11.5",
          sm5: "120.28",
          sm15: "92.71",
          sm25: "99.3",
          sm35: "114.28",
          sm45: "100.46",
          sm55: "108.06",
          st5: "15.1",
          st15: "17.8",
          st25: "18.2",
          st35: "18.5",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-08-29 22:00:00.000000000",
          at: "12.0",
          sm5: "120.87",
          sm15: "92.58",
          sm25: "99.3",
          sm35: "114.24",
          sm45: "100.47",
          sm55: "108.05",
          st5: "15.6",
          st15: "18.1",
          st25: "18.3",
          st35: "18.5",
          st45: "18.1",
          st55: "18.5",
        },
        {
          time: "2023-08-29 21:00:00.000000000",
          at: "12.7",
          sm5: "120.9",
          sm15: "92.28",
          sm25: "99.27",
          sm35: "114.2",
          sm45: "100.48",
          sm55: "108.05",
          st5: "16.1",
          st15: "18.2",
          st25: "18.3",
          st35: "18.3",
          st45: "18.0",
          st55: "18.6",
        },
        {
          time: "2023-08-29 20:00:00.000000000",
          at: "13.6",
          sm5: "120.74",
          sm15: "91.9",
          sm25: "99.22",
          sm35: "114.17",
          sm45: "100.49",
          sm55: "108.05",
          st5: "16.6",
          st15: "18.5",
          st25: "18.3",
          st35: "18.3",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-08-29 19:00:00.000000000",
          at: "14.7",
          sm5: "120.87",
          sm15: "91.48",
          sm25: "99.16",
          sm35: "114.15",
          sm45: "100.5",
          sm55: "108.05",
          st5: "17.2",
          st15: "18.7",
          st25: "18.3",
          st35: "18.3",
          st45: "18.0",
          st55: "18.6",
        },
        {
          time: "2023-08-29 18:00:00.000000000",
          at: "15.3",
          sm5: "119.36",
          sm15: "90.98",
          sm25: "99.08",
          sm35: "114.15",
          sm45: "100.51",
          sm55: "108.05",
          st5: "17.8",
          st15: "18.8",
          st25: "18.2",
          st35: "18.3",
          st45: "18.0",
          st55: "18.5",
        },
        {
          time: "2023-08-29 17:00:00.000000000",
          at: "17.0",
          sm5: "121.65",
          sm15: "90.42",
          sm25: "98.99",
          sm35: "114.15",
          sm45: "100.52",
          sm55: "108.05",
          st5: "18.6",
          st15: "18.8",
          st25: "18.1",
          st35: "18.2",
          st45: "18.0",
          st55: "18.6",
        },
        {
          time: "2023-08-29 16:00:00.000000000",
          at: "19.4",
          sm5: "119.67",
          sm15: "89.95",
          sm25: "98.88",
          sm35: "114.15",
          sm45: "100.53",
          sm55: "108.05",
          st5: "19.5",
          st15: "18.8",
          st25: "18.1",
          st35: "18.2",
          st45: "18.0",
          st55: "18.6",
        },
        {
          time: "2023-08-29 15:00:00.000000000",
          at: "21.9",
          sm5: "118.36",
          sm15: "89.66",
          sm25: "98.71",
          sm35: "114.21",
          sm45: "100.51",
          sm55: "108.04",
          st5: "20.0",
          st15: "18.7",
          st25: "18.0",
          st35: "18.2",
          st45: "18.0",
          st55: "18.6",
        },
        {
          time: "2023-08-29 14:00:00.000000000",
          at: "23.6",
          sm5: "117.74",
          sm15: "89.5",
          sm25: "98.55",
          sm35: "114.28",
          sm45: "100.51",
          sm55: "108.04",
          st5: "20.2",
          st15: "18.6",
          st25: "17.8",
          st35: "18.2",
          st45: "18.0",
          st55: "18.6",
        },
        {
          time: "2023-08-29 13:00:00.000000000",
          at: "24.5",
          sm5: "117.6",
          sm15: "89.46",
          sm25: "98.43",
          sm35: "114.33",
          sm45: "100.52",
          sm55: "108.04",
          st5: "20.2",
          st15: "18.3",
          st25: "17.8",
          st35: "18.2",
          st45: "18.1",
          st55: "18.6",
        },
        {
          time: "2023-08-29 12:00:00.000000000",
          at: "25.0",
          sm5: "117.88",
          sm15: "89.41",
          sm25: "98.34",
          sm35: "114.38",
          sm45: "100.52",
          sm55: "108.04",
          st5: "20.0",
          st15: "18.1",
          st25: "17.7",
          st35: "18.3",
          st45: "18.1",
          st55: "18.6",
        },
        {
          time: "2023-08-29 11:00:00.000000000",
          at: "25.2",
          sm5: "118.13",
          sm15: "89.46",
          sm25: "98.32",
          sm35: "114.4",
          sm45: "100.52",
          sm55: "108.05",
          st5: "20.0",
          st15: "17.8",
          st25: "17.7",
          st35: "18.2",
          st45: "18.1",
          st55: "18.6",
        },
        {
          time: "2023-08-29 10:00:00.000000000",
          at: "24.8",
          sm5: "118.55",
          sm15: "89.57",
          sm25: "98.34",
          sm35: "114.43",
          sm45: "100.53",
          sm55: "108.06",
          st5: "19.3",
          st15: "17.5",
          st25: "17.6",
          st35: "18.3",
          st45: "18.1",
          st55: "18.6",
        },
        {
          time: "2023-08-29 09:00:00.000000000",
          at: "24.1",
          sm5: "118.18",
          sm15: "89.67",
          sm25: "98.45",
          sm35: "114.44",
          sm45: "100.54",
          sm55: "108.07",
          st5: "18.8",
          st15: "17.2",
          st25: "17.6",
          st35: "18.3",
          st45: "18.1",
          st55: "17.6",
        },
        {
          time: "2023-08-29 08:00:00.000000000",
          at: "22.5",
          sm5: "118.32",
          sm15: "89.99",
          sm25: "98.63",
          sm35: "114.46",
          sm45: "100.56",
          sm55: "108.08",
          st5: "17.7",
          st15: "16.8",
          st25: "17.6",
          st35: "18.3",
          st45: "18.1",
          st55: "18.6",
        },
        {
          time: "2023-08-29 07:00:00.000000000",
          at: "19.7",
          sm5: "121.74",
          sm15: "90.46",
          sm25: "98.78",
          sm35: "114.49",
          sm45: "100.58",
          sm55: "108.09",
          st5: "16.5",
          st15: "16.7",
          st25: "17.7",
          st35: "18.3",
          st45: "18.2",
          st55: "18.7",
        },
        {
          time: "2023-08-29 06:00:00.000000000",
          at: "16.3",
          sm5: "121.02",
          sm15: "90.91",
          sm25: "99.06",
          sm35: "114.55",
          sm45: "100.63",
          sm55: "108.1",
          st5: "15.3",
          st15: "16.7",
          st25: "17.8",
          st35: "18.5",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-08-29 05:00:00.000000000",
          at: "13.5",
          sm5: "119.14",
          sm15: "91.31",
          sm25: "99.34",
          sm35: "114.57",
          sm45: "100.67",
          sm55: "108.09",
          st5: "14.5",
          st15: "16.8",
          st25: "17.8",
          st35: "18.5",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-08-29 04:00:00.000000000",
          at: "10.6",
          sm5: "115.62",
          sm15: "91.74",
          sm25: "99.57",
          sm35: "114.52",
          sm45: "100.7",
          sm55: "108.09",
          st5: "14.0",
          st15: "17.0",
          st25: "18.0",
          st35: "18.5",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-08-29 03:00:00.000000000",
          at: "10.1",
          sm5: "108.73",
          sm15: "92.56",
          sm25: "99.68",
          sm35: "114.54",
          sm45: "100.7",
          sm55: "108.09",
          st5: "14.0",
          st15: "17.1",
          st25: "18.0",
          st35: "18.5",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-08-29 02:00:00.000000000",
          at: "10.5",
          sm5: "110.18",
          sm15: "92.89",
          sm25: "99.77",
          sm35: "114.51",
          sm45: "100.7",
          sm55: "108.08",
          st5: "14.3",
          st15: "17.3",
          st25: "18.1",
          st35: "18.5",
          st45: "18.2",
          st55: "18.7",
        },
        {
          time: "2023-08-29 01:00:00.000000000",
          at: "11.1",
          sm5: "111.62",
          sm15: "93.17",
          sm25: "99.83",
          sm35: "114.48",
          sm45: "100.7",
          sm55: "108.08",
          st5: "14.7",
          st15: "17.6",
          st25: "18.2",
          st35: "18.5",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-08-29 00:00:00.000000000",
          at: "11.3",
          sm5: "112.89",
          sm15: "93.39",
          sm25: "99.86",
          sm35: "114.48",
          sm45: "100.7",
          sm55: "108.07",
          st5: "15.1",
          st15: "17.8",
          st25: "18.2",
          st35: "18.5",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-08-28 23:00:00.000000000",
          at: "12.3",
          sm5: "114.1",
          sm15: "93.49",
          sm25: "99.87",
          sm35: "114.49",
          sm45: "100.72",
          sm55: "108.07",
          st5: "15.7",
          st15: "18.0",
          st25: "18.2",
          st35: "18.6",
          st45: "18.2",
          st55: "18.7",
        },
        {
          time: "2023-08-28 22:00:00.000000000",
          at: "13.3",
          sm5: "115.3",
          sm15: "93.48",
          sm25: "99.88",
          sm35: "114.51",
          sm45: "100.74",
          sm55: "108.07",
          st5: "16.3",
          st15: "18.2",
          st25: "18.3",
          st35: "18.6",
          st45: "18.2",
          st55: "18.7",
        },
        {
          time: "2023-08-28 21:00:00.000000000",
          at: "14.6",
          sm5: "116.1",
          sm15: "93.31",
          sm25: "99.88",
          sm35: "114.47",
          sm45: "100.75",
          sm55: "108.07",
          st5: "16.8",
          st15: "18.3",
          st25: "18.3",
          st35: "18.5",
          st45: "18.2",
          st55: "18.7",
        },
        {
          time: "2023-08-28 20:00:00.000000000",
          at: "16.0",
          sm5: "114.86",
          sm15: "93.05",
          sm25: "99.87",
          sm35: "114.45",
          sm45: "100.76",
          sm55: "108.06",
          st5: "17.3",
          st15: "18.5",
          st25: "18.3",
          st35: "18.5",
          st45: "18.2",
          st55: "18.6",
        },
        {
          time: "2023-08-28 19:00:00.000000000",
          at: "17.2",
          sm5: "116.98",
          sm15: "92.77",
          sm25: "99.85",
          sm35: "114.43",
          sm45: "100.77",
          sm55: "108.06",
          st5: "17.7",
          st15: "18.5",
          st25: "18.3",
          st35: "18.5",
          st45: "18.2",
          st55: "18.7",
        },
        {
          time: "2023-08-28 18:00:00.000000000",
          at: "17.4",
          sm5: "117.2",
          sm15: "92.36",
          sm25: "99.82",
          sm35: "114.43",
          sm45: "100.78",
          sm55: "108.06",
          st5: "18.0",
          st15: "18.6",
          st25: "18.2",
          st35: "18.5",
          st45: "18.2",
          st55: "18.7",
        },
        {
          time: "2023-08-28 17:00:00.000000000",
          at: "18.0",
          sm5: "115.54",
          sm15: "91.96",
          sm25: "99.79",
          sm35: "114.43",
          sm45: "100.79",
          sm55: "108.06",
          st5: "18.3",
          st15: "18.6",
          st25: "18.2",
          st35: "18.5",
          st45: "18.2",
          st55: "18.7",
        },
        {
          time: "2023-08-28 16:00:00.000000000",
          at: "20.8",
          sm5: "113.69",
          sm15: "91.6",
          sm25: "99.71",
          sm35: "114.45",
          sm45: "100.81",
          sm55: "108.05",
          st5: "18.8",
          st15: "18.6",
          st25: "18.2",
          st35: "18.3",
          st45: "18.2",
          st55: "18.7",
        },
        {
          time: "2023-08-28 15:00:00.000000000",
          at: "22.8",
          sm5: "112.89",
          sm15: "91.31",
          sm25: "99.57",
          sm35: "114.52",
          sm45: "100.79",
          sm55: "108.04",
          st5: "19.2",
          st15: "18.6",
          st25: "18.1",
          st35: "18.5",
          st45: "18.2",
          st55: "18.7",
        },
        {
          time: "2023-08-28 14:00:00.000000000",
          at: "24.2",
          sm5: "112.52",
          sm15: "91.03",
          sm25: "99.42",
          sm35: "114.59",
          sm45: "100.78",
          sm55: "108.03",
          st5: "19.6",
          st15: "18.5",
          st25: "18.0",
          st35: "18.5",
          st45: "18.2",
          st55: "18.8",
        },
        {
          time: "2023-08-28 13:00:00.000000000",
          at: "24.8",
          sm5: "112.64",
          sm15: "90.82",
          sm25: "99.33",
          sm35: "114.64",
          sm45: "100.79",
          sm55: "108.04",
          st5: "19.7",
          st15: "18.3",
          st25: "18.0",
          st35: "18.5",
          st45: "18.2",
          st55: "18.7",
        },
        {
          time: "2023-08-28 12:00:00.000000000",
          at: "25.1",
          sm5: "113.34",
          sm15: "90.67",
          sm25: "99.27",
          sm35: "114.7",
          sm45: "100.8",
          sm55: "108.04",
          st5: "19.6",
          st15: "18.1",
          st25: "17.8",
          st35: "18.5",
          st45: "18.2",
          st55: "18.8",
        },
        {
          time: "2023-08-28 11:00:00.000000000",
          at: "25.3",
          sm5: "114.04",
          sm15: "90.58",
          sm25: "99.26",
          sm35: "114.75",
          sm45: "100.81",
          sm55: "108.05",
          st5: "19.6",
          st15: "17.8",
          st25: "17.8",
          st35: "18.5",
          st45: "18.2",
          st55: "18.8",
        },
        {
          time: "2023-08-28 10:00:00.000000000",
          at: "24.9",
          sm5: "114.69",
          sm15: "90.4",
          sm25: "99.3",
          sm35: "114.79",
          sm45: "100.81",
          sm55: "108.05",
          st5: "19.1",
          st15: "17.6",
          st25: "17.7",
          st35: "18.6",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-28 09:00:00.000000000",
          at: "24.3",
          sm5: "115.09",
          sm15: "90.37",
          sm25: "99.4",
          sm35: "114.8",
          sm45: "100.82",
          sm55: "108.06",
          st5: "18.6",
          st15: "17.3",
          st25: "17.7",
          st35: "18.5",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-28 08:00:00.000000000",
          at: "23.5",
          sm5: "115.17",
          sm15: "90.41",
          sm25: "99.57",
          sm35: "114.82",
          sm45: "100.83",
          sm55: "108.06",
          st5: "17.8",
          st15: "17.1",
          st25: "17.8",
          st35: "18.6",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-28 07:00:00.000000000",
          at: "21.0",
          sm5: "117.44",
          sm15: "90.53",
          sm25: "99.73",
          sm35: "114.84",
          sm45: "100.84",
          sm55: "108.08",
          st5: "16.7",
          st15: "17.0",
          st25: "17.8",
          st35: "18.6",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-28 06:00:00.000000000",
          at: "18.4",
          sm5: "118.73",
          sm15: "91.02",
          sm25: "99.95",
          sm35: "114.86",
          sm45: "100.85",
          sm55: "108.09",
          st5: "15.8",
          st15: "16.8",
          st25: "18.0",
          st35: "18.7",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-28 05:00:00.000000000",
          at: "14.7",
          sm5: "119.13",
          sm15: "91.41",
          sm25: "100.2",
          sm35: "114.91",
          sm45: "100.87",
          sm55: "108.09",
          st5: "14.8",
          st15: "16.8",
          st25: "18.0",
          st35: "18.7",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-28 04:00:00.000000000",
          at: "11.2",
          sm5: "119.57",
          sm15: "91.63",
          sm25: "100.42",
          sm35: "114.86",
          sm45: "100.91",
          sm55: "108.09",
          st5: "14.2",
          st15: "17.1",
          st25: "18.1",
          st35: "18.7",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-28 03:00:00.000000000",
          at: "10.6",
          sm5: "120.24",
          sm15: "92.07",
          sm25: "100.56",
          sm35: "114.83",
          sm45: "100.91",
          sm55: "108.08",
          st5: "14.3",
          st15: "17.3",
          st25: "18.2",
          st35: "18.7",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-28 02:00:00.000000000",
          at: "11.2",
          sm5: "120.25",
          sm15: "92.48",
          sm25: "100.69",
          sm35: "114.78",
          sm45: "100.91",
          sm55: "108.08",
          st5: "14.6",
          st15: "17.3",
          st25: "18.3",
          st35: "18.8",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-28 01:00:00.000000000",
          at: "11.7",
          sm5: "118.49",
          sm15: "92.79",
          sm25: "100.82",
          sm35: "114.73",
          sm45: "100.9",
          sm55: "108.07",
          st5: "14.8",
          st15: "17.6",
          st25: "18.3",
          st35: "18.8",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-28 00:00:00.000000000",
          at: "12.3",
          sm5: "115.73",
          sm15: "93.06",
          sm25: "100.93",
          sm35: "114.69",
          sm45: "100.91",
          sm55: "108.06",
          st5: "15.1",
          st15: "17.7",
          st25: "18.5",
          st35: "18.7",
          st45: "18.5",
          st55: "19.0",
        },
        {
          time: "2023-08-27 23:00:00.000000000",
          at: "12.4",
          sm5: "116.69",
          sm15: "93.31",
          sm25: "101.05",
          sm35: "114.72",
          sm45: "100.92",
          sm55: "108.06",
          st5: "15.2",
          st15: "18.0",
          st25: "18.5",
          st35: "18.8",
          st45: "18.3",
          st55: "19.0",
        },
        {
          time: "2023-08-27 22:00:00.000000000",
          at: "12.3",
          sm5: "117.29",
          sm15: "93.4",
          sm25: "101.14",
          sm35: "114.91",
          sm45: "100.99",
          sm55: "108.05",
          st5: "15.6",
          st15: "18.2",
          st25: "18.6",
          st35: "18.8",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-27 21:00:00.000000000",
          at: "12.4",
          sm5: "117.98",
          sm15: "93.29",
          sm25: "101.16",
          sm35: "115.08",
          sm45: "101.05",
          sm55: "108.05",
          st5: "15.8",
          st15: "18.5",
          st25: "18.7",
          st35: "18.7",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-27 20:00:00.000000000",
          at: "13.5",
          sm5: "118.13",
          sm15: "92.93",
          sm25: "101.1",
          sm35: "115.06",
          sm45: "101.05",
          sm55: "108.04",
          st5: "16.5",
          st15: "18.7",
          st25: "18.6",
          st35: "18.7",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-27 19:00:00.000000000",
          at: "14.0",
          sm5: "117.5",
          sm15: "92.54",
          sm25: "100.8",
          sm35: "115.09",
          sm45: "101.06",
          sm55: "108.04",
          st5: "17.0",
          st15: "19.0",
          st25: "18.6",
          st35: "18.7",
          st45: "18.3",
          st55: "19.0",
        },
        {
          time: "2023-08-27 18:00:00.000000000",
          at: "14.9",
          sm5: "116.32",
          sm15: "92.06",
          sm25: "100.14",
          sm35: "115.2",
          sm45: "101.07",
          sm55: "108.04",
          st5: "17.7",
          st15: "19.2",
          st25: "18.6",
          st35: "18.7",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-27 17:00:00.000000000",
          at: "16.8",
          sm5: "120.4",
          sm15: "91.5",
          sm25: "100.1",
          sm35: "115.2",
          sm45: "101.07",
          sm55: "108.03",
          st5: "18.7",
          st15: "19.2",
          st25: "18.6",
          st35: "18.6",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-27 16:00:00.000000000",
          at: "19.7",
          sm5: "118.88",
          sm15: "91.04",
          sm25: "100.03",
          sm35: "115.2",
          sm45: "101.08",
          sm55: "108.02",
          st5: "19.7",
          st15: "19.2",
          st25: "18.5",
          st35: "18.6",
          st45: "18.3",
          st55: "19.0",
        },
        {
          time: "2023-08-27 15:00:00.000000000",
          at: "22.3",
          sm5: "117.53",
          sm15: "90.68",
          sm25: "99.93",
          sm35: "115.22",
          sm45: "101.06",
          sm55: "108.01",
          st5: "20.3",
          st15: "19.1",
          st25: "18.3",
          st35: "18.6",
          st45: "18.3",
          st55: "18.8",
        },
        {
          time: "2023-08-27 14:00:00.000000000",
          at: "24.1",
          sm5: "116.85",
          sm15: "90.45",
          sm25: "99.79",
          sm35: "115.35",
          sm45: "101.06",
          sm55: "108.0",
          st5: "20.6",
          st15: "19.0",
          st25: "18.2",
          st35: "18.6",
          st45: "18.5",
          st55: "19.0",
        },
        {
          time: "2023-08-27 13:00:00.000000000",
          at: "25.0",
          sm5: "116.75",
          sm15: "90.28",
          sm25: "99.71",
          sm35: "115.42",
          sm45: "101.07",
          sm55: "108.0",
          st5: "20.6",
          st15: "18.7",
          st25: "18.1",
          st35: "18.6",
          st45: "18.3",
          st55: "19.0",
        },
        {
          time: "2023-08-27 12:00:00.000000000",
          at: "25.6",
          sm5: "116.99",
          sm15: "90.04",
          sm25: "99.65",
          sm35: "115.49",
          sm45: "101.07",
          sm55: "108.0",
          st5: "20.5",
          st15: "18.5",
          st25: "18.1",
          st35: "18.6",
          st45: "18.5",
          st55: "19.0",
        },
        {
          time: "2023-08-27 11:00:00.000000000",
          at: "25.9",
          sm5: "117.67",
          sm15: "89.77",
          sm25: "99.64",
          sm35: "115.5",
          sm45: "101.06",
          sm55: "108.0",
          st5: "20.3",
          st15: "18.1",
          st25: "18.0",
          st35: "18.7",
          st45: "18.5",
          st55: "19.0",
        },
        {
          time: "2023-08-27 10:00:00.000000000",
          at: "25.3",
          sm5: "118.43",
          sm15: "90.31",
          sm25: "99.66",
          sm35: "115.53",
          sm45: "101.05",
          sm55: "108.0",
          st5: "19.6",
          st15: "17.7",
          st25: "18.0",
          st35: "18.7",
          st45: "18.5",
          st55: "19.0",
        },
        {
          time: "2023-08-27 09:00:00.000000000",
          at: "25.1",
          sm5: "118.81",
          sm15: "90.39",
          sm25: "99.78",
          sm35: "115.53",
          sm45: "101.06",
          sm55: "108.01",
          st5: "19.2",
          st15: "17.5",
          st25: "18.0",
          st35: "18.8",
          st45: "18.6",
          st55: "19.0",
        },
        {
          time: "2023-08-27 08:00:00.000000000",
          at: "23.3",
          sm5: "119.15",
          sm15: "89.97",
          sm25: "99.94",
          sm35: "115.6",
          sm45: "101.08",
          sm55: "108.02",
          st5: "18.1",
          st15: "17.2",
          st25: "18.0",
          st35: "18.8",
          st45: "18.6",
          st55: "19.1",
        },
        {
          time: "2023-08-27 07:00:00.000000000",
          at: "21.0",
          sm5: "118.48",
          sm15: "89.77",
          sm25: "100.16",
          sm35: "115.64",
          sm45: "101.13",
          sm55: "108.04",
          st5: "16.7",
          st15: "17.0",
          st25: "18.1",
          st35: "18.8",
          st45: "18.6",
          st55: "19.0",
        },
        {
          time: "2023-08-27 06:00:00.000000000",
          at: "17.8",
          sm5: "119.18",
          sm15: "90.01",
          sm25: "100.38",
          sm35: "115.72",
          sm45: "101.17",
          sm55: "108.06",
          st5: "15.7",
          st15: "17.0",
          st25: "18.2",
          st35: "18.8",
          st45: "18.6",
          st55: "19.0",
        },
        {
          time: "2023-08-27 05:00:00.000000000",
          at: "14.5",
          sm5: "119.5",
          sm15: "90.31",
          sm25: "100.56",
          sm35: "115.79",
          sm45: "101.18",
          sm55: "108.05",
          st5: "14.8",
          st15: "17.0",
          st25: "18.2",
          st35: "19.0",
          st45: "18.6",
          st55: "19.1",
        },
        {
          time: "2023-08-27 04:00:00.000000000",
          at: "10.8",
          sm5: "120.05",
          sm15: "90.76",
          sm25: "100.76",
          sm35: "115.81",
          sm45: "101.23",
          sm55: "108.05",
          st5: "14.2",
          st15: "17.2",
          st25: "18.5",
          st35: "19.1",
          st45: "18.6",
          st55: "19.0",
        },
        {
          time: "2023-08-27 03:00:00.000000000",
          at: "10.4",
          sm5: "120.91",
          sm15: "91.41",
          sm25: "100.89",
          sm35: "115.8",
          sm45: "101.22",
          sm55: "108.04",
          st5: "14.2",
          st15: "17.3",
          st25: "18.5",
          st35: "19.0",
          st45: "18.6",
          st55: "19.0",
        },
        {
          time: "2023-08-27 02:00:00.000000000",
          at: "10.6",
          sm5: "121.81",
          sm15: "91.91",
          sm25: "101.01",
          sm35: "115.77",
          sm45: "101.22",
          sm55: "108.03",
          st5: "14.6",
          st15: "17.6",
          st25: "18.6",
          st35: "19.1",
          st45: "18.6",
          st55: "19.0",
        },
        {
          time: "2023-08-27 01:00:00.000000000",
          at: "11.1",
          sm5: "122.61",
          sm15: "92.33",
          sm25: "101.14",
          sm35: "115.74",
          sm45: "101.21",
          sm55: "108.02",
          st5: "14.8",
          st15: "17.8",
          st25: "18.7",
          st35: "19.1",
          st45: "18.6",
          st55: "19.1",
        },
        {
          time: "2023-08-27 00:00:00.000000000",
          at: "11.5",
          sm5: "122.95",
          sm15: "92.66",
          sm25: "101.26",
          sm35: "115.76",
          sm45: "101.22",
          sm55: "108.01",
          st5: "15.1",
          st15: "18.1",
          st25: "18.8",
          st35: "19.1",
          st45: "18.6",
          st55: "19.0",
        },
        {
          time: "2023-08-26 23:00:00.000000000",
          at: "12.0",
          sm5: "121.18",
          sm15: "92.87",
          sm25: "101.36",
          sm35: "115.75",
          sm45: "101.22",
          sm55: "108.01",
          st5: "15.3",
          st15: "18.3",
          st25: "18.8",
          st35: "19.1",
          st45: "18.6",
          st55: "19.0",
        },
        {
          time: "2023-08-26 22:00:00.000000000",
          at: "12.3",
          sm5: "120.55",
          sm15: "92.83",
          sm25: "101.43",
          sm35: "115.73",
          sm45: "101.22",
          sm55: "108.0",
          st5: "15.7",
          st15: "18.5",
          st25: "19.0",
          st35: "19.1",
          st45: "18.6",
          st55: "19.1",
        },
        {
          time: "2023-08-26 21:00:00.000000000",
          at: "12.6",
          sm5: "122.38",
          sm15: "92.67",
          sm25: "101.43",
          sm35: "115.72",
          sm45: "101.22",
          sm55: "107.99",
          st5: "16.2",
          st15: "18.7",
          st25: "19.0",
          st35: "19.1",
          st45: "18.6",
          st55: "19.1",
        },
        {
          time: "2023-08-26 20:00:00.000000000",
          at: "13.3",
          sm5: "123.27",
          sm15: "92.49",
          sm25: "101.4",
          sm35: "115.7",
          sm45: "101.22",
          sm55: "107.98",
          st5: "16.8",
          st15: "19.1",
          st25: "19.0",
          st35: "19.1",
          st45: "18.6",
          st55: "19.0",
        },
        {
          time: "2023-08-26 19:00:00.000000000",
          at: "14.4",
          sm5: "122.33",
          sm15: "92.04",
          sm25: "101.35",
          sm35: "115.68",
          sm45: "101.23",
          sm55: "107.98",
          st5: "17.5",
          st15: "19.2",
          st25: "19.0",
          st35: "19.1",
          st45: "18.6",
          st55: "19.1",
        },
        {
          time: "2023-08-26 18:00:00.000000000",
          at: "15.8",
          sm5: "125.03",
          sm15: "91.7",
          sm25: "101.34",
          sm35: "115.67",
          sm45: "101.23",
          sm55: "107.96",
          st5: "18.2",
          st15: "19.5",
          st25: "18.8",
          st35: "19.0",
          st45: "18.6",
          st55: "19.1",
        },
        {
          time: "2023-08-26 17:00:00.000000000",
          at: "18.0",
          sm5: "125.01",
          sm15: "91.31",
          sm25: "101.36",
          sm35: "115.67",
          sm45: "101.24",
          sm55: "107.95",
          st5: "19.1",
          st15: "19.5",
          st25: "18.8",
          st35: "19.0",
          st45: "18.6",
          st55: "19.1",
        },
        {
          time: "2023-08-26 16:00:00.000000000",
          at: "20.9",
          sm5: "123.31",
          sm15: "90.98",
          sm25: "101.07",
          sm35: "115.72",
          sm45: "101.23",
          sm55: "107.93",
          st5: "19.8",
          st15: "19.5",
          st25: "18.8",
          st35: "19.0",
          st45: "18.6",
          st55: "19.1",
        },
        {
          time: "2023-08-26 15:00:00.000000000",
          at: "22.5",
          sm5: "122.1",
          sm15: "90.69",
          sm25: "101.0",
          sm35: "115.74",
          sm45: "101.21",
          sm55: "107.92",
          st5: "20.5",
          st15: "19.3",
          st25: "18.7",
          st35: "19.0",
          st45: "18.6",
          st55: "19.1",
        },
        {
          time: "2023-08-26 14:00:00.000000000",
          at: "23.7",
          sm5: "121.56",
          sm15: "90.68",
          sm25: "100.94",
          sm35: "115.8",
          sm45: "101.19",
          sm55: "107.91",
          st5: "20.6",
          st15: "19.2",
          st25: "18.6",
          st35: "19.0",
          st45: "18.6",
          st55: "19.1",
        },
        {
          time: "2023-08-26 13:00:00.000000000",
          at: "24.8",
          sm5: "121.33",
          sm15: "91.06",
          sm25: "100.95",
          sm35: "115.86",
          sm45: "101.18",
          sm55: "107.9",
          st5: "20.7",
          st15: "19.0",
          st25: "18.5",
          st35: "19.0",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-26 12:00:00.000000000",
          at: "25.3",
          sm5: "121.49",
          sm15: "90.97",
          sm25: "101.0",
          sm35: "115.93",
          sm45: "101.18",
          sm55: "107.9",
          st5: "20.3",
          st15: "18.7",
          st25: "18.5",
          st35: "19.0",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-26 11:00:00.000000000",
          at: "25.5",
          sm5: "121.56",
          sm15: "89.99",
          sm25: "101.09",
          sm35: "115.97",
          sm45: "101.18",
          sm55: "107.89",
          st5: "20.1",
          st15: "18.5",
          st25: "18.5",
          st35: "19.1",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-26 10:00:00.000000000",
          at: "24.8",
          sm5: "122.37",
          sm15: "89.64",
          sm25: "101.23",
          sm35: "116.03",
          sm45: "101.18",
          sm55: "107.88",
          st5: "19.3",
          st15: "18.1",
          st25: "18.5",
          st35: "19.0",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-26 09:00:00.000000000",
          at: "24.1",
          sm5: "122.51",
          sm15: "89.52",
          sm25: "101.44",
          sm35: "116.06",
          sm45: "101.19",
          sm55: "107.88",
          st5: "18.8",
          st15: "18.0",
          st25: "18.5",
          st35: "19.1",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-26 08:00:00.000000000",
          at: "22.6",
          sm5: "123.97",
          sm15: "89.75",
          sm25: "101.71",
          sm35: "116.13",
          sm45: "101.2",
          sm55: "107.88",
          st5: "17.8",
          st15: "17.7",
          st25: "18.5",
          st35: "19.2",
          st45: "18.7",
          st55: "17.6",
        },
        {
          time: "2023-08-26 07:00:00.000000000",
          at: "20.2",
          sm5: "123.78",
          sm15: "89.86",
          sm25: "101.98",
          sm35: "116.15",
          sm45: "101.21",
          sm55: "107.89",
          st5: "16.8",
          st15: "17.7",
          st25: "18.6",
          st35: "19.2",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-26 06:00:00.000000000",
          at: "16.8",
          sm5: "123.57",
          sm15: "90.04",
          sm25: "102.24",
          sm35: "116.1",
          sm45: "101.22",
          sm55: "107.88",
          st5: "16.2",
          st15: "17.7",
          st25: "18.7",
          st35: "19.2",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-26 05:00:00.000000000",
          at: "14.0",
          sm5: "124.29",
          sm15: "90.29",
          sm25: "102.41",
          sm35: "116.06",
          sm45: "101.25",
          sm55: "107.86",
          st5: "15.5",
          st15: "17.8",
          st25: "18.8",
          st35: "19.2",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-26 04:00:00.000000000",
          at: "10.8",
          sm5: "125.42",
          sm15: "90.56",
          sm25: "102.28",
          sm35: "115.94",
          sm45: "101.26",
          sm55: "107.83",
          st5: "15.1",
          st15: "18.1",
          st25: "19.0",
          st35: "19.2",
          st45: "18.7",
          st55: "19.2",
        },
        {
          time: "2023-08-26 03:00:00.000000000",
          at: "10.7",
          sm5: "127.28",
          sm15: "90.64",
          sm25: "101.61",
          sm35: "115.86",
          sm45: "101.24",
          sm55: "107.81",
          st5: "15.3",
          st15: "18.3",
          st25: "19.0",
          st35: "19.3",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-26 02:00:00.000000000",
          at: "11.6",
          sm5: "124.32",
          sm15: "90.38",
          sm25: "101.33",
          sm35: "115.89",
          sm45: "101.23",
          sm55: "107.78",
          st5: "15.8",
          st15: "18.6",
          st25: "19.1",
          st35: "19.3",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-26 01:00:00.000000000",
          at: "12.8",
          sm5: "125.57",
          sm15: "90.48",
          sm25: "101.41",
          sm35: "115.85",
          sm45: "101.22",
          sm55: "107.75",
          st5: "16.5",
          st15: "18.8",
          st25: "19.2",
          st35: "19.3",
          st45: "18.7",
          st55: "19.2",
        },
        {
          time: "2023-08-26 00:00:00.000000000",
          at: "13.4",
          sm5: "124.11",
          sm15: "90.52",
          sm25: "101.5",
          sm35: "115.83",
          sm45: "101.21",
          sm55: "107.72",
          st5: "16.8",
          st15: "19.0",
          st25: "19.2",
          st35: "19.2",
          st45: "18.7",
          st55: "19.2",
        },
        {
          time: "2023-08-25 23:00:00.000000000",
          at: "14.8",
          sm5: "123.44",
          sm15: "90.48",
          sm25: "101.58",
          sm35: "115.81",
          sm45: "101.21",
          sm55: "107.69",
          st5: "17.3",
          st15: "19.2",
          st25: "19.2",
          st35: "19.2",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-25 22:00:00.000000000",
          at: "16.4",
          sm5: "125.09",
          sm15: "90.36",
          sm25: "101.66",
          sm35: "115.78",
          sm45: "101.19",
          sm55: "107.66",
          st5: "17.7",
          st15: "19.3",
          st25: "19.3",
          st35: "19.3",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-25 21:00:00.000000000",
          at: "16.2",
          sm5: "125.66",
          sm15: "90.18",
          sm25: "101.78",
          sm35: "115.79",
          sm45: "101.19",
          sm55: "107.63",
          st5: "18.0",
          st15: "19.5",
          st25: "19.3",
          st35: "19.3",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-25 20:00:00.000000000",
          at: "16.4",
          sm5: "124.97",
          sm15: "89.89",
          sm25: "101.89",
          sm35: "115.78",
          sm45: "101.18",
          sm55: "107.6",
          st5: "18.3",
          st15: "19.7",
          st25: "19.3",
          st35: "19.2",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-25 19:00:00.000000000",
          at: "16.6",
          sm5: "123.68",
          sm15: "89.64",
          sm25: "101.99",
          sm35: "115.8",
          sm45: "101.17",
          sm55: "107.55",
          st5: "18.7",
          st15: "19.8",
          st25: "19.3",
          st35: "19.1",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-25 18:00:00.000000000",
          at: "18.2",
          sm5: "121.82",
          sm15: "89.39",
          sm25: "102.08",
          sm35: "115.79",
          sm45: "101.15",
          sm55: "107.49",
          st5: "19.3",
          st15: "20.0",
          st25: "19.2",
          st35: "19.1",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-25 17:00:00.000000000",
          at: "20.6",
          sm5: "120.33",
          sm15: "89.18",
          sm25: "102.16",
          sm35: "115.8",
          sm45: "101.13",
          sm55: "107.42",
          st5: "20.1",
          st15: "20.0",
          st25: "19.1",
          st35: "19.1",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-25 16:00:00.000000000",
          at: "22.5",
          sm5: "119.42",
          sm15: "89.09",
          sm25: "102.22",
          sm35: "115.82",
          sm45: "101.07",
          sm55: "107.33",
          st5: "20.6",
          st15: "20.0",
          st25: "19.0",
          st35: "19.1",
          st45: "18.7",
          st55: "19.2",
        },
        {
          time: "2023-08-25 15:00:00.000000000",
          at: "23.6",
          sm5: "119.05",
          sm15: "89.04",
          sm25: "102.25",
          sm35: "115.84",
          sm45: "101.0",
          sm55: "107.19",
          st5: "21.1",
          st15: "19.8",
          st25: "19.0",
          st35: "19.1",
          st45: "18.7",
          st55: "19.1",
        },
        {
          time: "2023-08-25 14:00:00.000000000",
          at: "24.6",
          sm5: "119.25",
          sm15: "89.06",
          sm25: "102.3",
          sm35: "115.97",
          sm45: "100.95",
          sm55: "107.04",
          st5: "21.5",
          st15: "19.7",
          st25: "18.8",
          st35: "19.0",
          st45: "18.7",
          st55: "19.2",
        },
        {
          time: "2023-08-25 13:00:00.000000000",
          at: "25.3",
          sm5: "119.91",
          sm15: "89.17",
          sm25: "102.4",
          sm35: "116.0",
          sm45: "100.92",
          sm55: "106.89",
          st5: "21.5",
          st15: "19.6",
          st25: "18.7",
          st35: "19.1",
          st45: "18.7",
          st55: "19.2",
        },
        {
          time: "2023-08-25 12:00:00.000000000",
          at: "26.1",
          sm5: "120.79",
          sm15: "89.37",
          sm25: "102.53",
          sm35: "116.04",
          sm45: "100.9",
          sm55: "106.74",
          st5: "21.3",
          st15: "19.2",
          st25: "18.6",
          st35: "19.0",
          st45: "18.7",
          st55: "19.2",
        },
        {
          time: "2023-08-25 11:00:00.000000000",
          at: "26.3",
          sm5: "122.51",
          sm15: "89.67",
          sm25: "102.68",
          sm35: "115.97",
          sm45: "100.85",
          sm55: "106.65",
          st5: "21.2",
          st15: "18.8",
          st25: "18.5",
          st35: "19.1",
          st45: "18.7",
          st55: "19.2",
        },
        {
          time: "2023-08-25 10:00:00.000000000",
          at: "25.6",
          sm5: "124.91",
          sm15: "90.31",
          sm25: "102.8",
          sm35: "115.82",
          sm45: "100.77",
          sm55: "106.58",
          st5: "20.6",
          st15: "18.5",
          st25: "18.5",
          st35: "19.0",
          st45: "18.7",
          st55: "19.2",
        },
        {
          time: "2023-08-25 09:00:00.000000000",
          at: "24.4",
          sm5: "129.4",
          sm15: "92.78",
          sm25: "102.63",
          sm35: "115.57",
          sm45: "100.75",
          sm55: "106.52",
          st5: "20.1",
          st15: "18.2",
          st25: "18.5",
          st35: "19.1",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-25 08:00:00.000000000",
          at: "23.7",
          sm5: "142.63",
          sm15: "98.05",
          sm25: "101.88",
          sm35: "115.59",
          sm45: "100.77",
          sm55: "106.46",
          st5: "19.7",
          st15: "17.8",
          st25: "18.5",
          st35: "19.1",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-25 07:00:00.000000000",
          at: "21.8",
          sm5: "116.23",
          sm15: "88.21",
          sm25: "100.39",
          sm35: "115.79",
          sm45: "100.77",
          sm55: "106.4",
          st5: "17.6",
          st15: "17.7",
          st25: "18.6",
          st35: "19.1",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-25 06:00:00.000000000",
          at: "18.5",
          sm5: "115.95",
          sm15: "88.36",
          sm25: "100.64",
          sm35: "115.92",
          sm45: "100.8",
          sm55: "106.34",
          st5: "16.6",
          st15: "17.6",
          st25: "18.6",
          st35: "19.2",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-25 05:00:00.000000000",
          at: "15.3",
          sm5: "115.2",
          sm15: "88.46",
          sm25: "100.88",
          sm35: "116.05",
          sm45: "100.81",
          sm55: "106.28",
          st5: "15.6",
          st15: "17.7",
          st25: "18.7",
          st35: "19.2",
          st45: "19.0",
          st55: "19.2",
        },
        {
          time: "2023-08-25 04:00:00.000000000",
          at: "12.1",
          sm5: "115.12",
          sm15: "88.55",
          sm25: "101.1",
          sm35: "116.09",
          sm45: "100.81",
          sm55: "106.23",
          st5: "15.1",
          st15: "17.8",
          st25: "18.7",
          st35: "19.3",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-25 03:00:00.000000000",
          at: "11.5",
          sm5: "115.63",
          sm15: "88.61",
          sm25: "101.23",
          sm35: "116.1",
          sm45: "100.74",
          sm55: "106.21",
          st5: "15.2",
          st15: "18.1",
          st25: "18.8",
          st35: "19.2",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-25 02:00:00.000000000",
          at: "12.0",
          sm5: "115.97",
          sm15: "88.7",
          sm25: "101.36",
          sm35: "116.1",
          sm45: "100.64",
          sm55: "106.16",
          st5: "15.6",
          st15: "18.3",
          st25: "19.0",
          st35: "19.3",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-25 01:00:00.000000000",
          at: "12.5",
          sm5: "116.26",
          sm15: "88.8",
          sm25: "101.48",
          sm35: "116.11",
          sm45: "100.52",
          sm55: "106.16",
          st5: "15.8",
          st15: "18.5",
          st25: "19.1",
          st35: "19.3",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-25 00:00:00.000000000",
          at: "13.1",
          sm5: "116.72",
          sm15: "88.91",
          sm25: "101.58",
          sm35: "116.11",
          sm45: "100.37",
          sm55: "106.16",
          st5: "16.3",
          st15: "18.7",
          st25: "19.1",
          st35: "19.3",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-24 23:00:00.000000000",
          at: "13.6",
          sm5: "117.44",
          sm15: "89.31",
          sm25: "101.67",
          sm35: "116.11",
          sm45: "100.17",
          sm55: "106.17",
          st5: "16.7",
          st15: "19.0",
          st25: "19.2",
          st35: "19.3",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-24 22:00:00.000000000",
          at: "14.6",
          sm5: "118.3",
          sm15: "89.51",
          sm25: "101.72",
          sm35: "116.11",
          sm45: "99.93",
          sm55: "106.16",
          st5: "17.2",
          st15: "19.2",
          st25: "19.2",
          st35: "19.2",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-24 21:00:00.000000000",
          at: "15.6",
          sm5: "119.23",
          sm15: "89.67",
          sm25: "101.76",
          sm35: "116.1",
          sm45: "99.65",
          sm55: "106.14",
          st5: "17.7",
          st15: "19.3",
          st25: "19.1",
          st35: "19.2",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-24 20:00:00.000000000",
          at: "16.3",
          sm5: "120.19",
          sm15: "89.88",
          sm25: "101.75",
          sm35: "116.09",
          sm45: "99.33",
          sm55: "106.11",
          st5: "18.1",
          st15: "19.5",
          st25: "19.2",
          st35: "19.3",
          st45: "18.7",
          st55: "19.3",
        },
        {
          time: "2023-08-24 19:00:00.000000000",
          at: "17.5",
          sm5: "121.09",
          sm15: "90.1",
          sm25: "101.68",
          sm35: "116.0",
          sm45: "99.03",
          sm55: "106.07",
          st5: "18.6",
          st15: "19.6",
          st25: "19.1",
          st35: "19.2",
          st45: "18.8",
          st55: "19.3",
        },
        {
          time: "2023-08-24 18:00:00.000000000",
          at: "18.1",
          sm5: "121.98",
          sm15: "90.31",
          sm25: "101.55",
          sm35: "115.83",
          sm45: "98.82",
          sm55: "106.01",
          st5: "19.1",
          st15: "19.7",
          st25: "19.1",
          st35: "19.2",
          st45: "18.8",
          st55: "19.3",
        },
        {
          time: "2023-08-24 17:00:00.000000000",
          at: "19.3",
          sm5: "121.95",
          sm15: "90.49",
          sm25: "101.34",
          sm35: "115.57",
          sm45: "98.72",
          sm55: "105.95",
          st5: "19.6",
          st15: "19.7",
          st25: "19.0",
          st35: "19.2",
          st45: "18.8",
          st55: "19.3",
        },
        {
          time: "2023-08-24 16:00:00.000000000",
          at: "21.1",
          sm5: "122.73",
          sm15: "90.4",
          sm25: "100.8",
          sm35: "115.37",
          sm45: "98.63",
          sm55: "105.91",
          st5: "20.1",
          st15: "19.7",
          st25: "18.8",
          st35: "19.1",
          st45: "18.8",
          st55: "19.3",
        },
        {
          time: "2023-08-24 15:00:00.000000000",
          at: "21.8",
          sm5: "124.69",
          sm15: "90.79",
          sm25: "99.68",
          sm35: "115.48",
          sm45: "98.51",
          sm55: "105.89",
          st5: "20.3",
          st15: "19.5",
          st25: "18.8",
          st35: "19.2",
          st45: "18.8",
          st55: "19.3",
        },
        {
          time: "2023-08-24 14:00:00.000000000",
          at: "21.7",
          sm5: "131.69",
          sm15: "94.05",
          sm25: "99.68",
          sm35: "115.72",
          sm45: "98.42",
          sm55: "105.91",
          st5: "20.7",
          st15: "19.5",
          st25: "18.7",
          st35: "19.1",
          st45: "18.8",
          st55: "19.2",
        },
        {
          time: "2023-08-24 13:00:00.000000000",
          at: "26.3",
          sm5: "101.27",
          sm15: "76.99",
          sm25: "96.57",
          sm35: "114.39",
          sm45: "97.98",
          sm55: "105.95",
          st5: "21.0",
          st15: "19.2",
          st25: "18.6",
          st35: "19.1",
          st45: "18.8",
          st55: "19.5",
        },
        {
          time: "2023-08-24 12:00:00.000000000",
          at: "26.8",
          sm5: "101.84",
          sm15: "77.0",
          sm25: "96.66",
          sm35: "114.45",
          sm45: "97.97",
          sm55: "105.99",
          st5: "21.0",
          st15: "19.0",
          st25: "18.5",
          st35: "19.2",
          st45: "19.0",
          st55: "19.3",
        },
        {
          time: "2023-08-24 11:00:00.000000000",
          at: "27.1",
          sm5: "102.59",
          sm15: "77.01",
          sm25: "96.78",
          sm35: "114.48",
          sm45: "97.97",
          sm55: "106.03",
          st5: "20.7",
          st15: "18.6",
          st25: "18.5",
          st35: "19.2",
          st45: "19.0",
          st55: "19.5",
        },
        {
          time: "2023-08-24 10:00:00.000000000",
          at: "26.3",
          sm5: "103.46",
          sm15: "77.07",
          sm25: "96.91",
          sm35: "114.45",
          sm45: "97.97",
          sm55: "106.06",
          st5: "20.3",
          st15: "18.2",
          st25: "18.5",
          st35: "19.2",
          st45: "19.0",
          st55: "19.5",
        },
        {
          time: "2023-08-24 09:00:00.000000000",
          at: "26.0",
          sm5: "104.25",
          sm15: "77.14",
          sm25: "97.07",
          sm35: "114.41",
          sm45: "97.99",
          sm55: "106.09",
          st5: "19.6",
          st15: "17.8",
          st25: "18.5",
          st35: "19.2",
          st45: "19.1",
          st55: "19.5",
        },
        {
          time: "2023-08-24 08:00:00.000000000",
          at: "24.3",
          sm5: "104.88",
          sm15: "77.18",
          sm25: "97.26",
          sm35: "114.35",
          sm45: "98.05",
          sm55: "106.11",
          st5: "18.5",
          st15: "17.6",
          st25: "18.5",
          st35: "19.3",
          st45: "19.1",
          st55: "19.5",
        },
        {
          time: "2023-08-24 07:00:00.000000000",
          at: "21.1",
          sm5: "105.06",
          sm15: "77.21",
          sm25: "97.42",
          sm35: "114.26",
          sm45: "98.11",
          sm55: "106.13",
          st5: "17.2",
          st15: "17.5",
          st25: "18.6",
          st35: "19.3",
          st45: "19.1",
          st55: "19.2",
        },
        {
          time: "2023-08-24 06:00:00.000000000",
          at: "17.6",
          sm5: "105.02",
          sm15: "77.25",
          sm25: "97.57",
          sm35: "114.15",
          sm45: "98.17",
          sm55: "106.14",
          st5: "16.0",
          st15: "17.3",
          st25: "18.6",
          st35: "19.5",
          st45: "19.1",
          st55: "19.5",
        },
        {
          time: "2023-08-24 04:00:00.000000000",
          at: "11.3",
          sm5: "105.21",
          sm15: "77.29",
          sm25: "97.73",
          sm35: "113.58",
          sm45: "98.29",
          sm55: "106.1",
          st5: "14.6",
          st15: "17.6",
          st25: "18.8",
          st35: "19.6",
          st45: "19.1",
          st55: "19.5",
        },
        {
          time: "2023-08-24 03:00:00.000000000",
          at: "11.0",
          sm5: "105.71",
          sm15: "77.29",
          sm25: "97.72",
          sm35: "113.18",
          sm45: "98.31",
          sm55: "106.09",
          st5: "14.7",
          st15: "17.8",
          st25: "19.1",
          st35: "19.6",
          st45: "19.1",
          st55: "19.5",
        },
        {
          time: "2023-08-24 02:00:00.000000000",
          at: "11.3",
          sm5: "106.24",
          sm15: "77.27",
          sm25: "97.66",
          sm35: "112.79",
          sm45: "98.31",
          sm55: "106.08",
          st5: "15.0",
          st15: "18.1",
          st25: "19.2",
          st35: "19.6",
          st45: "19.1",
          st55: "19.5",
        },
        {
          time: "2023-08-24 01:00:00.000000000",
          at: "11.5",
          sm5: "106.81",
          sm15: "77.26",
          sm25: "97.49",
          sm35: "112.51",
          sm45: "98.28",
          sm55: "106.07",
          st5: "15.2",
          st15: "18.3",
          st25: "19.2",
          st35: "19.6",
          st45: "19.1",
          st55: "19.6",
        },
        {
          time: "2023-08-24 00:00:00.000000000",
          at: "11.5",
          sm5: "107.46",
          sm15: "77.23",
          sm25: "97.04",
          sm35: "112.44",
          sm45: "98.24",
          sm55: "106.06",
          st5: "15.5",
          st15: "18.6",
          st25: "19.3",
          st35: "19.7",
          st45: "19.1",
          st55: "19.6",
        },
        {
          time: "2023-08-23 23:00:00.000000000",
          at: "11.8",
          sm5: "108.26",
          sm15: "77.17",
          sm25: "96.16",
          sm35: "112.47",
          sm45: "98.2",
          sm55: "106.05",
          st5: "15.8",
          st15: "18.8",
          st25: "19.5",
          st35: "19.6",
          st45: "19.1",
          st55: "19.5",
        },
        {
          time: "2023-08-23 21:00:00.000000000",
          at: "12.1",
          sm5: "109.44",
          sm15: "77.12",
          sm25: "95.2",
          sm35: "112.48",
          sm45: "98.15",
          sm55: "106.03",
          st5: "16.3",
          st15: "19.2",
          st25: "19.5",
          st35: "19.6",
          st45: "19.1",
          st55: "19.6",
        },
        {
          time: "2023-08-23 20:00:00.000000000",
          at: "12.3",
          sm5: "111.36",
          sm15: "76.97",
          sm25: "94.93",
          sm35: "112.38",
          sm45: "98.13",
          sm55: "106.02",
          st5: "16.8",
          st15: "19.3",
          st25: "19.6",
          st35: "19.6",
          st45: "19.1",
          st55: "19.5",
        },
        {
          time: "2023-08-23 19:00:00.000000000",
          at: "15.1",
          sm5: "115.73",
          sm15: "74.76",
          sm25: "94.98",
          sm35: "112.28",
          sm45: "98.12",
          sm55: "106.01",
          st5: "17.5",
          st15: "19.6",
          st25: "19.6",
          st35: "19.6",
          st45: "19.1",
          st55: "19.6",
        },
        {
          time: "2023-08-23 18:00:00.000000000",
          at: "15.6",
          sm5: "93.41",
          sm15: "76.28",
          sm25: "95.0",
          sm35: "112.37",
          sm45: "98.1",
          sm55: "106.01",
          st5: "18.1",
          st15: "19.8",
          st25: "19.6",
          st35: "19.6",
          st45: "19.2",
          st55: "19.6",
        },
        {
          time: "2023-08-23 17:00:00.000000000",
          at: "16.6",
          sm5: "93.93",
          sm15: "76.31",
          sm25: "94.97",
          sm35: "112.35",
          sm45: "98.08",
          sm55: "106.01",
          st5: "18.7",
          st15: "20.1",
          st25: "19.5",
          st35: "19.5",
          st45: "19.1",
          st55: "19.6",
        },
        {
          time: "2023-08-23 16:00:00.000000000",
          at: "18.3",
          sm5: "94.6",
          sm15: "76.3",
          sm25: "94.9",
          sm35: "112.33",
          sm45: "98.06",
          sm55: "106.01",
          st5: "19.7",
          st15: "20.1",
          st25: "19.5",
          st35: "19.6",
          st45: "19.1",
          st55: "19.6",
        },
        {
          time: "2023-08-23 15:00:00.000000000",
          at: "21.0",
          sm5: "95.17",
          sm15: "76.26",
          sm25: "94.8",
          sm35: "112.34",
          sm45: "98.05",
          sm55: "106.01",
          st5: "20.6",
          st15: "20.0",
          st25: "19.3",
          st35: "19.6",
          st45: "19.1",
          st55: "19.6",
        },
        {
          time: "2023-08-23 14:00:00.000000000",
          at: "23.3",
          sm5: "95.31",
          sm15: "76.19",
          sm25: "94.71",
          sm35: "112.34",
          sm45: "98.03",
          sm55: "106.03",
          st5: "21.1",
          st15: "20.0",
          st25: "19.2",
          st35: "19.5",
          st45: "19.1",
          st55: "19.6",
        },
        {
          time: "2023-08-23 13:00:00.000000000",
          at: "24.6",
          sm5: "95.46",
          sm15: "76.16",
          sm25: "94.65",
          sm35: "112.4",
          sm45: "98.02",
          sm55: "106.06",
          st5: "21.3",
          st15: "19.8",
          st25: "19.2",
          st35: "19.5",
          st45: "19.2",
          st55: "19.6",
        },
        {
          time: "2023-08-23 12:00:00.000000000",
          at: "25.7",
          sm5: "95.79",
          sm15: "76.12",
          sm25: "94.7",
          sm35: "112.46",
          sm45: "98.04",
          sm55: "106.1",
          st5: "21.2",
          st15: "19.6",
          st25: "19.2",
          st35: "19.6",
          st45: "19.2",
          st55: "19.6",
        },
        {
          time: "2023-08-23 11:00:00.000000000",
          at: "26.3",
          sm5: "96.28",
          sm15: "76.11",
          sm25: "94.77",
          sm35: "112.54",
          sm45: "98.08",
          sm55: "106.14",
          st5: "21.1",
          st15: "19.3",
          st25: "19.0",
          st35: "19.6",
          st45: "19.2",
          st55: "19.2",
        },
        {
          time: "2023-08-23 10:00:00.000000000",
          at: "26.7",
          sm5: "96.91",
          sm15: "76.13",
          sm25: "94.88",
          sm35: "112.58",
          sm45: "98.12",
          sm55: "106.2",
          st5: "20.8",
          st15: "19.1",
          st25: "19.0",
          st35: "19.6",
          st45: "19.2",
          st55: "19.2",
        },
        {
          time: "2023-08-23 09:00:00.000000000",
          at: "26.2",
          sm5: "97.55",
          sm15: "76.18",
          sm25: "95.01",
          sm35: "112.65",
          sm45: "98.17",
          sm55: "106.24",
          st5: "20.3",
          st15: "18.7",
          st25: "19.0",
          st35: "19.7",
          st45: "19.3",
          st55: "19.7",
        },
        {
          time: "2023-08-23 08:00:00.000000000",
          at: "26.0",
          sm5: "98.14",
          sm15: "76.23",
          sm25: "95.22",
          sm35: "112.66",
          sm45: "98.23",
          sm55: "106.28",
          st5: "19.8",
          st15: "18.3",
          st25: "19.0",
          st35: "19.7",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-23 07:00:00.000000000",
          at: "25.1",
          sm5: "98.44",
          sm15: "76.25",
          sm25: "95.45",
          sm35: "112.69",
          sm45: "98.33",
          sm55: "106.33",
          st5: "18.7",
          st15: "18.2",
          st25: "19.1",
          st35: "19.8",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-23 06:00:00.000000000",
          at: "22.0",
          sm5: "98.49",
          sm15: "76.25",
          sm25: "95.65",
          sm35: "112.65",
          sm45: "98.4",
          sm55: "106.36",
          st5: "17.6",
          st15: "18.0",
          st25: "19.2",
          st35: "19.7",
          st45: "19.3",
          st55: "19.7",
        },
        {
          time: "2023-08-23 05:00:00.000000000",
          at: "17.8",
          sm5: "98.27",
          sm15: "76.27",
          sm25: "95.84",
          sm35: "112.65",
          sm45: "98.48",
          sm55: "106.39",
          st5: "16.5",
          st15: "18.0",
          st25: "19.2",
          st35: "19.8",
          st45: "19.3",
          st55: "19.7",
        },
        {
          time: "2023-08-23 04:00:00.000000000",
          at: "14.2",
          sm5: "97.96",
          sm15: "76.28",
          sm25: "95.98",
          sm35: "112.62",
          sm45: "98.55",
          sm55: "106.38",
          st5: "15.5",
          st15: "18.1",
          st25: "19.3",
          st35: "20.0",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-23 03:00:00.000000000",
          at: "11.1",
          sm5: "97.89",
          sm15: "76.31",
          sm25: "96.1",
          sm35: "112.47",
          sm45: "98.61",
          sm55: "106.38",
          st5: "15.0",
          st15: "18.2",
          st25: "19.5",
          st35: "20.0",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-23 02:00:00.000000000",
          at: "11.1",
          sm5: "98.01",
          sm15: "76.33",
          sm25: "96.16",
          sm35: "112.27",
          sm45: "98.62",
          sm55: "106.37",
          st5: "15.2",
          st15: "18.6",
          st25: "19.6",
          st35: "20.0",
          st45: "19.3",
          st55: "19.7",
        },
        {
          time: "2023-08-23 01:00:00.000000000",
          at: "11.6",
          sm5: "98.16",
          sm15: "76.34",
          sm25: "96.22",
          sm35: "112.04",
          sm45: "98.62",
          sm55: "106.36",
          st5: "15.6",
          st15: "18.7",
          st25: "19.7",
          st35: "20.1",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-23 00:00:00.000000000",
          at: "12.3",
          sm5: "98.33",
          sm15: "76.35",
          sm25: "96.29",
          sm35: "111.78",
          sm45: "98.62",
          sm55: "106.34",
          st5: "16.0",
          st15: "19.0",
          st25: "19.8",
          st35: "20.1",
          st45: "19.3",
          st55: "19.6",
        },
        {
          time: "2023-08-22 23:00:00.000000000",
          at: "12.7",
          sm5: "98.46",
          sm15: "76.38",
          sm25: "96.38",
          sm35: "111.48",
          sm45: "98.62",
          sm55: "106.33",
          st5: "16.2",
          st15: "19.2",
          st25: "19.8",
          st35: "20.1",
          st45: "19.3",
          st55: "19.7",
        },
        {
          time: "2023-08-22 22:00:00.000000000",
          at: "13.2",
          sm5: "98.62",
          sm15: "76.41",
          sm25: "96.51",
          sm35: "111.14",
          sm45: "98.62",
          sm55: "106.32",
          st5: "16.6",
          st15: "19.5",
          st25: "20.0",
          st35: "20.1",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-22 21:00:00.000000000",
          at: "13.3",
          sm5: "98.83",
          sm15: "76.47",
          sm25: "96.63",
          sm35: "110.78",
          sm45: "98.61",
          sm55: "106.3",
          st5: "16.8",
          st15: "19.7",
          st25: "20.1",
          st35: "20.1",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-22 20:00:00.000000000",
          at: "14.0",
          sm5: "99.09",
          sm15: "76.54",
          sm25: "96.74",
          sm35: "110.4",
          sm45: "98.61",
          sm55: "106.29",
          st5: "17.3",
          st15: "20.0",
          st25: "20.1",
          st35: "20.1",
          st45: "19.3",
          st55: "19.7",
        },
        {
          time: "2023-08-22 19:00:00.000000000",
          at: "14.9",
          sm5: "99.49",
          sm15: "76.62",
          sm25: "96.79",
          sm35: "110.03",
          sm45: "98.6",
          sm55: "106.29",
          st5: "18.0",
          st15: "20.2",
          st25: "20.2",
          st35: "20.1",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-22 18:00:00.000000000",
          at: "16.1",
          sm5: "99.97",
          sm15: "76.69",
          sm25: "96.81",
          sm35: "109.68",
          sm45: "98.58",
          sm55: "106.28",
          st5: "18.6",
          st15: "20.5",
          st25: "20.1",
          st35: "20.0",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-22 17:00:00.000000000",
          at: "16.6",
          sm5: "100.56",
          sm15: "76.75",
          sm25: "96.77",
          sm35: "109.37",
          sm45: "98.57",
          sm55: "106.28",
          st5: "19.3",
          st15: "20.6",
          st25: "20.1",
          st35: "20.0",
          st45: "19.3",
          st55: "19.7",
        },
        {
          time: "2023-08-22 16:00:00.000000000",
          at: "18.8",
          sm5: "101.2",
          sm15: "76.76",
          sm25: "96.66",
          sm35: "109.12",
          sm45: "98.53",
          sm55: "106.29",
          st5: "20.2",
          st15: "20.7",
          st25: "20.0",
          st35: "20.0",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-22 15:00:00.000000000",
          at: "21.9",
          sm5: "101.83",
          sm15: "76.73",
          sm25: "96.49",
          sm35: "108.96",
          sm45: "98.45",
          sm55: "106.3",
          st5: "21.0",
          st15: "20.7",
          st25: "20.0",
          st35: "20.0",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-22 14:00:00.000000000",
          at: "23.7",
          sm5: "102.42",
          sm15: "76.71",
          sm25: "96.32",
          sm35: "108.89",
          sm45: "98.44",
          sm55: "106.31",
          st5: "21.6",
          st15: "20.6",
          st25: "19.8",
          st35: "20.0",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-22 13:00:00.000000000",
          at: "25.0",
          sm5: "102.95",
          sm15: "76.7",
          sm25: "96.14",
          sm35: "108.94",
          sm45: "98.44",
          sm55: "106.34",
          st5: "21.7",
          st15: "20.5",
          st25: "19.8",
          st35: "20.0",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-22 12:00:00.000000000",
          at: "26.0",
          sm5: "103.7",
          sm15: "76.75",
          sm25: "95.95",
          sm35: "109.08",
          sm45: "98.45",
          sm55: "106.38",
          st5: "21.8",
          st15: "20.3",
          st25: "19.7",
          st35: "20.0",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-22 11:00:00.000000000",
          at: "26.5",
          sm5: "104.57",
          sm15: "76.82",
          sm25: "95.64",
          sm35: "109.3",
          sm45: "98.48",
          sm55: "106.42",
          st5: "21.6",
          st15: "20.1",
          st25: "19.6",
          st35: "20.0",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-22 10:00:00.000000000",
          at: "26.9",
          sm5: "105.82",
          sm15: "76.91",
          sm25: "95.11",
          sm35: "109.53",
          sm45: "98.5",
          sm55: "106.47",
          st5: "21.5",
          st15: "19.8",
          st25: "19.6",
          st35: "20.0",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-22 09:00:00.000000000",
          at: "25.8",
          sm5: "107.65",
          sm15: "77.12",
          sm25: "94.32",
          sm35: "109.8",
          sm45: "98.53",
          sm55: "106.49",
          st5: "21.0",
          st15: "19.6",
          st25: "19.6",
          st35: "20.1",
          st45: "19.6",
          st55: "19.2",
        },
        {
          time: "2023-08-22 08:00:00.000000000",
          at: "24.0",
          sm5: "111.08",
          sm15: "78.0",
          sm25: "93.54",
          sm35: "110.06",
          sm45: "98.58",
          sm55: "106.52",
          st5: "20.5",
          st15: "19.3",
          st25: "19.6",
          st35: "20.1",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-22 07:00:00.000000000",
          at: "24.3",
          sm5: "125.78",
          sm15: "84.59",
          sm25: "88.71",
          sm35: "110.72",
          sm45: "98.58",
          sm55: "106.56",
          st5: "20.5",
          st15: "19.1",
          st25: "19.7",
          st35: "20.1",
          st45: "19.5",
          st55: "19.8",
        },
        {
          time: "2023-08-22 06:00:00.000000000",
          at: "22.3",
          sm5: "95.32",
          sm15: "74.36",
          sm25: "81.86",
          sm35: "112.17",
          sm45: "98.5",
          sm55: "106.61",
          st5: "18.6",
          st15: "19.0",
          st25: "19.7",
          st35: "20.2",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-22 05:00:00.000000000",
          at: "18.6",
          sm5: "95.24",
          sm15: "74.41",
          sm25: "82.0",
          sm35: "112.29",
          sm45: "98.56",
          sm55: "106.62",
          st5: "17.5",
          st15: "19.0",
          st25: "19.8",
          st35: "20.2",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-22 04:00:00.000000000",
          at: "15.2",
          sm5: "95.19",
          sm15: "74.42",
          sm25: "82.03",
          sm35: "112.34",
          sm45: "98.61",
          sm55: "106.62",
          st5: "16.6",
          st15: "19.1",
          st25: "20.0",
          st35: "20.2",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-22 03:00:00.000000000",
          at: "12.8",
          sm5: "95.27",
          sm15: "74.43",
          sm25: "82.03",
          sm35: "112.34",
          sm45: "98.64",
          sm55: "106.62",
          st5: "16.2",
          st15: "19.3",
          st25: "20.1",
          st35: "20.3",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-22 02:00:00.000000000",
          at: "13.3",
          sm5: "95.5",
          sm15: "74.38",
          sm25: "81.99",
          sm35: "112.34",
          sm45: "98.62",
          sm55: "106.61",
          st5: "16.7",
          st15: "19.6",
          st25: "20.2",
          st35: "20.2",
          st45: "19.6",
          st55: "19.2",
        },
        {
          time: "2023-08-22 01:00:00.000000000",
          at: "13.8",
          sm5: "95.75",
          sm15: "74.31",
          sm25: "82.01",
          sm35: "112.35",
          sm45: "98.61",
          sm55: "106.6",
          st5: "17.2",
          st15: "19.7",
          st25: "20.2",
          st35: "20.3",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-22 00:00:00.000000000",
          at: "14.7",
          sm5: "96.02",
          sm15: "74.21",
          sm25: "82.07",
          sm35: "112.32",
          sm45: "98.58",
          sm55: "106.59",
          st5: "17.6",
          st15: "20.0",
          st25: "20.3",
          st35: "20.3",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-21 23:00:00.000000000",
          at: "15.4",
          sm5: "96.31",
          sm15: "74.08",
          sm25: "82.19",
          sm35: "112.29",
          sm45: "98.56",
          sm55: "106.57",
          st5: "18.0",
          st15: "20.2",
          st25: "20.3",
          st35: "20.3",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-21 22:00:00.000000000",
          at: "17.1",
          sm5: "96.61",
          sm15: "73.89",
          sm25: "82.35",
          sm35: "112.23",
          sm45: "98.53",
          sm55: "106.56",
          st5: "18.5",
          st15: "20.3",
          st25: "20.5",
          st35: "20.3",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-21 21:00:00.000000000",
          at: "18.0",
          sm5: "96.76",
          sm15: "73.66",
          sm25: "82.58",
          sm35: "112.17",
          sm45: "98.49",
          sm55: "106.55",
          st5: "18.7",
          st15: "20.6",
          st25: "20.5",
          st35: "20.2",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-21 20:00:00.000000000",
          at: "16.5",
          sm5: "97.01",
          sm15: "73.42",
          sm25: "82.82",
          sm35: "112.07",
          sm45: "98.48",
          sm55: "106.53",
          st5: "18.7",
          st15: "20.7",
          st25: "20.5",
          st35: "20.2",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-21 19:00:00.000000000",
          at: "16.8",
          sm5: "97.47",
          sm15: "73.13",
          sm25: "82.93",
          sm35: "111.99",
          sm45: "98.46",
          sm55: "106.52",
          st5: "19.3",
          st15: "21.0",
          st25: "20.5",
          st35: "20.2",
          st45: "19.5",
          st55: "19.8",
        },
        {
          time: "2023-08-21 18:00:00.000000000",
          at: "17.8",
          sm5: "97.95",
          sm15: "72.77",
          sm25: "82.89",
          sm35: "111.96",
          sm45: "98.43",
          sm55: "106.52",
          st5: "20.0",
          st15: "21.2",
          st25: "20.5",
          st35: "20.2",
          st45: "19.5",
          st55: "19.8",
        },
        {
          time: "2023-08-21 17:00:00.000000000",
          at: "19.1",
          sm5: "98.53",
          sm15: "72.31",
          sm25: "82.74",
          sm35: "111.96",
          sm45: "98.41",
          sm55: "106.52",
          st5: "20.7",
          st15: "21.3",
          st25: "20.3",
          st35: "20.1",
          st45: "19.5",
          st55: "19.2",
        },
        {
          time: "2023-08-21 16:00:00.000000000",
          at: "21.6",
          sm5: "99.2",
          sm15: "71.68",
          sm25: "82.52",
          sm35: "111.98",
          sm45: "98.37",
          sm55: "106.52",
          st5: "21.5",
          st15: "21.3",
          st25: "20.3",
          st35: "20.1",
          st45: "19.6",
          st55: "19.2",
        },
        {
          time: "2023-08-21 15:00:00.000000000",
          at: "23.1",
          sm5: "99.92",
          sm15: "70.92",
          sm25: "82.27",
          sm35: "112.06",
          sm45: "98.34",
          sm55: "106.53",
          st5: "22.2",
          st15: "21.3",
          st25: "20.2",
          st35: "20.1",
          st45: "19.5",
          st55: "19.2",
        },
        {
          time: "2023-08-21 14:00:00.000000000",
          at: "24.6",
          sm5: "100.77",
          sm15: "70.06",
          sm25: "82.06",
          sm35: "112.1",
          sm45: "98.32",
          sm55: "106.54",
          st5: "22.7",
          st15: "21.2",
          st25: "20.1",
          st35: "20.1",
          st45: "19.6",
          st55: "19.2",
        },
        {
          time: "2023-08-21 13:00:00.000000000",
          at: "25.6",
          sm5: "101.95",
          sm15: "69.17",
          sm25: "81.92",
          sm35: "112.16",
          sm45: "98.33",
          sm55: "106.56",
          st5: "22.8",
          st15: "21.0",
          st25: "20.0",
          st35: "20.0",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-21 12:00:00.000000000",
          at: "25.5",
          sm5: "103.91",
          sm15: "68.51",
          sm25: "81.84",
          sm35: "112.25",
          sm45: "98.36",
          sm55: "106.58",
          st5: "22.7",
          st15: "20.7",
          st25: "19.8",
          st35: "20.0",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-21 11:00:00.000000000",
          at: "23.6",
          sm5: "108.37",
          sm15: "68.19",
          sm25: "81.72",
          sm35: "112.41",
          sm45: "98.46",
          sm55: "106.62",
          st5: "22.5",
          st15: "20.5",
          st25: "19.8",
          st35: "20.1",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-21 10:00:00.000000000",
          at: "28.5",
          sm5: "80.97",
          sm15: "67.86",
          sm25: "81.64",
          sm35: "113.07",
          sm45: "98.57",
          sm55: "106.66",
          st5: "22.5",
          st15: "20.1",
          st25: "19.8",
          st35: "20.1",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-21 09:00:00.000000000",
          at: "27.6",
          sm5: "80.77",
          sm15: "68.71",
          sm25: "82.4",
          sm35: "113.44",
          sm45: "98.72",
          sm55: "106.7",
          st5: "22.0",
          st15: "19.8",
          st25: "19.7",
          st35: "20.1",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-21 08:00:00.000000000",
          at: "27.2",
          sm5: "80.42",
          sm15: "69.51",
          sm25: "83.73",
          sm35: "112.58",
          sm45: "98.91",
          sm55: "106.74",
          st5: "21.5",
          st15: "19.5",
          st25: "19.7",
          st35: "20.2",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-21 07:00:00.000000000",
          at: "26.0",
          sm5: "79.48",
          sm15: "70.05",
          sm25: "85.1",
          sm35: "112.44",
          sm45: "99.03",
          sm55: "106.77",
          st5: "20.2",
          st15: "19.2",
          st25: "19.8",
          st35: "20.2",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-21 06:00:00.000000000",
          at: "22.5",
          sm5: "78.62",
          sm15: "70.39",
          sm25: "86.07",
          sm35: "112.3",
          sm45: "99.13",
          sm55: "106.79",
          st5: "19.0",
          st15: "19.1",
          st25: "19.8",
          st35: "20.2",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-21 05:00:00.000000000",
          at: "19.3",
          sm5: "77.97",
          sm15: "70.62",
          sm25: "86.76",
          sm35: "112.17",
          sm45: "99.19",
          sm55: "106.79",
          st5: "17.8",
          st15: "19.1",
          st25: "20.0",
          st35: "20.2",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-21 04:00:00.000000000",
          at: "15.3",
          sm5: "77.36",
          sm15: "70.7",
          sm25: "87.09",
          sm35: "112.03",
          sm45: "99.25",
          sm55: "106.74",
          st5: "16.7",
          st15: "19.2",
          st25: "20.1",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-21 03:00:00.000000000",
          at: "12.6",
          sm5: "77.19",
          sm15: "70.79",
          sm25: "87.27",
          sm35: "111.62",
          sm45: "99.28",
          sm55: "106.74",
          st5: "16.3",
          st15: "19.5",
          st25: "20.1",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-21 02:00:00.000000000",
          at: "12.7",
          sm5: "77.38",
          sm15: "70.85",
          sm25: "87.39",
          sm35: "111.57",
          sm45: "99.26",
          sm55: "106.72",
          st5: "16.6",
          st15: "19.6",
          st25: "20.2",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-21 01:00:00.000000000",
          at: "13.1",
          sm5: "77.67",
          sm15: "70.93",
          sm25: "87.52",
          sm35: "111.49",
          sm45: "99.24",
          sm55: "106.71",
          st5: "17.0",
          st15: "20.0",
          st25: "20.3",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-21 00:00:00.000000000",
          at: "14.0",
          sm5: "78.03",
          sm15: "71.01",
          sm25: "87.67",
          sm35: "111.4",
          sm45: "99.22",
          sm55: "106.7",
          st5: "17.5",
          st15: "20.1",
          st25: "20.5",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-20 23:00:00.000000000",
          at: "14.1",
          sm5: "78.37",
          sm15: "71.11",
          sm25: "87.82",
          sm35: "111.3",
          sm45: "99.2",
          sm55: "106.69",
          st5: "18.0",
          st15: "20.3",
          st25: "20.5",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-20 22:00:00.000000000",
          at: "15.6",
          sm5: "78.96",
          sm15: "71.2",
          sm25: "87.96",
          sm35: "110.92",
          sm45: "99.19",
          sm55: "106.68",
          st5: "18.6",
          st15: "20.6",
          st25: "20.5",
          st35: "20.3",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-20 21:00:00.000000000",
          at: "17.6",
          sm5: "79.58",
          sm15: "71.29",
          sm25: "88.05",
          sm35: "110.82",
          sm45: "99.17",
          sm55: "106.68",
          st5: "19.3",
          st15: "20.7",
          st25: "20.6",
          st35: "20.3",
          st45: "19.6",
          st55: "20.0",
        },
        {
          time: "2023-08-20 20:00:00.000000000",
          at: "19.8",
          sm5: "80.08",
          sm15: "71.35",
          sm25: "88.11",
          sm35: "110.74",
          sm45: "99.15",
          sm55: "106.68",
          st5: "20.0",
          st15: "21.0",
          st25: "20.6",
          st35: "20.3",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-20 19:00:00.000000000",
          at: "20.5",
          sm5: "80.33",
          sm15: "71.43",
          sm25: "88.15",
          sm35: "110.51",
          sm45: "99.14",
          sm55: "106.67",
          st5: "20.2",
          st15: "21.1",
          st25: "20.6",
          st35: "20.3",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-20 18:00:00.000000000",
          at: "20.8",
          sm5: "80.55",
          sm15: "71.52",
          sm25: "88.14",
          sm35: "110.45",
          sm45: "99.12",
          sm55: "106.67",
          st5: "20.5",
          st15: "21.2",
          st25: "20.6",
          st35: "20.2",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-20 17:00:00.000000000",
          at: "21.1",
          sm5: "81.03",
          sm15: "71.63",
          sm25: "88.07",
          sm35: "110.3",
          sm45: "99.1",
          sm55: "106.68",
          st5: "21.0",
          st15: "21.2",
          st25: "20.5",
          st35: "20.2",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-20 16:00:00.000000000",
          at: "21.1",
          sm5: "81.6",
          sm15: "71.69",
          sm25: "87.92",
          sm35: "110.24",
          sm45: "99.09",
          sm55: "106.68",
          st5: "21.5",
          st15: "21.3",
          st25: "20.3",
          st35: "20.2",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-20 15:00:00.000000000",
          at: "23.6",
          sm5: "82.49",
          sm15: "71.7",
          sm25: "87.72",
          sm35: "110.04",
          sm45: "99.05",
          sm55: "106.7",
          st5: "22.2",
          st15: "21.3",
          st25: "20.3",
          st35: "20.2",
          st45: "19.6",
          st55: "20.0",
        },
        {
          time: "2023-08-20 14:00:00.000000000",
          at: "25.7",
          sm5: "83.24",
          sm15: "71.77",
          sm25: "87.73",
          sm35: "109.65",
          sm45: "99.01",
          sm55: "106.73",
          st5: "22.7",
          st15: "21.2",
          st25: "20.2",
          st35: "20.2",
          st45: "19.6",
          st55: "20.0",
        },
        {
          time: "2023-08-20 13:00:00.000000000",
          at: "26.7",
          sm5: "83.9",
          sm15: "72.0",
          sm25: "88.01",
          sm35: "109.59",
          sm45: "99.04",
          sm55: "106.77",
          st5: "22.8",
          st15: "21.1",
          st25: "20.1",
          st35: "20.2",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-20 12:00:00.000000000",
          at: "27.7",
          sm5: "84.39",
          sm15: "72.33",
          sm25: "88.5",
          sm35: "109.58",
          sm45: "99.1",
          sm55: "106.83",
          st5: "23.0",
          st15: "20.8",
          st25: "20.1",
          st35: "20.2",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-20 11:00:00.000000000",
          at: "28.0",
          sm5: "84.86",
          sm15: "72.7",
          sm25: "89.11",
          sm35: "108.73",
          sm45: "99.2",
          sm55: "106.88",
          st5: "23.0",
          st15: "20.6",
          st25: "20.0",
          st35: "20.2",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-20 10:00:00.000000000",
          at: "28.8",
          sm5: "85.11",
          sm15: "73.08",
          sm25: "89.66",
          sm35: "108.12",
          sm45: "99.28",
          sm55: "106.93",
          st5: "22.7",
          st15: "20.3",
          st25: "19.8",
          st35: "20.2",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-20 09:00:00.000000000",
          at: "27.8",
          sm5: "85.34",
          sm15: "73.49",
          sm25: "90.14",
          sm35: "108.24",
          sm45: "99.36",
          sm55: "106.98",
          st5: "22.1",
          st15: "20.0",
          st25: "19.8",
          st35: "20.2",
          st45: "19.7",
          st55: "19.2",
        },
        {
          time: "2023-08-20 08:00:00.000000000",
          at: "27.1",
          sm5: "85.57",
          sm15: "73.82",
          sm25: "90.69",
          sm35: "108.34",
          sm45: "99.47",
          sm55: "107.02",
          st5: "21.6",
          st15: "19.6",
          st25: "19.8",
          st35: "20.2",
          st45: "19.7",
          st55: "19.2",
        },
        {
          time: "2023-08-20 07:00:00.000000000",
          at: "25.6",
          sm5: "85.41",
          sm15: "74.03",
          sm25: "91.24",
          sm35: "108.4",
          sm45: "99.56",
          sm55: "107.04",
          st5: "20.3",
          st15: "19.3",
          st25: "19.8",
          st35: "20.3",
          st45: "19.8",
          st55: "19.2",
        },
        {
          time: "2023-08-20 06:00:00.000000000",
          at: "22.5",
          sm5: "85.28",
          sm15: "74.15",
          sm25: "91.57",
          sm35: "108.48",
          sm45: "99.64",
          sm55: "107.04",
          st5: "19.1",
          st15: "19.2",
          st25: "20.0",
          st35: "20.3",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-20 05:00:00.000000000",
          at: "19.7",
          sm5: "85.23",
          sm15: "74.23",
          sm25: "91.85",
          sm35: "108.52",
          sm45: "99.71",
          sm55: "107.04",
          st5: "18.1",
          st15: "19.1",
          st25: "20.0",
          st35: "20.3",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-20 04:00:00.000000000",
          at: "16.0",
          sm5: "85.04",
          sm15: "74.27",
          sm25: "92.05",
          sm35: "108.54",
          sm45: "99.78",
          sm55: "107.04",
          st5: "17.0",
          st15: "19.2",
          st25: "20.1",
          st35: "20.5",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-20 03:00:00.000000000",
          at: "13.7",
          sm5: "85.04",
          sm15: "74.31",
          sm25: "92.15",
          sm35: "108.49",
          sm45: "99.8",
          sm55: "107.03",
          st5: "16.7",
          st15: "19.3",
          st25: "20.2",
          st35: "20.5",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-20 02:00:00.000000000",
          at: "13.8",
          sm5: "85.15",
          sm15: "74.34",
          sm25: "92.21",
          sm35: "108.49",
          sm45: "99.8",
          sm55: "107.02",
          st5: "16.7",
          st15: "19.6",
          st25: "20.3",
          st35: "20.5",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-20 01:00:00.000000000",
          at: "14.0",
          sm5: "85.23",
          sm15: "74.37",
          sm25: "92.28",
          sm35: "108.48",
          sm45: "99.78",
          sm55: "107.01",
          st5: "17.0",
          st15: "19.8",
          st25: "20.5",
          st35: "20.6",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-20 00:00:00.000000000",
          at: "14.3",
          sm5: "85.39",
          sm15: "74.41",
          sm25: "92.38",
          sm35: "108.44",
          sm45: "99.76",
          sm55: "107.0",
          st5: "17.2",
          st15: "20.1",
          st25: "20.6",
          st35: "20.6",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-19 23:00:00.000000000",
          at: "14.8",
          sm5: "85.51",
          sm15: "74.45",
          sm25: "92.52",
          sm35: "108.35",
          sm45: "99.74",
          sm55: "106.99",
          st5: "17.5",
          st15: "20.2",
          st25: "20.6",
          st35: "20.6",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-19 22:00:00.000000000",
          at: "14.6",
          sm5: "85.68",
          sm15: "74.51",
          sm25: "92.7",
          sm35: "108.28",
          sm45: "99.73",
          sm55: "106.98",
          st5: "17.7",
          st15: "20.6",
          st25: "20.7",
          st35: "20.5",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-19 21:00:00.000000000",
          at: "15.0",
          sm5: "85.91",
          sm15: "74.58",
          sm25: "92.9",
          sm35: "108.15",
          sm45: "99.7",
          sm55: "106.98",
          st5: "18.2",
          st15: "20.8",
          st25: "20.7",
          st35: "20.5",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-19 20:00:00.000000000",
          at: "16.2",
          sm5: "86.24",
          sm15: "74.66",
          sm25: "93.06",
          sm35: "108.04",
          sm45: "99.69",
          sm55: "106.97",
          st5: "18.8",
          st15: "21.1",
          st25: "20.8",
          st35: "20.5",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-19 19:00:00.000000000",
          at: "17.2",
          sm5: "86.57",
          sm15: "74.77",
          sm25: "93.13",
          sm35: "107.97",
          sm45: "99.67",
          sm55: "106.98",
          st5: "19.6",
          st15: "21.3",
          st25: "20.7",
          st35: "20.5",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-19 18:00:00.000000000",
          at: "18.1",
          sm5: "86.96",
          sm15: "74.91",
          sm25: "93.11",
          sm35: "107.89",
          sm45: "99.65",
          sm55: "106.99",
          st5: "20.3",
          st15: "21.5",
          st25: "20.7",
          st35: "20.5",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-19 17:00:00.000000000",
          at: "19.8",
          sm5: "87.49",
          sm15: "75.03",
          sm25: "93.04",
          sm35: "107.88",
          sm45: "99.63",
          sm55: "107.03",
          st5: "21.1",
          st15: "21.6",
          st25: "20.7",
          st35: "20.5",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-19 16:00:00.000000000",
          at: "22.5",
          sm5: "88.05",
          sm15: "75.08",
          sm25: "92.89",
          sm35: "107.9",
          sm45: "99.61",
          sm55: "107.05",
          st5: "22.1",
          st15: "21.7",
          st25: "20.6",
          st35: "20.3",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-19 15:00:00.000000000",
          at: "24.3",
          sm5: "88.62",
          sm15: "75.08",
          sm25: "92.72",
          sm35: "107.92",
          sm45: "99.59",
          sm55: "107.08",
          st5: "22.7",
          st15: "21.7",
          st25: "20.5",
          st35: "20.3",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-19 14:00:00.000000000",
          at: "25.4",
          sm5: "89.29",
          sm15: "75.09",
          sm25: "92.59",
          sm35: "107.98",
          sm45: "99.59",
          sm55: "107.11",
          st5: "23.2",
          st15: "21.5",
          st25: "20.5",
          st35: "20.3",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-19 13:00:00.000000000",
          at: "26.6",
          sm5: "90.0",
          sm15: "75.13",
          sm25: "92.51",
          sm35: "108.04",
          sm45: "99.59",
          sm55: "107.15",
          st5: "23.3",
          st15: "21.3",
          st25: "20.3",
          st35: "20.3",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-19 12:00:00.000000000",
          at: "27.7",
          sm5: "90.89",
          sm15: "75.25",
          sm25: "92.53",
          sm35: "108.16",
          sm45: "99.61",
          sm55: "107.2",
          st5: "23.5",
          st15: "21.1",
          st25: "20.2",
          st35: "20.3",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-19 11:00:00.000000000",
          at: "28.3",
          sm5: "91.59",
          sm15: "75.35",
          sm25: "92.59",
          sm35: "108.24",
          sm45: "99.65",
          sm55: "107.24",
          st5: "23.3",
          st15: "20.8",
          st25: "20.1",
          st35: "20.3",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-19 10:00:00.000000000",
          at: "29.2",
          sm5: "92.32",
          sm15: "75.58",
          sm25: "92.19",
          sm35: "116.07",
          sm45: "99.44",
          sm55: "107.31",
          st5: "23.0",
          st15: "20.5",
          st25: "20.1",
          st35: "20.3",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-19 09:00:00.000000000",
          at: "28.0",
          sm5: "93.03",
          sm15: "75.65",
          sm25: "92.32",
          sm35: "116.21",
          sm45: "99.51",
          sm55: "107.33",
          st5: "22.3",
          st15: "20.2",
          st25: "20.0",
          st35: "20.3",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-19 08:00:00.000000000",
          at: "27.6",
          sm5: "93.71",
          sm15: "75.7",
          sm25: "92.55",
          sm35: "116.29",
          sm45: "99.59",
          sm55: "107.35",
          st5: "21.8",
          st15: "19.8",
          st25: "20.1",
          st35: "20.5",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-19 07:00:00.000000000",
          at: "26.0",
          sm5: "93.87",
          sm15: "75.74",
          sm25: "92.84",
          sm35: "116.35",
          sm45: "99.66",
          sm55: "107.36",
          st5: "20.5",
          st15: "19.6",
          st25: "20.1",
          st35: "20.5",
          st45: "20.0",
          st55: "20.0",
        },
        {
          time: "2023-08-19 06:00:00.000000000",
          at: "23.8",
          sm5: "93.82",
          sm15: "75.7",
          sm25: "93.06",
          sm35: "116.42",
          sm45: "99.74",
          sm55: "107.36",
          st5: "19.5",
          st15: "19.5",
          st25: "20.2",
          st35: "20.5",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-19 05:00:00.000000000",
          at: "20.8",
          sm5: "93.75",
          sm15: "75.67",
          sm25: "93.29",
          sm35: "116.47",
          sm45: "99.81",
          sm55: "107.36",
          st5: "18.5",
          st15: "19.5",
          st25: "20.3",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-19 04:00:00.000000000",
          at: "16.2",
          sm5: "93.56",
          sm15: "75.68",
          sm25: "93.43",
          sm35: "116.47",
          sm45: "99.89",
          sm55: "107.35",
          st5: "17.5",
          st15: "19.6",
          st25: "20.3",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-19 03:00:00.000000000",
          at: "14.2",
          sm5: "93.62",
          sm15: "75.68",
          sm25: "93.59",
          sm35: "114.65",
          sm45: "100.03",
          sm55: "107.33",
          st5: "17.2",
          st15: "19.8",
          st25: "20.5",
          st35: "20.6",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-19 02:00:00.000000000",
          at: "14.5",
          sm5: "93.68",
          sm15: "75.7",
          sm25: "93.61",
          sm35: "114.64",
          sm45: "100.02",
          sm55: "107.32",
          st5: "17.3",
          st15: "20.0",
          st25: "20.6",
          st35: "20.6",
          st45: "20.0",
          st55: "20.0",
        },
        {
          time: "2023-08-19 01:00:00.000000000",
          at: "15.0",
          sm5: "93.73",
          sm15: "75.73",
          sm25: "93.65",
          sm35: "114.61",
          sm45: "100.01",
          sm55: "107.31",
          st5: "17.6",
          st15: "20.1",
          st25: "20.7",
          st35: "20.6",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-19 00:00:00.000000000",
          at: "15.2",
          sm5: "93.76",
          sm15: "75.77",
          sm25: "93.71",
          sm35: "114.56",
          sm45: "99.97",
          sm55: "107.31",
          st5: "17.7",
          st15: "20.3",
          st25: "20.7",
          st35: "20.7",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-18 23:00:00.000000000",
          at: "14.8",
          sm5: "93.81",
          sm15: "75.82",
          sm25: "93.81",
          sm35: "114.47",
          sm45: "99.97",
          sm55: "107.3",
          st5: "18.0",
          st15: "20.6",
          st25: "20.8",
          st35: "20.6",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-18 22:00:00.000000000",
          at: "15.0",
          sm5: "93.91",
          sm15: "75.89",
          sm25: "93.95",
          sm35: "114.35",
          sm45: "99.95",
          sm55: "107.29",
          st5: "18.2",
          st15: "20.8",
          st25: "20.8",
          st35: "20.6",
          st45: "20.0",
          st55: "20.0",
        },
        {
          time: "2023-08-18 21:00:00.000000000",
          at: "15.5",
          sm5: "94.04",
          sm15: "75.97",
          sm25: "94.11",
          sm35: "114.23",
          sm45: "99.93",
          sm55: "107.29",
          st5: "18.7",
          st15: "21.1",
          st25: "21.0",
          st35: "20.6",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-18 20:00:00.000000000",
          at: "16.1",
          sm5: "94.2",
          sm15: "76.07",
          sm25: "94.21",
          sm35: "114.13",
          sm45: "99.91",
          sm55: "107.29",
          st5: "19.3",
          st15: "21.3",
          st25: "21.0",
          st35: "20.7",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-18 19:00:00.000000000",
          at: "17.0",
          sm5: "94.42",
          sm15: "76.21",
          sm25: "94.2",
          sm35: "114.06",
          sm45: "99.89",
          sm55: "107.29",
          st5: "20.0",
          st15: "21.6",
          st25: "21.0",
          st35: "20.6",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-18 18:00:00.000000000",
          at: "18.4",
          sm5: "94.7",
          sm15: "76.34",
          sm25: "94.1",
          sm35: "114.04",
          sm45: "99.86",
          sm55: "107.3",
          st5: "20.6",
          st15: "21.6",
          st25: "20.8",
          st35: "20.6",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-18 17:00:00.000000000",
          at: "19.8",
          sm5: "95.08",
          sm15: "76.41",
          sm25: "93.9",
          sm35: "114.05",
          sm45: "99.84",
          sm55: "107.31",
          st5: "21.3",
          st15: "21.8",
          st25: "20.8",
          st35: "20.5",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-18 16:00:00.000000000",
          at: "22.1",
          sm5: "95.57",
          sm15: "76.42",
          sm25: "93.66",
          sm35: "114.05",
          sm45: "99.82",
          sm55: "107.33",
          st5: "22.2",
          st15: "21.8",
          st25: "20.7",
          st35: "20.5",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-18 15:00:00.000000000",
          at: "24.0",
          sm5: "96.12",
          sm15: "76.41",
          sm25: "93.37",
          sm35: "114.09",
          sm45: "99.79",
          sm55: "107.34",
          st5: "22.7",
          st15: "21.8",
          st25: "20.7",
          st35: "20.5",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-18 14:00:00.000000000",
          at: "26.6",
          sm5: "96.65",
          sm15: "76.37",
          sm25: "93.11",
          sm35: "114.16",
          sm45: "99.77",
          sm55: "107.37",
          st5: "23.1",
          st15: "21.8",
          st25: "20.6",
          st35: "20.5",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-18 13:00:00.000000000",
          at: "27.3",
          sm5: "97.53",
          sm15: "76.37",
          sm25: "92.93",
          sm35: "114.3",
          sm45: "99.77",
          sm55: "107.4",
          st5: "23.3",
          st15: "21.6",
          st25: "20.5",
          st35: "20.5",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-18 12:00:00.000000000",
          at: "28.0",
          sm5: "98.81",
          sm15: "76.38",
          sm25: "92.76",
          sm35: "114.57",
          sm45: "99.8",
          sm55: "107.43",
          st5: "23.7",
          st15: "21.5",
          st25: "20.3",
          st35: "20.5",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-18 11:00:00.000000000",
          at: "28.8",
          sm5: "100.18",
          sm15: "76.4",
          sm25: "92.59",
          sm35: "114.92",
          sm45: "99.84",
          sm55: "107.45",
          st5: "23.8",
          st15: "21.1",
          st25: "20.3",
          st35: "20.5",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-18 10:00:00.000000000",
          at: "30.0",
          sm5: "101.21",
          sm15: "76.4",
          sm25: "92.24",
          sm35: "115.6",
          sm45: "99.86",
          sm55: "107.47",
          st5: "23.6",
          st15: "20.7",
          st25: "20.2",
          st35: "20.5",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-18 09:00:00.000000000",
          at: "28.3",
          sm5: "102.28",
          sm15: "76.4",
          sm25: "91.83",
          sm35: "116.2",
          sm45: "99.89",
          sm55: "107.48",
          st5: "22.8",
          st15: "20.3",
          st25: "20.2",
          st35: "20.5",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-18 08:00:00.000000000",
          at: "27.8",
          sm5: "100.0",
          sm15: "76.43",
          sm25: "91.35",
          sm35: "100.0",
          sm45: "99.93",
          sm55: "100.0",
          st5: "22.2",
          st15: "20.0",
          st25: "20.2",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-18 07:00:00.000000000",
          at: "25.1",
          sm5: "100.0",
          sm15: "76.51",
          sm25: "90.91",
          sm35: "100.0",
          sm45: "99.92",
          sm55: "100.0",
          st5: "20.6",
          st15: "19.7",
          st25: "20.2",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-18 06:00:00.000000000",
          at: "22.0",
          sm5: "100.0",
          sm15: "76.5",
          sm25: "90.67",
          sm35: "100.0",
          sm45: "99.98",
          sm55: "100.0",
          st5: "19.5",
          st15: "19.6",
          st25: "20.3",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-18 05:00:00.000000000",
          at: "18.9",
          sm5: "100.0",
          sm15: "76.43",
          sm25: "91.02",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.3",
          st15: "19.6",
          st25: "20.5",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-18 04:00:00.000000000",
          at: "15.6",
          sm5: "100.0",
          sm15: "76.21",
          sm25: "91.71",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "17.5",
          st15: "19.7",
          st25: "20.6",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-18 03:00:00.000000000",
          at: "14.6",
          sm5: "100.0",
          sm15: "77.31",
          sm25: "93.24",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "17.2",
          st15: "19.8",
          st25: "20.7",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-18 02:00:00.000000000",
          at: "13.8",
          sm5: "74.83",
          sm15: "69.47",
          sm25: "88.76",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "17.2",
          st15: "20.2",
          st25: "20.8",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-18 01:00:00.000000000",
          at: "13.9",
          sm5: "75.13",
          sm15: "69.56",
          sm25: "88.94",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "17.6",
          st15: "20.5",
          st25: "20.8",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-18 00:00:00.000000000",
          at: "14.8",
          sm5: "75.58",
          sm15: "69.66",
          sm25: "89.15",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.1",
          st15: "20.7",
          st25: "21.0",
          st35: "20.7",
          st45: "20.0",
          st55: "20.0",
        },
        {
          time: "2023-08-17 23:00:00.000000000",
          at: "15.2",
          sm5: "76.05",
          sm15: "69.78",
          sm25: "89.37",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.6",
          st15: "21.0",
          st25: "21.1",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 22:00:00.000000000",
          at: "16.6",
          sm5: "76.59",
          sm15: "69.91",
          sm25: "89.55",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.2",
          st15: "21.2",
          st25: "21.1",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 21:00:00.000000000",
          at: "17.1",
          sm5: "77.15",
          sm15: "70.05",
          sm25: "89.67",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.7",
          st15: "21.5",
          st25: "21.1",
          st35: "20.7",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-17 20:00:00.000000000",
          at: "18.3",
          sm5: "77.79",
          sm15: "70.17",
          sm25: "89.71",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.2",
          st15: "21.6",
          st25: "21.1",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 19:00:00.000000000",
          at: "19.5",
          sm5: "78.53",
          sm15: "70.22",
          sm25: "89.72",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.8",
          st15: "21.8",
          st25: "21.1",
          st35: "20.6",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-17 18:00:00.000000000",
          at: "21.2",
          sm5: "79.45",
          sm15: "70.28",
          sm25: "89.63",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.5",
          st15: "22.0",
          st25: "21.1",
          st35: "20.7",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-17 17:00:00.000000000",
          at: "24.0",
          sm5: "80.08",
          sm15: "70.33",
          sm25: "89.5",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.0",
          st15: "22.0",
          st25: "21.0",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 16:00:00.000000000",
          at: "26.5",
          sm5: "79.94",
          sm15: "70.4",
          sm25: "89.38",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.3",
          st15: "22.2",
          st25: "21.0",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 15:00:00.000000000",
          at: "26.9",
          sm5: "80.18",
          sm15: "70.44",
          sm25: "89.31",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.1",
          st15: "22.2",
          st25: "20.8",
          st35: "20.6",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-17 14:00:00.000000000",
          at: "26.5",
          sm5: "80.97",
          sm15: "70.56",
          sm25: "89.29",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.0",
          st15: "22.0",
          st25: "20.7",
          st35: "20.5",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-17 13:00:00.000000000",
          at: "27.7",
          sm5: "81.83",
          sm15: "70.95",
          sm25: "89.52",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.1",
          st15: "21.8",
          st25: "20.6",
          st35: "20.6",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-17 12:00:00.000000000",
          at: "28.8",
          sm5: "82.72",
          sm15: "71.51",
          sm25: "89.98",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.3",
          st15: "21.7",
          st25: "20.5",
          st35: "20.5",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 11:00:00.000000000",
          at: "29.5",
          sm5: "83.25",
          sm15: "72.05",
          sm25: "90.51",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.3",
          st15: "21.3",
          st25: "20.5",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 10:00:00.000000000",
          at: "30.4",
          sm5: "83.37",
          sm15: "72.51",
          sm25: "90.81",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.0",
          st15: "21.0",
          st25: "20.3",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 09:00:00.000000000",
          at: "29.1",
          sm5: "83.3",
          sm15: "72.97",
          sm25: "91.37",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.2",
          st15: "20.6",
          st25: "20.3",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 08:00:00.000000000",
          at: "28.3",
          sm5: "83.27",
          sm15: "73.32",
          sm25: "92.03",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.7",
          st15: "20.2",
          st25: "20.3",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 07:00:00.000000000",
          at: "26.3",
          sm5: "82.76",
          sm15: "73.55",
          sm25: "92.58",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.1",
          st15: "20.0",
          st25: "20.5",
          st35: "20.7",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-17 06:00:00.000000000",
          at: "23.5",
          sm5: "82.29",
          sm15: "73.72",
          sm25: "92.99",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.8",
          st15: "19.8",
          st25: "20.5",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 05:00:00.000000000",
          at: "20.1",
          sm5: "81.98",
          sm15: "73.8",
          sm25: "93.28",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.7",
          st15: "19.8",
          st25: "20.5",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 04:00:00.000000000",
          at: "16.0",
          sm5: "81.63",
          sm15: "73.86",
          sm25: "93.54",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "17.7",
          st15: "20.0",
          st25: "20.7",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 03:00:00.000000000",
          at: "14.5",
          sm5: "81.67",
          sm15: "73.91",
          sm25: "93.66",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "17.5",
          st15: "20.2",
          st25: "20.8",
          st35: "20.8",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-17 02:00:00.000000000",
          at: "14.8",
          sm5: "81.83",
          sm15: "74.0",
          sm25: "93.76",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "17.7",
          st15: "20.3",
          st25: "21.0",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 01:00:00.000000000",
          at: "15.2",
          sm5: "82.0",
          sm15: "74.01",
          sm25: "93.9",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.0",
          st15: "20.6",
          st25: "21.0",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-17 00:00:00.000000000",
          at: "15.3",
          sm5: "82.15",
          sm15: "74.07",
          sm25: "94.1",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.2",
          st15: "20.8",
          st25: "21.1",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 23:00:00.000000000",
          at: "15.2",
          sm5: "82.3",
          sm15: "74.16",
          sm25: "94.38",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.3",
          st15: "21.1",
          st25: "21.2",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 22:00:00.000000000",
          at: "15.5",
          sm5: "82.61",
          sm15: "74.26",
          sm25: "94.65",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.8",
          st15: "21.3",
          st25: "21.2",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 21:00:00.000000000",
          at: "16.3",
          sm5: "82.97",
          sm15: "74.37",
          sm25: "94.83",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.5",
          st15: "21.6",
          st25: "21.2",
          st35: "20.8",
          st45: "20.0",
          st55: "20.0",
        },
        {
          time: "2023-08-16 20:00:00.000000000",
          at: "17.0",
          sm5: "83.35",
          sm15: "74.51",
          sm25: "94.91",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.0",
          st15: "21.8",
          st25: "21.2",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 19:00:00.000000000",
          at: "18.0",
          sm5: "83.76",
          sm15: "74.67",
          sm25: "94.93",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.6",
          st15: "22.0",
          st25: "21.3",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 18:00:00.000000000",
          at: "19.7",
          sm5: "84.36",
          sm15: "74.78",
          sm25: "94.9",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.5",
          st15: "22.1",
          st25: "21.2",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 17:00:00.000000000",
          at: "22.0",
          sm5: "85.0",
          sm15: "74.88",
          sm25: "94.81",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.3",
          st15: "22.3",
          st25: "21.1",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 16:00:00.000000000",
          at: "23.8",
          sm5: "85.59",
          sm15: "74.92",
          sm25: "94.68",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.0",
          st15: "22.3",
          st25: "21.0",
          st35: "20.6",
          st45: "19.8",
          st55: "20.1",
        },
        {
          time: "2023-08-16 15:00:00.000000000",
          at: "25.1",
          sm5: "86.28",
          sm15: "74.93",
          sm25: "94.54",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.6",
          st15: "22.2",
          st25: "21.0",
          st35: "20.7",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 14:00:00.000000000",
          at: "26.5",
          sm5: "87.12",
          sm15: "74.99",
          sm25: "94.41",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.1",
          st15: "22.1",
          st25: "20.8",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 13:00:00.000000000",
          at: "27.8",
          sm5: "87.95",
          sm15: "75.14",
          sm25: "94.32",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.2",
          st15: "22.0",
          st25: "20.7",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 12:00:00.000000000",
          at: "28.6",
          sm5: "89.1",
          sm15: "75.42",
          sm25: "94.43",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.5",
          st15: "21.7",
          st25: "20.6",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 11:00:00.000000000",
          at: "29.5",
          sm5: "90.02",
          sm15: "75.66",
          sm25: "94.6",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.5",
          st15: "21.5",
          st25: "20.5",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 10:00:00.000000000",
          at: "30.4",
          sm5: "90.69",
          sm15: "75.83",
          sm25: "94.73",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.1",
          st15: "21.1",
          st25: "20.5",
          st35: "20.6",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-16 09:00:00.000000000",
          at: "28.8",
          sm5: "91.35",
          sm15: "75.97",
          sm25: "94.95",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.3",
          st15: "20.7",
          st25: "20.5",
          st35: "20.6",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-16 08:00:00.000000000",
          at: "28.1",
          sm5: "91.95",
          sm15: "76.04",
          sm25: "95.2",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.7",
          st15: "20.3",
          st25: "20.5",
          st35: "20.7",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-16 07:00:00.000000000",
          at: "26.1",
          sm5: "92.15",
          sm15: "76.12",
          sm25: "95.58",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.1",
          st15: "20.1",
          st25: "20.5",
          st35: "20.8",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-16 06:00:00.000000000",
          at: "22.8",
          sm5: "91.91",
          sm15: "76.2",
          sm25: "95.89",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.7",
          st15: "20.0",
          st25: "20.6",
          st35: "20.8",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-16 05:00:00.000000000",
          at: "19.6",
          sm5: "91.74",
          sm15: "76.23",
          sm25: "96.12",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.7",
          st15: "20.0",
          st25: "20.7",
          st35: "20.8",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-16 04:00:00.000000000",
          at: "15.8",
          sm5: "91.54",
          sm15: "76.29",
          sm25: "96.35",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "17.6",
          st15: "20.1",
          st25: "20.8",
          st35: "20.8",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-16 03:00:00.000000000",
          at: "14.5",
          sm5: "91.61",
          sm15: "76.36",
          sm25: "96.52",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "17.6",
          st15: "20.3",
          st25: "20.8",
          st35: "20.8",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-16 02:00:00.000000000",
          at: "14.7",
          sm5: "91.72",
          sm15: "76.44",
          sm25: "96.68",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "17.8",
          st15: "20.6",
          st25: "21.0",
          st35: "20.8",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-16 01:00:00.000000000",
          at: "15.6",
          sm5: "91.89",
          sm15: "76.52",
          sm25: "96.84",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.2",
          st15: "20.7",
          st25: "21.1",
          st35: "21.0",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-16 00:00:00.000000000",
          at: "16.3",
          sm5: "92.05",
          sm15: "76.61",
          sm25: "96.99",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.6",
          st15: "21.0",
          st25: "21.1",
          st35: "21.0",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-15 23:00:00.000000000",
          at: "16.4",
          sm5: "92.28",
          sm15: "76.7",
          sm25: "97.11",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.1",
          st15: "21.2",
          st25: "21.2",
          st35: "21.0",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-15 22:00:00.000000000",
          at: "17.5",
          sm5: "92.55",
          sm15: "76.76",
          sm25: "97.19",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.6",
          st15: "21.3",
          st25: "21.2",
          st35: "21.0",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-15 21:00:00.000000000",
          at: "18.3",
          sm5: "92.89",
          sm15: "76.83",
          sm25: "97.25",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.0",
          st15: "21.6",
          st25: "21.3",
          st35: "21.0",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-15 20:00:00.000000000",
          at: "19.3",
          sm5: "93.23",
          sm15: "76.86",
          sm25: "97.29",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.5",
          st15: "21.7",
          st25: "21.3",
          st35: "20.8",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-15 19:00:00.000000000",
          at: "22.0",
          sm5: "93.54",
          sm15: "76.89",
          sm25: "97.3",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.0",
          st15: "21.8",
          st25: "21.2",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-15 18:00:00.000000000",
          at: "24.0",
          sm5: "93.86",
          sm15: "76.91",
          sm25: "97.3",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.3",
          st15: "22.0",
          st25: "21.2",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-15 17:00:00.000000000",
          at: "22.3",
          sm5: "94.45",
          sm15: "76.97",
          sm25: "97.3",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.8",
          st15: "22.1",
          st25: "21.2",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-15 16:00:00.000000000",
          at: "24.1",
          sm5: "95.07",
          sm15: "76.97",
          sm25: "97.26",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.3",
          st15: "22.1",
          st25: "21.2",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-15 15:00:00.000000000",
          at: "26.4",
          sm5: "95.7",
          sm15: "76.96",
          sm25: "97.23",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.8",
          st15: "22.1",
          st25: "21.1",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-15 14:00:00.000000000",
          at: "27.4",
          sm5: "96.35",
          sm15: "76.95",
          sm25: "97.28",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.3",
          st15: "22.0",
          st25: "21.0",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-15 13:00:00.000000000",
          at: "28.1",
          sm5: "96.9",
          sm15: "76.97",
          sm25: "97.39",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.5",
          st15: "21.8",
          st25: "21.0",
          st35: "20.8",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-15 12:00:00.000000000",
          at: "28.9",
          sm5: "97.54",
          sm15: "77.01",
          sm25: "97.56",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.3",
          st15: "21.7",
          st25: "21.0",
          st35: "20.8",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-15 11:00:00.000000000",
          at: "29.4",
          sm5: "98.24",
          sm15: "77.04",
          sm25: "97.78",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.2",
          st15: "21.6",
          st25: "21.0",
          st35: "20.8",
          st45: "20.0",
          st55: "20.1",
        },
        {
          time: "2023-08-15 10:00:00.000000000",
          at: "30.8",
          sm5: "99.18",
          sm15: "77.09",
          sm25: "98.06",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.1",
          st15: "21.3",
          st25: "20.8",
          st35: "20.8",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-15 08:00:00.000000000",
          at: "28.3",
          sm5: "100.0",
          sm15: "77.18",
          sm25: "98.91",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.3",
          st15: "20.8",
          st25: "21.0",
          st35: "20.8",
          st45: "20.1",
          st55: "20.0",
        },
        {
          time: "2023-08-15 07:00:00.000000000",
          at: "26.5",
          sm5: "100.0",
          sm15: "77.39",
          sm25: "99.49",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.0",
          st15: "20.7",
          st25: "21.0",
          st35: "21.0",
          st45: "20.1",
          st55: "20.0",
        },
        {
          time: "2023-08-15 06:00:00.000000000",
          at: "23.6",
          sm5: "100.0",
          sm15: "77.46",
          sm25: "100.0",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.2",
          st15: "20.6",
          st25: "21.0",
          st35: "21.0",
          st45: "20.1",
          st55: "20.1",
        },
        {
          time: "2023-08-15 05:00:00.000000000",
          at: "19.8",
          sm5: "100.0",
          sm15: "77.55",
          sm25: "100.0",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.5",
          st15: "20.7",
          st25: "21.1",
          st35: "21.1",
          st45: "20.1",
          st55: "20.0",
        },
        {
          time: "2023-08-15 04:00:00.000000000",
          at: "15.7",
          sm5: "100.0",
          sm15: "77.71",
          sm25: "100.0",
          sm35: "100.0",
          sm45: "99.91",
          sm55: "100.0",
          st5: "18.6",
          st15: "20.8",
          st25: "21.2",
          st35: "21.0",
          st45: "20.1",
          st55: "20.0",
        },
        {
          time: "2023-08-15 03:00:00.000000000",
          at: "14.3",
          sm5: "100.0",
          sm15: "78.39",
          sm25: "100.0",
          sm35: "100.0",
          sm45: "99.47",
          sm55: "100.0",
          st5: "18.7",
          st15: "21.1",
          st25: "21.2",
          st35: "21.0",
          st45: "20.0",
          st55: "20.0",
        },
        {
          time: "2023-08-15 02:00:00.000000000",
          at: "17.0",
          sm5: "100.0",
          sm15: "93.94",
          sm25: "100.0",
          sm35: "100.0",
          sm45: "99.43",
          sm55: "100.0",
          st5: "18.7",
          st15: "20.7",
          st25: "21.2",
          st35: "21.0",
          st45: "20.0",
          st55: "20.0",
        },
        {
          time: "2023-08-15 01:00:00.000000000",
          at: "16.5",
          sm5: "62.33",
          sm15: "57.08",
          sm25: "69.36",
          sm35: "100.0",
          sm45: "99.2",
          sm55: "100.0",
          st5: "19.6",
          st15: "21.7",
          st25: "21.5",
          st35: "21.0",
          st45: "20.0",
          st55: "20.0",
        },
        {
          time: "2023-08-15 00:00:00.000000000",
          at: "17.5",
          sm5: "62.4",
          sm15: "57.25",
          sm25: "69.46",
          sm35: "100.0",
          sm45: "99.18",
          sm55: "100.0",
          st5: "20.2",
          st15: "22.0",
          st25: "21.5",
          st35: "21.0",
          st45: "20.0",
          st55: "20.0",
        },
        {
          time: "2023-08-14 23:00:00.000000000",
          at: "18.7",
          sm5: "62.51",
          sm15: "57.51",
          sm25: "69.51",
          sm35: "100.0",
          sm45: "99.17",
          sm55: "100.0",
          st5: "20.8",
          st15: "22.2",
          st25: "21.5",
          st35: "21.0",
          st45: "20.0",
          st55: "20.0",
        },
        {
          time: "2023-08-14 22:00:00.000000000",
          at: "20.6",
          sm5: "62.7",
          sm15: "57.84",
          sm25: "69.52",
          sm35: "100.0",
          sm45: "99.16",
          sm55: "100.0",
          st5: "21.7",
          st15: "22.3",
          st25: "21.6",
          st35: "21.0",
          st45: "20.0",
          st55: "19.8",
        },
        {
          time: "2023-08-14 21:00:00.000000000",
          at: "23.9",
          sm5: "62.87",
          sm15: "57.95",
          sm25: "69.52",
          sm35: "100.0",
          sm45: "99.14",
          sm55: "100.0",
          st5: "22.6",
          st15: "22.5",
          st25: "21.6",
          st35: "20.8",
          st45: "20.0",
          st55: "19.8",
        },
        {
          time: "2023-08-14 20:00:00.000000000",
          at: "23.7",
          sm5: "62.87",
          sm15: "57.93",
          sm25: "69.49",
          sm35: "100.0",
          sm45: "99.13",
          sm55: "100.0",
          st5: "22.7",
          st15: "22.6",
          st25: "21.5",
          st35: "20.8",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 19:00:00.000000000",
          at: "22.8",
          sm5: "62.97",
          sm15: "57.94",
          sm25: "69.42",
          sm35: "100.0",
          sm45: "99.13",
          sm55: "100.0",
          st5: "22.8",
          st15: "22.7",
          st25: "21.5",
          st35: "20.8",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 18:00:00.000000000",
          at: "24.3",
          sm5: "63.25",
          sm15: "57.95",
          sm25: "69.32",
          sm35: "100.0",
          sm45: "99.12",
          sm55: "100.0",
          st5: "23.3",
          st15: "22.7",
          st25: "21.5",
          st35: "20.7",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 17:00:00.000000000",
          at: "25.0",
          sm5: "63.49",
          sm15: "57.95",
          sm25: "69.2",
          sm35: "100.0",
          sm45: "99.1",
          sm55: "100.0",
          st5: "23.7",
          st15: "22.8",
          st25: "21.5",
          st35: "20.7",
          st45: "19.7",
          st55: "20.0",
        },
        {
          time: "2023-08-14 16:00:00.000000000",
          at: "27.1",
          sm5: "63.99",
          sm15: "57.95",
          sm25: "69.06",
          sm35: "100.0",
          sm45: "99.09",
          sm55: "100.0",
          st5: "24.3",
          st15: "23.0",
          st25: "21.3",
          st35: "20.7",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 15:00:00.000000000",
          at: "29.0",
          sm5: "64.54",
          sm15: "57.96",
          sm25: "69.0",
          sm35: "100.0",
          sm45: "99.12",
          sm55: "100.0",
          st5: "25.0",
          st15: "22.8",
          st25: "21.2",
          st35: "20.6",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 14:00:00.000000000",
          at: "30.3",
          sm5: "64.84",
          sm15: "58.04",
          sm25: "69.15",
          sm35: "100.0",
          sm45: "99.18",
          sm55: "100.0",
          st5: "25.5",
          st15: "22.7",
          st25: "21.1",
          st35: "20.6",
          st45: "19.7",
          st55: "19.2",
        },
        {
          time: "2023-08-14 13:00:00.000000000",
          at: "31.6",
          sm5: "64.99",
          sm15: "58.22",
          sm25: "69.53",
          sm35: "100.0",
          sm45: "99.24",
          sm55: "100.0",
          st5: "25.8",
          st15: "22.6",
          st25: "21.0",
          st35: "20.6",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 12:00:00.000000000",
          at: "32.1",
          sm5: "65.1",
          sm15: "58.51",
          sm25: "70.04",
          sm35: "100.0",
          sm45: "99.32",
          sm55: "100.0",
          st5: "25.8",
          st15: "22.3",
          st25: "21.0",
          st35: "20.6",
          st45: "19.8",
          st55: "19.2",
        },
        {
          time: "2023-08-14 11:00:00.000000000",
          at: "32.3",
          sm5: "65.28",
          sm15: "58.85",
          sm25: "70.64",
          sm35: "100.0",
          sm45: "99.43",
          sm55: "100.0",
          st5: "25.6",
          st15: "22.1",
          st25: "20.8",
          st35: "20.6",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 10:00:00.000000000",
          at: "31.1",
          sm5: "65.53",
          sm15: "59.26",
          sm25: "71.37",
          sm35: "100.0",
          sm45: "99.53",
          sm55: "100.0",
          st5: "25.0",
          st15: "21.8",
          st25: "20.7",
          st35: "20.6",
          st45: "19.8",
          st55: "20.0",
        },
        {
          time: "2023-08-14 09:00:00.000000000",
          at: "31.5",
          sm5: "65.76",
          sm15: "59.64",
          sm25: "72.17",
          sm35: "100.0",
          sm45: "99.64",
          sm55: "100.0",
          st5: "25.0",
          st15: "21.6",
          st25: "20.7",
          st35: "20.6",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 08:00:00.000000000",
          at: "31.0",
          sm5: "66.06",
          sm15: "60.05",
          sm25: "73.17",
          sm35: "100.0",
          sm45: "99.76",
          sm55: "100.0",
          st5: "24.6",
          st15: "21.2",
          st25: "20.7",
          st35: "20.6",
          st45: "19.8",
          st55: "19.2",
        },
        {
          time: "2023-08-14 07:00:00.000000000",
          at: "28.6",
          sm5: "66.33",
          sm15: "60.36",
          sm25: "74.21",
          sm35: "100.0",
          sm45: "99.86",
          sm55: "100.0",
          st5: "22.7",
          st15: "21.1",
          st25: "20.7",
          st35: "20.7",
          st45: "19.8",
          st55: "19.2",
        },
        {
          time: "2023-08-14 06:00:00.000000000",
          at: "25.1",
          sm5: "66.27",
          sm15: "60.67",
          sm25: "75.1",
          sm35: "100.0",
          sm45: "99.95",
          sm55: "100.0",
          st5: "21.6",
          st15: "21.0",
          st25: "20.7",
          st35: "20.6",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 05:00:00.000000000",
          at: "25.3",
          sm5: "66.09",
          sm15: "60.81",
          sm25: "75.65",
          sm35: "100.0",
          sm45: "99.98",
          sm55: "100.0",
          st5: "21.2",
          st15: "20.8",
          st25: "20.8",
          st35: "20.7",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 04:00:00.000000000",
          at: "20.7",
          sm5: "66.06",
          sm15: "61.02",
          sm25: "76.33",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.3",
          st15: "20.8",
          st25: "20.8",
          st35: "20.7",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 03:00:00.000000000",
          at: "18.1",
          sm5: "66.16",
          sm15: "61.14",
          sm25: "76.57",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.7",
          st15: "21.0",
          st25: "20.8",
          st35: "20.6",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 02:00:00.000000000",
          at: "19.3",
          sm5: "66.21",
          sm15: "61.18",
          sm25: "76.78",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.1",
          st15: "21.1",
          st25: "21.0",
          st35: "20.7",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 01:00:00.000000000",
          at: "19.0",
          sm5: "66.25",
          sm15: "61.24",
          sm25: "76.92",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.1",
          st15: "21.2",
          st25: "21.0",
          st35: "20.7",
          st45: "19.8",
          st55: "19.8",
        },
        {
          time: "2023-08-14 00:00:00.000000000",
          at: "19.0",
          sm5: "66.34",
          sm15: "61.32",
          sm25: "77.04",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.2",
          st15: "21.3",
          st25: "21.1",
          st35: "20.7",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 23:00:00.000000000",
          at: "19.8",
          sm5: "66.43",
          sm15: "61.4",
          sm25: "77.16",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.5",
          st15: "21.5",
          st25: "21.1",
          st35: "20.7",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 22:00:00.000000000",
          at: "20.3",
          sm5: "66.49",
          sm15: "61.53",
          sm25: "77.25",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.7",
          st15: "21.6",
          st25: "21.1",
          st35: "20.7",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 21:00:00.000000000",
          at: "19.7",
          sm5: "66.53",
          sm15: "61.67",
          sm25: "77.32",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.6",
          st15: "21.8",
          st25: "21.1",
          st35: "20.7",
          st45: "19.8",
          st55: "19.7",
        },
        {
          time: "2023-08-13 20:00:00.000000000",
          at: "19.5",
          sm5: "66.56",
          sm15: "61.81",
          sm25: "77.3",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.8",
          st15: "22.0",
          st25: "21.2",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-13 19:00:00.000000000",
          at: "20.1",
          sm5: "66.59",
          sm15: "62.03",
          sm25: "77.26",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.3",
          st15: "22.1",
          st25: "21.2",
          st35: "20.6",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 18:00:00.000000000",
          at: "20.1",
          sm5: "66.68",
          sm15: "62.03",
          sm25: "77.16",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.8",
          st15: "22.2",
          st25: "21.1",
          st35: "20.5",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-13 17:00:00.000000000",
          at: "22.0",
          sm5: "66.93",
          sm15: "62.04",
          sm25: "77.01",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.7",
          st15: "22.5",
          st25: "21.1",
          st35: "20.5",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-13 16:00:00.000000000",
          at: "24.5",
          sm5: "67.52",
          sm15: "62.03",
          sm25: "76.8",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.5",
          st15: "22.5",
          st25: "21.0",
          st35: "20.5",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-13 15:00:00.000000000",
          at: "26.6",
          sm5: "67.82",
          sm15: "62.18",
          sm25: "76.6",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.3",
          st15: "22.3",
          st25: "20.8",
          st35: "20.5",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 14:00:00.000000000",
          at: "28.4",
          sm5: "68.17",
          sm15: "62.28",
          sm25: "76.63",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.8",
          st15: "22.2",
          st25: "20.7",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 13:00:00.000000000",
          at: "29.8",
          sm5: "68.33",
          sm15: "62.52",
          sm25: "76.93",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "25.1",
          st15: "22.1",
          st25: "20.6",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 12:00:00.000000000",
          at: "30.7",
          sm5: "68.57",
          sm15: "62.87",
          sm25: "77.44",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "25.1",
          st15: "21.8",
          st25: "20.5",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 11:00:00.000000000",
          at: "31.1",
          sm5: "68.82",
          sm15: "63.28",
          sm25: "78.09",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "25.1",
          st15: "21.5",
          st25: "20.3",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 10:00:00.000000000",
          at: "32.3",
          sm5: "68.98",
          sm15: "63.72",
          sm25: "78.85",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.6",
          st15: "21.2",
          st25: "20.3",
          st35: "20.3",
          st45: "19.6",
          st55: "19.2",
        },
        {
          time: "2023-08-13 09:00:00.000000000",
          at: "29.5",
          sm5: "69.4",
          sm15: "64.21",
          sm25: "79.66",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.7",
          st15: "20.7",
          st25: "20.3",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 08:00:00.000000000",
          at: "28.7",
          sm5: "69.7",
          sm15: "64.56",
          sm25: "80.55",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.2",
          st15: "20.5",
          st25: "20.3",
          st35: "20.5",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-13 07:00:00.000000000",
          at: "26.5",
          sm5: "70.0",
          sm15: "64.85",
          sm25: "81.39",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.5",
          st15: "20.2",
          st25: "20.3",
          st35: "20.5",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 06:00:00.000000000",
          at: "23.4",
          sm5: "69.95",
          sm15: "65.18",
          sm25: "82.16",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.3",
          st15: "20.1",
          st25: "20.5",
          st35: "20.5",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 05:00:00.000000000",
          at: "21.1",
          sm5: "70.06",
          sm15: "65.47",
          sm25: "82.76",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.6",
          st15: "20.1",
          st25: "20.5",
          st35: "20.5",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-13 04:00:00.000000000",
          at: "16.6",
          sm5: "70.18",
          sm15: "65.61",
          sm25: "83.25",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.3",
          st15: "20.2",
          st25: "20.6",
          st35: "20.6",
          st45: "19.8",
          st55: "19.7",
        },
        {
          time: "2023-08-13 03:00:00.000000000",
          at: "14.7",
          sm5: "70.26",
          sm15: "65.71",
          sm25: "83.5",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.1",
          st15: "20.5",
          st25: "20.7",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-13 02:00:00.000000000",
          at: "15.1",
          sm5: "70.22",
          sm15: "65.81",
          sm25: "83.7",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.3",
          st15: "20.6",
          st25: "20.7",
          st35: "20.6",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-13 01:00:00.000000000",
          at: "15.3",
          sm5: "70.19",
          sm15: "65.93",
          sm25: "83.87",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.7",
          st15: "20.8",
          st25: "20.8",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-13 00:00:00.000000000",
          at: "15.7",
          sm5: "70.17",
          sm15: "66.04",
          sm25: "83.99",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.1",
          st15: "21.0",
          st25: "20.8",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-12 23:00:00.000000000",
          at: "16.5",
          sm5: "70.17",
          sm15: "66.17",
          sm25: "84.06",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.6",
          st15: "21.2",
          st25: "21.0",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-12 22:00:00.000000000",
          at: "17.6",
          sm5: "70.22",
          sm15: "66.3",
          sm25: "84.11",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.2",
          st15: "21.3",
          st25: "21.0",
          st35: "20.6",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-12 21:00:00.000000000",
          at: "19.0",
          sm5: "70.33",
          sm15: "66.48",
          sm25: "84.1",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.6",
          st15: "21.6",
          st25: "21.0",
          st35: "20.5",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-12 20:00:00.000000000",
          at: "20.0",
          sm5: "70.45",
          sm15: "66.55",
          sm25: "84.08",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.1",
          st15: "21.7",
          st25: "20.8",
          st35: "20.5",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-12 19:00:00.000000000",
          at: "21.5",
          sm5: "70.59",
          sm15: "66.58",
          sm25: "84.02",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.6",
          st15: "21.8",
          st25: "21.0",
          st35: "20.5",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-12 18:00:00.000000000",
          at: "21.9",
          sm5: "70.85",
          sm15: "66.63",
          sm25: "83.92",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.0",
          st15: "22.0",
          st25: "20.8",
          st35: "20.5",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-12 17:00:00.000000000",
          at: "23.2",
          sm5: "71.22",
          sm15: "66.66",
          sm25: "83.79",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.5",
          st15: "22.0",
          st25: "20.8",
          st35: "20.3",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-12 16:00:00.000000000",
          at: "24.6",
          sm5: "71.8",
          sm15: "66.7",
          sm25: "83.65",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.1",
          st15: "22.0",
          st25: "20.7",
          st35: "20.3",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-12 15:00:00.000000000",
          at: "26.5",
          sm5: "72.26",
          sm15: "66.76",
          sm25: "83.54",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.6",
          st15: "22.0",
          st25: "20.6",
          st35: "20.3",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-12 14:00:00.000000000",
          at: "27.5",
          sm5: "72.56",
          sm15: "66.93",
          sm25: "83.6",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.0",
          st15: "21.8",
          st25: "20.5",
          st35: "20.3",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-12 13:00:00.000000000",
          at: "28.1",
          sm5: "72.86",
          sm15: "67.24",
          sm25: "83.83",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.1",
          st15: "21.7",
          st25: "20.5",
          st35: "20.3",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-12 12:00:00.000000000",
          at: "28.3",
          sm5: "73.22",
          sm15: "67.58",
          sm25: "84.09",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.1",
          st15: "21.5",
          st25: "20.3",
          st35: "20.3",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-12 11:00:00.000000000",
          at: "29.4",
          sm5: "73.58",
          sm15: "67.98",
          sm25: "84.8",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.2",
          st15: "21.1",
          st25: "20.3",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-12 10:00:00.000000000",
          at: "30.1",
          sm5: "74.0",
          sm15: "68.46",
          sm25: "85.49",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.8",
          st15: "20.8",
          st25: "20.2",
          st35: "20.3",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-12 09:00:00.000000000",
          at: "28.3",
          sm5: "74.61",
          sm15: "68.93",
          sm25: "85.47",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.1",
          st15: "20.5",
          st25: "20.2",
          st35: "20.3",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-12 08:00:00.000000000",
          at: "27.0",
          sm5: "75.21",
          sm15: "69.31",
          sm25: "85.9",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.6",
          st15: "20.2",
          st25: "20.2",
          st35: "20.3",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-12 07:00:00.000000000",
          at: "25.0",
          sm5: "75.7",
          sm15: "69.62",
          sm25: "86.31",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.0",
          st15: "20.0",
          st25: "20.2",
          st35: "20.5",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-12 06:00:00.000000000",
          at: "22.6",
          sm5: "75.94",
          sm15: "69.87",
          sm25: "86.73",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.8",
          st15: "19.8",
          st25: "20.3",
          st35: "20.5",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-12 05:00:00.000000000",
          at: "20.1",
          sm5: "76.23",
          sm15: "70.08",
          sm25: "87.05",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.1",
          st15: "19.8",
          st25: "20.3",
          st35: "20.5",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-12 04:00:00.000000000",
          at: "15.9",
          sm5: "76.47",
          sm15: "70.21",
          sm25: "87.35",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.0",
          st15: "20.0",
          st25: "20.5",
          st35: "20.5",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-12 03:00:00.000000000",
          at: "14.7",
          sm5: "76.72",
          sm15: "70.3",
          sm25: "87.51",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.0",
          st15: "20.1",
          st25: "20.5",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-12 02:00:00.000000000",
          at: "16.0",
          sm5: "76.85",
          sm15: "70.37",
          sm25: "87.64",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.3",
          st15: "20.3",
          st25: "20.6",
          st35: "20.6",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-12 01:00:00.000000000",
          at: "16.4",
          sm5: "76.93",
          sm15: "70.43",
          sm25: "87.8",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.5",
          st15: "20.5",
          st25: "20.7",
          st35: "20.6",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-12 00:00:00.000000000",
          at: "16.5",
          sm5: "77.05",
          sm15: "70.52",
          sm25: "87.9",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "18.7",
          st15: "20.6",
          st25: "20.7",
          st35: "20.6",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-11 23:00:00.000000000",
          at: "17.1",
          sm5: "77.14",
          sm15: "70.61",
          sm25: "88.0",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.1",
          st15: "20.8",
          st25: "20.8",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 22:00:00.000000000",
          at: "17.4",
          sm5: "77.35",
          sm15: "70.69",
          sm25: "88.1",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.3",
          st15: "21.0",
          st25: "20.8",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 21:00:00.000000000",
          at: "18.0",
          sm5: "77.51",
          sm15: "70.8",
          sm25: "88.2",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.7",
          st15: "21.2",
          st25: "21.0",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 20:00:00.000000000",
          at: "18.8",
          sm5: "77.7",
          sm15: "70.9",
          sm25: "88.23",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.1",
          st15: "21.3",
          st25: "21.0",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 19:00:00.000000000",
          at: "19.6",
          sm5: "77.99",
          sm15: "70.97",
          sm25: "88.25",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.6",
          st15: "21.6",
          st25: "21.0",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 18:00:00.000000000",
          at: "20.6",
          sm5: "78.54",
          sm15: "71.05",
          sm25: "88.24",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.0",
          st15: "21.7",
          st25: "21.0",
          st35: "20.5",
          st45: "19.7",
          st55: "19.8",
        },
        {
          time: "2023-08-11 17:00:00.000000000",
          at: "21.9",
          sm5: "79.19",
          sm15: "71.13",
          sm25: "88.16",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.7",
          st15: "21.8",
          st25: "21.0",
          st35: "20.5",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 16:00:00.000000000",
          at: "24.1",
          sm5: "79.54",
          sm15: "71.16",
          sm25: "88.07",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.3",
          st15: "22.0",
          st25: "20.8",
          st35: "20.5",
          st45: "19.6",
          st55: "19.8",
        },
        {
          time: "2023-08-11 15:00:00.000000000",
          at: "25.3",
          sm5: "79.61",
          sm15: "71.19",
          sm25: "87.99",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.7",
          st15: "22.0",
          st25: "20.7",
          st35: "20.5",
          st45: "19.6",
          st55: "19.2",
        },
        {
          time: "2023-08-11 14:00:00.000000000",
          at: "26.0",
          sm5: "79.66",
          sm15: "71.34",
          sm25: "87.97",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.2",
          st15: "21.8",
          st25: "20.7",
          st35: "20.5",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 13:00:00.000000000",
          at: "27.1",
          sm5: "79.76",
          sm15: "71.59",
          sm25: "88.0",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.6",
          st15: "21.8",
          st25: "20.7",
          st35: "20.5",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-11 12:00:00.000000000",
          at: "28.3",
          sm5: "100.0",
          sm15: "71.94",
          sm25: "88.09",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.8",
          st15: "21.7",
          st25: "20.6",
          st35: "20.5",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-11 11:00:00.000000000",
          at: "29.0",
          sm5: "100.0",
          sm15: "72.41",
          sm25: "88.27",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.0",
          st15: "21.3",
          st25: "20.5",
          st35: "20.3",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 10:00:00.000000000",
          at: "29.6",
          sm5: "100.0",
          sm15: "72.99",
          sm25: "88.51",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.6",
          st15: "21.2",
          st25: "20.5",
          st35: "20.5",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 09:00:00.000000000",
          at: "27.7",
          sm5: "100.0",
          sm15: "73.59",
          sm25: "88.81",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.8",
          st15: "21.0",
          st25: "20.5",
          st35: "20.5",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 08:00:00.000000000",
          at: "26.8",
          sm5: "100.0",
          sm15: "74.01",
          sm25: "89.04",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.5",
          st15: "20.6",
          st25: "20.5",
          st35: "20.5",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 07:00:00.000000000",
          at: "24.3",
          sm5: "100.0",
          sm15: "74.38",
          sm25: "89.28",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.1",
          st15: "20.5",
          st25: "20.5",
          st35: "20.6",
          st45: "19.6",
          st55: "19.2",
        },
        {
          time: "2023-08-11 06:00:00.000000000",
          at: "22.4",
          sm5: "100.0",
          sm15: "74.63",
          sm25: "89.46",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.2",
          st15: "20.6",
          st25: "20.6",
          st35: "20.5",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 05:00:00.000000000",
          at: "21.0",
          sm5: "100.0",
          sm15: "74.79",
          sm25: "89.63",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.8",
          st15: "20.5",
          st25: "20.7",
          st35: "20.6",
          st45: "19.7",
          st55: "19.6",
        },
        {
          time: "2023-08-11 04:00:00.000000000",
          at: "18.7",
          sm5: "100.0",
          sm15: "74.89",
          sm25: "89.78",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.3",
          st15: "20.6",
          st25: "20.7",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-11 03:00:00.000000000",
          at: "18.1",
          sm5: "100.0",
          sm15: "74.95",
          sm25: "89.89",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.3",
          st15: "20.7",
          st25: "20.7",
          st35: "20.5",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-11 02:00:00.000000000",
          at: "18.4",
          sm5: "100.0",
          sm15: "75.02",
          sm25: "89.97",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.6",
          st15: "20.8",
          st25: "20.8",
          st35: "20.5",
          st45: "19.7",
          st55: "19.6",
        },
        {
          time: "2023-08-11 01:00:00.000000000",
          at: "18.5",
          sm5: "100.0",
          sm15: "75.08",
          sm25: "90.07",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.7",
          st15: "21.0",
          st25: "20.8",
          st35: "20.6",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-11 00:00:00.000000000",
          at: "19.0",
          sm5: "100.0",
          sm15: "75.13",
          sm25: "90.14",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.0",
          st15: "21.2",
          st25: "21.0",
          st35: "20.6",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-10 23:00:00.000000000",
          at: "19.8",
          sm5: "100.0",
          sm15: "75.2",
          sm25: "90.19",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.2",
          st15: "21.3",
          st25: "21.0",
          st35: "20.5",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 22:00:00.000000000",
          at: "20.2",
          sm5: "100.0",
          sm15: "75.27",
          sm25: "90.22",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.5",
          st15: "21.5",
          st25: "21.0",
          st35: "20.5",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 21:00:00.000000000",
          at: "20.5",
          sm5: "100.0",
          sm15: "75.33",
          sm25: "90.23",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.8",
          st15: "21.6",
          st25: "21.0",
          st35: "20.5",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 20:00:00.000000000",
          at: "20.9",
          sm5: "100.0",
          sm15: "75.4",
          sm25: "90.21",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.0",
          st15: "21.8",
          st25: "21.0",
          st35: "20.3",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 19:00:00.000000000",
          at: "19.5",
          sm5: "100.0",
          sm15: "75.49",
          sm25: "90.16",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "21.2",
          st15: "22.0",
          st25: "21.0",
          st35: "20.3",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 18:00:00.000000000",
          at: "20.9",
          sm5: "100.0",
          sm15: "75.46",
          sm25: "90.06",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.0",
          st15: "22.0",
          st25: "20.8",
          st35: "20.3",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 17:00:00.000000000",
          at: "23.1",
          sm5: "100.0",
          sm15: "75.32",
          sm25: "89.96",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.7",
          st15: "22.1",
          st25: "20.7",
          st35: "20.3",
          st45: "19.6",
          st55: "19.6",
        },
        {
          time: "2023-08-10 16:00:00.000000000",
          at: "25.6",
          sm5: "100.0",
          sm15: "75.23",
          sm25: "89.8",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.2",
          st15: "22.0",
          st25: "20.7",
          st35: "20.3",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 15:00:00.000000000",
          at: "26.6",
          sm5: "100.0",
          sm15: "75.27",
          sm25: "89.67",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.6",
          st15: "22.0",
          st25: "20.6",
          st35: "20.2",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 14:00:00.000000000",
          at: "26.6",
          sm5: "100.0",
          sm15: "75.46",
          sm25: "89.56",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.8",
          st15: "21.8",
          st25: "20.5",
          st35: "20.2",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 13:00:00.000000000",
          at: "25.7",
          sm5: "100.0",
          sm15: "75.7",
          sm25: "89.41",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.6",
          st15: "21.7",
          st25: "20.3",
          st35: "20.2",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-10 12:00:00.000000000",
          at: "28.3",
          sm5: "100.0",
          sm15: "75.62",
          sm25: "89.23",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.1",
          st15: "21.5",
          st25: "20.3",
          st35: "20.2",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 11:00:00.000000000",
          at: "30.1",
          sm5: "100.0",
          sm15: "75.85",
          sm25: "89.13",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "24.1",
          st15: "21.1",
          st25: "20.2",
          st35: "20.3",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 10:00:00.000000000",
          at: "29.7",
          sm5: "100.0",
          sm15: "76.29",
          sm25: "89.08",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "23.5",
          st15: "20.7",
          st25: "20.2",
          st35: "20.3",
          st45: "19.5",
          st55: "19.7",
        },
        {
          time: "2023-08-10 09:00:00.000000000",
          at: "28.0",
          sm5: "100.0",
          sm15: "76.53",
          sm25: "89.08",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.7",
          st15: "20.3",
          st25: "20.2",
          st35: "20.3",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 08:00:00.000000000",
          at: "26.9",
          sm5: "100.0",
          sm15: "76.67",
          sm25: "89.16",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "22.3",
          st15: "20.1",
          st25: "20.2",
          st35: "20.3",
          st45: "19.6",
          st55: "19.7",
        },
        {
          time: "2023-08-10 07:00:00.000000000",
          at: "24.5",
          sm5: "100.0",
          sm15: "76.68",
          sm25: "89.26",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "20.7",
          st15: "20.0",
          st25: "20.2",
          st35: "20.3",
          st45: "19.7",
          st55: "19.7",
        },
        {
          time: "2023-08-10 06:00:00.000000000",
          at: "21.9",
          sm5: "100.0",
          sm15: "76.83",
          sm25: "89.48",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "19.43",
          st15: "19.8",
          st25: "20.3",
          st35: "20.43",
          st45: "19.67",
          st55: "19.7",
        },
        {
          time: "2023-08-10 05:00:00.000000000",
          at: "21.5",
          sm5: "98.93",
          sm15: "72.36",
          sm25: "87.54",
          sm35: "100.0",
          sm45: "98.02",
          sm55: "98.93",
          st5: "19.29",
          st15: "19.72",
          st25: "20.37",
          st35: "20.33",
          st45: "19.74",
          st55: "19.69",
        },
        {
          time: "2023-08-10 04:00:00.000000000",
          at: "15.7",
          sm5: "86.92",
          sm15: "32.67",
          sm25: "67.49",
          sm35: "100.0",
          sm45: "78.38",
          sm55: "86.92",
          st5: "20.1",
          st15: "17.8",
          st25: "20.5",
          st35: "19.6",
          st45: "20.5",
          st55: "19.7",
        },
        {
          time: "2023-08-10 03:00:00.000000000",
          at: "14.4",
          sm5: "86.03",
          sm15: "31.38",
          sm25: "66.16",
          sm35: "100.0",
          sm45: "77.74",
          sm55: "86.03",
          st5: "20.2",
          st15: "17.7",
          st25: "20.6",
          st35: "19.6",
          st45: "20.5",
          st55: "19.6",
        },
        {
          time: "2023-08-10 02:00:00.000000000",
          at: "14.7",
          sm5: "86.16",
          sm15: "31.67",
          sm25: "66.4",
          sm35: "100.0",
          sm45: "77.9",
          sm55: "86.16",
          st5: "20.3",
          st15: "18.1",
          st25: "20.7",
          st35: "19.6",
          st45: "20.5",
          st55: "19.6",
        },
        {
          time: "2023-08-10 01:00:00.000000000",
          at: "15.3",
          sm5: "86.91",
          sm15: "32.84",
          sm25: "67.3",
          sm35: "100.0",
          sm45: "78.42",
          sm55: "86.91",
          st5: "20.7",
          st15: "18.5",
          st25: "20.7",
          st35: "19.6",
          st45: "20.5",
          st55: "19.6",
        },
        {
          time: "2023-08-10 00:00:00.000000000",
          at: "15.8",
          sm5: "87.17",
          sm15: "33.85",
          sm25: "68.12",
          sm35: "100.0",
          sm45: "78.82",
          sm55: "87.17",
          st5: "20.8",
          st15: "18.7",
          st25: "20.8",
          st35: "19.6",
          st45: "20.5",
          st55: "19.6",
        },
        {
          time: "2023-08-09 23:00:00.000000000",
          at: "16.0",
          sm5: "87.0",
          sm15: "33.9",
          sm25: "67.97",
          sm35: "100.0",
          sm45: "78.72",
          sm55: "87.0",
          st5: "21.1",
          st15: "19.1",
          st25: "20.8",
          st35: "19.5",
          st45: "20.5",
          st55: "19.6",
        },
        {
          time: "2023-08-09 22:00:00.000000000",
          at: "16.6",
          sm5: "87.71",
          sm15: "34.88",
          sm25: "68.69",
          sm35: "100.0",
          sm45: "79.19",
          sm55: "87.71",
          st5: "21.2",
          st15: "19.6",
          st25: "20.8",
          st35: "19.6",
          st45: "20.5",
          st55: "19.6",
        },
        {
          time: "2023-08-09 21:00:00.000000000",
          at: "17.5",
          sm5: "88.58",
          sm15: "36.59",
          sm25: "69.96",
          sm35: "100.0",
          sm45: "79.78",
          sm55: "88.58",
          st5: "21.5",
          st15: "20.0",
          st25: "21.0",
          st35: "19.5",
          st45: "20.5",
          st55: "19.6",
        },
        {
          time: "2023-08-09 20:00:00.000000000",
          at: "17.9",
          sm5: "89.01",
          sm15: "37.69",
          sm25: "70.65",
          sm35: "100.0",
          sm45: "80.45",
          sm55: "89.01",
          st5: "21.7",
          st15: "20.5",
          st25: "21.0",
          st35: "19.5",
          st45: "20.3",
          st55: "19.6",
        },
        {
          time: "2023-08-09 19:00:00.000000000",
          at: "19.0",
          sm5: "90.1",
          sm15: "39.03",
          sm25: "71.46",
          sm35: "100.0",
          sm45: "81.3",
          sm55: "90.1",
          st5: "21.8",
          st15: "21.1",
          st25: "21.0",
          st35: "19.6",
          st45: "20.3",
          st55: "19.5",
        },
        {
          time: "2023-08-09 18:00:00.000000000",
          at: "20.3",
          sm5: "91.63",
          sm15: "40.55",
          sm25: "72.44",
          sm35: "100.0",
          sm45: "82.88",
          sm55: "91.63",
          st5: "22.0",
          st15: "21.7",
          st25: "20.8",
          st35: "19.5",
          st45: "20.3",
          st55: "19.6",
        },
        {
          time: "2023-08-09 17:00:00.000000000",
          at: "21.3",
          sm5: "91.99",
          sm15: "40.63",
          sm25: "73.55",
          sm35: "100.0",
          sm45: "83.4",
          sm55: "91.99",
          st5: "22.1",
          st15: "22.3",
          st25: "20.7",
          st35: "19.5",
          st45: "20.2",
          st55: "19.6",
        },
        {
          time: "2023-08-09 16:00:00.000000000",
          at: "23.7",
          sm5: "94.06",
          sm15: "42.38",
          sm25: "75.7",
          sm35: "100.0",
          sm45: "85.36",
          sm55: "94.06",
          st5: "22.0",
          st15: "23.1",
          st25: "20.7",
          st35: "19.5",
          st45: "20.2",
          st55: "19.6",
        },
        {
          time: "2023-08-09 15:00:00.000000000",
          at: "25.5",
          sm5: "95.45",
          sm15: "43.48",
          sm25: "77.09",
          sm35: "100.0",
          sm45: "86.63",
          sm55: "95.45",
          st5: "21.8",
          st15: "23.3",
          st25: "20.6",
          st35: "19.3",
          st45: "20.2",
          st55: "19.6",
        },
        {
          time: "2023-08-09 14:00:00.000000000",
          at: "26.8",
          sm5: "96.69",
          sm15: "44.55",
          sm25: "78.44",
          sm35: "100.0",
          sm45: "87.82",
          sm55: "96.69",
          st5: "21.7",
          st15: "23.8",
          st25: "20.5",
          st35: "19.5",
          st45: "20.2",
          st55: "19.6",
        },
        {
          time: "2023-08-09 13:00:00.000000000",
          at: "27.3",
          sm5: "97.16",
          sm15: "44.95",
          sm25: "78.87",
          sm35: "100.0",
          sm45: "88.46",
          sm55: "97.16",
          st5: "21.6",
          st15: "23.7",
          st25: "20.5",
          st35: "19.5",
          st45: "20.2",
          st55: "19.2",
        },
        {
          time: "2023-08-09 12:00:00.000000000",
          at: "27.9",
          sm5: "97.58",
          sm15: "45.38",
          sm25: "78.63",
          sm35: "100.0",
          sm45: "88.59",
          sm55: "97.58",
          st5: "21.3",
          st15: "23.6",
          st25: "20.3",
          st35: "19.5",
          st45: "20.2",
          st55: "19.6",
        },
        {
          time: "2023-08-09 11:00:00.000000000",
          at: "27.9",
          sm5: "97.68",
          sm15: "45.8",
          sm25: "78.86",
          sm35: "100.0",
          sm45: "88.96",
          sm55: "97.68",
          st5: "21.1",
          st15: "23.3",
          st25: "20.3",
          st35: "19.6",
          st45: "20.2",
          st55: "19.6",
        },
        {
          time: "2023-08-09 10:00:00.000000000",
          at: "27.5",
          sm5: "97.6",
          sm15: "46.14",
          sm25: "78.82",
          sm35: "100.0",
          sm45: "88.77",
          sm55: "97.6",
          st5: "20.8",
          st15: "22.8",
          st25: "20.2",
          st35: "19.5",
          st45: "20.2",
          st55: "19.6",
        },
        {
          time: "2023-08-09 09:00:00.000000000",
          at: "27.1",
          sm5: "97.13",
          sm15: "46.45",
          sm25: "78.5",
          sm35: "100.0",
          sm45: "88.5",
          sm55: "97.13",
          st5: "20.6",
          st15: "22.5",
          st25: "20.2",
          st35: "19.5",
          st45: "20.2",
          st55: "19.6",
        },
        {
          time: "2023-08-09 08:00:00.000000000",
          at: "26.0",
          sm5: "96.42",
          sm15: "46.06",
          sm25: "77.58",
          sm35: "100.0",
          sm45: "88.17",
          sm55: "96.42",
          st5: "20.3",
          st15: "21.8",
          st25: "20.2",
          st35: "19.5",
          st45: "20.2",
          st55: "19.6",
        },
        {
          time: "2023-08-09 07:00:00.000000000",
          at: "24.4",
          sm5: "94.5",
          sm15: "44.64",
          sm25: "76.02",
          sm35: "100.0",
          sm45: "86.31",
          sm55: "94.5",
          st5: "20.2",
          st15: "20.7",
          st25: "20.3",
          st35: "19.5",
          st45: "20.3",
          st55: "19.6",
        },
        {
          time: "2023-08-09 06:00:00.000000000",
          at: "22.3",
          sm5: "93.22",
          sm15: "42.76",
          sm25: "74.13",
          sm35: "100.0",
          sm45: "84.56",
          sm55: "93.22",
          st5: "20.2",
          st15: "19.8",
          st25: "20.2",
          st35: "19.5",
          st45: "20.3",
          st55: "19.2",
        },
        {
          time: "2023-08-09 05:00:00.000000000",
          at: "20.5",
          sm5: "91.43",
          sm15: "40.96",
          sm25: "72.35",
          sm35: "100.0",
          sm45: "83.09",
          sm55: "91.43",
          st5: "20.2",
          st15: "19.3",
          st25: "20.3",
          st35: "19.5",
          st45: "20.3",
          st55: "19.6",
        },
        {
          time: "2023-08-09 04:00:00.000000000",
          at: "17.6",
          sm5: "88.79",
          sm15: "38.14",
          sm25: "68.97",
          sm35: "100.0",
          sm45: "80.4",
          sm55: "88.79",
          st5: "20.3",
          st15: "18.8",
          st25: "20.5",
          st35: "19.6",
          st45: "20.3",
          st55: "19.6",
        },
        {
          time: "2023-08-09 03:00:00.000000000",
          at: "17.6",
          sm5: "89.3",
          sm15: "38.63",
          sm25: "69.96",
          sm35: "100.0",
          sm45: "80.9",
          sm55: "89.3",
          st5: "20.5",
          st15: "18.8",
          st25: "20.5",
          st35: "19.5",
          st45: "20.3",
          st55: "19.5",
        },
        {
          time: "2023-08-09 02:00:00.000000000",
          at: "17.5",
          sm5: "88.27",
          sm15: "38.11",
          sm25: "69.5",
          sm35: "100.0",
          sm45: "80.27",
          sm55: "88.27",
          st5: "20.6",
          st15: "19.1",
          st25: "20.6",
          st35: "19.5",
          st45: "20.3",
          st55: "19.5",
        },
        {
          time: "2023-08-09 01:00:00.000000000",
          at: "17.8",
          sm5: "89.04",
          sm15: "38.78",
          sm25: "70.25",
          sm35: "100.0",
          sm45: "80.63",
          sm55: "89.04",
          st5: "20.7",
          st15: "19.2",
          st25: "20.7",
          st35: "19.5",
          st45: "20.3",
          st55: "19.6",
        },
        {
          time: "2023-08-09 00:00:00.000000000",
          at: "18.1",
          sm5: "89.43",
          sm15: "38.72",
          sm25: "70.11",
          sm35: "100.0",
          sm45: "81.16",
          sm55: "89.43",
          st5: "20.8",
          st15: "19.5",
          st25: "20.7",
          st35: "19.5",
          st45: "20.3",
          st55: "19.6",
        },
        {
          time: "2023-08-08 23:00:00.000000000",
          at: "18.4",
          sm5: "89.65",
          sm15: "39.5",
          sm25: "70.76",
          sm35: "100.0",
          sm45: "81.24",
          sm55: "89.65",
          st5: "21.1",
          st15: "19.7",
          st25: "20.7",
          st35: "19.5",
          st45: "20.3",
          st55: "19.5",
        },
        {
          time: "2023-08-08 22:00:00.000000000",
          at: "18.8",
          sm5: "89.9",
          sm15: "40.2",
          sm25: "71.3",
          sm35: "100.0",
          sm45: "81.51",
          sm55: "89.9",
          st5: "21.2",
          st15: "20.0",
          st25: "20.7",
          st35: "19.5",
          st45: "20.3",
          st55: "19.5",
        },
        {
          time: "2023-08-08 21:00:00.000000000",
          at: "19.6",
          sm5: "90.33",
          sm15: "41.06",
          sm25: "71.75",
          sm35: "100.0",
          sm45: "81.96",
          sm55: "90.33",
          st5: "21.3",
          st15: "20.3",
          st25: "20.7",
          st35: "19.3",
          st45: "20.3",
          st55: "19.5",
        },
        {
          time: "2023-08-08 20:00:00.000000000",
          at: "20.3",
          sm5: "91.21",
          sm15: "41.93",
          sm25: "72.55",
          sm35: "100.0",
          sm45: "82.78",
          sm55: "91.21",
          st5: "21.6",
          st15: "20.6",
          st25: "20.8",
          st35: "19.3",
          st45: "20.2",
          st55: "19.5",
        },
        {
          time: "2023-08-08 19:00:00.000000000",
          at: "21.3",
          sm5: "91.87",
          sm15: "43.06",
          sm25: "73.44",
          sm35: "100.0",
          sm45: "83.38",
          sm55: "91.87",
          st5: "21.7",
          st15: "21.0",
          st25: "20.7",
          st35: "19.3",
          st45: "20.2",
          st55: "19.5",
        },
        {
          time: "2023-08-08 18:00:00.000000000",
          at: "22.3",
          sm5: "93.05",
          sm15: "44.32",
          sm25: "74.8",
          sm35: "100.0",
          sm45: "84.71",
          sm55: "93.05",
          st5: "21.8",
          st15: "21.6",
          st25: "20.7",
          st35: "19.3",
          st45: "20.2",
          st55: "19.5",
        },
        {
          time: "2023-08-08 17:00:00.000000000",
          at: "24.0",
          sm5: "94.4",
          sm15: "45.65",
          sm25: "76.24",
          sm35: "100.0",
          sm45: "85.73",
          sm55: "94.4",
          st5: "22.0",
          st15: "22.2",
          st25: "20.6",
          st35: "19.3",
          st45: "20.1",
          st55: "19.5",
        },
        {
          time: "2023-08-08 16:00:00.000000000",
          at: "26.0",
          sm5: "95.71",
          sm15: "46.73",
          sm25: "77.63",
          sm35: "100.0",
          sm45: "87.1",
          sm55: "95.71",
          st5: "22.0",
          st15: "23.0",
          st25: "20.6",
          st35: "19.2",
          st45: "20.1",
          st55: "19.2",
        },
        {
          time: "2023-08-08 15:00:00.000000000",
          at: "27.2",
          sm5: "96.84",
          sm15: "47.82",
          sm25: "78.6",
          sm35: "100.0",
          sm45: "88.19",
          sm55: "96.84",
          st5: "22.0",
          st15: "23.5",
          st25: "20.5",
          st35: "19.3",
          st45: "20.0",
          st55: "19.5",
        },
        {
          time: "2023-08-08 14:00:00.000000000",
          at: "28.0",
          sm5: "97.73",
          sm15: "48.38",
          sm25: "79.25",
          sm35: "100.0",
          sm45: "89.1",
          sm55: "97.73",
          st5: "21.8",
          st15: "24.1",
          st25: "20.3",
          st35: "19.2",
          st45: "20.0",
          st55: "19.5",
        },
        {
          time: "2023-08-08 13:00:00.000000000",
          at: "28.9",
          sm5: "98.01",
          sm15: "49.07",
          sm25: "79.91",
          sm35: "100.0",
          sm45: "89.57",
          sm55: "98.01",
          st5: "21.6",
          st15: "24.5",
          st25: "20.1",
          st35: "19.2",
          st45: "20.0",
          st55: "19.5",
        },
        {
          time: "2023-08-08 12:00:00.000000000",
          at: "28.0",
          sm5: "97.18",
          sm15: "48.88",
          sm25: "78.56",
          sm35: "100.0",
          sm45: "88.69",
          sm55: "97.18",
          st5: "21.2",
          st15: "24.1",
          st25: "20.1",
          st35: "19.3",
          st45: "20.0",
          st55: "19.5",
        },
        {
          time: "2023-08-08 11:00:00.000000000",
          at: "30.5",
          sm5: "99.54",
          sm15: "50.97",
          sm25: "81.01",
          sm35: "100.0",
          sm45: "91.04",
          sm55: "99.54",
          st5: "21.0",
          st15: "24.3",
          st25: "20.0",
          st35: "19.3",
          st45: "20.0",
          st55: "19.5",
        },
        {
          time: "2023-08-08 10:00:00.000000000",
          at: "29.2",
          sm5: "98.31",
          sm15: "50.8",
          sm25: "80.08",
          sm35: "100.0",
          sm45: "90.04",
          sm55: "98.31",
          st5: "20.5",
          st15: "23.3",
          st25: "19.8",
          st35: "19.3",
          st45: "20.0",
          st55: "19.5",
        },
        {
          time: "2023-08-08 09:00:00.000000000",
          at: "28.7",
          sm5: "98.11",
          sm15: "50.66",
          sm25: "79.47",
          sm35: "100.0",
          sm45: "89.51",
          sm55: "98.11",
          st5: "20.1",
          st15: "22.7",
          st25: "19.8",
          st35: "19.5",
          st45: "20.1",
          st55: "19.5",
        },
        {
          time: "2023-08-08 08:00:00.000000000",
          at: "27.2",
          sm5: "96.7",
          sm15: "50.25",
          sm25: "78.21",
          sm35: "100.0",
          sm45: "88.43",
          sm55: "96.7",
          st5: "19.7",
          st15: "21.7",
          st25: "20.0",
          st35: "19.3",
          st45: "20.1",
          st55: "19.2",
        },
        {
          time: "2023-08-08 07:00:00.000000000",
          at: "24.8",
          sm5: "95.09",
          sm15: "48.69",
          sm25: "76.29",
          sm35: "100.0",
          sm45: "86.61",
          sm55: "95.09",
          st5: "19.6",
          st15: "20.3",
          st25: "19.8",
          st35: "19.3",
          st45: "20.1",
          st55: "19.5",
        },
        {
          time: "2023-08-08 06:00:00.000000000",
          at: "21.5",
          sm5: "92.09",
          sm15: "45.47",
          sm25: "72.32",
          sm35: "100.0",
          sm45: "84.12",
          sm55: "92.09",
          st5: "19.6",
          st15: "19.2",
          st25: "20.0",
          st35: "19.3",
          st45: "20.2",
          st55: "19.2",
        },
        {
          time: "2023-08-08 05:00:00.000000000",
          at: "18.6",
          sm5: "89.63",
          sm15: "42.64",
          sm25: "69.96",
          sm35: "100.0",
          sm45: "81.57",
          sm55: "89.63",
          st5: "19.6",
          st15: "18.3",
          st25: "20.1",
          st35: "19.5",
          st45: "20.2",
          st55: "19.3",
        },
        {
          time: "2023-08-08 04:00:00.000000000",
          at: "15.2",
          sm5: "86.25",
          sm15: "39.96",
          sm25: "66.82",
          sm35: "100.0",
          sm45: "78.88",
          sm55: "86.25",
          st5: "19.7",
          st15: "17.5",
          st25: "20.2",
          st35: "19.3",
          st45: "20.2",
          st55: "19.5",
        },
        {
          time: "2023-08-08 03:00:00.000000000",
          at: "14.3",
          sm5: "85.58",
          sm15: "38.84",
          sm25: "65.76",
          sm35: "100.0",
          sm45: "78.09",
          sm55: "85.58",
          st5: "20.0",
          st15: "17.5",
          st25: "20.3",
          st35: "19.3",
          st45: "20.2",
          st55: "19.5",
        },
        {
          time: "2023-08-08 02:00:00.000000000",
          at: "14.3",
          sm5: "85.16",
          sm15: "39.13",
          sm25: "65.7",
          sm35: "100.0",
          sm45: "77.72",
          sm55: "85.16",
          st5: "20.2",
          st15: "17.7",
          st25: "20.3",
          st35: "19.3",
          st45: "20.2",
          st55: "19.3",
        },
        {
          time: "2023-08-08 01:00:00.000000000",
          at: "14.5",
          sm5: "85.39",
          sm15: "39.62",
          sm25: "65.9",
          sm35: "100.0",
          sm45: "77.69",
          sm55: "85.39",
          st5: "20.3",
          st15: "18.1",
          st25: "20.5",
          st35: "19.3",
          st45: "20.2",
          st55: "19.3",
        },
        {
          time: "2023-08-08 00:00:00.000000000",
          at: "15.4",
          sm5: "86.49",
          sm15: "40.42",
          sm25: "67.31",
          sm35: "100.0",
          sm45: "78.63",
          sm55: "86.49",
          st5: "20.5",
          st15: "18.5",
          st25: "20.6",
          st35: "19.3",
          st45: "20.2",
          st55: "19.3",
        },
        {
          time: "2023-08-07 23:00:00.000000000",
          at: "16.3",
          sm5: "87.01",
          sm15: "41.56",
          sm25: "68.16",
          sm35: "100.0",
          sm45: "79.34",
          sm55: "87.01",
          st5: "20.7",
          st15: "19.0",
          st25: "20.6",
          st35: "19.3",
          st45: "20.2",
          st55: "19.3",
        },
        {
          time: "2023-08-07 22:00:00.000000000",
          at: "17.8",
          sm5: "88.51",
          sm15: "42.99",
          sm25: "69.85",
          sm35: "100.0",
          sm45: "80.65",
          sm55: "88.51",
          st5: "21.0",
          st15: "19.3",
          st25: "20.6",
          st35: "19.2",
          st45: "20.2",
          st55: "19.3",
        },
        {
          time: "2023-08-07 21:00:00.000000000",
          at: "16.6",
          sm5: "87.45",
          sm15: "42.36",
          sm25: "68.51",
          sm35: "100.0",
          sm45: "79.5",
          sm55: "87.45",
          st5: "21.2",
          st15: "19.5",
          st25: "20.6",
          st35: "19.2",
          st45: "20.2",
          st55: "19.3",
        },
        {
          time: "2023-08-07 20:00:00.000000000",
          at: "17.6",
          sm5: "88.4",
          sm15: "43.76",
          sm25: "70.27",
          sm35: "100.0",
          sm45: "80.61",
          sm55: "88.4",
          st5: "21.3",
          st15: "20.0",
          st25: "20.6",
          st35: "19.2",
          st45: "20.1",
          st55: "19.5",
        },
        {
          time: "2023-08-07 19:00:00.000000000",
          at: "18.5",
          sm5: "88.81",
          sm15: "44.74",
          sm25: "70.24",
          sm35: "100.0",
          sm45: "81.01",
          sm55: "88.81",
          st5: "21.5",
          st15: "20.6",
          st25: "20.6",
          st35: "19.2",
          st45: "20.1",
          st55: "19.5",
        },
        {
          time: "2023-08-07 18:00:00.000000000",
          at: "20.4",
          sm5: "90.47",
          sm15: "46.91",
          sm25: "72.59",
          sm35: "100.0",
          sm45: "82.49",
          sm55: "90.47",
          st5: "21.7",
          st15: "21.2",
          st25: "20.6",
          st35: "19.2",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-07 17:00:00.000000000",
          at: "21.8",
          sm5: "91.7",
          sm15: "48.5",
          sm25: "73.39",
          sm35: "100.0",
          sm45: "83.81",
          sm55: "91.7",
          st5: "21.7",
          st15: "21.8",
          st25: "20.5",
          st35: "19.1",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-07 14:00:00.000000000",
          at: "26.8",
          sm5: "95.74",
          sm15: "53.3",
          sm25: "78.24",
          sm35: "100.0",
          sm45: "87.83",
          sm55: "95.74",
          st5: "21.6",
          st15: "23.3",
          st25: "20.2",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-07 13:00:00.000000000",
          at: "27.5",
          sm5: "96.71",
          sm15: "54.86",
          sm25: "79.55",
          sm35: "100.0",
          sm45: "88.87",
          sm55: "96.71",
          st5: "21.5",
          st15: "23.6",
          st25: "20.1",
          st35: "19.2",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-07 12:00:00.000000000",
          at: "28.2",
          sm5: "97.53",
          sm15: "55.99",
          sm25: "80.38",
          sm35: "100.0",
          sm45: "89.62",
          sm55: "97.53",
          st5: "21.2",
          st15: "23.8",
          st25: "20.0",
          st35: "19.1",
          st45: "19.8",
          st55: "19.5",
        },
        {
          time: "2023-08-07 11:00:00.000000000",
          at: "29.5",
          sm5: "98.43",
          sm15: "58.29",
          sm25: "81.84",
          sm35: "100.0",
          sm45: "90.32",
          sm55: "98.43",
          st5: "21.0",
          st15: "23.8",
          st25: "19.8",
          st35: "19.2",
          st45: "19.8",
          st55: "19.5",
        },
        {
          time: "2023-08-07 10:00:00.000000000",
          at: "28.2",
          sm5: "97.58",
          sm15: "58.52",
          sm25: "81.73",
          sm35: "100.0",
          sm45: "89.39",
          sm55: "97.58",
          st5: "20.6",
          st15: "23.1",
          st25: "19.8",
          st35: "19.2",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-07 09:00:00.000000000",
          at: "27.5",
          sm5: "96.97",
          sm15: "59.59",
          sm25: "86.01",
          sm35: "100.0",
          sm45: "88.65",
          sm55: "96.97",
          st5: "20.2",
          st15: "22.7",
          st25: "19.7",
          st35: "19.2",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-07 08:00:00.000000000",
          at: "24.6",
          sm5: "93.96",
          sm15: "60.11",
          sm25: "90.98",
          sm35: "100.0",
          sm45: "84.54",
          sm55: "93.96",
          st5: "20.0",
          st15: "21.7",
          st25: "19.7",
          st35: "19.1",
          st45: "19.8",
          st55: "19.2",
        },
        {
          time: "2023-08-07 07:00:00.000000000",
          at: "21.9",
          sm5: "91.73",
          sm15: "67.42",
          sm25: "88.2",
          sm35: "100.0",
          sm45: "82.3",
          sm55: "91.73",
          st5: "19.7",
          st15: "21.0",
          st25: "19.7",
          st35: "19.2",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-07 06:00:00.000000000",
          at: "23.3",
          sm5: "93.44",
          sm15: "38.7",
          sm25: "72.65",
          sm35: "100.0",
          sm45: "85.28",
          sm55: "93.44",
          st5: "19.5",
          st15: "19.7",
          st25: "19.8",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-07 05:00:00.000000000",
          at: "20.2",
          sm5: "90.77",
          sm15: "35.59",
          sm25: "69.71",
          sm35: "100.0",
          sm45: "82.47",
          sm55: "90.77",
          st5: "19.5",
          st15: "18.7",
          st25: "19.8",
          st35: "19.2",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-07 04:00:00.000000000",
          at: "16.7",
          sm5: "87.09",
          sm15: "31.66",
          sm25: "65.93",
          sm35: "100.0",
          sm45: "79.17",
          sm55: "87.09",
          st5: "19.6",
          st15: "18.0",
          st25: "20.0",
          st35: "19.2",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-07 03:00:00.000000000",
          at: "16.1",
          sm5: "86.69",
          sm15: "31.2",
          sm25: "65.38",
          sm35: "100.0",
          sm45: "78.94",
          sm55: "86.69",
          st5: "19.8",
          st15: "17.8",
          st25: "20.0",
          st35: "19.1",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-07 02:00:00.000000000",
          at: "15.5",
          sm5: "85.73",
          sm15: "30.29",
          sm25: "64.6",
          sm35: "100.0",
          sm45: "78.07",
          sm55: "85.73",
          st5: "20.0",
          st15: "18.0",
          st25: "20.1",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-07 01:00:00.000000000",
          at: "16.2",
          sm5: "86.98",
          sm15: "31.81",
          sm25: "65.97",
          sm35: "100.0",
          sm45: "79.18",
          sm55: "86.98",
          st5: "20.1",
          st15: "18.3",
          st25: "20.2",
          st35: "19.1",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-07 00:00:00.000000000",
          at: "16.8",
          sm5: "87.3",
          sm15: "32.92",
          sm25: "66.77",
          sm35: "100.0",
          sm45: "79.47",
          sm55: "87.3",
          st5: "20.2",
          st15: "18.7",
          st25: "20.1",
          st35: "19.2",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-08-06 23:00:00.000000000",
          at: "17.9",
          sm5: "87.88",
          sm15: "33.64",
          sm25: "67.72",
          sm35: "100.0",
          sm45: "80.16",
          sm55: "87.88",
          st5: "20.5",
          st15: "19.0",
          st25: "20.2",
          st35: "19.1",
          st45: "19.8",
          st55: "19.2",
        },
        {
          time: "2023-08-06 22:00:00.000000000",
          at: "17.5",
          sm5: "87.88",
          sm15: "33.6",
          sm25: "67.4",
          sm35: "100.0",
          sm45: "80.27",
          sm55: "87.88",
          st5: "20.6",
          st15: "19.2",
          st25: "20.2",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-06 21:00:00.000000000",
          at: "20.0",
          sm5: "90.56",
          sm15: "36.59",
          sm25: "70.35",
          sm35: "100.0",
          sm45: "82.13",
          sm55: "90.56",
          st5: "20.7",
          st15: "19.7",
          st25: "20.2",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-06 20:00:00.000000000",
          at: "20.5",
          sm5: "90.36",
          sm15: "36.92",
          sm25: "70.19",
          sm35: "100.0",
          sm45: "82.38",
          sm55: "90.36",
          st5: "20.8",
          st15: "20.0",
          st25: "20.2",
          st35: "19.1",
          st45: "19.8",
          st55: "19.2",
        },
        {
          time: "2023-08-06 19:00:00.000000000",
          at: "19.5",
          sm5: "89.36",
          sm15: "36.51",
          sm25: "69.5",
          sm35: "100.0",
          sm45: "81.75",
          sm55: "89.36",
          st5: "21.1",
          st15: "20.5",
          st25: "20.2",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-06 18:00:00.000000000",
          at: "20.3",
          sm5: "90.62",
          sm15: "37.43",
          sm25: "70.3",
          sm35: "100.0",
          sm45: "82.76",
          sm55: "90.62",
          st5: "21.1",
          st15: "21.0",
          st25: "20.1",
          st35: "19.1",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-08-06 17:00:00.000000000",
          at: "23.5",
          sm5: "92.64",
          sm15: "39.62",
          sm25: "73.16",
          sm35: "100.0",
          sm45: "84.91",
          sm55: "92.64",
          st5: "21.1",
          st15: "21.7",
          st25: "20.0",
          st35: "19.1",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-08-06 16:00:00.000000000",
          at: "25.5",
          sm5: "94.79",
          sm15: "41.23",
          sm25: "74.72",
          sm35: "100.0",
          sm45: "86.91",
          sm55: "94.79",
          st5: "21.2",
          st15: "22.2",
          st25: "19.8",
          st35: "19.1",
          st45: "19.6",
          st55: "19.5",
        },
        {
          time: "2023-08-06 15:00:00.000000000",
          at: "26.5",
          sm5: "95.51",
          sm15: "42.31",
          sm25: "75.63",
          sm35: "100.0",
          sm45: "87.37",
          sm55: "95.51",
          st5: "21.1",
          st15: "22.7",
          st25: "19.8",
          st35: "19.1",
          st45: "19.6",
          st55: "19.3",
        },
        {
          time: "2023-08-06 14:00:00.000000000",
          at: "27.8",
          sm5: "96.83",
          sm15: "43.08",
          sm25: "76.83",
          sm35: "100.0",
          sm45: "88.55",
          sm55: "96.83",
          st5: "21.0",
          st15: "23.0",
          st25: "19.7",
          st35: "19.1",
          st45: "19.6",
          st55: "19.5",
        },
        {
          time: "2023-08-06 13:00:00.000000000",
          at: "28.7",
          sm5: "97.72",
          sm15: "44.33",
          sm25: "77.6",
          sm35: "100.0",
          sm45: "89.62",
          sm55: "97.72",
          st5: "20.6",
          st15: "23.2",
          st25: "19.6",
          st35: "19.1",
          st45: "19.6",
          st55: "19.3",
        },
        {
          time: "2023-08-06 12:00:00.000000000",
          at: "29.5",
          sm5: "98.32",
          sm15: "45.46",
          sm25: "78.5",
          sm35: "100.0",
          sm45: "90.15",
          sm55: "98.32",
          st5: "20.3",
          st15: "23.3",
          st25: "19.5",
          st35: "19.1",
          st45: "19.6",
          st55: "19.3",
        },
        {
          time: "2023-08-06 11:00:00.000000000",
          at: "30.5",
          sm5: "98.97",
          sm15: "46.54",
          sm25: "79.7",
          sm35: "100.0",
          sm45: "91.07",
          sm55: "98.97",
          st5: "20.0",
          st15: "23.3",
          st25: "19.2",
          st35: "19.1",
          st45: "19.7",
          st55: "19.5",
        },
        {
          time: "2023-08-06 10:00:00.000000000",
          at: "29.5",
          sm5: "98.22",
          sm15: "45.9",
          sm25: "78.21",
          sm35: "100.0",
          sm45: "90.08",
          sm55: "98.22",
          st5: "19.6",
          st15: "22.6",
          st25: "19.2",
          st35: "19.1",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-08-06 08:00:00.000000000",
          at: "29.4",
          sm5: "98.34",
          sm15: "46.19",
          sm25: "78.66",
          sm35: "100.0",
          sm45: "90.07",
          sm55: "98.34",
          st5: "19.1",
          st15: "22.1",
          st25: "19.2",
          st35: "19.2",
          st45: "19.7",
          st55: "19.2",
        },
        {
          time: "2023-08-06 07:00:00.000000000",
          at: "27.8",
          sm5: "97.1",
          sm15: "45.44",
          sm25: "77.15",
          sm35: "100.0",
          sm45: "89.3",
          sm55: "97.1",
          st5: "18.7",
          st15: "20.7",
          st25: "19.2",
          st35: "19.2",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-06 06:00:00.000000000",
          at: "24.8",
          sm5: "95.14",
          sm15: "43.26",
          sm25: "74.42",
          sm35: "100.0",
          sm45: "86.79",
          sm55: "95.14",
          st5: "18.5",
          st15: "19.2",
          st25: "19.5",
          st35: "19.2",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-06 05:00:00.000000000",
          at: "20.1",
          sm5: "90.75",
          sm15: "39.25",
          sm25: "70.07",
          sm35: "100.0",
          sm45: "83.16",
          sm55: "90.75",
          st5: "18.5",
          st15: "17.5",
          st25: "19.5",
          st35: "19.2",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-06 04:00:00.000000000",
          at: "15.4",
          sm5: "86.56",
          sm15: "34.72",
          sm25: "65.44",
          sm35: "100.0",
          sm45: "79.1",
          sm55: "86.56",
          st5: "18.5",
          st15: "16.2",
          st25: "19.6",
          st35: "19.2",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-06 03:00:00.000000000",
          at: "11.9",
          sm5: "82.62",
          sm15: "30.82",
          sm25: "61.14",
          sm35: "97.14",
          sm45: "75.33",
          sm55: "82.62",
          st5: "18.7",
          st15: "15.5",
          st25: "19.7",
          st35: "19.2",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-06 02:00:00.000000000",
          at: "11.2",
          sm5: "81.76",
          sm15: "29.92",
          sm25: "60.05",
          sm35: "95.9",
          sm45: "74.49",
          sm55: "81.76",
          st5: "19.1",
          st15: "15.6",
          st25: "19.8",
          st35: "19.2",
          st45: "20.0",
          st55: "19.5",
        },
        {
          time: "2023-08-06 01:00:00.000000000",
          at: "11.8",
          sm5: "81.8",
          sm15: "30.52",
          sm25: "60.59",
          sm35: "96.46",
          sm45: "74.91",
          sm55: "81.8",
          st5: "19.3",
          st15: "15.8",
          st25: "20.0",
          st35: "19.3",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-06 00:00:00.000000000",
          at: "12.0",
          sm5: "82.68",
          sm15: "31.49",
          sm25: "61.81",
          sm35: "97.41",
          sm45: "75.6",
          sm55: "82.68",
          st5: "19.6",
          st15: "16.2",
          st25: "20.1",
          st35: "19.2",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-05 23:00:00.000000000",
          at: "12.3",
          sm5: "82.83",
          sm15: "31.26",
          sm25: "61.46",
          sm35: "97.04",
          sm45: "75.42",
          sm55: "82.83",
          st5: "19.8",
          st15: "16.6",
          st25: "20.2",
          st35: "19.2",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-05 22:00:00.000000000",
          at: "12.9",
          sm5: "83.59",
          sm15: "32.31",
          sm25: "62.73",
          sm35: "98.14",
          sm45: "76.07",
          sm55: "83.59",
          st5: "20.1",
          st15: "17.1",
          st25: "20.2",
          st35: "19.1",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-05 21:00:00.000000000",
          at: "13.4",
          sm5: "83.72",
          sm15: "32.54",
          sm25: "63.07",
          sm35: "98.38",
          sm45: "76.43",
          sm55: "83.72",
          st5: "20.5",
          st15: "17.6",
          st25: "20.3",
          st35: "19.2",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-05 20:00:00.000000000",
          at: "14.3",
          sm5: "84.52",
          sm15: "33.62",
          sm25: "63.97",
          sm35: "99.65",
          sm45: "77.35",
          sm55: "84.52",
          st5: "20.7",
          st15: "18.2",
          st25: "20.3",
          st35: "19.1",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-05 19:00:00.000000000",
          at: "15.4",
          sm5: "85.96",
          sm15: "35.45",
          sm25: "65.57",
          sm35: "100.0",
          sm45: "78.32",
          sm55: "85.96",
          st5: "21.0",
          st15: "19.1",
          st25: "20.3",
          st35: "19.1",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-05 18:00:00.000000000",
          at: "16.4",
          sm5: "86.59",
          sm15: "36.23",
          sm25: "66.48",
          sm35: "100.0",
          sm45: "79.04",
          sm55: "86.59",
          st5: "21.2",
          st15: "19.8",
          st25: "20.3",
          st35: "19.1",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-05 17:00:00.000000000",
          at: "18.1",
          sm5: "88.17",
          sm15: "38.4",
          sm25: "68.04",
          sm35: "100.0",
          sm45: "80.5",
          sm55: "88.17",
          st5: "21.3",
          st15: "20.6",
          st25: "20.3",
          st35: "19.2",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-05 16:00:00.000000000",
          at: "20.3",
          sm5: "90.42",
          sm15: "41.05",
          sm25: "70.46",
          sm35: "100.0",
          sm45: "82.53",
          sm55: "90.42",
          st5: "21.3",
          st15: "21.7",
          st25: "20.2",
          st35: "19.2",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-05 15:00:00.000000000",
          at: "23.6",
          sm5: "93.41",
          sm15: "44.0",
          sm25: "74.21",
          sm35: "100.0",
          sm45: "85.85",
          sm55: "93.41",
          st5: "21.3",
          st15: "22.5",
          st25: "20.0",
          st35: "19.1",
          st45: "19.7",
          st55: "19.5",
        },
        {
          time: "2023-08-05 14:00:00.000000000",
          at: "25.8",
          sm5: "94.79",
          sm15: "45.22",
          sm25: "76.07",
          sm35: "100.0",
          sm45: "87.09",
          sm55: "94.79",
          st5: "21.2",
          st15: "23.0",
          st25: "20.0",
          st35: "19.2",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-08-05 13:00:00.000000000",
          at: "27.1",
          sm5: "96.34",
          sm15: "46.62",
          sm25: "76.93",
          sm35: "100.0",
          sm45: "88.28",
          sm55: "96.34",
          st5: "21.1",
          st15: "23.2",
          st25: "19.8",
          st35: "19.1",
          st45: "19.7",
          st55: "19.5",
        },
        {
          time: "2023-08-05 12:00:00.000000000",
          at: "28.0",
          sm5: "97.07",
          sm15: "47.49",
          sm25: "77.95",
          sm35: "100.0",
          sm45: "89.26",
          sm55: "97.07",
          st5: "20.7",
          st15: "23.5",
          st25: "19.7",
          st35: "19.2",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-08-05 11:00:00.000000000",
          at: "28.6",
          sm5: "97.26",
          sm15: "48.23",
          sm25: "77.98",
          sm35: "100.0",
          sm45: "89.44",
          sm55: "97.26",
          st5: "20.5",
          st15: "23.5",
          st25: "19.6",
          st35: "19.2",
          st45: "19.7",
          st55: "19.5",
        },
        {
          time: "2023-08-05 10:00:00.000000000",
          at: "29.5",
          sm5: "98.34",
          sm15: "49.25",
          sm25: "79.22",
          sm35: "100.0",
          sm45: "90.58",
          sm55: "98.34",
          st5: "20.0",
          st15: "23.2",
          st25: "19.5",
          st35: "19.2",
          st45: "19.8",
          st55: "19.5",
        },
        {
          time: "2023-08-05 09:00:00.000000000",
          at: "28.0",
          sm5: "97.11",
          sm15: "48.46",
          sm25: "77.81",
          sm35: "100.0",
          sm45: "89.63",
          sm55: "97.11",
          st5: "19.6",
          st15: "22.1",
          st25: "19.3",
          st35: "19.2",
          st45: "19.8",
          st55: "19.5",
        },
        {
          time: "2023-08-05 08:00:00.000000000",
          at: "27.6",
          sm5: "96.58",
          sm15: "48.27",
          sm25: "77.63",
          sm35: "100.0",
          sm45: "88.99",
          sm55: "96.58",
          st5: "19.2",
          st15: "21.5",
          st25: "19.5",
          st35: "19.2",
          st45: "19.8",
          st55: "19.5",
        },
        {
          time: "2023-08-05 07:00:00.000000000",
          at: "25.6",
          sm5: "94.96",
          sm15: "47.15",
          sm25: "76.09",
          sm35: "100.0",
          sm45: "87.4",
          sm55: "94.96",
          st5: "19.0",
          st15: "20.1",
          st25: "19.5",
          st35: "19.3",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-05 06:00:00.000000000",
          at: "23.4",
          sm5: "93.25",
          sm15: "45.35",
          sm25: "73.58",
          sm35: "100.0",
          sm45: "85.68",
          sm55: "93.25",
          st5: "18.8",
          st15: "19.0",
          st25: "19.5",
          st35: "19.2",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-05 05:00:00.000000000",
          at: "20.0",
          sm5: "90.45",
          sm15: "42.2",
          sm25: "70.36",
          sm35: "100.0",
          sm45: "82.76",
          sm55: "90.45",
          st5: "18.8",
          st15: "17.8",
          st25: "19.7",
          st35: "19.3",
          st45: "20.0",
          st55: "19.5",
        },
        {
          time: "2023-08-05 04:00:00.000000000",
          at: "16.3",
          sm5: "86.32",
          sm15: "38.65",
          sm25: "66.82",
          sm35: "100.0",
          sm45: "79.72",
          sm55: "86.32",
          st5: "19.0",
          st15: "16.8",
          st25: "19.8",
          st35: "19.3",
          st45: "20.1",
          st55: "19.5",
        },
        {
          time: "2023-08-05 03:00:00.000000000",
          at: "12.8",
          sm5: "83.16",
          sm15: "35.13",
          sm25: "62.82",
          sm35: "98.06",
          sm45: "76.36",
          sm55: "83.16",
          st5: "19.1",
          st15: "16.2",
          st25: "19.8",
          st35: "19.2",
          st45: "20.1",
          st55: "19.2",
        },
        {
          time: "2023-08-05 02:00:00.000000000",
          at: "12.3",
          sm5: "82.61",
          sm15: "34.95",
          sm25: "62.22",
          sm35: "97.21",
          sm45: "75.72",
          sm55: "82.61",
          st5: "19.3",
          st15: "16.3",
          st25: "20.1",
          st35: "19.2",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-05 01:00:00.000000000",
          at: "12.6",
          sm5: "82.76",
          sm15: "35.18",
          sm25: "62.7",
          sm35: "97.36",
          sm45: "75.98",
          sm55: "82.76",
          st5: "19.6",
          st15: "16.6",
          st25: "20.1",
          st35: "19.2",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-05 00:00:00.000000000",
          at: "12.8",
          sm5: "83.09",
          sm15: "35.33",
          sm25: "63.2",
          sm35: "98.12",
          sm45: "76.38",
          sm55: "83.09",
          st5: "19.8",
          st15: "16.8",
          st25: "20.2",
          st35: "19.3",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-04 23:00:00.000000000",
          at: "13.0",
          sm5: "83.27",
          sm15: "35.45",
          sm25: "63.39",
          sm35: "97.98",
          sm45: "76.42",
          sm55: "83.27",
          st5: "20.1",
          st15: "17.2",
          st25: "20.3",
          st35: "19.2",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-04 22:00:00.000000000",
          at: "13.4",
          sm5: "83.48",
          sm15: "36.26",
          sm25: "64.08",
          sm35: "98.07",
          sm45: "76.65",
          sm55: "83.48",
          st5: "20.3",
          st15: "17.6",
          st25: "20.5",
          st35: "19.3",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-04 21:00:00.000000000",
          at: "13.8",
          sm5: "84.06",
          sm15: "36.73",
          sm25: "64.77",
          sm35: "98.89",
          sm45: "77.04",
          sm55: "84.06",
          st5: "20.6",
          st15: "18.1",
          st25: "20.5",
          st35: "19.2",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-04 20:00:00.000000000",
          at: "14.5",
          sm5: "84.26",
          sm15: "39.84",
          sm25: "65.21",
          sm35: "99.13",
          sm45: "77.42",
          sm55: "84.26",
          st5: "20.8",
          st15: "18.7",
          st25: "20.5",
          st35: "19.2",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-04 19:00:00.000000000",
          at: "16.2",
          sm5: "86.27",
          sm15: "39.08",
          sm25: "67.16",
          sm35: "100.0",
          sm45: "79.18",
          sm55: "86.27",
          st5: "21.1",
          st15: "19.5",
          st25: "20.5",
          st35: "19.2",
          st45: "20.0",
          st55: "19.5",
        },
        {
          time: "2023-08-04 18:00:00.000000000",
          at: "17.5",
          sm5: "87.26",
          sm15: "40.19",
          sm25: "68.86",
          sm35: "100.0",
          sm45: "80.32",
          sm55: "87.26",
          st5: "21.3",
          st15: "20.2",
          st25: "20.5",
          st35: "19.2",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-04 17:00:00.000000000",
          at: "18.6",
          sm5: "88.79",
          sm15: "41.74",
          sm25: "70.27",
          sm35: "100.0",
          sm45: "81.43",
          sm55: "88.79",
          st5: "21.5",
          st15: "21.0",
          st25: "20.3",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-04 16:00:00.000000000",
          at: "21.8",
          sm5: "90.9",
          sm15: "44.68",
          sm25: "72.96",
          sm35: "100.0",
          sm45: "83.77",
          sm55: "90.9",
          st5: "21.5",
          st15: "21.8",
          st25: "20.2",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-04 15:00:00.000000000",
          at: "23.6",
          sm5: "92.91",
          sm15: "46.45",
          sm25: "74.79",
          sm35: "100.0",
          sm45: "85.68",
          sm55: "92.91",
          st5: "21.5",
          st15: "22.6",
          st25: "20.2",
          st35: "19.2",
          st45: "19.8",
          st55: "19.5",
        },
        {
          time: "2023-08-04 14:00:00.000000000",
          at: "25.6",
          sm5: "94.77",
          sm15: "48.7",
          sm25: "77.16",
          sm35: "100.0",
          sm45: "87.48",
          sm55: "94.77",
          st5: "21.3",
          st15: "22.8",
          st25: "20.1",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-04 13:00:00.000000000",
          at: "27.1",
          sm5: "96.15",
          sm15: "50.0",
          sm25: "78.81",
          sm35: "100.0",
          sm45: "88.79",
          sm55: "96.15",
          st5: "21.2",
          st15: "23.2",
          st25: "20.0",
          st35: "19.2",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-04 12:00:00.000000000",
          at: "27.5",
          sm5: "96.37",
          sm15: "51.26",
          sm25: "79.07",
          sm35: "100.0",
          sm45: "88.98",
          sm55: "96.37",
          st5: "21.0",
          st15: "23.3",
          st25: "19.7",
          st35: "19.2",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-08-04 11:00:00.000000000",
          at: "28.0",
          sm5: "96.75",
          sm15: "52.35",
          sm25: "80.24",
          sm35: "100.0",
          sm45: "89.6",
          sm55: "96.75",
          st5: "20.6",
          st15: "23.2",
          st25: "19.6",
          st35: "19.2",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-08-04 10:00:00.000000000",
          at: "29.1",
          sm5: "97.65",
          sm15: "54.19",
          sm25: "81.84",
          sm35: "100.0",
          sm45: "90.33",
          sm55: "97.65",
          st5: "20.2",
          st15: "23.1",
          st25: "19.6",
          st35: "19.2",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-08-04 09:00:00.000000000",
          at: "27.6",
          sm5: "96.4",
          sm15: "54.15",
          sm25: "81.74",
          sm35: "100.0",
          sm45: "89.2",
          sm55: "96.4",
          st5: "19.8",
          st15: "22.2",
          st25: "19.5",
          st35: "19.2",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-04 08:00:00.000000000",
          at: "27.1",
          sm5: "95.91",
          sm15: "55.33",
          sm25: "82.48",
          sm35: "100.0",
          sm45: "88.23",
          sm55: "95.91",
          st5: "19.5",
          st15: "21.7",
          st25: "19.5",
          st35: "19.2",
          st45: "19.8",
          st55: "19.2",
        },
        {
          time: "2023-08-04 07:00:00.000000000",
          at: "24.4",
          sm5: "93.45",
          sm15: "55.64",
          sm25: "88.91",
          sm35: "100.0",
          sm45: "85.47",
          sm55: "93.45",
          st5: "19.2",
          st15: "20.5",
          st25: "19.5",
          st35: "19.3",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-04 06:00:00.000000000",
          at: "21.5",
          sm5: "90.78",
          sm15: "60.71",
          sm25: "85.74",
          sm35: "100.0",
          sm45: "82.55",
          sm55: "90.78",
          st5: "19.0",
          st15: "19.7",
          st25: "19.5",
          st35: "19.3",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-04 05:00:00.000000000",
          at: "20.7",
          sm5: "90.58",
          sm15: "42.02",
          sm25: "70.63",
          sm35: "100.0",
          sm45: "81.72",
          sm55: "90.58",
          st5: "19.0",
          st15: "18.2",
          st25: "19.7",
          st35: "19.3",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-04 04:00:00.000000000",
          at: "16.6",
          sm5: "86.54",
          sm15: "38.05",
          sm25: "66.25",
          sm35: "100.0",
          sm45: "77.72",
          sm55: "86.54",
          st5: "19.0",
          st15: "17.0",
          st25: "19.8",
          st35: "19.2",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-04 03:00:00.000000000",
          at: "13.0",
          sm5: "82.99",
          sm15: "34.68",
          sm25: "62.89",
          sm35: "98.17",
          sm45: "74.27",
          sm55: "82.99",
          st5: "19.2",
          st15: "16.3",
          st25: "19.8",
          st35: "19.2",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-04 02:00:00.000000000",
          at: "12.2",
          sm5: "81.94",
          sm15: "33.5",
          sm25: "61.64",
          sm35: "96.97",
          sm45: "73.13",
          sm55: "81.94",
          st5: "19.5",
          st15: "16.5",
          st25: "20.1",
          st35: "19.2",
          st45: "20.1",
          st55: "19.5",
        },
        {
          time: "2023-08-04 01:00:00.000000000",
          at: "12.9",
          sm5: "82.87",
          sm15: "34.65",
          sm25: "62.45",
          sm35: "98.22",
          sm45: "74.14",
          sm55: "82.87",
          st5: "19.7",
          st15: "16.8",
          st25: "20.2",
          st35: "19.2",
          st45: "20.1",
          st55: "19.2",
        },
        {
          time: "2023-08-04 00:00:00.000000000",
          at: "13.5",
          sm5: "83.03",
          sm15: "35.1",
          sm25: "63.24",
          sm35: "98.42",
          sm45: "74.34",
          sm55: "83.03",
          st5: "19.8",
          st15: "17.2",
          st25: "20.2",
          st35: "19.2",
          st45: "20.1",
          st55: "19.2",
        },
        {
          time: "2023-08-03 23:00:00.000000000",
          at: "13.6",
          sm5: "82.78",
          sm15: "35.21",
          sm25: "63.04",
          sm35: "98.27",
          sm45: "73.92",
          sm55: "82.78",
          st5: "20.2",
          st15: "17.6",
          st25: "20.3",
          st35: "19.2",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-03 22:00:00.000000000",
          at: "14.6",
          sm5: "84.6",
          sm15: "36.42",
          sm25: "64.92",
          sm35: "99.85",
          sm45: "75.1",
          sm55: "84.6",
          st5: "20.3",
          st15: "18.2",
          st25: "20.3",
          st35: "19.2",
          st45: "20.1",
          st55: "19.5",
        },
        {
          time: "2023-08-03 21:00:00.000000000",
          at: "15.3",
          sm5: "85.47",
          sm15: "37.82",
          sm25: "66.03",
          sm35: "100.0",
          sm45: "76.08",
          sm55: "85.47",
          st5: "20.6",
          st15: "18.6",
          st25: "20.3",
          st35: "19.2",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-03 20:00:00.000000000",
          at: "15.9",
          sm5: "85.7",
          sm15: "38.21",
          sm25: "66.57",
          sm35: "100.0",
          sm45: "76.44",
          sm55: "85.7",
          st5: "20.8",
          st15: "19.1",
          st25: "20.5",
          st35: "19.2",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-03 19:00:00.000000000",
          at: "17.4",
          sm5: "87.18",
          sm15: "40.1",
          sm25: "67.68",
          sm35: "100.0",
          sm45: "77.86",
          sm55: "87.18",
          st5: "21.0",
          st15: "19.7",
          st25: "20.5",
          st35: "19.1",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-03 18:00:00.000000000",
          at: "21.1",
          sm5: "90.41",
          sm15: "43.7",
          sm25: "72.49",
          sm35: "100.0",
          sm45: "81.12",
          sm55: "90.41",
          st5: "21.1",
          st15: "20.2",
          st25: "20.3",
          st35: "19.1",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-08-03 17:00:00.000000000",
          at: "20.8",
          sm5: "89.99",
          sm15: "43.49",
          sm25: "71.89",
          sm35: "100.0",
          sm45: "80.67",
          sm55: "89.99",
          st5: "21.2",
          st15: "20.7",
          st25: "20.3",
          st35: "19.2",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-03 16:00:00.000000000",
          at: "21.3",
          sm5: "90.48",
          sm15: "44.49",
          sm25: "72.76",
          sm35: "100.0",
          sm45: "81.08",
          sm55: "90.48",
          st5: "21.3",
          st15: "21.3",
          st25: "20.2",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-03 15:00:00.000000000",
          at: "24.1",
          sm5: "93.38",
          sm15: "47.82",
          sm25: "75.71",
          sm35: "100.0",
          sm45: "83.84",
          sm55: "93.38",
          st5: "21.3",
          st15: "21.8",
          st25: "20.2",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-03 14:00:00.000000000",
          at: "25.5",
          sm5: "94.5",
          sm15: "49.47",
          sm25: "77.02",
          sm35: "100.0",
          sm45: "85.02",
          sm55: "94.5",
          st5: "21.2",
          st15: "22.2",
          st25: "20.1",
          st35: "19.1",
          st45: "19.8",
          st55: "19.2",
        },
        {
          time: "2023-08-03 13:00:00.000000000",
          at: "26.5",
          sm5: "95.11",
          sm15: "50.95",
          sm25: "78.11",
          sm35: "100.0",
          sm45: "85.44",
          sm55: "95.11",
          st5: "21.1",
          st15: "22.7",
          st25: "20.0",
          st35: "19.1",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-08-03 12:00:00.000000000",
          at: "27.0",
          sm5: "95.42",
          sm15: "51.75",
          sm25: "79.42",
          sm35: "100.0",
          sm45: "85.91",
          sm55: "95.42",
          st5: "21.0",
          st15: "22.6",
          st25: "19.8",
          st35: "19.2",
          st45: "19.8",
          st55: "19.2",
        },
        {
          time: "2023-08-03 11:00:00.000000000",
          at: "27.4",
          sm5: "95.98",
          sm15: "53.67",
          sm25: "81.28",
          sm35: "100.0",
          sm45: "86.19",
          sm55: "95.98",
          st5: "20.7",
          st15: "22.8",
          st25: "19.7",
          st35: "19.1",
          st45: "19.8",
          st55: "19.2",
        },
        {
          time: "2023-08-03 10:00:00.000000000",
          at: "29.0",
          sm5: "97.22",
          sm15: "56.62",
          sm25: "85.87",
          sm35: "100.0",
          sm45: "87.22",
          sm55: "97.22",
          st5: "20.3",
          st15: "22.8",
          st25: "19.7",
          st35: "19.1",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-08-03 09:00:00.000000000",
          at: "25.5",
          sm5: "94.17",
          sm15: "57.52",
          sm25: "90.55",
          sm35: "100.0",
          sm45: "83.37",
          sm55: "94.17",
          st5: "20.1",
          st15: "21.8",
          st25: "19.6",
          st35: "19.1",
          st45: "19.7",
          st55: "19.2",
        },
        {
          time: "2023-08-03 08:00:00.000000000",
          at: "23.1",
          sm5: "92.04",
          sm15: "64.3",
          sm25: "87.05",
          sm35: "100.0",
          sm45: "80.58",
          sm55: "92.04",
          st5: "19.7",
          st15: "21.6",
          st25: "19.6",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-03 07:00:00.000000000",
          at: "25.9",
          sm5: "94.59",
          sm15: "43.76",
          sm25: "74.94",
          sm35: "100.0",
          sm45: "86.79",
          sm55: "94.59",
          st5: "19.3",
          st15: "20.3",
          st25: "19.6",
          st35: "19.1",
          st45: "19.8",
          st55: "19.3",
        },
        {
          time: "2023-08-03 06:00:00.000000000",
          at: "24.1",
          sm5: "92.85",
          sm15: "42.83",
          sm25: "73.55",
          sm35: "100.0",
          sm45: "85.48",
          sm55: "92.85",
          st5: "19.3",
          st15: "19.6",
          st25: "19.7",
          st35: "19.2",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-08-03 05:00:00.000000000",
          at: "20.7",
          sm5: "89.9",
          sm15: "39.71",
          sm25: "70.04",
          sm35: "100.0",
          sm45: "82.76",
          sm55: "89.9",
          st5: "19.2",
          st15: "18.7",
          st25: "19.7",
          st35: "19.2",
          st45: "20.0",
          st55: "19.3",
        },
        {
          time: "2023-08-03 04:00:00.000000000",
          at: "17.3",
          sm5: "87.05",
          sm15: "37.02",
          sm25: "66.97",
          sm35: "100.0",
          sm45: "79.97",
          sm55: "87.05",
          st5: "19.3",
          st15: "17.6",
          st25: "19.8",
          st35: "19.1",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-08-03 03:00:00.000000000",
          at: "14.3",
          sm5: "83.87",
          sm15: "33.83",
          sm25: "63.53",
          sm35: "99.19",
          sm45: "76.49",
          sm55: "83.87",
          st5: "19.5",
          st15: "17.1",
          st25: "20.0",
          st35: "19.1",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-08-03 02:00:00.000000000",
          at: "13.4",
          sm5: "83.13",
          sm15: "33.29",
          sm25: "62.38",
          sm35: "97.92",
          sm45: "75.88",
          sm55: "83.13",
          st5: "19.7",
          st15: "17.2",
          st25: "20.1",
          st35: "19.1",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-08-03 01:00:00.000000000",
          at: "14.3",
          sm5: "83.79",
          sm15: "34.11",
          sm25: "63.52",
          sm35: "98.89",
          sm45: "76.82",
          sm55: "83.79",
          st5: "20.0",
          st15: "17.7",
          st25: "20.2",
          st35: "19.1",
          st45: "20.1",
          st55: "19.2",
        },
        {
          time: "2023-08-03 00:00:00.000000000",
          at: "15.0",
          sm5: "84.71",
          sm15: "34.97",
          sm25: "64.7",
          sm35: "100.0",
          sm45: "77.46",
          sm55: "84.71",
          st5: "20.1",
          st15: "18.1",
          st25: "20.2",
          st35: "19.1",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-08-02 23:00:00.000000000",
          at: "16.0",
          sm5: "85.46",
          sm15: "36.73",
          sm25: "66.39",
          sm35: "100.0",
          sm45: "78.47",
          sm55: "85.46",
          st5: "20.3",
          st15: "18.5",
          st25: "20.3",
          st35: "19.1",
          st45: "20.1",
          st55: "19.1",
        },
        {
          time: "2023-08-02 22:00:00.000000000",
          at: "16.4",
          sm5: "86.02",
          sm15: "36.99",
          sm25: "66.71",
          sm35: "100.0",
          sm45: "78.53",
          sm55: "86.02",
          st5: "20.5",
          st15: "18.7",
          st25: "20.3",
          st35: "19.1",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-08-02 21:00:00.000000000",
          at: "16.1",
          sm5: "85.92",
          sm15: "36.91",
          sm25: "66.75",
          sm35: "100.0",
          sm45: "78.48",
          sm55: "85.92",
          st5: "20.7",
          st15: "18.8",
          st25: "20.5",
          st35: "19.0",
          st45: "20.1",
          st55: "19.1",
        },
        {
          time: "2023-08-02 20:00:00.000000000",
          at: "16.6",
          sm5: "86.29",
          sm15: "37.63",
          sm25: "67.69",
          sm35: "100.0",
          sm45: "79.12",
          sm55: "86.29",
          st5: "20.8",
          st15: "19.2",
          st25: "20.5",
          st35: "19.0",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-08-02 19:00:00.000000000",
          at: "16.2",
          sm5: "85.88",
          sm15: "36.98",
          sm25: "66.58",
          sm35: "100.0",
          sm45: "78.49",
          sm55: "85.88",
          st5: "21.1",
          st15: "19.6",
          st25: "20.5",
          st35: "19.0",
          st45: "20.0",
          st55: "19.1",
        },
        {
          time: "2023-08-02 18:00:00.000000000",
          at: "17.3",
          sm5: "86.59",
          sm15: "38.48",
          sm25: "68.37",
          sm35: "100.0",
          sm45: "79.47",
          sm55: "86.59",
          st5: "21.3",
          st15: "20.2",
          st25: "20.5",
          st35: "19.0",
          st45: "19.8",
          st55: "19.1",
        },
        {
          time: "2023-08-02 17:00:00.000000000",
          at: "18.6",
          sm5: "87.85",
          sm15: "40.38",
          sm25: "69.93",
          sm35: "100.0",
          sm45: "80.4",
          sm55: "87.85",
          st5: "21.5",
          st15: "20.8",
          st25: "20.3",
          st35: "19.0",
          st45: "19.8",
          st55: "19.1",
        },
        {
          time: "2023-08-02 16:00:00.000000000",
          at: "21.0",
          sm5: "90.0",
          sm15: "42.8",
          sm25: "72.62",
          sm35: "100.0",
          sm45: "82.64",
          sm55: "90.0",
          st5: "21.5",
          st15: "21.6",
          st25: "20.3",
          st35: "19.0",
          st45: "19.7",
          st55: "19.1",
        },
        {
          time: "2023-08-02 15:00:00.000000000",
          at: "24.1",
          sm5: "92.53",
          sm15: "46.12",
          sm25: "75.94",
          sm35: "100.0",
          sm45: "84.71",
          sm55: "92.53",
          st5: "21.5",
          st15: "22.0",
          st25: "20.2",
          st35: "19.0",
          st45: "19.7",
          st55: "19.1",
        },
        {
          time: "2023-08-02 14:00:00.000000000",
          at: "25.1",
          sm5: "93.25",
          sm15: "47.88",
          sm25: "78.13",
          sm35: "100.0",
          sm45: "85.54",
          sm55: "93.25",
          st5: "21.5",
          st15: "22.5",
          st25: "20.0",
          st35: "19.0",
          st45: "19.6",
          st55: "19.1",
        },
        {
          time: "2023-08-02 13:00:00.000000000",
          at: "26.5",
          sm5: "93.97",
          sm15: "49.75",
          sm25: "80.0",
          sm35: "100.0",
          sm45: "86.36",
          sm55: "93.97",
          st5: "21.3",
          st15: "23.0",
          st25: "19.8",
          st35: "18.8",
          st45: "19.6",
          st55: "19.1",
        },
        {
          time: "2023-08-02 12:00:00.000000000",
          at: "26.5",
          sm5: "94.55",
          sm15: "51.82",
          sm25: "83.16",
          sm35: "100.0",
          sm45: "86.34",
          sm55: "94.55",
          st5: "21.2",
          st15: "23.0",
          st25: "19.7",
          st35: "18.8",
          st45: "19.6",
          st55: "19.1",
        },
        {
          time: "2023-08-02 11:00:00.000000000",
          at: "24.1",
          sm5: "92.3",
          sm15: "53.8",
          sm25: "89.44",
          sm35: "100.0",
          sm45: "82.4",
          sm55: "92.3",
          st5: "21.0",
          st15: "23.1",
          st25: "19.6",
          st35: "19.0",
          st45: "19.6",
          st55: "19.1",
        },
        {
          time: "2023-08-02 10:00:00.000000000",
          at: "30.0",
          sm5: "97.56",
          sm15: "43.53",
          sm25: "77.69",
          sm35: "100.0",
          sm45: "87.55",
          sm55: "97.56",
          st5: "20.3",
          st15: "23.3",
          st25: "19.5",
          st35: "19.0",
          st45: "19.6",
          st55: "19.1",
        },
        {
          time: "2023-08-02 09:00:00.000000000",
          at: "28.3",
          sm5: "95.91",
          sm15: "42.27",
          sm25: "75.77",
          sm35: "100.0",
          sm45: "85.99",
          sm55: "95.91",
          st5: "20.0",
          st15: "22.3",
          st25: "19.3",
          st35: "18.8",
          st45: "19.5",
          st55: "19.1",
        },
        {
          time: "2023-08-02 08:00:00.000000000",
          at: "27.5",
          sm5: "95.48",
          sm15: "41.85",
          sm25: "74.99",
          sm35: "100.0",
          sm45: "85.4",
          sm55: "95.48",
          st5: "19.6",
          st15: "21.8",
          st25: "19.5",
          st35: "19.0",
          st45: "19.6",
          st55: "19.2",
        },
        {
          time: "2023-08-02 07:00:00.000000000",
          at: "25.9",
          sm5: "94.24",
          sm15: "41.01",
          sm25: "73.88",
          sm35: "100.0",
          sm45: "84.5",
          sm55: "94.24",
          st5: "19.3",
          st15: "20.7",
          st25: "19.3",
          st35: "18.8",
          st45: "19.6",
          st55: "19.1",
        },
        {
          time: "2023-08-02 06:00:00.000000000",
          at: "23.8",
          sm5: "92.6",
          sm15: "39.5",
          sm25: "72.28",
          sm35: "100.0",
          sm45: "82.87",
          sm55: "92.6",
          st5: "19.2",
          st15: "19.8",
          st25: "19.5",
          st35: "19.0",
          st45: "19.7",
          st55: "19.1",
        },
        {
          time: "2023-08-02 05:00:00.000000000",
          at: "21.0",
          sm5: "90.34",
          sm15: "37.2",
          sm25: "69.71",
          sm35: "100.0",
          sm45: "80.44",
          sm55: "90.34",
          st5: "19.2",
          st15: "18.7",
          st25: "19.6",
          st35: "19.0",
          st45: "19.6",
          st55: "19.1",
        },
        {
          time: "2023-08-02 04:00:00.000000000",
          at: "17.8",
          sm5: "87.29",
          sm15: "34.33",
          sm25: "66.32",
          sm35: "100.0",
          sm45: "77.69",
          sm55: "87.29",
          st5: "19.2",
          st15: "17.7",
          st25: "19.6",
          st35: "19.0",
          st45: "19.7",
          st55: "19.1",
        },
        {
          time: "2023-08-02 03:00:00.000000000",
          at: "14.8",
          sm5: "83.69",
          sm15: "31.08",
          sm25: "62.4",
          sm35: "99.31",
          sm45: "74.75",
          sm55: "83.69",
          st5: "19.3",
          st15: "17.3",
          st25: "19.7",
          st35: "18.8",
          st45: "19.7",
          st55: "19.1",
        },
        {
          time: "2023-08-02 02:00:00.000000000",
          at: "14.3",
          sm5: "83.38",
          sm15: "30.53",
          sm25: "61.81",
          sm35: "98.58",
          sm45: "73.93",
          sm55: "83.38",
          st5: "19.6",
          st15: "17.5",
          st25: "19.7",
          st35: "18.8",
          st45: "19.7",
          st55: "19.1",
        },
        {
          time: "2023-08-02 01:00:00.000000000",
          at: "15.6",
          sm5: "84.47",
          sm15: "31.71",
          sm25: "62.82",
          sm35: "99.78",
          sm45: "74.93",
          sm55: "84.47",
          st5: "19.7",
          st15: "17.8",
          st25: "19.8",
          st35: "18.8",
          st45: "19.6",
          st55: "19.0",
        },
        {
          time: "2023-08-02 00:00:00.000000000",
          at: "16.5",
          sm5: "85.56",
          sm15: "32.88",
          sm25: "63.58",
          sm35: "100.0",
          sm45: "75.74",
          sm55: "85.56",
          st5: "19.8",
          st15: "18.2",
          st25: "20.0",
          st35: "18.8",
          st45: "19.6",
          st55: "19.1",
        },
        {
          time: "2023-08-01 23:00:00.000000000",
          at: "17.8",
          sm5: "87.03",
          sm15: "34.6",
          sm25: "65.02",
          sm35: "100.0",
          sm45: "77.13",
          sm55: "87.03",
          st5: "20.1",
          st15: "18.3",
          st25: "20.0",
          st35: "18.8",
          st45: "19.7",
          st55: "19.0",
        },
        {
          time: "2023-08-01 22:00:00.000000000",
          at: "16.6",
          sm5: "85.56",
          sm15: "33.44",
          sm25: "63.77",
          sm35: "100.0",
          sm45: "76.33",
          sm55: "85.56",
          st5: "20.3",
          st15: "18.6",
          st25: "20.0",
          st35: "18.8",
          st45: "19.6",
          st55: "19.0",
        },
        {
          time: "2023-08-01 21:00:00.000000000",
          at: "16.7",
          sm5: "85.38",
          sm15: "33.8",
          sm25: "63.08",
          sm35: "100.0",
          sm45: "76.22",
          sm55: "85.38",
          st5: "20.5",
          st15: "19.0",
          st25: "20.0",
          st35: "18.7",
          st45: "19.5",
          st55: "19.0",
        },
        {
          time: "2023-08-01 20:00:00.000000000",
          at: "17.3",
          sm5: "86.08",
          sm15: "34.35",
          sm25: "63.56",
          sm35: "100.0",
          sm45: "76.94",
          sm55: "86.08",
          st5: "20.6",
          st15: "19.5",
          st25: "20.0",
          st35: "18.7",
          st45: "19.5",
          st55: "19.1",
        },
        {
          time: "2023-08-01 19:00:00.000000000",
          at: "21.9",
          sm5: "90.1",
          sm15: "37.56",
          sm25: "67.82",
          sm35: "100.0",
          sm45: "80.57",
          sm55: "90.1",
          st5: "20.8",
          st15: "20.5",
          st25: "19.8",
          st35: "18.7",
          st45: "19.5",
          st55: "19.0",
        },
        {
          time: "2023-08-01 18:00:00.000000000",
          at: "23.0",
          sm5: "91.4",
          sm15: "38.81",
          sm25: "69.32",
          sm35: "100.0",
          sm45: "81.74",
          sm55: "91.4",
          st5: "20.8",
          st15: "20.8",
          st25: "19.8",
          st35: "18.7",
          st45: "19.3",
          st55: "19.1",
        },
        {
          time: "2023-08-01 17:00:00.000000000",
          at: "22.1",
          sm5: "90.76",
          sm15: "38.64",
          sm25: "67.67",
          sm35: "100.0",
          sm45: "80.9",
          sm55: "90.76",
          st5: "21.0",
          st15: "21.2",
          st25: "19.7",
          st35: "18.7",
          st45: "19.3",
          st55: "19.0",
        },
        {
          time: "2023-08-01 16:00:00.000000000",
          at: "23.3",
          sm5: "91.8",
          sm15: "39.99",
          sm25: "68.64",
          sm35: "100.0",
          sm45: "81.63",
          sm55: "91.8",
          st5: "21.0",
          st15: "21.7",
          st25: "19.6",
          st35: "18.7",
          st45: "19.2",
          st55: "19.1",
        },
        {
          time: "2023-08-01 15:00:00.000000000",
          at: "23.9",
          sm5: "91.88",
          sm15: "41.24",
          sm25: "69.53",
          sm35: "100.0",
          sm45: "82.17",
          sm55: "91.88",
          st5: "20.8",
          st15: "22.1",
          st25: "19.5",
          st35: "18.7",
          st45: "19.1",
          st55: "19.1",
        },
        {
          time: "2023-08-01 14:00:00.000000000",
          at: "25.7",
          sm5: "93.23",
          sm15: "43.4",
          sm25: "71.44",
          sm35: "100.0",
          sm45: "83.42",
          sm55: "93.23",
          st5: "20.7",
          st15: "22.5",
          st25: "19.3",
          st35: "18.7",
          st45: "19.2",
          st55: "19.1",
        },
        {
          time: "2023-08-01 13:00:00.000000000",
          at: "23.5",
          sm5: "91.45",
          sm15: "43.16",
          sm25: "69.53",
          sm35: "100.0",
          sm45: "82.0",
          sm55: "91.45",
          st5: "20.6",
          st15: "22.6",
          st25: "19.1",
          st35: "18.7",
          st45: "19.2",
          st55: "19.1",
        },
        {
          time: "2023-08-01 12:00:00.000000000",
          at: "26.9",
          sm5: "94.45",
          sm15: "50.83",
          sm25: "82.93",
          sm35: "100.0",
          sm45: "82.49",
          sm55: "94.45",
          st5: "20.5",
          st15: "23.0",
          st25: "19.1",
          st35: "18.7",
          st45: "19.1",
          st55: "17.6",
        },
        {
          time: "2023-08-01 11:00:00.000000000",
          at: "28.5",
          sm5: "95.7",
          sm15: "32.17",
          sm25: "72.27",
          sm35: "100.0",
          sm45: "86.48",
          sm55: "95.7",
          st5: "19.8",
          st15: "23.2",
          st25: "18.8",
          st35: "18.7",
          st45: "19.1",
          st55: "19.1",
        },
        {
          time: "2023-08-01 10:00:00.000000000",
          at: "31.5",
          sm5: "98.48",
          sm15: "34.79",
          sm25: "74.91",
          sm35: "100.0",
          sm45: "88.99",
          sm55: "98.48",
          st5: "19.3",
          st15: "23.3",
          st25: "18.7",
          st35: "18.7",
          st45: "19.1",
          st55: "19.1",
        },
        {
          time: "2023-08-01 09:00:00.000000000",
          at: "30.5",
          sm5: "97.61",
          sm15: "34.71",
          sm25: "74.46",
          sm35: "100.0",
          sm45: "88.48",
          sm55: "97.61",
          st5: "18.8",
          st15: "22.2",
          st25: "18.7",
          st35: "18.7",
          st45: "19.2",
          st55: "19.1",
        },
        {
          time: "2023-08-01 08:00:00.000000000",
          at: "29.1",
          sm5: "96.28",
          sm15: "33.94",
          sm25: "73.18",
          sm35: "100.0",
          sm45: "87.26",
          sm55: "96.28",
          st5: "18.5",
          st15: "21.5",
          st25: "18.7",
          st35: "18.8",
          st45: "19.2",
          st55: "19.1",
        },
        {
          time: "2023-08-01 07:00:00.000000000",
          at: "27.0",
          sm5: "94.62",
          sm15: "31.99",
          sm25: "71.3",
          sm35: "100.0",
          sm45: "85.78",
          sm55: "94.62",
          st5: "18.1",
          st15: "19.8",
          st25: "18.7",
          st35: "18.8",
          st45: "19.2",
          st55: "19.1",
        },
        {
          time: "2023-08-01 06:00:00.000000000",
          at: "24.2",
          sm5: "92.31",
          sm15: "28.59",
          sm25: "68.62",
          sm35: "100.0",
          sm45: "83.6",
          sm55: "92.31",
          st5: "17.8",
          st15: "19.0",
          st25: "18.7",
          st35: "18.8",
          st45: "19.3",
          st55: "19.1",
        },
        {
          time: "2023-08-01 05:00:00.000000000",
          at: "20.0",
          sm5: "89.12",
          sm15: "23.93",
          sm25: "65.23",
          sm35: "100.0",
          sm45: "80.67",
          sm55: "89.12",
          st5: "17.8",
          st15: "17.1",
          st25: "18.8",
          st35: "18.8",
          st45: "19.3",
          st55: "19.1",
        },
        {
          time: "2023-08-01 04:00:00.000000000",
          at: "14.8",
          sm5: "83.82",
          sm15: "18.14",
          sm25: "59.0",
          sm35: "99.01",
          sm45: "75.49",
          sm55: "83.82",
          st5: "17.8",
          st15: "15.6",
          st25: "19.0",
          st35: "18.8",
          st45: "19.5",
          st55: "19.1",
        },
        {
          time: "2023-08-01 03:00:00.000000000",
          at: "11.0",
          sm5: "79.96",
          sm15: "14.2",
          sm25: "54.62",
          sm35: "94.74",
          sm45: "71.42",
          sm55: "79.96",
          st5: "18.1",
          st15: "14.8",
          st25: "19.2",
          st35: "18.8",
          st45: "19.3",
          st55: "19.2",
        },
        {
          time: "2023-08-01 02:00:00.000000000",
          at: "10.0",
          sm5: "78.54",
          sm15: "13.25",
          sm25: "53.2",
          sm35: "93.47",
          sm45: "70.73",
          sm55: "78.54",
          st5: "18.3",
          st15: "14.8",
          st25: "19.2",
          st35: "18.8",
          st45: "19.5",
          st55: "19.1",
        },
        {
          time: "2023-08-01 01:00:00.000000000",
          at: "10.4",
          sm5: "79.0",
          sm15: "13.45",
          sm25: "53.51",
          sm35: "93.62",
          sm45: "71.0",
          sm55: "79.0",
          st5: "18.7",
          st15: "15.2",
          st25: "19.3",
          st35: "18.7",
          st45: "19.5",
          st55: "19.1",
        },
        {
          time: "2023-08-01 00:00:00.000000000",
          at: "11.2",
          sm5: "80.03",
          sm15: "14.4",
          sm25: "54.77",
          sm35: "95.02",
          sm45: "71.92",
          sm55: "80.03",
          st5: "19.0",
          st15: "15.7",
          st25: "19.5",
          st35: "18.8",
          st45: "19.5",
          st55: "19.1",
        },
        {
          time: "2023-07-31 23:00:00.000000000",
          at: "12.1",
          sm5: "80.55",
          sm15: "15.43",
          sm25: "56.0",
          sm35: "95.69",
          sm45: "72.53",
          sm55: "80.55",
          st5: "19.2",
          st15: "16.2",
          st25: "19.6",
          st35: "18.8",
          st45: "19.5",
          st55: "19.1",
        },
        {
          time: "2023-07-31 22:00:00.000000000",
          at: "13.0",
          sm5: "81.8",
          sm15: "16.45",
          sm25: "57.6",
          sm35: "97.24",
          sm45: "73.81",
          sm55: "81.8",
          st5: "19.5",
          st15: "16.7",
          st25: "19.6",
          st35: "18.8",
          st45: "19.5",
          st55: "19.1",
        },
        {
          time: "2023-07-31 21:00:00.000000000",
          at: "13.6",
          sm5: "82.17",
          sm15: "16.68",
          sm25: "57.83",
          sm35: "97.28",
          sm45: "73.53",
          sm55: "82.17",
          st5: "19.7",
          st15: "17.1",
          st25: "19.6",
          st35: "18.7",
          st45: "19.5",
          st55: "19.1",
        },
        {
          time: "2023-07-31 20:00:00.000000000",
          at: "14.0",
          sm5: "82.46",
          sm15: "17.47",
          sm25: "58.29",
          sm35: "97.54",
          sm45: "74.16",
          sm55: "82.46",
          st5: "20.0",
          st15: "17.6",
          st25: "19.7",
          st35: "18.7",
          st45: "19.5",
          st55: "19.1",
        },
        {
          time: "2023-07-31 19:00:00.000000000",
          at: "14.6",
          sm5: "83.11",
          sm15: "18.66",
          sm25: "59.3",
          sm35: "98.06",
          sm45: "74.49",
          sm55: "83.11",
          st5: "20.2",
          st15: "18.2",
          st25: "19.6",
          st35: "18.7",
          st45: "19.3",
          st55: "19.1",
        },
        {
          time: "2023-07-31 18:00:00.000000000",
          at: "16.5",
          sm5: "84.8",
          sm15: "21.17",
          sm25: "61.26",
          sm35: "100.0",
          sm45: "76.0",
          sm55: "84.8",
          st5: "20.3",
          st15: "19.2",
          st25: "19.6",
          st35: "18.7",
          st45: "19.3",
          st55: "19.1",
        },
        {
          time: "2023-07-31 17:00:00.000000000",
          at: "17.9",
          sm5: "86.19",
          sm15: "23.15",
          sm25: "63.09",
          sm35: "100.0",
          sm45: "77.64",
          sm55: "86.19",
          st5: "20.5",
          st15: "20.0",
          st25: "19.5",
          st35: "18.7",
          st45: "19.3",
          st55: "19.1",
        },
        {
          time: "2023-07-31 16:00:00.000000000",
          at: "21.1",
          sm5: "89.28",
          sm15: "27.13",
          sm25: "66.64",
          sm35: "100.0",
          sm45: "80.36",
          sm55: "89.28",
          st5: "20.5",
          st15: "21.0",
          st25: "19.5",
          st35: "18.7",
          st45: "19.2",
          st55: "19.1",
        },
        {
          time: "2023-07-31 15:00:00.000000000",
          at: "23.8",
          sm5: "91.46",
          sm15: "30.27",
          sm25: "69.28",
          sm35: "100.0",
          sm45: "82.74",
          sm55: "91.46",
          st5: "20.5",
          st15: "21.7",
          st25: "19.2",
          st35: "18.7",
          st45: "19.2",
          st55: "19.1",
        },
        {
          time: "2023-07-31 14:00:00.000000000",
          at: "26.1",
          sm5: "94.29",
          sm15: "32.46",
          sm25: "71.72",
          sm35: "100.0",
          sm45: "84.88",
          sm55: "94.29",
          st5: "20.3",
          st15: "22.1",
          st25: "19.1",
          st35: "18.7",
          st45: "19.1",
          st55: "19.1",
        },
        {
          time: "2023-07-31 13:00:00.000000000",
          at: "27.6",
          sm5: "95.12",
          sm15: "33.95",
          sm25: "73.11",
          sm35: "100.0",
          sm45: "85.93",
          sm55: "95.12",
          st5: "20.1",
          st15: "22.6",
          st25: "19.0",
          st35: "18.7",
          st45: "19.1",
          st55: "19.1",
        },
        {
          time: "2023-07-31 12:00:00.000000000",
          at: "28.0",
          sm5: "95.3",
          sm15: "34.41",
          sm25: "73.59",
          sm35: "100.0",
          sm45: "86.02",
          sm55: "95.3",
          st5: "19.8",
          st15: "22.5",
          st25: "19.0",
          st35: "18.7",
          st45: "19.1",
          st55: "19.1",
        },
        {
          time: "2023-07-31 11:00:00.000000000",
          at: "28.5",
          sm5: "95.66",
          sm15: "34.74",
          sm25: "74.17",
          sm35: "100.0",
          sm45: "86.68",
          sm55: "95.66",
          st5: "19.3",
          st15: "22.6",
          st25: "18.7",
          st35: "18.8",
          st45: "19.1",
          st55: "19.2",
        },
        {
          time: "2023-07-31 10:00:00.000000000",
          at: "31.4",
          sm5: "98.17",
          sm15: "36.69",
          sm25: "76.2",
          sm35: "100.0",
          sm45: "89.2",
          sm55: "98.17",
          st5: "19.0",
          st15: "22.6",
          st25: "18.7",
          st35: "18.8",
          st45: "19.2",
          st55: "19.1",
        },
        {
          time: "2023-07-31 09:00:00.000000000",
          at: "28.4",
          sm5: "96.16",
          sm15: "33.76",
          sm25: "74.15",
          sm35: "100.0",
          sm45: "87.43",
          sm55: "96.16",
          st5: "18.5",
          st15: "21.0",
          st25: "18.6",
          st35: "18.8",
          st45: "19.2",
          st55: "19.2",
        },
        {
          time: "2023-07-31 08:00:00.000000000",
          at: "26.8",
          sm5: "94.97",
          sm15: "32.11",
          sm25: "72.76",
          sm35: "100.0",
          sm45: "86.19",
          sm55: "94.97",
          st5: "18.2",
          st15: "20.1",
          st25: "18.7",
          st35: "18.8",
          st45: "19.2",
          st55: "19.2",
        },
        {
          time: "2023-07-31 07:00:00.000000000",
          at: "24.3",
          sm5: "92.5",
          sm15: "29.11",
          sm25: "69.96",
          sm35: "100.0",
          sm45: "84.12",
          sm55: "92.5",
          st5: "18.0",
          st15: "18.8",
          st25: "18.6",
          st35: "19.0",
          st45: "19.2",
          st55: "19.2",
        },
        {
          time: "2023-07-31 06:00:00.000000000",
          at: "21.9",
          sm5: "90.63",
          sm15: "26.99",
          sm25: "67.83",
          sm35: "100.0",
          sm45: "82.2",
          sm55: "90.63",
          st5: "17.8",
          st15: "18.1",
          st25: "18.7",
          st35: "19.0",
          st45: "19.3",
          st55: "19.2",
        },
        {
          time: "2023-07-31 05:00:00.000000000",
          at: "18.8",
          sm5: "87.2",
          sm15: "25.08",
          sm25: "64.3",
          sm35: "100.0",
          sm45: "79.0",
          sm55: "87.2",
          st5: "17.8",
          st15: "16.8",
          st25: "18.8",
          st35: "18.8",
          st45: "19.3",
          st55: "19.2",
        },
        {
          time: "2023-07-31 04:00:00.000000000",
          at: "15.6",
          sm5: "84.85",
          sm15: "23.1",
          sm25: "61.58",
          sm35: "100.0",
          sm45: "76.27",
          sm55: "84.85",
          st5: "17.8",
          st15: "15.8",
          st25: "18.8",
          st35: "18.8",
          st45: "19.3",
          st55: "19.2",
        },
        {
          time: "2023-07-31 03:00:00.000000000",
          at: "11.6",
          sm5: "81.0",
          sm15: "19.54",
          sm25: "56.75",
          sm35: "95.33",
          sm45: "72.56",
          sm55: "81.0",
          st5: "18.0",
          st15: "15.1",
          st25: "19.0",
          st35: "19.0",
          st45: "19.5",
          st55: "19.2",
        },
        {
          time: "2023-07-31 02:00:00.000000000",
          at: "11.8",
          sm5: "81.16",
          sm15: "19.63",
          sm25: "57.35",
          sm35: "95.9",
          sm45: "72.69",
          sm55: "81.16",
          st5: "18.2",
          st15: "15.3",
          st25: "19.2",
          st35: "18.8",
          st45: "19.5",
          st55: "19.2",
        },
        {
          time: "2023-07-31 01:00:00.000000000",
          at: "12.1",
          sm5: "81.01",
          sm15: "19.77",
          sm25: "57.49",
          sm35: "96.43",
          sm45: "72.85",
          sm55: "81.01",
          st5: "18.5",
          st15: "15.5",
          st25: "19.2",
          st35: "19.0",
          st45: "19.5",
          st55: "19.2",
        },
        {
          time: "2023-07-31 00:00:00.000000000",
          at: "11.2",
          sm5: "79.97",
          sm15: "18.68",
          sm25: "56.12",
          sm35: "95.12",
          sm45: "71.79",
          sm55: "79.97",
          st5: "18.6",
          st15: "15.6",
          st25: "19.5",
          st35: "19.0",
          st45: "19.5",
          st55: "19.2",
        },
        {
          time: "2023-07-30 23:00:00.000000000",
          at: "11.6",
          sm5: "80.37",
          sm15: "19.06",
          sm25: "56.41",
          sm35: "94.63",
          sm45: "72.13",
          sm55: "80.37",
          st5: "19.0",
          st15: "15.8",
          st25: "19.5",
          st35: "19.0",
          st45: "19.5",
          st55: "19.2",
        },
        {
          time: "2023-07-30 22:00:00.000000000",
          at: "12.3",
          sm5: "80.76",
          sm15: "19.25",
          sm25: "57.17",
          sm35: "95.94",
          sm45: "72.58",
          sm55: "80.76",
          st5: "19.1",
          st15: "16.3",
          st25: "19.5",
          st35: "18.8",
          st45: "19.5",
          st55: "19.2",
        },
        {
          time: "2023-07-30 21:00:00.000000000",
          at: "12.8",
          sm5: "81.2",
          sm15: "19.88",
          sm25: "58.09",
          sm35: "96.66",
          sm45: "72.95",
          sm55: "81.2",
          st5: "19.3",
          st15: "16.6",
          st25: "19.6",
          st35: "18.8",
          st45: "19.6",
          st55: "19.2",
        },
        {
          time: "2023-07-30 20:00:00.000000000",
          at: "13.0",
          sm5: "81.97",
          sm15: "20.46",
          sm25: "58.55",
          sm35: "97.03",
          sm45: "73.45",
          sm55: "81.97",
          st5: "19.7",
          st15: "17.0",
          st25: "19.6",
          st35: "18.8",
          st45: "19.5",
          st55: "19.2",
        },
        {
          time: "2023-07-30 19:00:00.000000000",
          at: "13.6",
          sm5: "82.04",
          sm15: "20.04",
          sm25: "58.78",
          sm35: "97.68",
          sm45: "73.51",
          sm55: "82.04",
          st5: "19.8",
          st15: "17.6",
          st25: "19.6",
          st35: "18.8",
          st45: "19.5",
          st55: "19.2",
        },
        {
          time: "2023-07-30 18:00:00.000000000",
          at: "14.7",
          sm5: "82.77",
          sm15: "20.71",
          sm25: "60.12",
          sm35: "98.44",
          sm45: "74.21",
          sm55: "82.77",
          st5: "20.1",
          st15: "18.2",
          st25: "19.6",
          st35: "18.8",
          st45: "19.3",
          st55: "19.2",
        },
        {
          time: "2023-07-30 17:00:00.000000000",
          at: "16.6",
          sm5: "85.41",
          sm15: "22.71",
          sm25: "63.08",
          sm35: "100.0",
          sm45: "76.25",
          sm55: "85.41",
          st5: "20.2",
          st15: "19.2",
          st25: "19.5",
          st35: "18.8",
          st45: "19.3",
          st55: "19.2",
        },
        {
          time: "2023-07-30 16:00:00.000000000",
          at: "20.1",
          sm5: "88.16",
          sm15: "25.93",
          sm25: "66.73",
          sm35: "100.0",
          sm45: "79.28",
          sm55: "88.16",
          st5: "20.3",
          st15: "20.3",
          st25: "19.5",
          st35: "18.8",
          st45: "19.3",
          st55: "19.2",
        },
        {
          time: "2023-07-30 15:00:00.000000000",
          at: "22.6",
          sm5: "90.89",
          sm15: "28.61",
          sm25: "69.4",
          sm35: "100.0",
          sm45: "81.71",
          sm55: "90.89",
          st5: "20.2",
          st15: "21.0",
          st25: "19.3",
          st35: "18.8",
          st45: "19.3",
          st55: "19.2",
        },
        {
          time: "2023-07-30 14:00:00.000000000",
          at: "24.8",
          sm5: "92.69",
          sm15: "31.0",
          sm25: "71.8",
          sm35: "100.0",
          sm45: "83.4",
          sm55: "92.69",
          st5: "20.1",
          st15: "21.5",
          st25: "19.2",
          st35: "19.0",
          st45: "19.3",
          st55: "19.3",
        },
        {
          time: "2023-07-30 13:00:00.000000000",
          at: "26.2",
          sm5: "94.31",
          sm15: "32.48",
          sm25: "73.28",
          sm35: "100.0",
          sm45: "84.92",
          sm55: "94.31",
          st5: "20.0",
          st15: "21.8",
          st25: "19.0",
          st35: "18.8",
          st45: "19.2",
          st55: "19.3",
        },
        {
          time: "2023-07-30 12:00:00.000000000",
          at: "26.6",
          sm5: "94.45",
          sm15: "33.07",
          sm25: "73.68",
          sm35: "100.0",
          sm45: "85.32",
          sm55: "94.45",
          st5: "19.7",
          st15: "21.8",
          st25: "18.8",
          st35: "19.0",
          st45: "19.2",
          st55: "19.3",
        },
        {
          time: "2023-07-30 11:00:00.000000000",
          at: "27.1",
          sm5: "94.62",
          sm15: "33.45",
          sm25: "73.6",
          sm35: "100.0",
          sm45: "85.26",
          sm55: "94.62",
          st5: "19.3",
          st15: "22.1",
          st25: "18.8",
          st35: "19.0",
          st45: "19.2",
          st55: "19.3",
        },
        {
          time: "2023-07-30 10:00:00.000000000",
          at: "30.0",
          sm5: "97.13",
          sm15: "36.05",
          sm25: "76.69",
          sm35: "100.0",
          sm45: "88.13",
          sm55: "97.13",
          st5: "19.0",
          st15: "22.2",
          st25: "18.7",
          st35: "19.0",
          st45: "19.3",
          st55: "19.2",
        },
        {
          time: "2023-07-30 09:00:00.000000000",
          at: "27.5",
          sm5: "95.09",
          sm15: "33.61",
          sm25: "73.68",
          sm35: "100.0",
          sm45: "86.1",
          sm55: "95.09",
          st5: "18.5",
          st15: "20.8",
          st25: "18.7",
          st35: "19.0",
          st45: "19.3",
          st55: "19.3",
        },
        {
          time: "2023-07-30 08:00:00.000000000",
          at: "25.9",
          sm5: "93.86",
          sm15: "32.5",
          sm25: "72.47",
          sm35: "100.0",
          sm45: "85.42",
          sm55: "93.86",
          st5: "18.1",
          st15: "19.7",
          st25: "18.7",
          st35: "19.1",
          st45: "19.5",
          st55: "19.3",
        },
        {
          time: "2023-07-30 07:00:00.000000000",
          at: "23.7",
          sm5: "92.12",
          sm15: "30.65",
          sm25: "70.65",
          sm35: "100.0",
          sm45: "83.87",
          sm55: "92.12",
          st5: "17.8",
          st15: "18.6",
          st25: "18.7",
          st35: "19.1",
          st45: "19.5",
          st55: "19.3",
        },
        {
          time: "2023-07-30 06:00:00.000000000",
          at: "22.0",
          sm5: "90.31",
          sm15: "28.89",
          sm25: "68.61",
          sm35: "100.0",
          sm45: "82.06",
          sm55: "90.31",
          st5: "17.7",
          st15: "17.8",
          st25: "18.8",
          st35: "19.1",
          st45: "19.5",
          st55: "19.3",
        },
        {
          time: "2023-07-30 05:00:00.000000000",
          at: "18.5",
          sm5: "87.85",
          sm15: "26.35",
          sm25: "65.33",
          sm35: "100.0",
          sm45: "79.71",
          sm55: "87.85",
          st5: "17.7",
          st15: "16.5",
          st25: "19.0",
          st35: "19.2",
          st45: "19.6",
          st55: "19.3",
        },
        {
          time: "2023-07-30 04:00:00.000000000",
          at: "14.7",
          sm5: "84.02",
          sm15: "23.52",
          sm25: "60.91",
          sm35: "99.38",
          sm45: "75.86",
          sm55: "84.02",
          st5: "17.8",
          st15: "15.5",
          st25: "19.1",
          st35: "19.1",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-07-30 03:00:00.000000000",
          at: "10.6",
          sm5: "79.45",
          sm15: "20.19",
          sm25: "56.13",
          sm35: "94.52",
          sm45: "71.49",
          sm55: "79.45",
          st5: "18.1",
          st15: "14.6",
          st25: "19.2",
          st35: "19.1",
          st45: "19.6",
          st55: "19.3",
        },
        {
          time: "2023-07-30 02:00:00.000000000",
          at: "10.1",
          sm5: "78.81",
          sm15: "19.78",
          sm25: "55.64",
          sm35: "93.63",
          sm45: "70.31",
          sm55: "78.81",
          st5: "18.3",
          st15: "14.7",
          st25: "19.3",
          st35: "19.1",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-07-30 01:00:00.000000000",
          at: "10.0",
          sm5: "78.98",
          sm15: "19.25",
          sm25: "55.87",
          sm35: "93.8",
          sm45: "70.68",
          sm55: "78.98",
          st5: "18.6",
          st15: "15.0",
          st25: "19.5",
          st35: "19.2",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-07-30 00:00:00.000000000",
          at: "10.4",
          sm5: "79.28",
          sm15: "19.65",
          sm25: "56.35",
          sm35: "94.37",
          sm45: "71.14",
          sm55: "79.28",
          st5: "18.8",
          st15: "15.3",
          st25: "19.5",
          st35: "19.1",
          st45: "19.7",
          st55: "19.5",
        },
        {
          time: "2023-07-29 23:00:00.000000000",
          at: "11.4",
          sm5: "80.62",
          sm15: "20.24",
          sm25: "57.29",
          sm35: "95.43",
          sm45: "71.71",
          sm55: "80.62",
          st5: "19.1",
          st15: "15.8",
          st25: "19.7",
          st35: "19.1",
          st45: "19.7",
          st55: "19.3",
        },
        {
          time: "2023-07-29 22:00:00.000000000",
          at: "12.0",
          sm5: "81.25",
          sm15: "20.98",
          sm25: "58.49",
          sm35: "96.53",
          sm45: "72.71",
          sm55: "81.25",
          st5: "19.3",
          st15: "16.3",
          st25: "19.7",
          st35: "19.1",
          st45: "19.7",
          st55: "19.5",
        },
        {
          time: "2023-07-29 21:00:00.000000000",
          at: "12.9",
          sm5: "81.93",
          sm15: "21.61",
          sm25: "59.45",
          sm35: "97.33",
          sm45: "73.09",
          sm55: "81.93",
          st5: "19.6",
          st15: "17.0",
          st25: "19.7",
          st35: "19.0",
          st45: "19.7",
          st55: "19.5",
        },
        {
          time: "2023-07-29 20:00:00.000000000",
          at: "14.4",
          sm5: "83.88",
          sm15: "22.39",
          sm25: "61.42",
          sm35: "98.92",
          sm45: "74.86",
          sm55: "83.88",
          st5: "19.8",
          st15: "17.6",
          st25: "19.7",
          st35: "19.1",
          st45: "19.7",
          st55: "19.5",
        },
        {
          time: "2023-07-29 19:00:00.000000000",
          at: "14.8",
          sm5: "83.92",
          sm15: "22.74",
          sm25: "62.14",
          sm35: "99.41",
          sm45: "75.08",
          sm55: "83.92",
          st5: "20.1",
          st15: "18.1",
          st25: "19.8",
          st35: "19.1",
          st45: "19.6",
          st55: "19.3",
        },
        {
          time: "2023-07-29 18:00:00.000000000",
          at: "15.2",
          sm5: "84.42",
          sm15: "22.96",
          sm25: "62.5",
          sm35: "100.0",
          sm45: "75.36",
          sm55: "84.42",
          st5: "20.2",
          st15: "18.7",
          st25: "19.7",
          st35: "19.0",
          st45: "19.6",
          st55: "19.5",
        },
        {
          time: "2023-07-29 17:00:00.000000000",
          at: "16.7",
          sm5: "85.62",
          sm15: "23.74",
          sm25: "63.86",
          sm35: "100.0",
          sm45: "76.72",
          sm55: "85.62",
          st5: "20.3",
          st15: "19.6",
          st25: "19.6",
          st35: "19.0",
          st45: "19.6",
          st55: "19.5",
        },
        {
          time: "2023-07-29 16:00:00.000000000",
          at: "20.0",
          sm5: "88.48",
          sm15: "27.4",
          sm25: "67.75",
          sm35: "100.0",
          sm45: "79.13",
          sm55: "88.48",
          st5: "20.5",
          st15: "20.5",
          st25: "19.6",
          st35: "19.1",
          st45: "19.5",
          st55: "19.5",
        },
        {
          time: "2023-07-29 15:00:00.000000000",
          at: "22.8",
          sm5: "91.34",
          sm15: "30.0",
          sm25: "70.17",
          sm35: "100.0",
          sm45: "81.81",
          sm55: "91.34",
          st5: "20.3",
          st15: "21.1",
          st25: "19.5",
          st35: "19.1",
          st45: "19.5",
          st55: "19.5",
        },
        {
          time: "2023-07-29 14:00:00.000000000",
          at: "24.5",
          sm5: "92.31",
          sm15: "31.43",
          sm25: "72.06",
          sm35: "100.0",
          sm45: "82.72",
          sm55: "92.31",
          st5: "20.3",
          st15: "21.5",
          st25: "19.3",
          st35: "19.1",
          st45: "19.5",
          st55: "19.6",
        },
        {
          time: "2023-07-29 13:00:00.000000000",
          at: "25.5",
          sm5: "93.76",
          sm15: "32.48",
          sm25: "72.96",
          sm35: "100.0",
          sm45: "84.15",
          sm55: "93.76",
          st5: "20.1",
          st15: "21.8",
          st25: "19.1",
          st35: "19.1",
          st45: "19.5",
          st55: "19.6",
        },
        {
          time: "2023-07-29 12:00:00.000000000",
          at: "26.0",
          sm5: "93.84",
          sm15: "33.33",
          sm25: "73.71",
          sm35: "100.0",
          sm45: "84.54",
          sm55: "93.84",
          st5: "19.8",
          st15: "21.8",
          st25: "19.0",
          st35: "19.1",
          st45: "19.5",
          st55: "19.6",
        },
        {
          time: "2023-07-29 11:00:00.000000000",
          at: "26.6",
          sm5: "93.93",
          sm15: "34.01",
          sm25: "73.96",
          sm35: "100.0",
          sm45: "84.9",
          sm55: "93.93",
          st5: "19.5",
          st15: "22.1",
          st25: "19.0",
          st35: "19.2",
          st45: "19.5",
          st55: "19.6",
        },
        {
          time: "2023-07-29 10:00:00.000000000",
          at: "29.8",
          sm5: "97.15",
          sm15: "36.98",
          sm25: "77.16",
          sm35: "100.0",
          sm45: "87.53",
          sm55: "97.15",
          st5: "19.0",
          st15: "22.2",
          st25: "18.8",
          st35: "19.2",
          st45: "19.6",
          st55: "19.6",
        },
        {
          time: "2023-07-29 09:00:00.000000000",
          at: "27.3",
          sm5: "95.3",
          sm15: "35.04",
          sm25: "75.04",
          sm35: "100.0",
          sm45: "85.73",
          sm55: "95.3",
          st5: "18.5",
          st15: "20.6",
          st25: "18.8",
          st35: "19.3",
          st45: "19.6",
          st55: "19.6",
        },
        {
          time: "2023-07-29 08:00:00.000000000",
          at: "25.3",
          sm5: "93.68",
          sm15: "33.21",
          sm25: "73.13",
          sm35: "100.0",
          sm45: "84.16",
          sm55: "93.68",
          st5: "18.1",
          st15: "19.3",
          st25: "18.8",
          st35: "19.2",
          st45: "19.7",
          st55: "19.6",
        },
        {
          time: "2023-07-29 07:00:00.000000000",
          at: "23.3",
          sm5: "91.64",
          sm15: "31.24",
          sm25: "70.95",
          sm35: "100.0",
          sm45: "82.42",
          sm55: "91.64",
          st5: "17.8",
          st15: "18.3",
          st25: "19.0",
          st35: "19.3",
          st45: "19.7",
          st55: "19.6",
        },
        {
          time: "2023-07-29 06:00:00.000000000",
          at: "21.2",
          sm5: "89.95",
          sm15: "29.68",
          sm25: "69.3",
          sm35: "100.0",
          sm45: "80.9",
          sm55: "89.95",
          st5: "17.7",
          st15: "17.5",
          st25: "19.0",
          st35: "19.3",
          st45: "19.8",
          st55: "19.6",
        },
        {
          time: "2023-07-29 05:00:00.000000000",
          at: "17.6",
          sm5: "86.6",
          sm15: "26.2",
          sm25: "65.41",
          sm35: "100.0",
          sm45: "77.58",
          sm55: "86.6",
          st5: "17.7",
          st15: "16.2",
          st25: "19.2",
          st35: "19.5",
          st45: "19.8",
          st55: "19.7",
        },
        {
          time: "2023-07-29 04:00:00.000000000",
          at: "14.0",
          sm5: "83.05",
          sm15: "22.19",
          sm25: "61.1",
          sm35: "98.6",
          sm45: "74.19",
          sm55: "83.05",
          st5: "17.8",
          st15: "15.2",
          st25: "19.3",
          st35: "19.5",
          st45: "20.0",
          st55: "19.6",
        },
        {
          time: "2023-07-29 03:00:00.000000000",
          at: "10.0",
          sm5: "78.96",
          sm15: "17.94",
          sm25: "56.18",
          sm35: "93.7",
          sm45: "69.8",
          sm55: "78.96",
          st5: "18.0",
          st15: "14.3",
          st25: "19.5",
          st35: "19.5",
          st45: "20.0",
          st55: "19.6",
        },
        {
          time: "2023-07-29 02:00:00.000000000",
          at: "9.5",
          sm5: "78.0",
          sm15: "17.65",
          sm25: "55.52",
          sm35: "92.62",
          sm45: "68.91",
          sm55: "78.0",
          st5: "18.2",
          st15: "14.6",
          st25: "19.6",
          st35: "19.5",
          st45: "20.0",
          st55: "19.7",
        },
        {
          time: "2023-07-29 01:00:00.000000000",
          at: "9.7",
          sm5: "78.51",
          sm15: "18.17",
          sm25: "56.01",
          sm35: "93.4",
          sm45: "69.18",
          sm55: "78.51",
          st5: "18.6",
          st15: "14.8",
          st25: "19.7",
          st35: "19.5",
          st45: "20.1",
          st55: "19.6",
        },
        {
          time: "2023-07-29 00:00:00.000000000",
          at: "10.2",
          sm5: "79.23",
          sm15: "18.67",
          sm25: "56.76",
          sm35: "94.04",
          sm45: "69.77",
          sm55: "79.23",
          st5: "18.8",
          st15: "15.2",
          st25: "19.8",
          st35: "19.5",
          st45: "20.1",
          st55: "19.6",
        },
        {
          time: "2023-07-28 23:00:00.000000000",
          at: "10.6",
          sm5: "79.37",
          sm15: "19.08",
          sm25: "57.45",
          sm35: "94.48",
          sm45: "70.3",
          sm55: "79.37",
          st5: "19.2",
          st15: "15.5",
          st25: "19.8",
          st35: "19.5",
          st45: "20.1",
          st55: "19.7",
        },
        {
          time: "2023-07-28 22:00:00.000000000",
          at: "11.2",
          sm5: "80.09",
          sm15: "19.82",
          sm25: "58.31",
          sm35: "95.07",
          sm45: "70.43",
          sm55: "80.09",
          st5: "19.5",
          st15: "16.1",
          st25: "20.0",
          st35: "19.5",
          st45: "20.1",
          st55: "19.6",
        },
        {
          time: "2023-07-28 21:00:00.000000000",
          at: "12.1",
          sm5: "81.19",
          sm15: "20.43",
          sm25: "59.21",
          sm35: "96.1",
          sm45: "71.35",
          sm55: "81.19",
          st5: "19.7",
          st15: "16.6",
          st25: "20.1",
          st35: "19.5",
          st45: "20.1",
          st55: "19.6",
        },
        {
          time: "2023-07-28 20:00:00.000000000",
          at: "12.8",
          sm5: "81.4",
          sm15: "21.08",
          sm25: "59.96",
          sm35: "96.59",
          sm45: "71.69",
          sm55: "81.4",
          st5: "20.0",
          st15: "17.1",
          st25: "20.1",
          st35: "19.5",
          st45: "20.1",
          st55: "19.7",
        },
        {
          time: "2023-07-28 19:00:00.000000000",
          at: "14.1",
          sm5: "83.0",
          sm15: "22.65",
          sm25: "61.61",
          sm35: "98.66",
          sm45: "73.11",
          sm55: "83.0",
          st5: "20.2",
          st15: "17.7",
          st25: "20.1",
          st35: "19.3",
          st45: "20.1",
          st55: "19.6",
        },
        {
          time: "2023-07-28 18:00:00.000000000",
          at: "15.0",
          sm5: "83.88",
          sm15: "23.55",
          sm25: "62.83",
          sm35: "99.61",
          sm45: "73.96",
          sm55: "83.88",
          st5: "20.3",
          st15: "18.3",
          st25: "20.1",
          st35: "19.3",
          st45: "20.1",
          st55: "19.7",
        },
        {
          time: "2023-07-28 17:00:00.000000000",
          at: "15.9",
          sm5: "84.71",
          sm15: "24.73",
          sm25: "64.08",
          sm35: "100.0",
          sm45: "74.67",
          sm55: "84.71",
          st5: "20.5",
          st15: "19.0",
          st25: "20.1",
          st35: "19.5",
          st45: "20.1",
          st55: "19.7",
        },
        {
          time: "2023-07-28 16:00:00.000000000",
          at: "19.1",
          sm5: "87.53",
          sm15: "27.51",
          sm25: "67.49",
          sm35: "100.0",
          sm45: "77.07",
          sm55: "87.53",
          st5: "20.6",
          st15: "19.8",
          st25: "20.1",
          st35: "19.5",
          st45: "20.1",
          st55: "19.7",
        },
        {
          time: "2023-07-28 15:00:00.000000000",
          at: "21.2",
          sm5: "89.5",
          sm15: "29.85",
          sm25: "69.69",
          sm35: "100.0",
          sm45: "78.83",
          sm55: "89.5",
          st5: "20.6",
          st15: "20.3",
          st25: "20.1",
          st35: "19.5",
          st45: "20.1",
          st55: "19.7",
        },
        {
          time: "2023-07-28 13:00:00.000000000",
          at: "24.37",
          sm5: "91.51",
          sm15: "32.5",
          sm25: "72.41",
          sm35: "100.0",
          sm45: "80.12",
          sm55: "91.51",
          st5: "20.47",
          st15: "21.11",
          st25: "19.8",
          st35: "19.53",
          st45: "20.03",
          st55: "19.69",
        },
        {
          time: "2023-07-28 12:00:00.000000000",
          at: "25.46",
          sm5: "90.0",
          sm15: "35.1",
          sm25: "71.5",
          sm35: "98.12",
          sm45: "78.57",
          sm55: "90.0",
          st5: "71.28",
          st15: "17.42",
          st25: "79.16",
          st35: "79.6",
          st45: "23.64",
          st55: "37.22",
        },
        {
          time: "2023-07-28 11:00:00.000000000",
          at: "24.5",
          sm5: "92.9",
          sm15: "52.23",
          sm25: "75.02",
          sm35: "100.0",
          sm45: "85.15",
          sm55: "92.9",
          st5: "35.2",
          st15: "2.3",
          st25: "175.8",
          st35: "364.8",
          st45: "37.6",
          st55: "105.7",
        },
        {
          time: "2023-07-28 10:00:00.000000000",
          at: "27.6",
          sm5: "95.6",
          sm15: "56.25",
          sm25: "78.74",
          sm35: "100.0",
          sm45: "87.96",
          sm55: "95.6",
          st5: "95.2",
          st15: "2.3",
          st25: "154.1",
          st35: "6.4",
          st45: "37.5",
          st55: "119.7",
        },
        {
          time: "2023-07-28 09:00:00.000000000",
          at: "25.8",
          sm5: "94.31",
          sm15: "56.16",
          sm25: "77.45",
          sm35: "100.0",
          sm45: "86.63",
          sm55: "94.31",
          st5: "37.0",
          st15: "2.3",
          st25: "166.9",
          st35: "211.2",
          st45: "37.5",
          st55: "307.2",
        },
        {
          time: "2023-07-28 08:00:00.000000000",
          at: "23.5",
          sm5: "92.27",
          sm15: "55.46",
          sm25: "75.7",
          sm35: "100.0",
          sm45: "84.55",
          sm55: "92.27",
          st5: "8.2",
          st15: "2.3",
          st25: "184.3",
          st35: "108.8",
          st45: "37.6",
          st55: "190.8",
        },
        {
          time: "2023-07-28 07:00:00.000000000",
          at: "21.1",
          sm5: "90.81",
          sm15: "54.82",
          sm25: "74.71",
          sm35: "100.0",
          sm45: "83.01",
          sm55: "90.81",
          st5: "204.4",
          st15: "2.3",
          st25: "197.3",
          st35: "73.6",
          st45: "37.6",
          st55: "407.4",
        },
        {
          time: "2023-07-28 06:00:00.000000000",
          at: "19.7",
          sm5: "88.92",
          sm15: "54.85",
          sm25: "74.23",
          sm35: "100.0",
          sm45: "81.09",
          sm55: "88.92",
          st5: "274.2",
          st15: "2.3",
          st25: "211.8",
          st35: "371.2",
          st45: "37.7",
          st55: "268.0",
        },
        {
          time: "2023-07-28 05:00:00.000000000",
          at: "16.5",
          sm5: "85.88",
          sm15: "54.11",
          sm25: "80.91",
          sm35: "100.0",
          sm45: "76.48",
          sm55: "85.88",
          st5: "166.4",
          st15: "2.3",
          st25: "236.3",
          st35: "387.2",
          st45: "37.9",
          st55: "97.6",
        },
        {
          time: "2023-07-28 04:00:00.000000000",
          at: "15.0",
          sm5: "84.32",
          sm15: "60.81",
          sm25: "78.54",
          sm35: "100.0",
          sm45: "75.3",
          sm55: "84.32",
          st5: "64.0",
          st15: "2.3",
          st25: "249.3",
          st35: "182.4",
          st45: "37.9",
          st55: "263.7",
        },
        {
          time: "2023-07-28 03:00:00.000000000",
          at: "12.6",
          sm5: "81.53",
          sm15: "22.61",
          sm25: "57.36",
          sm35: "96.92",
          sm45: "71.37",
          sm55: "81.53",
          st5: "26.6",
          st15: "2.3",
          st25: "271.7",
          st35: "12.8",
          st45: "38.0",
          st55: "407.3",
        },
        {
          time: "2023-07-28 02:00:00.000000000",
          at: "12.8",
          sm5: "81.75",
          sm15: "23.02",
          sm25: "58.01",
          sm35: "97.08",
          sm45: "71.56",
          sm55: "81.75",
          st5: "42.2",
          st15: "2.3",
          st25: "270.5",
          st35: "166.4",
          st45: "38.0",
          st55: "379.7",
        },
        {
          time: "2023-07-28 01:00:00.000000000",
          at: "13.8",
          sm5: "82.79",
          sm15: "24.08",
          sm25: "58.88",
          sm35: "98.4",
          sm45: "72.23",
          sm55: "82.79",
          st5: "75.4",
          st15: "2.3",
          st25: "261.0",
          st35: "345.6",
          st45: "38.0",
          st55: "285.7",
        },
        {
          time: "2023-07-28 00:00:00.000000000",
          at: "15.1",
          sm5: "84.06",
          sm15: "25.24",
          sm25: "60.35",
          sm35: "99.78",
          sm45: "73.19",
          sm55: "84.06",
          st5: "209.6",
          st15: "2.3",
          st25: "251.2",
          st35: "83.2",
          st45: "38.0",
          st55: "151.5",
        },
        {
          time: "2023-07-27 23:00:00.000000000",
          at: "16.3",
          sm5: "85.51",
          sm15: "26.27",
          sm25: "62.23",
          sm35: "100.0",
          sm45: "74.69",
          sm55: "85.51",
          st5: "8.4",
          st15: "2.3",
          st25: "240.7",
          st35: "32.0",
          st45: "37.9",
          st55: "349.4",
        },
        {
          time: "2023-07-27 22:00:00.000000000",
          at: "17.3",
          sm5: "86.68",
          sm15: "27.23",
          sm25: "63.16",
          sm35: "100.0",
          sm45: "74.94",
          sm55: "86.68",
          st5: "325.8",
          st15: "2.3",
          st25: "232.9",
          st35: "342.4",
          st45: "37.9",
          st55: "314.9",
        },
        {
          time: "2023-07-27 21:00:00.000000000",
          at: "18.3",
          sm5: "87.35",
          sm15: "28.44",
          sm25: "64.65",
          sm35: "100.0",
          sm45: "76.19",
          sm55: "87.35",
          st5: "11.4",
          st15: "2.3",
          st25: "224.6",
          st35: "224.0",
          st45: "37.9",
          st55: "138.3",
        },
        {
          time: "2023-07-27 20:00:00.000000000",
          at: "19.5",
          sm5: "88.49",
          sm15: "29.57",
          sm25: "66.03",
          sm35: "100.0",
          sm45: "77.24",
          sm55: "88.49",
          st5: "316.4",
          st15: "2.3",
          st25: "216.3",
          st35: "9.6",
          st45: "37.8",
          st55: "400.1",
        },
        {
          time: "2023-07-27 19:00:00.000000000",
          at: "20.6",
          sm5: "89.38",
          sm15: "30.78",
          sm25: "67.22",
          sm35: "100.0",
          sm45: "78.16",
          sm55: "89.38",
          st5: "173.8",
          st15: "2.3",
          st25: "208.3",
          st35: "361.6",
          st45: "37.8",
          st55: "271.4",
        },
        {
          time: "2023-07-27 18:00:00.000000000",
          at: "21.7",
          sm5: "90.35",
          sm15: "31.92",
          sm25: "68.06",
          sm35: "100.0",
          sm45: "78.78",
          sm55: "90.35",
          st5: "250.8",
          st15: "2.3",
          st25: "201.0",
          st35: "22.4",
          st45: "37.8",
          st55: "182.9",
        },
        {
          time: "2023-07-27 17:00:00.000000000",
          at: "23.0",
          sm5: "91.55",
          sm15: "33.45",
          sm25: "69.82",
          sm35: "100.0",
          sm45: "80.03",
          sm55: "91.55",
          st5: "257.8",
          st15: "2.3",
          st25: "190.1",
          st35: "83.2",
          st45: "37.8",
          st55: "7.5",
        },
        {
          time: "2023-07-27 16:00:00.000000000",
          at: "21.1",
          sm5: "89.4",
          sm15: "31.92",
          sm25: "67.5",
          sm35: "100.0",
          sm45: "77.75",
          sm55: "89.4",
          st5: "57.6",
          st15: "2.3",
          st25: "206.9",
          st35: "115.2",
          st45: "37.8",
          st55: "328.9",
        },
        {
          time: "2023-07-27 15:00:00.000000000",
          at: "23.9",
          sm5: "92.3",
          sm15: "34.35",
          sm25: "70.75",
          sm35: "100.0",
          sm45: "80.53",
          sm55: "92.3",
          st5: "182.0",
          st15: "2.3",
          st25: "182.8",
          st35: "348.8",
          st45: "37.7",
          st55: "346.3",
        },
        {
          time: "2023-07-27 14:00:00.000000000",
          at: "25.0",
          sm5: "93.13",
          sm15: "35.82",
          sm25: "71.35",
          sm35: "100.0",
          sm45: "81.31",
          sm55: "93.13",
          st5: "280.8",
          st15: "2.3",
          st25: "178.0",
          st35: "198.4",
          st45: "37.7",
          st55: "237.1",
        },
        {
          time: "2023-07-27 13:00:00.000000000",
          at: "25.6",
          sm5: "93.65",
          sm15: "36.9",
          sm25: "71.94",
          sm35: "100.0",
          sm45: "81.97",
          sm55: "93.65",
          st5: "23.8",
          st15: "2.3",
          st25: "173.9",
          st35: "390.4",
          st45: "37.7",
          st55: "143.5",
        },
        {
          time: "2023-07-27 12:00:00.000000000",
          at: "24.7",
          sm5: "92.69",
          sm15: "36.54",
          sm25: "70.75",
          sm35: "100.0",
          sm45: "81.33",
          sm55: "92.69",
          st5: "166.4",
          st15: "2.3",
          st25: "180.9",
          st35: "16.0",
          st45: "37.7",
          st55: "234.7",
        },
        {
          time: "2023-07-27 11:00:00.000000000",
          at: "30.2",
          sm5: "97.3",
          sm15: "41.64",
          sm25: "76.27",
          sm35: "100.0",
          sm45: "84.72",
          sm55: "97.3",
          st5: "171.0",
          st15: "2.3",
          st25: "141.4",
          st35: "169.6",
          st45: "37.6",
          st55: "166.0",
        },
        {
          time: "2023-07-27 10:00:00.000000000",
          at: "31.9",
          sm5: "98.47",
          sm15: "43.74",
          sm25: "77.82",
          sm35: "100.0",
          sm45: "85.93",
          sm55: "98.47",
          st5: "178.2",
          st15: "2.3",
          st25: "130.3",
          st35: "41.6",
          st45: "37.5",
          st55: "405.6",
        },
        {
          time: "2023-07-27 09:00:00.000000000",
          at: "32.5",
          sm5: "99.11",
          sm15: "44.77",
          sm25: "78.51",
          sm35: "100.0",
          sm45: "86.88",
          sm55: "99.11",
          st5: "113.2",
          st15: "2.3",
          st25: "124.7",
          st35: "224.0",
          st45: "37.5",
          st55: "272.7",
        },
        {
          time: "2023-07-27 08:00:00.000000000",
          at: "30.3",
          sm5: "97.7",
          sm15: "43.74",
          sm25: "76.68",
          sm35: "100.0",
          sm45: "85.49",
          sm55: "97.7",
          st5: "233.6",
          st15: "2.3",
          st25: "138.3",
          st35: "44.8",
          st45: "37.6",
          st55: "58.1",
        },
        {
          time: "2023-07-27 07:00:00.000000000",
          at: "28.0",
          sm5: "96.07",
          sm15: "42.08",
          sm25: "74.65",
          sm35: "100.0",
          sm45: "83.98",
          sm55: "96.07",
          st5: "322.0",
          st15: "2.3",
          st25: "151.2",
          st35: "300.8",
          st45: "37.6",
          st55: "271.3",
        },
        {
          time: "2023-07-27 06:00:00.000000000",
          at: "25.3",
          sm5: "93.67",
          sm15: "39.78",
          sm25: "71.95",
          sm35: "100.0",
          sm45: "81.58",
          sm55: "93.67",
          st5: "399.2",
          st15: "2.3",
          st25: "172.1",
          st35: "201.6",
          st45: "37.7",
          st55: "198.4",
        },
        {
          time: "2023-07-27 05:00:00.000000000",
          at: "20.3",
          sm5: "89.36",
          sm15: "35.22",
          sm25: "66.81",
          sm35: "100.0",
          sm45: "77.57",
          sm55: "89.36",
          st5: "115.8",
          st15: "2.3",
          st25: "206.9",
          st35: "163.2",
          st45: "37.8",
          st55: "353.8",
        },
        {
          time: "2023-07-27 04:00:00.000000000",
          at: "16.1",
          sm5: "85.25",
          sm15: "30.82",
          sm25: "62.35",
          sm35: "100.0",
          sm45: "74.02",
          sm55: "85.25",
          st5: "187.8",
          st15: "2.3",
          st25: "241.1",
          st35: "35.2",
          st45: "38.0",
          st55: "34.6",
        },
        {
          time: "2023-07-27 03:00:00.000000000",
          at: "11.5",
          sm5: "80.53",
          sm15: "26.53",
          sm25: "57.17",
          sm35: "95.64",
          sm45: "68.96",
          sm55: "80.53",
          st5: "379.2",
          st15: "2.3",
          st25: "280.8",
          st35: "99.2",
          st45: "38.1",
          st55: "336.1",
        },
        {
          time: "2023-07-27 02:00:00.000000000",
          at: "10.7",
          sm5: "79.54",
          sm15: "25.62",
          sm25: "56.43",
          sm35: "94.42",
          sm45: "68.09",
          sm55: "79.54",
          st5: "108.4",
          st15: "2.3",
          st25: "289.5",
          st35: "236.8",
          st45: "38.2",
          st55: "49.4",
        },
        {
          time: "2023-07-27 01:00:00.000000000",
          at: "11.5",
          sm5: "80.68",
          sm15: "26.8",
          sm25: "57.39",
          sm35: "95.9",
          sm45: "68.94",
          sm55: "80.68",
          st5: "366.2",
          st15: "2.3",
          st25: "279.0",
          st35: "96.0",
          st45: "38.1",
          st55: "339.3",
        },
        {
          time: "2023-07-27 00:00:00.000000000",
          at: "12.1",
          sm5: "80.74",
          sm15: "27.5",
          sm25: "58.11",
          sm35: "96.07",
          sm45: "69.15",
          sm55: "80.74",
          st5: "258.0",
          st15: "2.3",
          st25: "277.7",
          st35: "352.0",
          st45: "38.1",
          st55: "310.7",
        },
        {
          time: "2023-07-26 23:00:00.000000000",
          at: "12.8",
          sm5: "81.07",
          sm15: "28.0",
          sm25: "58.68",
          sm35: "96.54",
          sm45: "69.56",
          sm55: "81.07",
          st5: "30.4",
          st15: "2.3",
          st25: "274.3",
          st35: "339.2",
          st45: "38.1",
          st55: "252.4",
        },
        {
          time: "2023-07-26 22:00:00.000000000",
          at: "13.3",
          sm5: "82.31",
          sm15: "28.73",
          sm25: "59.67",
          sm35: "97.59",
          sm45: "70.34",
          sm55: "82.31",
          st5: "243.6",
          st15: "2.3",
          st25: "266.9",
          st35: "89.6",
          st45: "38.1",
          st55: "142.7",
        },
        {
          time: "2023-07-26 21:00:00.000000000",
          at: "14.0",
          sm5: "82.51",
          sm15: "29.3",
          sm25: "60.62",
          sm35: "98.26",
          sm45: "70.69",
          sm55: "82.51",
          st5: "110.0",
          st15: "2.3",
          st25: "262.1",
          st35: "140.8",
          st45: "38.1",
          st55: "93.3",
        },
        {
          time: "2023-07-26 20:00:00.000000000",
          at: "15.1",
          sm5: "83.71",
          sm15: "30.49",
          sm25: "61.75",
          sm35: "99.57",
          sm45: "71.33",
          sm55: "83.71",
          st5: "72.8",
          st15: "2.3",
          st25: "252.7",
          st35: "227.2",
          st45: "38.1",
          st55: "2.6",
        },
        {
          time: "2023-07-26 19:00:00.000000000",
          at: "16.1",
          sm5: "84.55",
          sm15: "31.99",
          sm25: "63.3",
          sm35: "100.0",
          sm45: "72.48",
          sm55: "84.55",
          st5: "67.6",
          st15: "2.3",
          st25: "243.1",
          st35: "9.6",
          st45: "38.0",
          st55: "251.5",
        },
        {
          time: "2023-07-26 18:00:00.000000000",
          at: "17.5",
          sm5: "86.17",
          sm15: "33.23",
          sm25: "64.57",
          sm35: "100.0",
          sm45: "73.62",
          sm55: "86.17",
          st5: "174.6",
          st15: "2.3",
          st25: "232.7",
          st35: "268.8",
          st45: "38.0",
          st55: "90.6",
        },
        {
          time: "2023-07-26 17:00:00.000000000",
          at: "18.2",
          sm5: "86.63",
          sm15: "33.98",
          sm25: "65.18",
          sm35: "100.0",
          sm45: "74.28",
          sm55: "86.63",
          st5: "256.6",
          st15: "2.3",
          st25: "230.3",
          st35: "358.4",
          st45: "37.9",
          st55: "407.5",
        },
        {
          time: "2023-07-26 15:00:00.000000000",
          at: "24.5",
          sm5: "92.25",
          sm15: "40.7",
          sm25: "72.02",
          sm35: "100.0",
          sm45: "80.04",
          sm55: "92.25",
          st5: "281.0",
          st15: "2.3",
          st25: "179.9",
          st35: "182.4",
          st45: "37.8",
          st55: "6.0",
        },
        {
          time: "2023-07-26 14:00:00.000000000",
          at: "26.5",
          sm5: "94.25",
          sm15: "42.82",
          sm25: "73.21",
          sm35: "100.0",
          sm45: "81.66",
          sm55: "94.25",
          st5: "108.2",
          st15: "2.3",
          st25: "170.1",
          st35: "246.4",
          st45: "37.7",
          st55: "187.4",
        },
        {
          time: "2023-07-26 13:00:00.000000000",
          at: "27.8",
          sm5: "95.13",
          sm15: "44.61",
          sm25: "75.11",
          sm35: "100.0",
          sm45: "82.64",
          sm55: "95.13",
          st5: "287.0",
          st15: "2.3",
          st25: "157.3",
          st35: "252.8",
          st45: "37.7",
          st55: "49.6",
        },
        {
          time: "2023-07-26 12:00:00.000000000",
          at: "28.8",
          sm5: "96.06",
          sm15: "46.03",
          sm25: "76.0",
          sm35: "100.0",
          sm45: "83.53",
          sm55: "96.06",
          st5: "258.8",
          st15: "2.3",
          st25: "148.5",
          st35: "320.0",
          st45: "37.6",
          st55: "334.3",
        },
        {
          time: "2023-07-26 11:00:00.000000000",
          at: "29.4",
          sm5: "96.35",
          sm15: "46.8",
          sm25: "76.32",
          sm35: "100.0",
          sm45: "83.78",
          sm55: "96.35",
          st5: "84.4",
          st15: "2.3",
          st25: "147.8",
          st35: "256.0",
          st45: "37.6",
          st55: "299.0",
        },
        {
          time: "2023-07-26 10:00:00.000000000",
          at: "32.6",
          sm5: "98.71",
          sm15: "49.85",
          sm25: "79.22",
          sm35: "100.0",
          sm45: "86.43",
          sm55: "98.71",
          st5: "28.2",
          st15: "2.3",
          st25: "125.9",
          st35: "313.6",
          st45: "37.5",
          st55: "334.4",
        },
        {
          time: "2023-07-26 09:00:00.000000000",
          at: "30.8",
          sm5: "97.68",
          sm15: "49.1",
          sm25: "77.68",
          sm35: "100.0",
          sm45: "85.18",
          sm55: "97.68",
          st5: "18.2",
          st15: "2.3",
          st25: "138.3",
          st35: "336.0",
          st45: "37.6",
          st55: "101.2",
        },
        {
          time: "2023-07-26 08:00:00.000000000",
          at: "28.1",
          sm5: "95.89",
          sm15: "47.78",
          sm25: "75.5",
          sm35: "100.0",
          sm45: "83.57",
          sm55: "95.89",
          st5: "376.2",
          st15: "2.3",
          st25: "151.9",
          st35: "73.6",
          st45: "37.6",
          st55: "329.1",
        },
        {
          time: "2023-07-26 07:00:00.000000000",
          at: "25.6",
          sm5: "93.75",
          sm15: "45.73",
          sm25: "73.18",
          sm35: "100.0",
          sm45: "81.67",
          sm55: "93.75",
          st5: "164.2",
          st15: "2.3",
          st25: "169.6",
          st35: "236.8",
          st45: "37.7",
          st55: "185.7",
        },
        {
          time: "2023-07-26 06:00:00.000000000",
          at: "23.8",
          sm5: "91.73",
          sm15: "44.01",
          sm25: "71.38",
          sm35: "100.0",
          sm45: "79.94",
          sm55: "91.73",
          st5: "234.0",
          st15: "2.3",
          st25: "183.7",
          st35: "252.8",
          st45: "37.8",
          st55: "20.8",
        },
        {
          time: "2023-07-26 05:00:00.000000000",
          at: "19.9",
          sm5: "88.73",
          sm15: "40.9",
          sm25: "67.39",
          sm35: "100.0",
          sm45: "76.82",
          sm55: "88.73",
          st5: "257.8",
          st15: "2.3",
          st25: "212.4",
          st35: "390.4",
          st45: "37.9",
          st55: "49.7",
        },
        {
          time: "2023-07-26 04:00:00.000000000",
          at: "15.3",
          sm5: "84.32",
          sm15: "36.18",
          sm25: "62.33",
          sm35: "100.0",
          sm45: "72.53",
          sm55: "84.32",
          st5: "228.8",
          st15: "2.3",
          st25: "248.8",
          st35: "336.0",
          st45: "38.0",
          st55: "244.0",
        },
        {
          time: "2023-07-26 03:00:00.000000000",
          at: "10.3",
          sm5: "79.19",
          sm15: "31.19",
          sm25: "56.22",
          sm35: "94.26",
          sm45: "68.49",
          sm55: "79.19",
          st5: "83.8",
          st15: "2.3",
          st25: "290.7",
          st35: "51.2",
          st45: "38.1",
          st55: "403.2",
        },
        {
          time: "2023-07-26 02:00:00.000000000",
          at: "9.9",
          sm5: "78.78",
          sm15: "30.62",
          sm25: "55.51",
          sm35: "93.29",
          sm45: "68.3",
          sm55: "78.78",
          st5: "179.6",
          st15: "2.3",
          st25: "297.6",
          st35: "128.0",
          st45: "38.2",
          st55: "19.7",
        },
        {
          time: "2023-07-26 01:00:00.000000000",
          at: "10.1",
          sm5: "78.44",
          sm15: "30.52",
          sm25: "55.63",
          sm35: "93.58",
          sm45: "68.02",
          sm55: "78.44",
          st5: "366.2",
          st15: "2.3",
          st25: "295.6",
          st35: "9.6",
          st45: "38.2",
          st55: "59.0",
        },
        {
          time: "2023-07-26 00:00:00.000000000",
          at: "10.5",
          sm5: "79.34",
          sm15: "31.07",
          sm25: "56.29",
          sm35: "94.44",
          sm45: "68.7",
          sm55: "79.34",
          st5: "362.8",
          st15: "2.3",
          st25: "289.4",
          st35: "144.0",
          st45: "38.1",
          st55: "373.0",
        },
        {
          time: "2023-07-25 23:00:00.000000000",
          at: "10.8",
          sm5: "79.45",
          sm15: "31.2",
          sm25: "56.61",
          sm35: "94.02",
          sm45: "68.07",
          sm55: "79.45",
          st5: "177.4",
          st15: "2.3",
          st25: "292.4",
          st35: "182.4",
          st45: "38.2",
          st55: "53.0",
        },
        {
          time: "2023-07-25 21:00:00.000000000",
          at: "11.9",
          sm5: "80.26",
          sm15: "32.25",
          sm25: "57.96",
          sm35: "95.39",
          sm45: "69.12",
          sm55: "80.26",
          st5: "143.8",
          st15: "2.3",
          st25: "282.6",
          st35: "57.6",
          st45: "38.1",
          st55: "313.8",
        },
        {
          time: "2023-07-25 20:00:00.000000000",
          at: "12.6",
          sm5: "80.81",
          sm15: "32.95",
          sm25: "58.95",
          sm35: "96.27",
          sm45: "69.64",
          sm55: "80.81",
          st5: "342.2",
          st15: "2.3",
          st25: "276.3",
          st35: "67.2",
          st45: "38.1",
          st55: "241.0",
        },
        {
          time: "2023-07-25 19:00:00.000000000",
          at: "13.4",
          sm5: "81.94",
          sm15: "33.67",
          sm25: "59.83",
          sm35: "96.67",
          sm45: "70.31",
          sm55: "81.94",
          st5: "349.2",
          st15: "2.3",
          st25: "273.4",
          st35: "364.8",
          st45: "38.1",
          st55: "146.7",
        },
        {
          time: "2023-07-25 18:00:00.000000000",
          at: "15.0",
          sm5: "83.24",
          sm15: "35.24",
          sm25: "61.77",
          sm35: "99.06",
          sm45: "71.75",
          sm55: "83.24",
          st5: "41.4",
          st15: "2.3",
          st25: "256.3",
          st35: "259.2",
          st45: "38.0",
          st55: "353.0",
        },
        {
          time: "2023-07-25 17:00:00.000000000",
          at: "16.6",
          sm5: "85.08",
          sm15: "37.27",
          sm25: "64.01",
          sm35: "100.0",
          sm45: "73.57",
          sm55: "85.08",
          st5: "377.6",
          st15: "2.3",
          st25: "241.3",
          st35: "262.4",
          st45: "38.0",
          st55: "97.9",
        },
        {
          time: "2023-07-25 16:00:00.000000000",
          at: "20.0",
          sm5: "88.23",
          sm15: "40.92",
          sm25: "67.8",
          sm35: "100.0",
          sm45: "76.64",
          sm55: "88.23",
          st5: "326.0",
          st15: "2.3",
          st25: "216.8",
          st35: "144.0",
          st45: "37.9",
          st55: "74.5",
        },
        {
          time: "2023-07-25 15:00:00.000000000",
          at: "22.6",
          sm5: "90.34",
          sm15: "43.12",
          sm25: "70.25",
          sm35: "100.0",
          sm45: "78.88",
          sm55: "90.34",
          st5: "282.0",
          st15: "2.3",
          st25: "197.4",
          st35: "9.6",
          st45: "37.8",
          st55: "169.0",
        },
        {
          time: "2023-07-25 13:00:00.000000000",
          at: "25.7",
          sm5: "93.26",
          sm15: "46.4",
          sm25: "73.48",
          sm35: "100.0",
          sm45: "81.06",
          sm55: "93.26",
          st5: "30.8",
          st15: "2.3",
          st25: "173.8",
          st35: "355.2",
          st45: "37.7",
          st55: "271.5",
        },
        {
          time: "2023-07-25 12:00:00.000000000",
          at: "26.3",
          sm5: "93.41",
          sm15: "47.29",
          sm25: "74.18",
          sm35: "100.0",
          sm45: "81.68",
          sm55: "93.41",
          st5: "357.8",
          st15: "2.3",
          st25: "174.3",
          st35: "224.0",
          st45: "37.7",
          st55: "184.9",
        },
        {
          time: "2023-07-25 11:00:00.000000000",
          at: "26.4",
          sm5: "93.51",
          sm15: "48.02",
          sm25: "74.1",
          sm35: "100.0",
          sm45: "82.08",
          sm55: "93.51",
          st5: "106.2",
          st15: "2.3",
          st25: "171.2",
          st35: "256.0",
          st45: "37.7",
          st55: "128.3",
        },
        {
          time: "2023-07-25 10:00:00.000000000",
          at: "29.0",
          sm5: "95.9",
          sm15: "51.03",
          sm25: "77.47",
          sm35: "100.0",
          sm45: "83.91",
          sm55: "95.9",
          st5: "406.6",
          st15: "2.3",
          st25: "151.9",
          st35: "172.8",
          st45: "37.6",
          st55: "280.2",
        },
        {
          time: "2023-07-25 09:00:00.000000000",
          at: "27.5",
          sm5: "94.93",
          sm15: "50.9",
          sm25: "76.06",
          sm35: "100.0",
          sm45: "82.81",
          sm55: "94.93",
          st5: "163.0",
          st15: "2.3",
          st25: "162.7",
          st35: "115.2",
          st45: "37.7",
          st55: "26.3",
        },
        {
          time: "2023-07-25 08:00:00.000000000",
          at: "25.5",
          sm5: "93.26",
          sm15: "50.65",
          sm25: "74.61",
          sm35: "100.0",
          sm45: "81.81",
          sm55: "93.26",
          st5: "380.8",
          st15: "2.3",
          st25: "174.4",
          st35: "9.6",
          st45: "37.7",
          st55: "166.2",
        },
        {
          time: "2023-07-25 07:00:00.000000000",
          at: "23.4",
          sm5: "91.21",
          sm15: "49.24",
          sm25: "72.35",
          sm35: "100.0",
          sm45: "79.58",
          sm55: "91.21",
          st5: "78.4",
          st15: "2.3",
          st25: "192.1",
          st35: "118.4",
          st45: "37.8",
          st55: "70.6",
        },
        {
          time: "2023-07-25 06:00:00.000000000",
          at: "21.2",
          sm5: "89.07",
          sm15: "48.24",
          sm25: "70.76",
          sm35: "100.0",
          sm45: "77.85",
          sm55: "89.07",
          st5: "163.2",
          st15: "2.3",
          st25: "204.9",
          st35: "249.6",
          st45: "37.8",
          st55: "313.7",
        },
        {
          time: "2023-07-25 05:00:00.000000000",
          at: "18.5",
          sm5: "86.89",
          sm15: "46.79",
          sm25: "68.24",
          sm35: "100.0",
          sm45: "75.72",
          sm55: "86.89",
          st5: "325.8",
          st15: "2.3",
          st25: "226.2",
          st35: "19.2",
          st45: "37.9",
          st55: "204.5",
        },
        {
          time: "2023-07-25 04:00:00.000000000",
          at: "16.2",
          sm5: "84.67",
          sm15: "45.43",
          sm25: "66.39",
          sm35: "100.0",
          sm45: "74.07",
          sm55: "84.67",
          st5: "61.2",
          st15: "2.3",
          st25: "244.9",
          st35: "108.8",
          st45: "38.0",
          st55: "27.3",
        },
        {
          time: "2023-07-25 03:00:00.000000000",
          at: "12.3",
          sm5: "80.72",
          sm15: "42.3",
          sm25: "62.13",
          sm35: "95.64",
          sm45: "69.53",
          sm55: "80.72",
          st5: "175.6",
          st15: "2.3",
          st25: "280.8",
          st35: "300.8",
          st45: "38.1",
          st55: "256.6",
        },
        {
          time: "2023-07-25 02:00:00.000000000",
          at: "12.0",
          sm5: "80.29",
          sm15: "43.49",
          sm25: "62.55",
          sm35: "95.11",
          sm45: "69.12",
          sm55: "80.29",
          st5: "231.6",
          st15: "2.3",
          st25: "284.6",
          st35: "204.8",
          st45: "38.1",
          st55: "314.2",
        },
        {
          time: "2023-07-25 01:00:00.000000000",
          at: "12.6",
          sm5: "80.48",
          sm15: "45.73",
          sm25: "64.58",
          sm35: "95.71",
          sm45: "68.28",
          sm55: "80.48",
          st5: "140.2",
          st15: "2.3",
          st25: "280.3",
          st35: "140.8",
          st45: "38.2",
          st55: "22.9",
        },
        {
          time: "2023-07-24 23:00:00.000000000",
          at: "14.7",
          sm5: "82.63",
          sm15: "16.95",
          sm25: "48.5",
          sm35: "97.76",
          sm45: "74.78",
          sm55: "82.63",
          st5: "62.4",
          st15: "2.3",
          st25: "265.6",
          st35: "262.4",
          st45: "37.9",
          st55: "337.4",
        },
        {
          time: "2023-07-24 22:00:00.000000000",
          at: "15.6",
          sm5: "83.62",
          sm15: "16.18",
          sm25: "49.31",
          sm35: "98.68",
          sm45: "73.18",
          sm55: "83.62",
          st5: "198.4",
          st15: "2.3",
          st25: "259.1",
          st35: "80.0",
          st45: "38.0",
          st55: "152.2",
        },
        {
          time: "2023-07-24 21:00:00.000000000",
          at: "17.4",
          sm5: "84.77",
          sm15: "17.74",
          sm25: "51.7",
          sm35: "100.0",
          sm45: "74.19",
          sm55: "84.77",
          st5: "284.0",
          st15: "2.3",
          st25: "245.3",
          st35: "16.0",
          st45: "38.0",
          st55: "10.9",
        },
        {
          time: "2023-07-24 20:00:00.000000000",
          at: "18.1",
          sm5: "85.84",
          sm15: "18.55",
          sm25: "52.56",
          sm35: "100.0",
          sm45: "74.91",
          sm55: "85.84",
          st5: "318.2",
          st15: "2.3",
          st25: "238.7",
          st35: "160.0",
          st45: "37.9",
          st55: "318.1",
        },
        {
          time: "2023-07-24 19:00:00.000000000",
          at: "20.0",
          sm5: "87.99",
          sm15: "20.03",
          sm25: "54.77",
          sm35: "100.0",
          sm45: "76.47",
          sm55: "87.99",
          st5: "321.6",
          st15: "2.3",
          st25: "223.6",
          st35: "249.6",
          st45: "37.9",
          st55: "98.6",
        },
        {
          time: "2023-07-24 17:00:00.000000000",
          at: "22.8",
          sm5: "90.12",
          sm15: "22.2",
          sm25: "58.21",
          sm35: "100.0",
          sm45: "78.49",
          sm55: "90.12",
          st5: "74.0",
          st15: "2.3",
          st25: "204.1",
          st35: "166.4",
          st45: "37.8",
          st55: "224.4",
        },
        {
          time: "2023-07-24 16:00:00.000000000",
          at: "24.5",
          sm5: "91.35",
          sm15: "23.59",
          sm25: "60.27",
          sm35: "100.0",
          sm45: "80.02",
          sm55: "91.35",
          st5: "355.0",
          st15: "2.3",
          st25: "194.0",
          st35: "76.8",
          st45: "37.8",
          st55: "8.9",
        },
        {
          time: "2023-07-24 12:00:00.000000000",
          at: "30.4",
          sm5: "7.17",
          sm15: "2.59",
          sm25: "8.15",
          sm35: "9.39",
          sm45: "8.77",
          sm55: "7.17",
          st5: "288.4",
          st15: "2.3",
          st25: "149.2",
          st35: "336.0",
          st45: "37.6",
          st55: "52.4",
        },
        {
          time: "2023-07-24 11:00:00.000000000",
          at: "30.6",
          sm5: "7.2",
          sm15: "2.63",
          sm25: "8.15",
          sm35: "9.38",
          sm45: "8.86",
          sm55: "7.2",
          st5: "207.2",
          st15: "2.3",
          st25: "149.7",
          st35: "92.8",
          st45: "37.5",
          st55: "319.7",
        },
        {
          time: "2023-07-24 10:00:00.000000000",
          at: "31.8",
          sm5: "7.26",
          sm15: "2.72",
          sm25: "8.25",
          sm35: "9.45",
          sm45: "9.08",
          sm55: "7.26",
          st5: "330.2",
          st15: "2.3",
          st25: "142.8",
          st35: "188.8",
          st45: "37.4",
          st55: "401.6",
        },
        {
          time: "2023-07-24 09:00:00.000000000",
          at: "31.8",
          sm5: "7.3",
          sm15: "2.78",
          sm25: "8.25",
          sm35: "9.49",
          sm45: "9.12",
          sm55: "7.3",
          st5: "314.6",
          st15: "2.3",
          st25: "139.0",
          st35: "102.4",
          st45: "37.4",
          st55: "341.6",
        },
        {
          time: "2023-07-24 08:00:00.000000000",
          at: "28.9",
          sm5: "7.08",
          sm15: "2.62",
          sm25: "8.03",
          sm35: "9.26",
          sm45: "8.88",
          sm55: "7.08",
          st5: "249.4",
          st15: "2.3",
          st25: "160.9",
          st35: "387.2",
          st45: "37.5",
          st55: "288.1",
        },
        {
          time: "2023-07-24 07:00:00.000000000",
          at: "26.8",
          sm5: "6.93",
          sm15: "2.52",
          sm25: "7.91",
          sm35: "9.13",
          sm45: "8.75",
          sm55: "6.93",
          st5: "345.4",
          st15: "2.3",
          st25: "173.2",
          st35: "259.2",
          st45: "37.6",
          st55: "73.8",
        },
        {
          time: "2023-07-24 06:00:00.000000000",
          at: "24.9",
          sm5: "6.79",
          sm15: "2.42",
          sm25: "7.76",
          sm35: "8.98",
          sm45: "8.62",
          sm55: "6.79",
          st5: "253.4",
          st15: "2.3",
          st25: "187.4",
          st35: "211.2",
          st45: "37.6",
          st55: "272.9",
        },
        {
          time: "2023-07-24 05:00:00.000000000",
          at: "22.5",
          sm5: "6.6",
          sm15: "2.24",
          sm25: "7.6",
          sm35: "8.8",
          sm45: "8.44",
          sm55: "6.6",
          st5: "27.8",
          st15: "2.3",
          st25: "204.6",
          st35: "144.0",
          st45: "37.7",
          st55: "135.3",
        },
        {
          time: "2023-07-24 04:00:00.000000000",
          at: "20.0",
          sm5: "6.35",
          sm15: "2.02",
          sm25: "7.36",
          sm35: "8.58",
          sm45: "8.19",
          sm55: "6.35",
          st5: "178.8",
          st15: "2.3",
          st25: "225.4",
          st35: "320.0",
          st45: "37.8",
          st55: "109.2",
        },
        {
          time: "2023-07-24 03:00:00.000000000",
          at: "16.7",
          sm5: "6.15",
          sm15: "1.71",
          sm25: "7.13",
          sm35: "8.33",
          sm45: "7.98",
          sm55: "6.15",
          st5: "140.6",
          st15: "2.3",
          st25: "249.6",
          st35: "99.2",
          st45: "37.9",
          st55: "15.6",
        },
        {
          time: "2023-07-24 02:00:00.000000000",
          at: "16.6",
          sm5: "6.11",
          sm15: "1.72",
          sm25: "7.17",
          sm35: "8.37",
          sm45: "7.98",
          sm55: "6.11",
          st5: "253.0",
          st15: "2.3",
          st25: "245.9",
          st35: "400.0",
          st45: "37.9",
          st55: "14.2",
        },
        {
          time: "2023-07-24 01:00:00.000000000",
          at: "16.3",
          sm5: "6.08",
          sm15: "1.69",
          sm25: "7.12",
          sm35: "8.3",
          sm45: "7.94",
          sm55: "6.08",
          st5: "255.4",
          st15: "2.3",
          st25: "252.1",
          st35: "339.2",
          st45: "37.9",
          st55: "87.4",
        },
        {
          time: "2023-07-24 00:00:00.000000000",
          at: "17.4",
          sm5: "6.16",
          sm15: "1.8",
          sm25: "7.22",
          sm35: "8.39",
          sm45: "8.02",
          sm55: "6.16",
          st5: "359.8",
          st15: "2.3",
          st25: "243.7",
          st35: "294.4",
          st45: "37.8",
          st55: "369.1",
        },
        {
          time: "2023-07-23 23:00:00.000000000",
          at: "19.6",
          sm5: "6.33",
          sm15: "2.0",
          sm25: "7.42",
          sm35: "8.55",
          sm45: "8.18",
          sm55: "6.33",
          st5: "23.2",
          st15: "2.3",
          st25: "228.3",
          st35: "256.0",
          st45: "37.8",
          st55: "130.1",
        },
        {
          time: "2023-07-23 22:00:00.000000000",
          at: "20.8",
          sm5: "6.45",
          sm15: "2.13",
          sm25: "7.53",
          sm35: "8.67",
          sm45: "8.27",
          sm55: "6.45",
          st5: "265.8",
          st15: "2.3",
          st25: "217.5",
          st35: "0.0",
          st45: "37.7",
          st55: "395.7",
        },
        {
          time: "2023-07-23 21:00:00.000000000",
          at: "20.9",
          sm5: "6.42",
          sm15: "2.13",
          sm25: "7.53",
          sm35: "8.65",
          sm45: "8.26",
          sm55: "6.42",
          st5: "325.8",
          st15: "2.3",
          st25: "219.0",
          st35: "150.4",
          st45: "37.8",
          st55: "6.4",
        },
        {
          time: "2023-07-23 20:00:00.000000000",
          at: "21.5",
          sm5: "6.46",
          sm15: "2.14",
          sm25: "7.59",
          sm35: "8.68",
          sm45: "8.28",
          sm55: "6.46",
          st5: "4.0",
          st15: "2.3",
          st25: "216.0",
          st35: "150.4",
          st45: "37.7",
          st55: "384.9",
        },
        {
          time: "2023-07-23 19:00:00.000000000",
          at: "22.6",
          sm5: "6.56",
          sm15: "2.28",
          sm25: "7.72",
          sm35: "8.78",
          sm45: "8.38",
          sm55: "6.56",
          st5: "33.8",
          st15: "2.3",
          st25: "207.1",
          st35: "380.8",
          st45: "37.7",
          st55: "238.0",
        },
        {
          time: "2023-07-23 18:00:00.000000000",
          at: "24.8",
          sm5: "6.72",
          sm15: "2.45",
          sm25: "7.9",
          sm35: "8.94",
          sm45: "8.56",
          sm55: "6.72",
          st5: "187.2",
          st15: "2.3",
          st25: "191.5",
          st35: "361.6",
          st45: "37.6",
          st55: "366.9",
        },
        {
          time: "2023-07-23 17:00:00.000000000",
          at: "24.9",
          sm5: "6.76",
          sm15: "2.43",
          sm25: "7.92",
          sm35: "8.94",
          sm45: "8.55",
          sm55: "6.76",
          st5: "166.4",
          st15: "2.3",
          st25: "191.3",
          st35: "291.2",
          st45: "37.6",
          st55: "380.3",
        },
        {
          time: "2023-07-23 16:00:00.000000000",
          at: "27.6",
          sm5: "6.93",
          sm15: "2.66",
          sm25: "8.12",
          sm35: "9.13",
          sm45: "8.73",
          sm55: "6.93",
          st5: "254.8",
          st15: "2.3",
          st25: "173.9",
          st35: "339.2",
          st45: "37.6",
          st55: "106.6",
        },
        {
          time: "2023-07-23 15:00:00.000000000",
          at: "29.2",
          sm5: "7.05",
          sm15: "2.78",
          sm25: "8.24",
          sm35: "9.25",
          sm45: "8.84",
          sm55: "7.05",
          st5: "226.6",
          st15: "2.3",
          st25: "162.1",
          st35: "124.8",
          st45: "37.5",
          st55: "348.1",
        },
        {
          time: "2023-07-23 14:00:00.000000000",
          at: "30.9",
          sm5: "7.17",
          sm15: "2.91",
          sm25: "8.32",
          sm35: "9.37",
          sm45: "8.97",
          sm55: "7.17",
          st5: "224.6",
          st15: "2.3",
          st25: "151.0",
          st35: "44.8",
          st45: "37.5",
          st55: "157.0",
        },
        {
          time: "2023-07-23 13:00:00.000000000",
          at: "32.7",
          sm5: "7.3",
          sm15: "3.09",
          sm25: "8.47",
          sm35: "9.52",
          sm45: "9.1",
          sm55: "7.3",
          st5: "312.0",
          st15: "2.3",
          st25: "136.5",
          st35: "9.6",
          st45: "37.4",
          st55: "358.1",
        },
        {
          time: "2023-07-23 12:00:00.000000000",
          at: "32.5",
          sm5: "7.28",
          sm15: "3.14",
          sm25: "8.44",
          sm35: "9.48",
          sm45: "9.09",
          sm55: "7.28",
          st5: "214.4",
          st15: "2.3",
          st25: "140.3",
          st35: "41.6",
          st45: "37.4",
          st55: "383.6",
        },
        {
          time: "2023-07-23 11:00:00.000000000",
          at: "32.7",
          sm5: "7.29",
          sm15: "3.23",
          sm25: "8.5",
          sm35: "9.5",
          sm45: "9.09",
          sm55: "7.29",
          st5: "407.0",
          st15: "2.3",
          st25: "138.2",
          st35: "163.2",
          st45: "37.4",
          st55: "381.3",
        },
        {
          time: "2023-07-23 10:00:00.000000000",
          at: "35.0",
          sm5: "7.51",
          sm15: "3.49",
          sm25: "8.67",
          sm35: "9.7",
          sm45: "9.3",
          sm55: "7.51",
          st5: "17.8",
          st15: "2.3",
          st25: "119.3",
          st35: "390.4",
          st45: "37.4",
          st55: "61.9",
        },
        {
          time: "2023-07-23 09:00:00.000000000",
          at: "33.8",
          sm5: "7.38",
          sm15: "3.39",
          sm25: "8.61",
          sm35: "9.6",
          sm45: "9.22",
          sm55: "7.38",
          st5: "112.0",
          st15: "2.3",
          st25: "128.4",
          st35: "380.8",
          st45: "37.4",
          st55: "180.5",
        },
        {
          time: "2023-07-23 08:00:00.000000000",
          at: "30.7",
          sm5: "7.17",
          sm15: "3.07",
          sm25: "8.37",
          sm35: "9.37",
          sm45: "8.99",
          sm55: "7.17",
          st5: "135.2",
          st15: "2.3",
          st25: "150.9",
          st35: "211.2",
          st45: "37.5",
          st55: "116.0",
        },
        {
          time: "2023-07-23 07:00:00.000000000",
          at: "29.0",
          sm5: "7.06",
          sm15: "2.81",
          sm25: "8.31",
          sm35: "9.27",
          sm45: "8.9",
          sm55: "7.06",
          st5: "20.8",
          st15: "2.3",
          st25: "160.2",
          st35: "22.4",
          st45: "37.5",
          st55: "256.5",
        },
        {
          time: "2023-07-23 06:00:00.000000000",
          at: "27.2",
          sm5: "6.92",
          sm15: "2.64",
          sm25: "8.18",
          sm35: "9.15",
          sm45: "8.77",
          sm55: "6.92",
          st5: "325.8",
          st15: "2.3",
          st25: "171.3",
          st35: "387.2",
          st45: "37.6",
          st55: "42.7",
        },
        {
          time: "2023-07-23 05:00:00.000000000",
          at: "22.1",
          sm5: "6.53",
          sm15: "2.38",
          sm25: "7.82",
          sm35: "8.79",
          sm45: "8.41",
          sm55: "6.53",
          st5: "228.4",
          st15: "2.3",
          st25: "206.3",
          st35: "99.2",
          st45: "37.7",
          st55: "188.2",
        },
        {
          time: "2023-07-23 04:00:00.000000000",
          at: "18.0",
          sm5: "6.22",
          sm15: "2.06",
          sm25: "7.52",
          sm35: "8.44",
          sm45: "8.07",
          sm55: "6.22",
          st5: "122.2",
          st15: "2.3",
          st25: "238.8",
          st35: "150.4",
          st45: "37.8",
          st55: "298.6",
        },
        {
          time: "2023-07-23 03:00:00.000000000",
          at: "13.1",
          sm5: "5.74",
          sm15: "1.58",
          sm25: "7.06",
          sm35: "7.99",
          sm45: "7.62",
          sm55: "5.74",
          st5: "204.2",
          st15: "2.3",
          st25: "282.3",
          st35: "131.2",
          st45: "38.0",
          st55: "163.9",
        },
        {
          time: "2023-07-23 02:00:00.000000000",
          at: "12.3",
          sm5: "5.69",
          sm15: "1.55",
          sm25: "6.99",
          sm35: "7.97",
          sm45: "7.38",
          sm55: "5.69",
          st5: "305.4",
          st15: "2.3",
          st25: "283.5",
          st35: "188.8",
          st45: "38.1",
          st55: "108.2",
        },
        {
          time: "2023-07-23 01:00:00.000000000",
          at: "12.9",
          sm5: "5.73",
          sm15: "1.61",
          sm25: "7.08",
          sm35: "7.98",
          sm45: "7.41",
          sm55: "5.73",
          st5: "150.6",
          st15: "2.3",
          st25: "283.2",
          st35: "60.8",
          st45: "38.1",
          st55: "65.7",
        },
        {
          time: "2023-07-23 00:00:00.000000000",
          at: "14.1",
          sm5: "5.84",
          sm15: "1.68",
          sm25: "7.16",
          sm35: "8.09",
          sm45: "7.52",
          sm55: "5.84",
          st5: "261.0",
          st15: "2.3",
          st25: "272.1",
          st35: "76.8",
          st45: "38.0",
          st55: "315.4",
        },
        {
          time: "2023-07-22 23:00:00.000000000",
          at: "14.2",
          sm5: "5.85",
          sm15: "1.76",
          sm25: "7.21",
          sm35: "8.15",
          sm45: "7.55",
          sm55: "5.85",
          st5: "316.6",
          st15: "2.3",
          st25: "266.9",
          st35: "291.2",
          st45: "38.0",
          st55: "268.3",
        },
        {
          time: "2023-07-22 22:00:00.000000000",
          at: "15.3",
          sm5: "5.91",
          sm15: "1.8",
          sm25: "7.28",
          sm35: "8.16",
          sm45: "7.55",
          sm55: "5.91",
          st5: "184.6",
          st15: "2.3",
          st25: "265.7",
          st35: "300.8",
          st45: "38.0",
          st55: "266.5",
        },
        {
          time: "2023-07-22 21:00:00.000000000",
          at: "17.1",
          sm5: "6.11",
          sm15: "2.0",
          sm25: "7.51",
          sm35: "8.38",
          sm45: "7.7",
          sm55: "6.11",
          st5: "258.4",
          st15: "2.3",
          st25: "244.6",
          st35: "393.6",
          st45: "38.0",
          st55: "39.9",
        },
        {
          time: "2023-07-22 20:00:00.000000000",
          at: "18.1",
          sm5: "6.19",
          sm15: "2.07",
          sm25: "7.59",
          sm35: "8.47",
          sm45: "7.78",
          sm55: "6.19",
          st5: "80.0",
          st15: "2.3",
          st25: "236.4",
          st35: "128.0",
          st45: "37.9",
          st55: "322.4",
        },
        {
          time: "2023-07-22 19:00:00.000000000",
          at: "19.3",
          sm5: "6.23",
          sm15: "2.13",
          sm25: "7.69",
          sm35: "8.52",
          sm45: "7.81",
          sm55: "6.23",
          st5: "47.6",
          st15: "2.3",
          st25: "231.3",
          st35: "89.6",
          st45: "37.9",
          st55: "285.4",
        },
        {
          time: "2023-07-22 18:00:00.000000000",
          at: "22.7",
          sm5: "6.53",
          sm15: "2.36",
          sm25: "7.94",
          sm35: "8.77",
          sm45: "8.1",
          sm55: "6.53",
          st5: "404.4",
          st15: "2.3",
          st25: "207.7",
          st35: "348.8",
          st45: "37.8",
          st55: "246.7",
        },
        {
          time: "2023-07-22 17:00:00.000000000",
          at: "23.5",
          sm5: "6.6",
          sm15: "2.44",
          sm25: "8.04",
          sm35: "8.85",
          sm45: "8.17",
          sm55: "6.6",
          st5: "114.4",
          st15: "2.3",
          st25: "199.9",
          st35: "329.6",
          st45: "37.8",
          st55: "137.6",
        },
        {
          time: "2023-07-22 16:00:00.000000000",
          at: "26.6",
          sm5: "6.84",
          sm15: "2.72",
          sm25: "8.26",
          sm35: "9.11",
          sm45: "8.4",
          sm55: "6.84",
          st5: "65.0",
          st15: "2.3",
          st25: "175.7",
          st35: "86.4",
          st45: "37.7",
          st55: "201.8",
        },
        {
          time: "2023-07-22 15:00:00.000000000",
          at: "28.5",
          sm5: "6.96",
          sm15: "2.86",
          sm25: "8.4",
          sm35: "9.21",
          sm45: "8.57",
          sm55: "6.96",
          st5: "9.2",
          st15: "2.3",
          st25: "165.9",
          st35: "188.8",
          st45: "37.6",
          st55: "349.6",
        },
        {
          time: "2023-07-22 14:00:00.000000000",
          at: "29.6",
          sm5: "7.03",
          sm15: "2.99",
          sm25: "8.47",
          sm35: "9.29",
          sm45: "8.71",
          sm55: "7.03",
          st5: "197.0",
          st15: "2.3",
          st25: "158.5",
          st35: "377.6",
          st45: "37.6",
          st55: "139.9",
        },
        {
          time: "2023-07-22 13:00:00.000000000",
          at: "31.4",
          sm5: "7.15",
          sm15: "3.16",
          sm25: "8.61",
          sm35: "9.38",
          sm45: "8.83",
          sm55: "7.15",
          st5: "180.2",
          st15: "2.3",
          st25: "149.4",
          st35: "3.2",
          st45: "37.5",
          st55: "365.2",
        },
        {
          time: "2023-07-22 12:00:00.000000000",
          at: "31.8",
          sm5: "7.22",
          sm15: "3.22",
          sm25: "8.65",
          sm35: "9.45",
          sm45: "8.95",
          sm55: "7.22",
          st5: "380.2",
          st15: "2.3",
          st25: "142.8",
          st35: "384.0",
          st45: "37.5",
          st55: "185.3",
        },
        {
          time: "2023-07-22 11:00:00.000000000",
          at: "32.6",
          sm5: "7.25",
          sm15: "3.29",
          sm25: "8.7",
          sm35: "9.47",
          sm45: "9.08",
          sm55: "7.25",
          st5: "318.0",
          st15: "2.3",
          st25: "140.9",
          st35: "22.4",
          st45: "37.4",
          st55: "401.1",
        },
        {
          time: "2023-07-22 10:00:00.000000000",
          at: "34.1",
          sm5: "7.35",
          sm15: "3.43",
          sm25: "8.81",
          sm35: "9.57",
          sm45: "9.18",
          sm55: "7.35",
          st5: "271.8",
          st15: "2.3",
          st25: "131.7",
          st35: "291.2",
          st45: "37.4",
          st55: "241.0",
        },
        {
          time: "2023-07-22 09:00:00.000000000",
          at: "32.0",
          sm5: "7.23",
          sm15: "3.32",
          sm25: "8.65",
          sm35: "9.44",
          sm45: "9.07",
          sm55: "7.23",
          st5: "0.0",
          st15: "4.8",
          st25: "77.1",
          st35: "4.8",
          st45: "77.1",
          st55: "38.5",
        },
        {
          time: "2023-07-22 07:00:00.000000000",
          at: "28.2",
          sm5: "6.97",
          sm15: "3.18",
          sm25: "8.39",
          sm35: "9.21",
          sm45: "8.83",
          sm55: "6.97",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-22 06:00:00.000000000",
          at: "26.1",
          sm5: "6.79",
          sm15: "3.08",
          sm25: "8.26",
          sm35: "9.07",
          sm45: "8.64",
          sm55: "6.79",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-22 05:00:00.000000000",
          at: "22.5",
          sm5: "6.51",
          sm15: "2.83",
          sm25: "7.99",
          sm35: "8.81",
          sm45: "8.36",
          sm55: "6.51",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-22 04:00:00.000000000",
          at: "19.0",
          sm5: "6.22",
          sm15: "2.51",
          sm25: "7.71",
          sm35: "8.51",
          sm45: "8.02",
          sm55: "6.22",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-22 03:00:00.000000000",
          at: "13.8",
          sm5: "5.78",
          sm15: "2.06",
          sm25: "7.29",
          sm35: "8.06",
          sm45: "7.26",
          sm55: "5.78",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-22 02:00:00.000000000",
          at: "13.1",
          sm5: "5.68",
          sm15: "2.02",
          sm25: "7.24",
          sm35: "7.97",
          sm45: "7.19",
          sm55: "5.68",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-22 01:00:00.000000000",
          at: "13.4",
          sm5: "5.77",
          sm15: "2.06",
          sm25: "7.31",
          sm35: "8.08",
          sm45: "7.26",
          sm55: "5.77",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-22 00:00:00.000000000",
          at: "14.6",
          sm5: "5.84",
          sm15: "2.18",
          sm25: "7.4",
          sm35: "8.14",
          sm45: "7.34",
          sm55: "5.84",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-21 23:00:00.000000000",
          at: "16.6",
          sm5: "6.0",
          sm15: "2.33",
          sm25: "7.55",
          sm35: "8.31",
          sm45: "7.49",
          sm55: "6.0",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-21 22:00:00.000000000",
          at: "17.2",
          sm5: "6.06",
          sm15: "2.4",
          sm25: "7.65",
          sm35: "8.38",
          sm45: "7.56",
          sm55: "6.06",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-21 21:00:00.000000000",
          at: "17.1",
          sm5: "6.04",
          sm15: "2.42",
          sm25: "7.64",
          sm35: "8.35",
          sm45: "7.54",
          sm55: "6.04",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-21 20:00:00.000000000",
          at: "18.2",
          sm5: "6.15",
          sm15: "2.51",
          sm25: "7.74",
          sm35: "8.45",
          sm45: "7.64",
          sm55: "6.15",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-21 19:00:00.000000000",
          at: "20.7",
          sm5: "6.35",
          sm15: "2.7",
          sm25: "7.94",
          sm35: "8.63",
          sm45: "7.8",
          sm55: "6.35",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-21 18:00:00.000000000",
          at: "22.5",
          sm5: "6.46",
          sm15: "2.85",
          sm25: "8.08",
          sm35: "8.77",
          sm45: "7.9",
          sm55: "6.46",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-21 17:00:00.000000000",
          at: "22.1",
          sm5: "6.47",
          sm15: "2.84",
          sm25: "8.1",
          sm35: "8.75",
          sm45: "7.92",
          sm55: "6.47",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-21 16:00:00.000000000",
          at: "25.0",
          sm5: "6.68",
          sm15: "3.1",
          sm25: "8.32",
          sm35: "8.96",
          sm45: "8.16",
          sm55: "6.68",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-21 15:00:00.000000000",
          at: "27.1",
          sm5: "6.82",
          sm15: "3.25",
          sm25: "8.43",
          sm35: "9.09",
          sm45: "8.28",
          sm55: "6.82",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-21 14:00:00.000000000",
          at: "29.37",
          sm5: "6.91",
          sm15: "3.39",
          sm25: "8.53",
          sm35: "9.2",
          sm45: "8.37",
          sm55: "6.91",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-21 13:00:00.000000000",
          at: "30.72",
          sm5: "7.04",
          sm15: "3.56",
          sm25: "8.65",
          sm35: "9.31",
          sm45: "8.48",
          sm55: "7.04",
          st5: "0.0",
          st15: "0.3",
          st25: "4.8",
          st35: "77.1",
          st45: "4.8",
          st55: "79.2",
        },
        {
          time: "2023-07-21 00:00:00.000000000",
          at: "13.7",
          sm5: "5.74",
          sm15: "2.83",
          sm25: "7.39",
          sm35: "8.04",
          sm45: "7.38",
          sm55: "5.74",
          st5: "20.15",
          st15: "17.25",
          st25: "20.4",
          st35: "19.25",
          st45: "20.2",
          st55: "19.25",
        },
        {
          time: "2023-07-20 23:00:00.000000000",
          at: "14.27",
          sm5: "5.77",
          sm15: "2.87",
          sm25: "7.44",
          sm35: "8.08",
          sm45: "7.43",
          sm55: "5.77",
          st5: "20.43",
          st15: "17.6",
          st25: "20.53",
          st35: "19.2",
          st45: "20.2",
          st55: "19.27",
        },
        {
          time: "2023-07-20 22:00:00.000000000",
          at: "14.38",
          sm5: "5.78",
          sm15: "2.88",
          sm25: "7.46",
          sm35: "8.08",
          sm45: "7.43",
          sm55: "5.78",
          st5: "20.65",
          st15: "18.05",
          st25: "20.6",
          st35: "19.23",
          st45: "20.2",
          st55: "19.25",
        },
        {
          time: "2023-07-20 21:00:00.000000000",
          at: "14.6",
          sm5: "5.81",
          sm15: "2.94",
          sm25: "7.49",
          sm35: "8.11",
          sm45: "7.46",
          sm55: "5.81",
          st5: "20.93",
          st15: "18.47",
          st25: "20.6",
          st35: "19.2",
          st45: "20.13",
          st55: "19.27",
        },
        {
          time: "2023-07-20 20:00:00.000000000",
          at: "15.25",
          sm5: "5.88",
          sm15: "3.02",
          sm25: "7.57",
          sm35: "8.16",
          sm45: "7.53",
          sm55: "5.88",
          st5: "21.15",
          st15: "18.9",
          st25: "20.6",
          st35: "19.2",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-07-20 19:00:00.000000000",
          at: "17.0",
          sm5: "5.99",
          sm15: "3.2",
          sm25: "7.69",
          sm35: "8.29",
          sm45: "7.63",
          sm55: "5.99",
          st5: "21.38",
          st15: "19.7",
          st25: "20.6",
          st35: "19.18",
          st45: "20.02",
          st55: "19.24",
        },
        {
          time: "2023-07-20 18:00:00.000000000",
          at: "17.47",
          sm5: "6.04",
          sm15: "3.26",
          sm25: "7.73",
          sm35: "8.33",
          sm45: "7.69",
          sm55: "6.04",
          st5: "21.67",
          st15: "20.37",
          st25: "20.6",
          st35: "19.1",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-07-20 17:00:00.000000000",
          at: "18.55",
          sm5: "6.12",
          sm15: "3.35",
          sm25: "7.81",
          sm35: "8.41",
          sm45: "7.77",
          sm55: "6.12",
          st5: "21.7",
          st15: "20.9",
          st25: "20.5",
          st35: "19.15",
          st45: "19.8",
          st55: "19.22",
        },
        {
          time: "2023-07-20 16:00:00.000000000",
          at: "22.57",
          sm5: "6.4",
          sm15: "3.65",
          sm25: "8.09",
          sm35: "8.7",
          sm45: "8.06",
          sm55: "6.4",
          st5: "21.8",
          st15: "22.03",
          st25: "20.3",
          st35: "19.13",
          st45: "19.8",
          st55: "19.21",
        },
        {
          time: "2023-07-20 15:00:00.000000000",
          at: "25.7",
          sm5: "6.66",
          sm15: "3.92",
          sm25: "8.35",
          sm35: "8.95",
          sm45: "8.31",
          sm55: "6.66",
          st5: "21.8",
          st15: "22.83",
          st25: "20.17",
          st35: "19.13",
          st45: "19.77",
          st55: "19.22",
        },
        {
          time: "2023-07-20 14:00:00.000000000",
          at: "27.63",
          sm5: "6.83",
          sm15: "4.03",
          sm25: "8.47",
          sm35: "9.07",
          sm45: "8.46",
          sm55: "6.83",
          st5: "21.69",
          st15: "23.64",
          st25: "20.04",
          st35: "19.1",
          st45: "19.7",
          st55: "19.22",
        },
        {
          time: "2023-07-20 11:00:00.000000000",
          at: "30.2",
          sm5: "7.02",
          sm15: "4.39",
          sm25: "8.67",
          sm35: "9.28",
          sm45: "8.67",
          sm55: "7.02",
          st5: "20.2",
          st15: "24.2",
          st25: "19.3",
          st35: "19.1",
          st45: "19.7",
          st55: "19.22",
        },
        {
          time: "2023-07-20 10:00:00.000000000",
          at: "31.5",
          sm5: "7.09",
          sm15: "4.44",
          sm25: "8.75",
          sm35: "9.37",
          sm45: "8.75",
          sm55: "7.09",
          st5: "20.1",
          st15: "24.1",
          st25: "19.3",
          st35: "19.2",
          st45: "19.7",
          st55: "19.25",
        },
        {
          time: "2023-07-20 08:00:00.000000000",
          at: "27.4",
          sm5: "6.79",
          sm15: "4.23",
          sm25: "8.46",
          sm35: "9.1",
          sm45: "8.45",
          sm55: "6.79",
          st5: "19.2",
          st15: "21.3",
          st25: "19.3",
          st35: "19.2",
          st45: "19.8",
          st55: "19.23",
        },
        {
          time: "2023-07-20 06:00:00.000000000",
          at: "23.7",
          sm5: "6.54",
          sm15: "3.97",
          sm25: "8.2",
          sm35: "8.8",
          sm45: "8.2",
          sm55: "6.54",
          st5: "18.6",
          st15: "19.8",
          st25: "19.5",
          st35: "19.3",
          st45: "19.8",
          st55: "19.23",
        },
        {
          time: "2023-07-20 05:00:00.000000000",
          at: "19.1",
          sm5: "6.1",
          sm15: "3.54",
          sm25: "7.78",
          sm35: "8.38",
          sm45: "7.8",
          sm55: "6.1",
          st5: "18.6",
          st15: "17.5",
          st25: "19.6",
          st35: "19.2",
          st45: "20.0",
          st55: "19.26",
        },
        {
          time: "2023-07-20 04:00:00.000000000",
          at: "15.85",
          sm5: "5.86",
          sm15: "3.24",
          sm25: "7.52",
          sm35: "8.15",
          sm45: "7.55",
          sm55: "5.86",
          st5: "18.7",
          st15: "16.6",
          st25: "19.7",
          st35: "19.25",
          st45: "20.0",
          st55: "19.4",
        },
        {
          time: "2023-07-20 03:00:00.000000000",
          at: "12.03",
          sm5: "5.51",
          sm15: "2.87",
          sm25: "7.19",
          sm35: "7.82",
          sm45: "7.21",
          sm55: "5.51",
          st5: "18.8",
          st15: "15.73",
          st25: "19.73",
          st35: "19.3",
          st45: "20.03",
          st55: "19.43",
        },
        {
          time: "2023-07-20 02:00:00.000000000",
          at: "11.1",
          sm5: "5.41",
          sm15: "2.77",
          sm25: "7.09",
          sm35: "7.73",
          sm45: "7.15",
          sm55: "5.41",
          st5: "19.1",
          st15: "15.83",
          st25: "19.87",
          st35: "19.3",
          st45: "20.1",
          st55: "19.27",
        },
        {
          time: "2023-07-20 01:00:00.000000000",
          at: "11.73",
          sm5: "5.49",
          sm15: "2.86",
          sm25: "7.18",
          sm35: "7.8",
          sm45: "7.22",
          sm55: "5.49",
          st5: "19.3",
          st15: "16.2",
          st25: "20.03",
          st35: "19.27",
          st45: "20.1",
          st55: "19.37",
        },
        {
          time: "2023-07-20 00:00:00.000000000",
          at: "12.2",
          sm5: "5.53",
          sm15: "2.89",
          sm25: "7.21",
          sm35: "7.84",
          sm45: "7.3",
          sm55: "5.53",
          st5: "19.55",
          st15: "16.58",
          st25: "20.08",
          st35: "19.25",
          st45: "20.1",
          st55: "19.3",
        },
        {
          time: "2023-07-19 23:00:00.000000000",
          at: "12.97",
          sm5: "5.61",
          sm15: "2.97",
          sm25: "7.29",
          sm35: "7.91",
          sm45: "7.38",
          sm55: "5.61",
          st5: "19.73",
          st15: "17.1",
          st25: "20.13",
          st35: "19.23",
          st45: "20.1",
          st55: "19.37",
        },
        {
          time: "2023-07-19 22:00:00.000000000",
          at: "13.77",
          sm5: "5.67",
          sm15: "3.05",
          sm25: "7.38",
          sm35: "7.99",
          sm45: "7.49",
          sm55: "5.67",
          st5: "20.03",
          st15: "17.47",
          st25: "20.2",
          st35: "19.3",
          st45: "20.1",
          st55: "19.37",
        },
        {
          time: "2023-07-19 21:00:00.000000000",
          at: "14.48",
          sm5: "5.74",
          sm15: "3.13",
          sm25: "7.44",
          sm35: "8.05",
          sm45: "7.6",
          sm55: "5.74",
          st5: "20.2",
          st15: "17.85",
          st25: "20.25",
          st35: "19.25",
          st45: "20.1",
          st55: "19.33",
        },
        {
          time: "2023-07-19 20:00:00.000000000",
          at: "15.6",
          sm5: "5.82",
          sm15: "3.2",
          sm25: "7.53",
          sm35: "8.12",
          sm45: "7.68",
          sm55: "5.82",
          st5: "20.46",
          st15: "18.36",
          st25: "20.28",
          st35: "19.24",
          st45: "20.08",
          st55: "19.28",
        },
        {
          time: "2023-07-19 19:00:00.000000000",
          at: "17.18",
          sm5: "5.94",
          sm15: "3.33",
          sm25: "7.66",
          sm35: "8.25",
          sm45: "7.8",
          sm55: "5.94",
          st5: "20.6",
          st15: "18.75",
          st25: "20.28",
          st35: "19.22",
          st45: "20.07",
          st55: "19.32",
        },
        {
          time: "2023-07-19 18:00:00.000000000",
          at: "18.25",
          sm5: "5.99",
          sm15: "3.41",
          sm25: "7.75",
          sm35: "8.31",
          sm45: "7.87",
          sm55: "5.99",
          st5: "20.8",
          st15: "19.45",
          st25: "20.2",
          st35: "19.25",
          st45: "20.05",
          st55: "19.27",
        },
        {
          time: "2023-07-19 17:00:00.000000000",
          at: "18.66",
          sm5: "6.04",
          sm15: "3.47",
          sm25: "7.78",
          sm35: "8.37",
          sm45: "7.91",
          sm55: "6.04",
          st5: "20.93",
          st15: "19.99",
          st25: "20.2",
          st35: "19.21",
          st45: "20.0",
          st55: "19.31",
        },
        {
          time: "2023-07-19 16:00:00.000000000",
          at: "21.7",
          sm5: "6.28",
          sm15: "3.73",
          sm25: "8.02",
          sm35: "8.59",
          sm45: "8.14",
          sm55: "6.28",
          st5: "21.03",
          st15: "20.67",
          st25: "20.13",
          st35: "19.27",
          st45: "19.93",
          st55: "19.24",
        },
        {
          time: "2023-07-19 15:00:00.000000000",
          at: "24.0",
          sm5: "6.48",
          sm15: "3.92",
          sm25: "8.2",
          sm35: "8.77",
          sm45: "8.33",
          sm55: "6.48",
          st5: "21.0",
          st15: "21.19",
          st25: "20.0",
          st35: "19.2",
          st45: "19.8",
          st55: "19.27",
        },
        {
          time: "2023-07-19 03:00:00.000000000",
          at: "9.8",
          sm5: "5.23",
          sm15: "3.62",
          sm25: "7.12",
          sm35: "7.56",
          sm45: "7.15",
          sm55: "5.23",
          st5: "19.1",
          st15: "15.1",
          st25: "20.2",
          st35: "19.5",
          st45: "20.5",
          st55: "19.2",
        },
        {
          time: "2023-07-19 01:00:00.000000000",
          at: "10.4",
          sm5: "5.26",
          sm15: "3.79",
          sm25: "7.34",
          sm35: "7.59",
          sm45: "7.15",
          sm55: "5.26",
          st5: "19.6",
          st15: "15.7",
          st25: "20.5",
          st35: "19.5",
          st45: "20.5",
          st55: "19.5",
        },
        {
          time: "2023-07-19 00:00:00.000000000",
          at: "10.7",
          sm5: "5.3",
          sm15: "3.91",
          sm25: "7.56",
          sm35: "7.61",
          sm45: "7.13",
          sm55: "5.3",
          st5: "19.95",
          st15: "16.3",
          st25: "20.65",
          st35: "19.5",
          st45: "20.5",
          st55: "19.3",
        },
        {
          time: "2023-07-18 23:00:00.000000000",
          at: "11.6",
          sm5: "5.35",
          sm15: "4.55",
          sm25: "7.54",
          sm35: "7.69",
          sm45: "7.17",
          sm55: "5.35",
          st5: "20.3",
          st15: "16.7",
          st25: "20.8",
          st35: "19.5",
          st45: "20.5",
          st55: "19.3",
        },
        {
          time: "2023-07-18 22:00:00.000000000",
          at: "13.75",
          sm5: "5.57",
          sm15: "4.82",
          sm25: "8.41",
          sm35: "7.9",
          sm45: "7.26",
          sm55: "5.57",
          st5: "20.5",
          st15: "17.1",
          st25: "20.8",
          st35: "19.3",
          st45: "20.5",
          st55: "19.3",
        },
        {
          time: "2023-07-18 21:00:00.000000000",
          at: "14.27",
          sm5: "5.61",
          sm15: "1.21",
          sm25: "6.65",
          sm35: "7.91",
          sm45: "7.55",
          sm55: "5.61",
          st5: "21.23",
          st15: "18.53",
          st25: "21.0",
          st35: "19.3",
          st45: "20.3",
          st55: "19.37",
        },
        {
          time: "2023-07-18 20:00:00.000000000",
          at: "14.37",
          sm5: "5.62",
          sm15: "1.22",
          sm25: "6.67",
          sm35: "7.92",
          sm45: "7.57",
          sm55: "5.62",
          st5: "21.6",
          st15: "18.87",
          st25: "21.1",
          st35: "19.3",
          st45: "20.3",
          st55: "19.27",
        },
        {
          time: "2023-07-18 19:00:00.000000000",
          at: "14.4",
          sm5: "5.61",
          sm15: "1.23",
          sm25: "6.67",
          sm35: "7.93",
          sm45: "7.56",
          sm55: "5.61",
          st5: "21.85",
          st15: "19.35",
          st25: "21.05",
          st35: "19.2",
          st45: "20.3",
          st55: "19.3",
        },
        {
          time: "2023-07-18 18:00:00.000000000",
          at: "15.52",
          sm5: "5.7",
          sm15: "1.33",
          sm25: "6.77",
          sm35: "8.02",
          sm45: "7.67",
          sm55: "5.7",
          st5: "22.07",
          st15: "20.15",
          st25: "21.0",
          st35: "19.2",
          st45: "20.2",
          st55: "19.28",
        },
        {
          time: "2023-07-18 17:00:00.000000000",
          at: "19.44",
          sm5: "6.01",
          sm15: "1.64",
          sm25: "7.09",
          sm35: "8.3",
          sm45: "7.96",
          sm55: "6.01",
          st5: "22.3",
          st15: "21.62",
          st25: "20.84",
          st35: "19.22",
          st45: "20.16",
          st55: "19.23",
        },
        {
          time: "2023-07-18 16:00:00.000000000",
          at: "23.9",
          sm5: "6.35",
          sm15: "2.02",
          sm25: "7.44",
          sm35: "8.65",
          sm45: "8.32",
          sm55: "6.35",
          st5: "22.5",
          st15: "22.86",
          st25: "20.71",
          st35: "19.2",
          st45: "20.1",
          st55: "19.21",
        },
        {
          time: "2023-07-18 15:00:00.000000000",
          at: "26.7",
          sm5: "6.57",
          sm15: "2.24",
          sm25: "7.65",
          sm35: "8.85",
          sm45: "8.5",
          sm55: "6.57",
          st5: "22.5",
          st15: "24.0",
          st25: "20.6",
          st35: "19.2",
          st45: "20.03",
          st55: "19.22",
        },
        {
          time: "2023-07-18 14:00:00.000000000",
          at: "29.55",
          sm5: "6.75",
          sm15: "2.46",
          sm25: "7.87",
          sm35: "9.05",
          sm45: "8.69",
          sm55: "6.75",
          st5: "22.3",
          st15: "25.1",
          st25: "20.4",
          st35: "19.25",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-07-18 13:00:00.000000000",
          at: "30.8",
          sm5: "6.82",
          sm15: "2.59",
          sm25: "7.97",
          sm35: "9.11",
          sm45: "8.78",
          sm55: "6.82",
          st5: "21.8",
          st15: "25.5",
          st25: "20.1",
          st35: "19.2",
          st45: "19.8",
          st55: "19.28",
        },
        {
          time: "2023-07-18 11:00:00.000000000",
          at: "31.9",
          sm5: "6.95",
          sm15: "2.77",
          sm25: "8.12",
          sm35: "9.22",
          sm45: "8.9",
          sm55: "6.95",
          st5: "21.0",
          st15: "25.0",
          st25: "19.8",
          st35: "19.2",
          st45: "19.8",
          st55: "19.23",
        },
        {
          time: "2023-07-18 10:00:00.000000000",
          at: "32.8",
          sm5: "6.97",
          sm15: "2.84",
          sm25: "8.15",
          sm35: "9.25",
          sm45: "8.91",
          sm55: "6.97",
          st5: "20.5",
          st15: "25.0",
          st25: "19.7",
          st35: "19.2",
          st45: "20.0",
          st55: "19.28",
        },
        {
          time: "2023-07-18 06:00:00.000000000",
          at: "23.8",
          sm5: "6.39",
          sm15: "2.39",
          sm25: "7.65",
          sm35: "8.7",
          sm45: "8.35",
          sm55: "6.39",
          st5: "19.0",
          st15: "20.0",
          st25: "19.8",
          st35: "19.5",
          st45: "20.2",
          st55: "19.28",
        },
        {
          time: "2023-07-18 04:00:00.000000000",
          at: "16.2",
          sm5: "5.76",
          sm15: "1.76",
          sm25: "7.02",
          sm35: "8.06",
          sm45: "7.76",
          sm55: "5.76",
          st5: "19.07",
          st15: "16.57",
          st25: "20.1",
          st35: "19.43",
          st45: "20.27",
          st55: "19.33",
        },
        {
          time: "2023-07-18 03:00:00.000000000",
          at: "11.75",
          sm5: "5.34",
          sm15: "1.31",
          sm25: "6.63",
          sm35: "7.65",
          sm45: "7.32",
          sm55: "5.34",
          st5: "19.2",
          st15: "15.8",
          st25: "20.2",
          st35: "19.5",
          st45: "20.3",
          st55: "19.3",
        },
        {
          time: "2023-07-18 02:00:00.000000000",
          at: "9.8",
          sm5: "5.15",
          sm15: "1.12",
          sm25: "6.42",
          sm35: "7.45",
          sm45: "7.13",
          sm55: "5.15",
          st5: "19.65",
          st15: "15.65",
          st25: "20.4",
          st35: "19.5",
          st45: "20.3",
          st55: "19.3",
        },
        {
          time: "2023-07-18 01:00:00.000000000",
          at: "10.13",
          sm5: "5.17",
          sm15: "1.14",
          sm25: "6.46",
          sm35: "7.5",
          sm45: "7.17",
          sm55: "5.17",
          st5: "19.83",
          st15: "16.1",
          st25: "20.53",
          st35: "19.3",
          st45: "20.3",
          st55: "19.3",
        },
        {
          time: "2023-07-18 00:00:00.000000000",
          at: "10.55",
          sm5: "5.24",
          sm15: "1.22",
          sm25: "6.5",
          sm35: "7.56",
          sm45: "7.22",
          sm55: "5.24",
          st5: "20.15",
          st15: "16.5",
          st25: "20.65",
          st35: "19.5",
          st45: "20.3",
          st55: "19.3",
        },
        {
          time: "2023-07-17 23:00:00.000000000",
          at: "11.4",
          sm5: "5.3",
          sm15: "1.3",
          sm25: "6.59",
          sm35: "7.62",
          sm45: "7.29",
          sm55: "5.3",
          st5: "20.43",
          st15: "16.97",
          st25: "20.7",
          st35: "19.35",
          st45: "20.3",
          st55: "19.28",
        },
        {
          time: "2023-07-17 22:00:00.000000000",
          at: "12.4",
          sm5: "5.38",
          sm15: "1.36",
          sm25: "6.65",
          sm35: "7.69",
          sm45: "7.38",
          sm55: "5.38",
          st5: "20.7",
          st15: "17.53",
          st25: "20.8",
          st35: "19.3",
          st45: "20.3",
          st55: "19.3",
        },
        {
          time: "2023-07-17 21:00:00.000000000",
          at: "13.07",
          sm5: "5.44",
          sm15: "1.43",
          sm25: "6.72",
          sm35: "7.73",
          sm45: "7.42",
          sm55: "5.44",
          st5: "21.07",
          st15: "18.0",
          st25: "20.8",
          st35: "19.27",
          st45: "20.3",
          st55: "19.3",
        },
        {
          time: "2023-07-17 20:00:00.000000000",
          at: "13.3",
          sm5: "5.44",
          sm15: "1.44",
          sm25: "6.71",
          sm35: "7.75",
          sm45: "7.45",
          sm55: "5.44",
          st5: "21.2",
          st15: "18.3",
          st25: "20.8",
          st35: "19.2",
          st45: "20.3",
          st55: "19.3",
        },
        {
          time: "2023-07-17 17:00:00.000000000",
          at: "20.04",
          sm5: "5.99",
          sm15: "2.08",
          sm25: "7.33",
          sm35: "8.31",
          sm45: "7.98",
          sm55: "5.99",
          st5: "22.18",
          st15: "21.42",
          st25: "20.72",
          st35: "19.2",
          st45: "20.1",
          st55: "19.22",
        },
        {
          time: "2023-07-17 16:00:00.000000000",
          at: "23.67",
          sm5: "6.25",
          sm15: "2.35",
          sm25: "7.59",
          sm35: "8.57",
          sm45: "8.26",
          sm55: "6.25",
          st5: "22.2",
          st15: "22.53",
          st25: "20.64",
          st35: "19.2",
          st45: "20.04",
          st55: "19.21",
        },
        {
          time: "2023-07-17 15:00:00.000000000",
          at: "26.08",
          sm5: "6.43",
          sm15: "2.56",
          sm25: "7.79",
          sm35: "8.73",
          sm45: "8.43",
          sm55: "6.43",
          st5: "22.2",
          st15: "23.44",
          st25: "20.54",
          st35: "19.2",
          st45: "20.04",
          st55: "19.22",
        },
        {
          time: "2023-07-17 14:00:00.000000000",
          at: "29.23",
          sm5: "6.67",
          sm15: "2.84",
          sm25: "8.04",
          sm35: "8.98",
          sm45: "8.65",
          sm55: "6.67",
          st5: "22.07",
          st15: "24.57",
          st25: "20.27",
          st35: "19.2",
          st45: "19.93",
          st55: "19.21",
        },
        {
          time: "2023-07-17 13:00:00.000000000",
          at: "30.47",
          sm5: "6.72",
          sm15: "2.97",
          sm25: "8.12",
          sm35: "9.03",
          sm45: "8.71",
          sm55: "6.72",
          st5: "21.7",
          st15: "24.93",
          st25: "20.17",
          st35: "19.2",
          st45: "19.93",
          st55: "19.22",
        },
        {
          time: "2023-07-17 12:00:00.000000000",
          at: "30.2",
          sm5: "6.73",
          sm15: "2.98",
          sm25: "8.12",
          sm35: "9.01",
          sm45: "8.72",
          sm55: "6.73",
          st5: "21.5",
          st15: "24.6",
          st25: "20.1",
          st35: "19.2",
          st45: "20.0",
          st55: "19.23",
        },
        {
          time: "2023-07-17 03:00:00.000000000",
          at: "10.67",
          sm5: "5.21",
          sm15: "1.69",
          sm25: "6.65",
          sm35: "7.54",
          sm45: "7.24",
          sm55: "5.21",
          st5: "19.57",
          st15: "15.87",
          st25: "20.3",
          st35: "19.37",
          st45: "20.3",
          st55: "19.23",
        },
        {
          time: "2023-07-17 02:00:00.000000000",
          at: "10.4",
          sm5: "5.17",
          sm15: "1.63",
          sm25: "6.61",
          sm35: "7.48",
          sm45: "7.19",
          sm55: "5.17",
          st5: "19.83",
          st15: "16.1",
          st25: "20.43",
          st35: "19.37",
          st45: "20.3",
          st55: "19.27",
        },
        {
          time: "2023-07-17 01:00:00.000000000",
          at: "11.07",
          sm5: "5.2",
          sm15: "1.67",
          sm25: "6.65",
          sm35: "7.53",
          sm45: "7.24",
          sm55: "5.2",
          st5: "20.1",
          st15: "16.6",
          st25: "20.6",
          st35: "19.3",
          st45: "20.3",
          st55: "19.27",
        },
        {
          time: "2023-07-17 00:00:00.000000000",
          at: "11.6",
          sm5: "5.26",
          sm15: "1.75",
          sm25: "6.72",
          sm35: "7.61",
          sm45: "7.3",
          sm55: "5.26",
          st5: "20.2",
          st15: "17.0",
          st25: "20.6",
          st35: "19.3",
          st45: "20.3",
          st55: "19.2",
        },
        {
          time: "2023-07-16 23:00:00.000000000",
          at: "12.3",
          sm5: "5.3",
          sm15: "1.8",
          sm25: "6.77",
          sm35: "7.62",
          sm45: "7.34",
          sm55: "5.3",
          st5: "20.63",
          st15: "17.57",
          st25: "20.73",
          st35: "19.27",
          st45: "20.3",
          st55: "19.23",
        },
        {
          time: "2023-07-16 22:00:00.000000000",
          at: "12.93",
          sm5: "5.37",
          sm15: "1.86",
          sm25: "6.82",
          sm35: "7.69",
          sm45: "7.4",
          sm55: "5.37",
          st5: "20.95",
          st15: "18.1",
          st25: "20.78",
          st35: "19.27",
          st45: "20.3",
          st55: "19.2",
        },
        {
          time: "2023-07-16 21:00:00.000000000",
          at: "14.3",
          sm5: "5.5",
          sm15: "2.01",
          sm25: "6.97",
          sm35: "7.8",
          sm45: "7.52",
          sm55: "5.5",
          st5: "21.2",
          st15: "18.75",
          st25: "20.8",
          st35: "19.25",
          st45: "20.3",
          st55: "19.2",
        },
        {
          time: "2023-07-16 20:00:00.000000000",
          at: "16.45",
          sm5: "5.68",
          sm15: "2.22",
          sm25: "7.17",
          sm35: "8.02",
          sm45: "7.71",
          sm55: "5.68",
          st5: "21.4",
          st15: "19.25",
          st25: "20.8",
          st35: "19.25",
          st45: "20.25",
          st55: "19.2",
        },
        {
          time: "2023-07-16 19:00:00.000000000",
          at: "17.47",
          sm5: "5.73",
          sm15: "2.31",
          sm25: "7.24",
          sm35: "8.06",
          sm45: "7.76",
          sm55: "5.73",
          st5: "21.7",
          st15: "19.87",
          st25: "20.93",
          st35: "19.2",
          st45: "20.2",
          st55: "19.2",
        },
        {
          time: "2023-07-16 18:00:00.000000000",
          at: "17.08",
          sm5: "5.71",
          sm15: "2.29",
          sm25: "7.22",
          sm35: "8.05",
          sm45: "7.76",
          sm55: "5.71",
          st5: "21.98",
          st15: "20.45",
          st25: "20.8",
          st35: "19.2",
          st45: "20.15",
          st55: "19.2",
        },
        {
          time: "2023-07-16 17:00:00.000000000",
          at: "18.54",
          sm5: "5.8",
          sm15: "2.41",
          sm25: "7.32",
          sm35: "8.13",
          sm45: "7.85",
          sm55: "5.8",
          st5: "22.1",
          st15: "21.18",
          st25: "20.8",
          st35: "19.2",
          st45: "20.1",
          st55: "19.2",
        },
        {
          time: "2023-07-16 16:00:00.000000000",
          at: "23.94",
          sm5: "6.19",
          sm15: "2.82",
          sm25: "7.71",
          sm35: "8.51",
          sm45: "8.22",
          sm55: "6.19",
          st5: "22.24",
          st15: "22.48",
          st25: "20.66",
          st35: "19.16",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-07-16 15:00:00.000000000",
          at: "26.38",
          sm5: "6.4",
          sm15: "3.05",
          sm25: "7.93",
          sm35: "8.71",
          sm45: "8.43",
          sm55: "6.4",
          st5: "22.3",
          st15: "23.43",
          st25: "20.6",
          st35: "19.14",
          st45: "20.0",
          st55: "19.21",
        },
        {
          time: "2023-07-16 14:00:00.000000000",
          at: "29.47",
          sm5: "6.61",
          sm15: "3.31",
          sm25: "8.15",
          sm35: "8.92",
          sm45: "8.64",
          sm55: "6.61",
          st5: "22.17",
          st15: "24.4",
          st25: "20.37",
          st35: "19.13",
          st45: "20.0",
          st55: "19.21",
        },
        {
          time: "2023-07-16 13:00:00.000000000",
          at: "30.5",
          sm5: "6.71",
          sm15: "3.44",
          sm25: "8.24",
          sm35: "9.02",
          sm45: "8.74",
          sm55: "6.71",
          st5: "21.89",
          st15: "24.89",
          st25: "20.2",
          st35: "19.16",
          st45: "19.8",
          st55: "19.21",
        },
        {
          time: "2023-07-16 12:00:00.000000000",
          at: "30.5",
          sm5: "6.7",
          sm15: "3.47",
          sm25: "8.25",
          sm35: "9.01",
          sm45: "8.74",
          sm55: "6.7",
          st5: "21.55",
          st15: "24.68",
          st25: "20.04",
          st35: "19.2",
          st45: "19.88",
          st55: "19.2",
        },
        {
          time: "2023-07-16 11:00:00.000000000",
          at: "30.74",
          sm5: "6.7",
          sm15: "3.51",
          sm25: "8.27",
          sm35: "9.01",
          sm45: "8.75",
          sm55: "6.7",
          st5: "21.05",
          st15: "24.48",
          st25: "19.88",
          st35: "19.2",
          st45: "19.86",
          st55: "19.21",
        },
        {
          time: "2023-07-16 10:00:00.000000000",
          at: "31.59",
          sm5: "6.75",
          sm15: "3.61",
          sm25: "8.32",
          sm35: "9.06",
          sm45: "8.79",
          sm55: "6.75",
          st5: "20.56",
          st15: "24.41",
          st25: "19.76",
          st35: "19.2",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-07-15 18:00:00.000000000",
          at: "20.55",
          sm5: "5.86",
          sm15: "2.85",
          sm25: "7.51",
          sm35: "8.18",
          sm45: "7.99",
          sm55: "5.86",
          st5: "22.0",
          st15: "20.45",
          st25: "21.0",
          st35: "19.1",
          st45: "20.2",
          st55: "19.15",
        },
        {
          time: "2023-07-15 17:00:00.000000000",
          at: "21.4",
          sm5: "5.91",
          sm15: "2.92",
          sm25: "7.55",
          sm35: "8.24",
          sm45: "8.02",
          sm55: "5.91",
          st5: "22.2",
          st15: "21.13",
          st25: "20.87",
          st35: "19.13",
          st45: "20.13",
          st55: "18.79",
        },
        {
          time: "2023-07-15 16:00:00.000000000",
          at: "24.03",
          sm5: "6.13",
          sm15: "3.17",
          sm25: "7.77",
          sm35: "8.46",
          sm45: "8.25",
          sm55: "6.13",
          st5: "22.3",
          st15: "22.2",
          st25: "20.8",
          st35: "19.1",
          st45: "20.1",
          st55: "18.38",
        },
        {
          time: "2023-07-15 15:00:00.000000000",
          at: "26.86",
          sm5: "6.3",
          sm15: "3.38",
          sm25: "7.95",
          sm35: "8.63",
          sm45: "8.44",
          sm55: "6.3",
          st5: "22.44",
          st15: "23.34",
          st25: "20.7",
          st35: "19.1",
          st45: "20.03",
          st55: "19.2",
        },
        {
          time: "2023-07-15 14:00:00.000000000",
          at: "29.88",
          sm5: "6.54",
          sm15: "3.66",
          sm25: "8.2",
          sm35: "8.85",
          sm45: "8.67",
          sm55: "6.54",
          st5: "22.27",
          st15: "24.41",
          st25: "20.57",
          st35: "19.1",
          st45: "20.0",
          st55: "18.82",
        },
        {
          time: "2023-07-15 13:00:00.000000000",
          at: "31.7",
          sm5: "6.66",
          sm15: "3.84",
          sm25: "8.31",
          sm35: "8.96",
          sm45: "8.81",
          sm55: "6.66",
          st5: "22.0",
          st15: "25.1",
          st25: "20.3",
          st35: "19.1",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-07-15 12:00:00.000000000",
          at: "31.45",
          sm5: "6.63",
          sm15: "3.86",
          sm25: "8.31",
          sm35: "8.94",
          sm45: "8.78",
          sm55: "6.63",
          st5: "21.7",
          st15: "24.9",
          st25: "20.25",
          st35: "19.1",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-07-15 11:00:00.000000000",
          at: "30.57",
          sm5: "6.56",
          sm15: "3.83",
          sm25: "8.24",
          sm35: "8.88",
          sm45: "8.71",
          sm55: "6.56",
          st5: "21.25",
          st15: "24.09",
          st25: "20.13",
          st35: "19.1",
          st45: "20.0",
          st55: "17.95",
        },
        {
          time: "2023-07-15 10:00:00.000000000",
          at: "31.13",
          sm5: "6.61",
          sm15: "3.95",
          sm25: "8.29",
          sm35: "8.92",
          sm45: "8.78",
          sm55: "6.61",
          st5: "20.8",
          st15: "23.77",
          st25: "20.0",
          st35: "19.13",
          st45: "20.0",
          st55: "18.38",
        },
        {
          time: "2023-07-15 09:00:00.000000000",
          at: "31.37",
          sm5: "6.64",
          sm15: "4.01",
          sm25: "8.32",
          sm35: "8.93",
          sm45: "8.79",
          sm55: "6.64",
          st5: "20.43",
          st15: "23.03",
          st25: "20.0",
          st35: "19.2",
          st45: "20.0",
          st55: "18.38",
        },
        {
          time: "2023-07-14 17:00:00.000000000",
          at: "21.77",
          sm5: "5.86",
          sm15: "3.48",
          sm25: "7.56",
          sm35: "8.13",
          sm45: "8.14",
          sm55: "5.86",
          st5: "22.0",
          st15: "22.13",
          st25: "20.6",
          st35: "19.0",
          st45: "19.8",
          st55: "17.97",
        },
        {
          time: "2023-07-14 16:00:00.000000000",
          at: "23.53",
          sm5: "4.14",
          sm15: "2.51",
          sm25: "5.31",
          sm35: "5.72",
          sm45: "5.73",
          sm55: "4.14",
          st5: "22.0",
          st15: "22.98",
          st25: "20.5",
          st35: "19.0",
          st45: "19.8",
          st55: "17.95",
        },
        {
          time: "2023-07-14 15:00:00.000000000",
          at: "27.06",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "21.89",
          st15: "23.47",
          st25: "20.3",
          st35: "19.06",
          st45: "19.8",
          st55: "17.93",
        },
        {
          time: "2023-07-14 14:00:00.000000000",
          at: "29.87",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "21.77",
          st15: "23.93",
          st25: "20.3",
          st35: "19.2",
          st45: "20.0",
          st55: "19.2",
        },
        {
          time: "2023-07-14 13:00:00.000000000",
          at: "32.97",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "26.13",
          st15: "28.41",
          st25: "25.07",
          st35: "23.33",
          st45: "24.24",
          st55: "22.96",
        },
        {
          time: "2023-07-14 11:00:00.000000000",
          at: "26.7",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "27.1",
          st15: "26.8",
          st25: "27.0",
          st35: "26.7",
          st45: "27.2",
          st55: "27.2",
        },
        {
          time: "2023-07-14 10:00:00.000000000",
          at: "26.1",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "26.3",
          st15: "26.1",
          st25: "26.2",
          st35: "26.1",
          st45: "26.5",
          st55: "26.3",
        },
        {
          time: "2023-07-14 09:00:00.000000000",
          at: "26.7",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "27.63",
          st15: "26.83",
          st25: "28.0",
          st35: "26.97",
          st45: "27.57",
          st55: "27.23",
        },
        {
          time: "2023-07-14 07:00:00.000000000",
          at: "25.6",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "25.7",
          st15: "25.6",
          st25: "25.53",
          st35: "25.2",
          st45: "25.6",
          st55: "25.67",
        },
        {
          time: "2023-07-13 05:00:00.000000000",
          at: "23.4",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "23.5",
          st15: "23.3",
          st25: "23.5",
          st35: "23.6",
          st45: "23.8",
          st55: "24.1",
        },
        {
          time: "2023-07-13 04:00:00.000000000",
          at: "23.8",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "24.1",
          st15: "23.8",
          st25: "24.0",
          st35: "23.8",
          st45: "24.2",
          st55: "24.3",
        },
        {
          time: "2023-07-13 03:00:00.000000000",
          at: "23.8",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "24.1",
          st15: "23.8",
          st25: "24.0",
          st35: "23.8",
          st45: "24.2",
          st55: "24.3",
        },
        {
          time: "2023-07-13 02:00:00.000000000",
          at: "23.8",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "24.1",
          st15: "23.8",
          st25: "24.0",
          st35: "23.8",
          st45: "24.2",
          st55: "24.3",
        },
        {
          time: "2023-07-13 01:00:00.000000000",
          at: "24.0",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "24.2",
          st15: "24.0",
          st25: "24.1",
          st35: "24.0",
          st45: "24.3",
          st55: "24.5",
        },
        {
          time: "2023-07-13 00:00:00.000000000",
          at: "24.0",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "24.3",
          st15: "24.1",
          st25: "24.2",
          st35: "24.1",
          st45: "24.5",
          st55: "24.5",
        },
        {
          time: "2023-07-12 23:00:00.000000000",
          at: "24.1",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "24.5",
          st15: "24.2",
          st25: "24.3",
          st35: "24.2",
          st45: "24.6",
          st55: "24.7",
        },
        {
          time: "2023-07-12 22:00:00.000000000",
          at: "24.3",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "24.6",
          st15: "24.3",
          st25: "24.5",
          st35: "24.3",
          st45: "24.7",
          st55: "24.7",
        },
        {
          time: "2023-07-12 21:00:00.000000000",
          at: "24.5",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "24.7",
          st15: "24.5",
          st25: "24.6",
          st35: "24.5",
          st45: "24.8",
          st55: "25.0",
        },
        {
          time: "2023-07-12 20:00:00.000000000",
          at: "24.7",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "25.0",
          st15: "24.7",
          st25: "24.8",
          st35: "24.7",
          st45: "25.1",
          st55: "25.2",
        },
        {
          time: "2023-07-12 19:00:00.000000000",
          at: "25.0",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "25.2",
          st15: "25.0",
          st25: "25.1",
          st35: "25.0",
          st45: "25.3",
          st55: "25.5",
        },
        {
          time: "2023-07-12 18:00:00.000000000",
          at: "25.4",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "25.6",
          st15: "25.3",
          st25: "25.5",
          st35: "25.3",
          st45: "25.7",
          st55: "25.8",
        },
        {
          time: "2023-07-12 17:00:00.000000000",
          at: "26.0",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "26.2",
          st15: "26.0",
          st25: "26.1",
          st35: "26.0",
          st45: "26.3",
          st55: "26.5",
        },
        {
          time: "2023-07-12 16:00:00.000000000",
          at: "27.1",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "27.3",
          st15: "27.1",
          st25: "27.2",
          st35: "27.0",
          st45: "27.3",
          st55: "27.6",
        },
        {
          time: "2023-07-12 15:00:00.000000000",
          at: "27.5",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "27.7",
          st15: "27.44",
          st25: "27.48",
          st35: "27.36",
          st45: "27.76",
          st55: "27.84",
        },
        {
          time: "2023-07-12 13:00:00.000000000",
          at: "25.9",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "26.5",
          st15: "26.2",
          st25: "26.3",
          st35: "26.1",
          st45: "26.5",
          st55: "26.5",
        },
        {
          time: "2023-07-12 11:00:00.000000000",
          at: "25.1",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "25.6",
          st15: "25.3",
          st25: "25.55",
          st35: "25.3",
          st45: "25.7",
          st55: "25.7",
        },
        {
          time: "2023-07-12 10:00:00.000000000",
          at: "25.18",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "25.68",
          st15: "25.38",
          st25: "25.52",
          st35: "25.25",
          st45: "25.7",
          st55: "25.7",
        },
        {
          time: "2023-07-12 09:00:00.000000000",
          at: "25.33",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "25.77",
          st15: "25.57",
          st25: "25.63",
          st35: "25.53",
          st45: "25.93",
          st55: "26.07",
        },
        {
          time: "2023-07-12 08:00:00.000000000",
          at: "25.3",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "25.7",
          st15: "25.5",
          st25: "25.6",
          st35: "25.5",
          st45: "25.8",
          st55: "25.8",
        },
        {
          time: "2023-07-11 15:00:00.000000000",
          at: "26.3",
          sm5: "0.0",
          sm15: "0.0",
          sm25: "0.0",
          sm35: "0.0",
          sm45: "0.0",
          sm55: "0.0",
          st5: "26.6",
          st15: "26.2",
          st25: "26.8",
          st35: "26.0",
          st45: "27.1",
          st55: "25.6",
        },
        {
          time: "2023-07-07 12:00:00.000000000",
          at: "27.15",
          sm5: "78.26",
          sm15: "78.26",
          sm25: "78.26",
          sm35: "100.0",
          sm45: "100.0",
          sm55: "100.0",
          st5: "5.83",
          st15: "5.84",
          st25: "5.91",
          st35: "0.0",
          st45: "0.0",
          st55: "0.0",
        },
        {
          time: "2023-07-07 11:00:00.000000000",
          at: "26.67",
          sm5: "100.0",
          sm15: "100.0",
          sm25: "100.0",
          sm35: "NULL",
          sm45: "NULL",
          sm55: "NULL",
          st5: "0.0",
          st15: "0.0",
          st25: "0.0",
          st35: "NULL",
          st45: "NULL",
          st55: "NULL",
        },
        {
          time: "2023-07-07 09:00:00.000000000",
          at: "26.57",
          sm5: "100.0",
          sm15: "100.0",
          sm25: "100.0",
          sm35: "NULL",
          sm45: "NULL",
          sm55: "NULL",
          st5: "0.0",
          st15: "0.0",
          st25: "0.0",
          st35: "NULL",
          st45: "NULL",
          st55: "NULL",
        },
        {
          time: "2023-07-07 08:00:00.000000000",
          at: "27.05",
          sm5: "100.0",
          sm15: "100.0",
          sm25: "100.0",
          sm35: "NULL",
          sm45: "NULL",
          sm55: "NULL",
          st5: "0.0",
          st15: "0.0",
          st25: "0.0",
          st35: "NULL",
          st45: "NULL",
          st55: "NULL",
        },
      ],
    },
  ];

  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth", inline: "start" });
  };
  return (
    // <div className='flex flex-col gap-3 pt-16 max-sm:pt-0   mx-auto max-w-8xl  sm:px-6  max-sm:justify-center  lg:px-8 mb-64 '>
    //   <div className='flex max-sm:flex-col gap-3'>
    //     <div className=' w-full max-sm:w-full h-auto  '>
    //       {console.log('fieldcardrender ici  : ', fieldData)}
    //       <div className='flex flex-row justify-between '>
    //         <p className='text-4xl font-medium  max-sm:text-2xl my-3 ml-3'>{fieldData.name}</p>
    //         <div
    //           className='mt-4 w-10 hover:cursor-pointer h-10 max-sm:right-1  max-sm:pt-0 max-sm:mt-2 flex  items-center  '
    //           onClick={() => {
    //             navigate(`settings`, {
    //               state: fieldData,
    //             });
    //           }}
    //         >
    //           <img
    //             src={SettingsIcon}
    //             className='w-10 h-10 bg-white p-2 rounded-lg'
    //           />
    //         </div>
    //       </div>
    //       <div className='bg-white rounded-md  '>
    //         {fieldData.center?.lat ? (
    //           <MapContainer
    //             center={[fieldData.center.lat, fieldData.center.lng]}
    //             zoom={fieldData.zoom - 1}
    //             scrollWheelZoom={false}
    //             className='w-full max-sm:w-full h-[31rem] max-sm:h-96 z-0 rounded-md'
    //             dragging={false}
    //             zoomControl={false}
    //             attributionControl={false}
    //           >
    //             <TileLayer

    //               url='https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}'
    //             />
    //                         <AttributionControl
    //           position="bottomright"
    //           prefix={"Leaflet"}
    //         />

    //             {fieldData.geometry_type == "polygon" && <Polygon positions={position} />}
    //         {fieldData.geometry_type == "circle" && <Circle  center={fieldData.center} radius={fieldData.radius}/>}
    //             {fieldData.devices
    //               ? fieldData.devices.map((e) => {
    //                   console.log('fieldData.devices?.map((da)', e);
    //                   return e?.coords?.lat ? (
    //                     <Marker
    //                     onClick={()=>alert("selam")}
    //                       key={Math.random()}
    //                       position={[e.coords.lat, e.coords.lon]}
    //                       icon={
    //                         e.type == 'izci' ? markerIzciIcon : markerOcakIcon
    //                       }
    //                     >
    //                       <Tooltip direction='top' offset={[0, -45]} opacity={0.8}  permanent >
    //                         {e.id.slice(-4)}
    //                       </Tooltip>
    //                     </Marker>
    //                   ) : (
    //                     ''
    //                   );
    //                 })
    //               : ''}
    //           </MapContainer>
    //         ) : (
    //           <div className='flex  h-[31rem] max-sm:h-52 z-0 rounded-t-md items-center justify-center  '>
    //             <div className='flex flex-col items-center justify-center '>
    //               <img src={NoDrawn} className='w-20 mb-3' />
    //               <p className='text-xl font-semibold'>No drawn field</p>
    //               <p className='text-sm font-medium w-3/4 text-center'>
    //                 Define your field using the map. track your devices on map
    //               </p>
    //             </div>
    //           </div>
    //         )}
    //         {/* <div>
    //           <p className='font-bold text-lg m-2'>Soil Moisture Status</p>
    //           <p className='  h-48'>
    //             {fieldData.devices ? (
    //               'buraya grafik'
    //             ) : (
    //               <div className='flex flex-row gap-2 justify-center items-center m-3'>
    //                 <img src={SetupFieldImg} className='w-20' />
    //                 <p className='text-xl w-28 font-semibold text-slate-500'>
    //                   No installed Device
    //                 </p>
    //               </div>
    //             )}
    //           </p>
    //         </div> */}
    //       </div>
    //     </div>
    //     <div className='w-1/3 max-sm:w-full'>
    //       <p className='text-4xl font-bold my-3  invisible max-sm:hidden'>s</p>
    //       {/* <ReactWeather
    //         isLoading={isLoading}
    //         errorMessage={errorMessage}
    //         data={data}
    //         lang='en'
    //         locationLabel={fieldTown}
    //         unitsLabels={{ temperature: '°', windSpeed: 'Km/h' }}
    //         showForecast
    //       /> */}
    //       <div className=' w-full   h-[31rem] max-sm:h-[33rem]  '>
    //       <WeatherCard lat={fieldData?.center?.lat} lon={fieldData?.center?.lng} lang={"en"} />
    //       </div>
    //     </div>
    //   </div>
    //   <div className='flex flex-col'>
    //     <p className='text-4xl text-blue-700  ml-4 mt-5'> Devices</p>
    //     <div>
    //       <Devices data={fieldData} />
    //     </div>
    //   </div>
    // </div>
    <div
      id="section1"
      ref={myRef}
      className="relative sm:px-6 max-sm:p-5 flex flex-col gap-3 lg:pt-0 pt-16 max-sm:pt-0   mx-auto   max-sm:justify-center  lg:px-8 mb-64 "
    >
      <div
        onClick={() => {
          var navi = document.getElementById("mobilBody");

          if (navi.classList.contains("mobilSmallBody")) {
            return;
          } else {
            executeScroll();
          }
        }}
        className="sticky lg:w-full lg:mb-0 w-11/12 mx-auto top-2  z-50  mt-6 -mb-9 flex flex-row justify-between bg-white  bg-opacity-80 shadow-myShadow1 lg:shadow-lg backdrop-blur-[2rem] rounded-2xl items-center h-16 py-11 px-4"
      >
        <div>
          <p className="text-4xl lg:text-lg font-medium  max-sm:text-lg text-textDark ">
            {fieldData?.name}
          </p>
          <p className="text-xl lg:text-sm font-medium  max-sm:text-sm text-textLight -mt-1 ">
            {fieldTown} 
          </p>
        </div>
        { fieldData.role !== "viewer" && <div
          className="mt-4 w-10 hover:cursor-pointer h-10 max-sm:right-1  max-sm:pt-0 max-sm:mt-2 flex  items-center  "
          onClick={() => {
            navigate(`settings`, {
              state: fieldData,
            });
          }}
        >
          <img
            src={SettingsIcon}
            className="w-10 h-10 bg-white p-2 rounded-lg shadow-miniMap"
          />
        </div>}
      </div>
      <div className="relative flex max-sm:flex-col gap-3  lg:flex-col lg:bg-white lg:p-3 lg:rounded-2xl">
        <div className=" w-full max-sm:w-full h-auto  ">
          {console.log("fieldcardrender ici  : ", fieldData)}

          <div className="relative  rounded-md  z-0 shadow-myShadow1 lg:shadow-none">
            {fieldData.center?.lat ? (
              <MapContainer
                center={[fieldData.center.lat, fieldData.center.lng]}
                zoom={fieldData.zoom - 2}
                scrollWheelZoom={false}
                className="w-full lg:w-2/3 max-sm:w-full h-[31rem] max-sm:h-72 z-0 rounded-2xl"
                dragging={false}
                zoomControl={false}
                attributionControl={false}
              >
                <TileLayer url="https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}" />
                <AttributionControl position="bottomright" prefix={"Leaflet"} />

                {fieldData.geometry_type == "polygon" && (
                  <Polygon
                    positions={position}
                    color="#ffffff"
                    fillColor="#ffffff"
                  />
                )}
                {fieldData.geometry_type == "circle" && (
                  <Circle
                    center={fieldData.center}
                    radius={fieldData.radius}
                    color="#ffffff"
                    fillColor="#ffffff"
                  />
                )}
                {fieldData.devices
                  ? fieldData.devices.map((e, index) => {
                      console.log("fieldData.devices?.map((da)", e);
                      return e?.coords?.lat ? (
                        <Marker
                          key={Math.random()}
                          position={[e.coords.lat, e.coords.lon]}
                          icon={
                            index == 5
                              ? markerIzciIcon
                              : index == 7
                              ? markerOcakIcon
                              : index == 4
                              ? markerIzciIcon
                              : markerOptimal
                          }
                        >
                          <Tooltip
                            direction="top"
                            offset={[0, -15]}
                            opacity={0.7}
                          >
                            <div className=" w-40 h-20 bg-white py-1 px-2">
                              <p className="text-lg text-textDark font-semibold">
                                {e.id.slice(-4)}
                              </p>
                            </div>
                          </Tooltip>
                        </Marker>
                      ) : (
                        ""
                      );
                    })
                  : ""}
              </MapContainer>
            ) : (
              <div className="flex  h-[31rem] max-sm:h-52 z-0 rounded-t-md items-center justify-center  ">
                <div className="flex flex-col items-center justify-center ">
                  <img src={NoDrawn} className="w-20 mb-3" />
                  <p className="text-xl font-semibold">{t('fieldsNoDrawn')}</p>
                  <p className="text-sm font-medium w-3/4 text-center">
                  {t('fieldsDefineField')}
                  </p>
                </div>
              </div>
            )}
            {/* <div>
              <p className='font-bold text-lg m-2'>Soil Moisture Status</p>
              <p className='  h-48'>
                {fieldData.devices ? (
                  'buraya grafik'
                ) : (
                  <div className='flex flex-row gap-2 justify-center items-center m-3'>
                    <img src={SetupFieldImg} className='w-20' />
                    <p className='text-xl w-28 font-semibold text-slate-500'>
                      No installed Device
                    </p>
                  </div>
                )}
              </p>
            </div> */}
          </div>
        </div>
        <div className="mt-4  sm:mt-2 lg:absolute lg:right-5 lg:bottom-2 z-10 ">
          <div className=" h-[12.5rem] w-full max-w-[22rem] rounded-2xl lg:shadow-none lg:border border-dashed border- bg-white  bg-opacity-50 shadow-myShadow1 backdrop-blur-[39.5px] ">
            <Carousel autoSlide={true} autoSlideInterval={8000}>
              {slides.map((s) => {
                switch (s) {
                  case "3":
                    return (
                      <div
                        className=" h-full my-auto w-full flex flex-none  "
                        onClick={() => {
                          navigate(`Haymana%20Mısır/F6AE`, {
                            state: data[2],
                          });
                        }}
                      >
                        <div className=" w-full flex flex-row text-textDark ">
                          <div className="w-9/12 h-full  flex flex-col justify-center pl-7">
                            <div onClick={(event) => event.stopPropagation()}>
                              <p
                                className="text-xs font-semibold text-blue-600"
                                onClick={() => {
                                  navigate(`Haymana Mısır`, {
                                    state: data[0],
                                  });
                                }}
                              >
                                Haymana Mısır
                              </p>
                            </div>

                            <p className="text-md font-medium  ">
                            {t('device')}
                            <span className="text-blue-600"> 74A4</span>{" "}
                            {t('homeBatteryLow')}

                            </p>
                            <p className="pt-4 text-textLight">
                            {t('homeChangeBattery')}

                            </p>
                          </div>
                          <div className="w-3/12 h-full flex items-center pr-3">
                            <img
                              className="absolute  h-32 "
                              src={alertLowBat}
                            />
                          </div>
                        </div>
                      </div>
                    );
                    break;
                  case "4":
                    return (
                      <div
                        className=" h-full w-full flex flex-none my-auto"
                        onClick={() => {
                          navigate(`Haymana%20Mısır/F6AE`, {
                            state: data[2],
                          });
                        }}
                      >
                        <div className=" w-full flex flex-row text-textDark ">
                          <div className="w-9/12 h-full  flex flex-col justify-center pl-7">
                            <div onClick={(event) => event.stopPropagation()}>
                              <p
                                className="text-xs font-semibold text-blue-600"
                                onClick={() => {
                                  navigate(`Haymana Mısır`, {
                                    state: data[0],
                                  });
                                }}
                              >
                                Kalecik Üzüm
                              </p>
                            </div>

                            <p className="text-md font-medium  ">
                            {t('device')}
 <span className="text-blue-600"> 74A4</span>{" "}
 {t('homeConnectionProblem')}

                            </p>
                            <p className="pt-4 text-textLight">
 {t('homeCannotSendData')}
                               </p>
                          </div>
                          <div className="w-3/12 h-full flex items-center pr-3">
                            <img
                              className="absolute  h-32 "
                              src={alertNoConnection}
                            />
                          </div>
                        </div>
                      </div>
                    );
                    break;
                  case "5":
                    return (
                      <div
                        className=" h-full w-full flex flex-none my-auto"
                        onClick={() => {
                          navigate(`Haymana%20Mısır/F6AE`, {
                            state: data[2],
                          });
                        }}
                      >
                        <div className=" w-full flex flex-row text-textDark ">
                          <div className="w-9/12 h-full  flex flex-col justify-center pl-7">
                            <div onClick={(event) => event.stopPropagation()}>
                              <p
                                className="text-xs font-semibold text-blue-600"
                                onClick={() => {
                                  navigate(`Haymana Mısır`, {
                                    state: data[0],
                                  });
                                }}
                              >
                                Haymana Mısır
                              </p>
                            </div>

                            <p className="text-md font-medium  ">
                            {t('device')}
                              <span className="text-blue-600"> VANA1</span>                             {t('homeTurnedOn')}

                            </p>
                            <p className="pt-4 text-textLight">
                            {t('homeOpenedBetween')}
                            </p>
                          </div>
                          <div className="w-5/12 h-full flex items-center pr-3">
                            <img className="  h-auto w-32" src={alertVana} />
                          </div>
                        </div>
                      </div>
                    );
                    break;
                }
              })}
            </Carousel>
          </div>
        </div>
        <div className="w-1/3 max-sm:w-full lg:absolute lg:right-0 lg:top-0 z-0">
          {/* <ReactWeather
            isLoading={isLoading}
            errorMessage={errorMessage}
            data={data}
            lang='en'
            locationLabel={fieldTown}
            unitsLabels={{ temperature: '°', windSpeed: 'Km/h' }}
            showForecast
          /> */}
          <div className=" w-full   h-[28rem] max-sm:h-[34rem] -mb-52 ">
            <WeatherCard
              lat={fieldData?.center?.lat}
              lon={fieldData?.center?.lng}
              lang={i18n.language}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div
          id="myElement13"
          className="myElement13  z-10  pt-8 pb-4 transition-all duration-300  ease-in-out delay-75"
        >
          <p className="text-4xl text-textDark font-medium ">{t('devices')}</p>
          <div id="bigselam " className="h-16 -mb-6 overflow-hidden ">
            <div className="filterscroll w-full pt-2 h-14   text-textDark text-xs font-bold flex flex-row gap-2  overflow-x-scroll  ">
              <span className="h-fit bg-white px-2 py-1 rounded-md shadow-miniMap ">
                {" "}
                optimal
              </span>
              <span className="h-fit bg-white px-4 py-1 rounded-md shadow-miniMap whitespace-nowrap">
                
                {t('refill')}
              </span>
              <span className="h-fit bg-white px-4 py-1 rounded-md shadow-miniMap whitespace-nowrap">
                
                {t('over')}

              </span>
              <span className="h-fit bg-white px-4 py-1 rounded-md shadow-miniMap">
                {" "}
                {t('on')}
                
              </span>
              <span className=" h-fit bg-white px-4 py-1 rounded-md shadow-miniMap">
                {" "}
                {t('off')}

              </span>
            </div>
          </div>
        </div>
        <div>
          <Devices data={fieldData} />
        </div>
      </div>
    </div>
  );
}

export default SelectedField;
