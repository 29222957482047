import React from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  Popup,
  useMapEvent,
  FeatureGroup,
  Circle,
  Polygon,
  AttributionControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { map } from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import SetupFieldImg from "../assets/setupFieldImg.png";
import { useNavigate } from "react-router-dom";
import Refill from "../assets/refill.svg";
import Optimal from "../assets/optimal.svg";
import Full from "../assets/full.svg";
import Izci from "../assets/Izci2.svg";
import NoInstalled from "../assets/noinstalleddevice.svg";
import NoDrawn from "../assets/nodrawn.svg";
import { useTranslation } from "react-i18next";
const markerIzciIcon = new L.Icon({
  iconUrl: require("../assets/softpinkmarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});
const markerOcakIcon = new L.Icon({
  iconUrl: require("../assets/softorangemarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

function FieldCard({ data }) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  var position = [];
  var positionset =
    data?.geometry?.length > 0 &&
    data.geometry.map((e) => {
      position.push([e.lat, e.lng]);
    });
  console.log("fieldcard : ", data);

  return (
    <div
      className="relative px-5 py-6  w-[calc(25%_-_3rem)]  min-w-[20rem]   h-auto max-sm:w-full bg-white shadow-myShadow1 rounded-2xl"
      onClick={() => {
        navigate(
          `${
            data.name
              ? data.name
              : `${data.isDevice ? data.devices?.[0]?.id : data.name}`
          }`,
          {
            state: data,
          }
        );
      }}
    >
      {console.log("fieldcardrender ici  : ", data)}
      <p className=" flex flex-row justify-between">
        <p className="text-lg lg:text-base font-semibold text-textDark">
          {data.isDevice ? data.devices?.[0]?.id.slice(-4) : data.name}
        </p>
        {data.role && (
          <p className="text-sm lg:text-xs text-white px-1 py-1 bg-blue-400 rounded-md">
            {data.role}
          </p>
        )}
      </p>
      <div className="flex flex-row justify-between text-sm lg:text-xs">
        <p className="text-textLight mb-4">Haymana Ankara</p>
        <p className="text-textLight mb-4">{t("grape")}</p>
      </div>
      <div className="bg-white rounded-md   ">
        {data.center?.lat ? (
          <div className="relative w-full z-[9] h-40 rounded-xl shadow-miniMap mb-4">
            <MapContainer
              center={[data.center.lat, data.center.lng]}
              zoom={data.zoom - 2}
              scrollWheelZoom={false}
              className="w-full h-full rounded-xl z-0"
              dragging={false}
              zoomControl={false}
              attributionControl={false}
            >
              <TileLayer
                url="https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
                // http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}
              />
              <AttributionControl position="bottomright" prefix={"Leaflet"} />
              {data.geometry_type == "polygon" && (
                <Polygon
                  positions={position}
                  color="#ffffff"
                  fillColor="#ffffff"
                />
              )}
              {data.geometry_type == "circle" && (
                <Circle
                  center={data.center}
                  radius={data.radius}
                  color="#ffffff"
                  fillColor="#ffffff"
                />
              )}
              {/* {data.devices
              ? data.devices.map((e) => {
                  console.log('data.devices?.map((da)', e);
                  return e?.coords?.lat ? (
                    <Marker
                      key={e.id}
                      position={[e.coords.lat, e.coords.lon]}
                      icon={e.type == 'izci' ? markerIzciIcon : markerOcakIcon}
                    >
                      <Popup autoClose={false}>
                        A pretty CSS3 popup. <br /> Easily customizable.
                      </Popup>
                    </Marker>
                  ) : (
                    ''
                  );
                })
              : ''} */}
              {data.devices?.[0]?.coords ? (
                <Marker
                  key={data.devices[0].id}
                  position={[
                    data.devices[0].coords?.lat,
                    data.devices[0].coords?.lon,
                  ]}
                  icon={
                    data.devices[0].type == "izci"
                      ? markerIzciIcon
                      : markerOcakIcon
                  }
                >
                  <Popup autoClose={false}>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              ) : (
                ""
              )}
              {data.devices?.[1]?.coords ? (
                <Marker
                  key={data.devices[1].id}
                  position={[
                    data.devices[1].coords.lat,
                    data.devices[1].coords.lon,
                  ]}
                  icon={
                    data.devices[1].type == "izci"
                      ? markerIzciIcon
                      : markerOcakIcon
                  }
                >
                  <Popup autoClose={false}>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              ) : (
                ""
              )}
              {data?.devices?.[2]?.coords ? (
                <Marker
                  key={data.devices[2].id}
                  position={[
                    data?.devices[2]?.coords?.lat,
                    data?.devices[2]?.coords?.lon,
                  ]}
                  icon={markerOcakIcon}
                >
                  <Popup autoClose={false}>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              ) : (
                ""
              )}
            </MapContainer>
          </div>
        ) : (
          <div className="relative flex flex-col  max-sm:w-full bg-slate-50 z-[9] h-40  mb-4 rounded-xl items-center justify-center ">
            <img src={NoDrawn} className="w-20 h-12 mb-3" />
            <p className="text-xl lg:text-base font-semibold text-textDark">
              {" "}
              {t("fieldsNoDrawn")}
            </p>
            <p className="text-sm lg:text-xs font-medium w-3/4 text-center text-textLight">
              {t("fieldsDefineField")}
            </p>
          </div>
        )}

        <div className="relative z-0 ">
          <div className="relative  h-36 flex justify-center ">
            {data.devices?.length > 0 ? (
              // <div className='flex flex-row justify-center bg-slate-200 gap-2'>

              //   <p className='text-5xl font-bold'>{data.devices.length}</p>
              //   <p className='text-lg self-end'>Device</p>
              // </div>
              <div className="flex flex-row  w-full ">
                <div className="flex flex-col  w-full">
                  <p className="flex flex-row gap-1 text-sm font-semibold text-textDark">
                    {t("fieldsLastMeasurement")}
                  </p>
                  <p className="text-sm text-textLight">
                    {t("device")} E95E <br /> 16:43, 11.01.2023
                  </p>
                  <div className="w-full gap-8  flex flex-row pt-5">
                    <div className="flex flex-col items-center text-textDark">
                      <p className="text-xs font-semibold">{t("airtemp")} </p>
                      <p className="text-xl">12 °</p>
                    </div>
                    <div className="flex flex-col items-center text-textDark">
                      <p className="text-xs font-semibold"> {t("wind")}</p>
                      <p className="text-xl flex flex-row items-end">
                        5 <p className="text-xs pb-1 pl-">km/h</p>
                      </p>
                    </div>
                    <div className="flex flex-col items-center text-textDark">
                      <p className="text-xs font-semibold"> {t("humidity")}</p>
                      <p className="text-xl flex flex-row items-end">
                        48 <p className="text-xs pb-1 pl-">%</p>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="absolute right-0  leftside flex flex-row items-start  -top-6 ">
                  {/* <div className="">
                    <p className="font-medium text-slate-500">izci</p>
                    <p className="text-5xl font-bold">{data.devices.length}</p>
                  </div>
                  <img src={Izci} className="h-24  " /> */}
                  <div
                    className={`${
                      data.name == "Haymana Mısır" ||
                      data.name == "Kalecik Üzüm" ||
                      data.name == "FFD0"
                        ? ""
                        : "hidden"
                    } bg-[#F201AF] h-20 mb-8 w-14  rounded-bl-[1.25rem] text-white font-bold`}
                  >
                    <div className="flex flex-col items-center justify-center pt-3">
                      <p className="text-4xl">
                        {data.name == "Haymana Mısır" || data.name == "FFD0"
                          ? "1"
                          : "2"}
                      </p>
                      <p className="text-sm">{t("refillshort")}</p>
                    </div>
                  </div>
                  <div
                    className={`${
                      data.name == "Haymana Mısır" ||
                      data.name == "Kalecik Üzüm"
                        ? ""
                        : "hidden"
                    } bg-[#FFA800] h-24 mb-8 w-14  rounded-bl-[1.25rem] text-white font-bold `}
                  >
                    <div className="flex flex-col items-center justify-center pt-6">
                      <p className="text-4xl">1</p>
                      <p className="text-sm">{t("overshort")}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-row  justify-center items-center p-3 ">
                <img src={NoInstalled} className="w-12 mr-5 " />
                <p className="text-lg font-medium text-textDark">
                  {t("fieldsNoInstalled")}
                  <p className="text-xs font-medium text-textLight">
                    {t("fieldsInstallDevice")}
                  </p>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FieldCard;
