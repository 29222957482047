import React, { useContext, useState, useEffect } from "react";
import { AccountContext } from "../events/Account";
import Devices from "../components/Devices";
import DeviceCard from "../components/DeviceCard";
import DeviceOcakCard from "../components/DeviceOcakCard";
import DeviceVanaCard from "../components/DeviceVanaCard";
import DevicePompaCard from "../components/DevicePompaCard";
import DeviceTofCard from "../components/DeviceTofCard";
import { useTranslation } from "react-i18next";
function DevicesPage() {
  const { t, i18n } = useTranslation();

  const { apiKey, user_id } = useContext(AccountContext);
  const [fields, setFields] = useState([]);
  const [devices, setDevices] = useState([]);
  const [izci, setIzci] = useState([]);
  const [ocak, setOcak] = useState([]);
  const [lonelyDevices, setLonelyDevices] = useState([]);
  var allfield = [];
  var allocak = [];
  var allizci = [];
  var alldevice = [];
  var lonelyocak = [];
  var lonelyizci = [];
  useEffect(() => {

    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };
    //userdatafetch
    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
      requestOptionsGET
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        alldevice = result.body.devices && result.body.devices;
        allfield = result.body.fields && result.body.fields;
        fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/userOcakDevice`,
          requestOptionsGET
        )
          .then((response) => response.json())
          .then((result) => {
            console.log("333 User Ocak Device :", result);
            allocak =
              result.body &&
              result.body.filter((field) => field.deleted_at == undefined);
            fetch(
              `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/userIzciDevice`,
              requestOptionsGET
            )
              .then((response) => response.json())
              .then((result) => {
                console.log("333 User Izci Device :", result);
                allizci =
                  result.body &&
                  result.body.filter((field) => field.deleted_at == undefined);
                allizci = allizci.sort((a, b) => a.izci_length - b.izci_length);
                var tempdevices;
                allfield.map((f, inf) => {
                  if (f.devices) {
                    f.devices.map((d, ind) => {
                      allocak.find((o, ino) => {
                        if (o.id == d) {
                          console.log(
                            "find method oca :",
                            o,
                            "index oca: ",
                            ino
                          );
                          allfield[inf].devices[ind] = o;
                        }
                      });
                      allizci.find((o, ino) => {
                        if (o.id == d) {
                          console.log(
                            "find method izc:",
                            o,
                            "index izc: ",
                            ino
                          );
                          allfield[inf].devices[ind] = o;
                        }
                      });
                    });
                  }
                });
                setFields(allfield);
                setDevices(alldevice);
                setOcak(allocak);
                setIzci(allizci);
              })
              .catch((error) => console.log("error", error));
          })
          .catch((error) => console.log("error", error));
      })
      .catch((error) => console.log("error", error));
    //userocakfetch

    //userizcifetch
  }, []);

  return (
    <div className="flex flex-col p-5  max-sm:pt-0  mx-auto max-w-8xl  sm:px-6  max-sm:justify-center  lg:px-8">
      <div
        id="myElement13"
        className="myElement13 sticky top-0 w-screen lg:w-full lg:relative lg:bg-none px-5 -ml-5 z-10 max-sm:bg-[#f8f9fa] pt-10 pb-4 transition-all duration-300  ease-in-out delay-75"
      >
        <p className="text-4xl text-textDark font-medium ">
          {t('devices')}
        </p>
        <div id="bigselam">
          <p className="text-textLight selam" id="selam">
           {t('monitor')}
          </p>
          <div className="w-full pt-2 h-8  text-textDark text-xs font-bold flex flex-row gap-2">
              <span className="bg-white px-2 py-1 rounded-md shadow-miniMap">
                {" "}
                optimal
              </span>
              <span className="bg-white px-4 py-1 rounded-md shadow-miniMap">
                {" "}
                {t('refill')}
              </span>
              <span className="bg-white px-4 py-1 rounded-md shadow-miniMap">
                {" "}
                {t('over')}

              </span>
              <span className="bg-white px-4 py-1 rounded-md shadow-miniMap">
                {" "}
                {t('on')}

              </span>
              <span className="bg-white px-4 py-1 rounded-md shadow-miniMap">
                {" "}
                {t('off')}

              </span>
            </div>
        </div>
      </div>
      <div className="flex flex-row max-sm:flex-col w-full   flex-wrap ">
        <p className="text-textDark font-medium text-lg mb-4">{t('irrigation')}</p>
        <div className=" flex flex-row flex-wrap gap-6 max-sm:gap-4 max-sm:justify-between  w-full">
          {izci.map((e) => {
            return e.type == "vana" && <DeviceVanaCard data={e} />;
          })}

          {izci.map((e) => {
            return e.type == "pompa" && <DevicePompaCard data={e} />;
          })}
        </div>

        <p className="text-textDark font-medium text-lg mb-4 mt-8 w-full ">
          {t('sensors')}
        </p>
        {ocak.map((e) => {
          return <DeviceOcakCard data={e} />;
        })}

        {izci.map((e) => {
          return e.type == "izci" && <DeviceCard data={e} />;
        })}
        {izci.map((e) => {
          return e.type == "tof" && <DeviceTofCard data={e} />;
        })}
        
      </div>
      
    </div>
  );
}

export default DevicesPage;
