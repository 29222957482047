import React from "react";
import DeviceCard from "./DeviceCard";
import DeviceOcakCard from "./DeviceOcakCard";
import { Link } from "react-router-dom";
import InstallDevice from "../assets/installnewdevice.svg";
import FarmerWoman from "../assets/farmerwoman.png";
import DeviceVanaCard from "./DeviceVanaCard";
import DevicePompaCard from "./DevicePompaCard";
import DeviceTofCard from "./DeviceTofCard";
import { useTranslation } from "react-i18next";

function Devices({ data }) {
  const { t, i18n } = useTranslation();

  data?.devices?.sort((a, b) =>
    a.izci_length > b.izci_length ? 1 : b.izci_length > a.izci_length ? -1 : 0
  );
  data.devices = data?.devices?.filter(
    (obj) => obj.user_deleted_at == undefined || obj.user_deleted_at == false
  )
  console.log("seribak",data?.devices?.filter(
    (obj) => obj.user_deleted_at == undefined || obj.user_deleted_at == false
  ))
    console.log("seribak2",data.devices)
  return (
    // <div className='flex flex-row max-sm:flex-col w-full   flex-wrap'>
    //   <p className='text-textDark font-medium text-lg'>Irrigation</p>
    //   {data?.devices
    //     ? data?.devices?.map((e) => {
    //         if (e.type == 'ocak') {
    //           return <DeviceOcakCard key={e.id} data={e} />;
    //         }
    //       })
    //     : ''}
    //     {data?.devices
    //     ? data?.devices?.map((e) => {
    //       if (e.type == 'vana') {
    //         return <DeviceVanaCard key={e.id} data={e} />;
    //       }
    //     })
    //     : ''}
    //     {data?.devices
    //     ? data?.devices?.map((e) => {
    //       if (e.type == 'tof') {
    //           return <DeviceTofCard key={e.id} data={e} />;
    //         }
    //       })
    //       : ''}
    //     {data?.devices
    //     ? data?.devices?.map((e) => {
    //       if (e.type == 'pompa') {
    //         return <DevicePompaCard key={e.id} data={e} />;
    //       }
    //     })
    //     : ''}
    //     {data?.devices
    //       ? data?.devices?.map((e) => {
    //           if (e.type == 'izci') {
    //             return <DeviceCard key={e.id} data={e} />;
    //           }
    //         })
    //       : ''}
    //   <div className='p-2 mt-[3.75rem] '>
    //     <Link
    //       to='/setupDevice'
    //       className=' bg-orange-600 w-full h-full pt-[3.75rem]'
    //     >
    //       <div className='h-full bg-white rounded-md p-16 flex flex-col justify-center items-center'>
    //         <img src={InstallDevice} className='w-44 mb-5' />
    //         <img src={FarmerWoman} className='w-32' />
    //       </div>
    //     </Link>
    //   </div>
    // </div>
    <div className="flex flex-row max-sm:flex-col w-full   flex-wrap ">
      <p className="text-textDark font-medium text-lg mb-4">{t('irrigation')}</p>
      <div className=" flex flex-row flex-wrap gap-6 max-sm:gap-4 max-sm:justify-between  w-full">
      {data?.devices
        ? data?.devices?.map((e) => {
            if (e.type == "tof") {
              return <DeviceTofCard key={e.id} data={e} fieldData={data}/>;
            }
          })
        : ""}
        {data?.devices
      ? data?.devices?.map((e) => {
        if (e.type == 'pompa') {
          return <DevicePompaCard key={e.id} data={e} fieldData={data} />;
        }
      })
      : ''}
        {data?.devices
          ? data?.devices?.map((e) => {
              if (e.type == "vana") {
                return <DeviceVanaCard key={e.id} data={e} fieldData={data}/>;
              }
            })
          : ""}
          

        
      </div>

      <p className="text-textDark font-medium text-lg mb-4 mt-8 w-full ">
        {t('sensors')}
      </p>
      {data?.devices
        ? data?.devices?.map((e) => {
            if (e.type == "ocak") {
              return <DeviceOcakCard key={e.id} data={e} fieldData={data}/>;
            }
          })
        : ""}

      {data?.devices
        ? data?.devices?.map((e) => {
            if (e.type == "izci") {
              return <DeviceCard key={e.id} data={e} fieldData={data}/>;
            }
          })
        : ""}
      {/* {data?.devices
        ? data?.devices?.map((e) => {
            if (e.type == "tof") {
              return <DeviceTofCard key={e.id} data={e} />;
            }
          })
        : ""} */}
      {/* <div className="p-2  ">
        <Link to="/setupDevice" className="  w-full h-full ">
          <div className="h-full bg-white shadow-myShadow1 rounded-2xl p-16 flex flex-col justify-center items-center">
            <img src={InstallDevice} className="w-32 mb-5" />
            <img src={FarmerWoman} className="w-20" />
          </div>
        </Link>
      </div> */}
    </div>
  );
}

export default Devices;
