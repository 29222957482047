import Signup from './pages/Signup';
import Login from './pages/Login';
import { Account, AccountContext } from './events/Account';
import { useContext } from 'react';
import Setting from './pages/Setting';
import ForgotPassword from './pages/ForgotPassword';
import Timeseries from './pages/TimeSeries';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginLayout from './pages/LoginLayout';
import PrivateRoute from './pages/PrivateRoute';
import Layout from './pages/Layout';
import Home from './pages/Home';
import SetupField from './pages/SetupField';
import SetupDevice from './pages/SetupDevice';
import SelectedField from './pages/SelectedField';
import SelectedDevice from './pages/SelectedDevice';
import SelectedFieldLayout from './pages/SelectedFieldLayout';
import DevicesPage from './pages/DevicesPage';
import FieldSetting from './pages/FieldSetting';
import "./App.css"
import Profile from './pages/Profile';
function App() {
  document.documentElement.style.setProperty(
    '--vh',
    window.innerHeight * 0.01 + 'px'
  );
  const { status } = useContext(AccountContext);

  return (
    <BrowserRouter>
    
      <Routes>
        <Route path='/' element={<LoginLayout />}>
          <Route index path='login' element={<Login />} />
          <Route path='signup' element={<Signup />} />
          <Route path='forgotPassword' element={<ForgotPassword />} />
        </Route>
        <Route
          path='/'
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route index element={<Home />} />
          <Route path=':fieldname' element={<SelectedFieldLayout />}>
            <Route index element={<SelectedField />} />
            <Route path=':deviceid' element={<SelectedDevice />} />
            <Route path='settings' element={<FieldSetting />} />
          </Route>

          <Route path='devices' element={<DevicesPage />} />
          <Route path='setupField' element={<SetupField />} />
          <Route path='SetupDevice' element={<SetupDevice />} />
          <Route path='profile' element={<Profile />} />
          {/* <Route path='izci' element={<IzciLayout />}>
            <Route index element={<Izci />} />
            <Route path=':id' element={<SelectedIzci />} />
          </Route>
          <Route path='user' element={<User />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
{
  /* <Route path='/' element={<Status />}>
{status ? (
  <Route path='/' index element={<Setting />} />
) : (
  <>
    <Route path='/' element={<Login />} />
    <Route path='/signup' element={<Signup />} />
    <Route path='/forgotPassword' element={<ForgotPassword />} />
  </>
)}
<Route path='/timeseries' element={<Timeseries />} />
</Route> */
}
