import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import Drop from '../assets/drop.svg';
import Temp from '../assets/temp.svg';
import Particle from '../assets/particle.svg';
import { useParams, useLocation } from 'react-router-dom';
import ReactWeather, { useOpenWeather } from 'react-open-weather';

function SelectedOcak() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { deviceid } = useParams();
  const location = useLocation();
  // console.log('seleceddevice : ', deviceid, 'locaiton :', location);
  const [fieldData, setFieldData] = useState(location.state && location.state);
  const [full, setfull] = useState(false);
  const [lastMeasureDate, setLastMeasureDate] = useState();
  const [lastData, setLastData] = useState();
  const [fieldTown, setFieldTown] = useState();

  var position = [];
  const tempcurrent = [
    lastData?.at
      ? {
          at: Number(lastData.at).toFixed(2),
        }
      : null,
    lastData?.sm5
      ? {
          mois: Number(lastData.sm5).toFixed(2),
          temp: Number(lastData.st5).toFixed(0),
          cm: '05',
        }
      : null,
    lastData?.sm15
      ? {
          mois: Number(lastData.sm15).toFixed(2),
          temp: Number(lastData.st15).toFixed(0),
          cm: '15',
        }
      : null,
    lastData?.sm25
      ? {
          mois: Number(lastData.sm25).toFixed(2),
          temp: Number(lastData.st25).toFixed(0),
          cm: '25',
        }
      : null,
    lastData?.sm35
      ? {
          mois: Number(lastData.sm35).toFixed(2),
          temp: Number(lastData.st35).toFixed(0),
          cm: '35',
        }
      : null,
    lastData?.sm45
      ? {
          mois: Number(lastData.sm45).toFixed(2),
          temp: Number(lastData.st45).toFixed(0),
          cm: '45',
        }
      : null,
    lastData?.sm55
      ? {
          mois: Number(lastData.sm55).toFixed(2),
          temp: lastData?.st55 && Number(lastData.st55).toFixed(0),
          cm: '55',
        }
      : null,
    lastData?.sm65
      ? {
          mois: Number(lastData.sm65).toFixed(2),
          temp: lastData?.st65 && Number(lastData.st65).toFixed(0),
          cm: '65',
        }
      : null,
    lastData?.sm75
      ? {
          mois: Number(lastData.sm75).toFixed(2),
          temp: lastData?.st75 && Number(lastData.st75).toFixed(0),
          cm: '75',
        }
      : null,
    lastData?.sm85
      ? {
          mois: Number(lastData.sm85).toFixed(2),
          temp: lastData?.st85 && Number(lastData.st85).toFixed(0),
          cm: '85',
        }
      : null,
    // { mois: 47, temp: 23, cm: '65' },
    // { mois: 45, temp: 23, cm: '75' },
    // { mois: 40, temp: 22, cm: '85' },
  ];
  const { data, isLoading, errorMessage, locationLabel } = useOpenWeather({
    key: '649aab4a6e31bce8122c212ee9b978bf',
    // lat: 36.924754,
    // lon: 30.749551,
    lat: fieldData?.coords?.lat,
    lon: fieldData?.coords?.lon,
    lang: 'tr',
    unit: 'metric', // values are (metric, standard, imperial)
  });
  useEffect(() => {
    const requestOptionsnom = {
      method: 'GET',
    };
    fetch(
      `https://nominatim.openstreetmap.org/search?q=${fieldData?.coords?.lat},${fieldData?.coords?.lon}&addressdetails=1&format=json`,
      requestOptionsnom
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(('coord nomi:', result));
        setFieldTown(result[0].address.town);
      })
      .catch((err) => console.log('err : ', err));
  }, []);

  useEffect(() => {
    fetch(
      `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=ocak&timeResolution=last`,
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        var parsedlastdata = JSON.parse(result.body);
        // console.log('lastdata parse :', parsedlastdata);
        setLastData(parsedlastdata);
        var templasttime = new Date(parsedlastdata.time);

        setLastMeasureDate(formatDate(templasttime));
      })
      .catch((err) => {
        // console.log('lasdatafetcherr : ', err);
      });
    fetch(
      `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=ocak`,
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        var asd = JSON.stringify(result.body);
        var obj = [];
        result.body.map((e) => {
          obj.push(JSON.parse(e));
        });
        // console.log('parse all:', obj[0]['sm5']);
        fieldData.tsdata = obj;
      })
      .then(() => setfull(true))
      .catch((err) => {
        // console.log('devicecard err :', err);
      });
    const interval = setInterval(() => {
      console.log('This will run every 5 second!');
      fetch(
        `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=ocak&timeResolution=last`,
        {
          method: 'GET',
        }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          var parsedlastdata = JSON.parse(result.body);
          console.log('lastdata parse :', parsedlastdata);
          setLastData(parsedlastdata);
          var templasttime = new Date(parsedlastdata.time);

          setLastMeasureDate(formatDate(templasttime));
        })
        .catch((err) => {
          console.log('lasdatafetcherr : ', err);
        });
      fetch(
        `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=ocak`,
        {
          method: 'GET',
        }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          var asd = JSON.stringify(result.body);
          var obj = [];
          result.body.map((e) => {
            obj.push(JSON.parse(e));
          });
          console.log('parse all:', obj[0]['sm5']);
          fieldData.tsdata = obj;
        })
        .then(() => setfull(true))
        .catch((err) => {
          console.log('devicecard err :', err);
        });
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  function formatDate(date) {
    var mdate = new Date(date);
    mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
    return `       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }
     ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    `;
  }
  function yaz(attrname) {
    var time = [];
    var value = [];
    var lasttime;
    fieldData.tsdata?.map((data) => {
      if (Date.parse(data.time) - lasttime < -7200000) {
        value.push([Date.parse(data.time) + 3200000, null]);
      }
      var mdate = new Date(data.time);
      mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
      value.push([mdate, Number(data[attrname]).toFixed(2)]);

      lasttime = Date.parse(data.time);
    });
    return value;
  }

  const DrawStackedTSSM = (data) => {
    
    var graphData1 = yaz('am');
    return StackedLine([graphData1], ['Humidity']);
  };
  const DrawStackedTSST = (data) => {
    switch (fieldData.izci_length) {
      case '60':
        var graphData1 = yaz('st5');
        var graphData2 = yaz('st15');
        var graphData3 = yaz('st25');
        var graphData4 = yaz('st35');
        var graphData5 = yaz('st45');
        var graphData6 = yaz('st55');
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
          ],
          ['st05', 'st15', 'st25', 'st35', 'st45', 'st55']
        );
      case '90':
        var graphData1 = yaz('st5');
        var graphData2 = yaz('st15');
        var graphData3 = yaz('st25');
        var graphData4 = yaz('st35');
        var graphData5 = yaz('st45');
        var graphData6 = yaz('st55');
        var graphData7 = yaz('st65');
        var graphData8 = yaz('st75');
        var graphData9 = yaz('st85');
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
            graphData7,
            graphData8,
            graphData9,
          ],
          [
            'st05',
            'st15',
            'st25',
            'st35',
            'st45',
            'st55',
            'st65',
            'st75',
            'st85',
          ]
        );
      default:
        var graphData1 = yaz('st5');
        var graphData2 = yaz('st15');
        var graphData3 = yaz('st25');
        return StackedLine(
          [graphData1, graphData2, graphData3],
          ['st05', 'st15', 'st25']
        );
    }
  };
  const DrawStackedTSAT = (data) => {
    var graphData1 = yaz('at');
    return StackedLine([graphData1], ['at']);
  };

  //tubitak ec icin
  const [mois, setMois] = useState(0);
  const [temp, setTemp] = useState(24.5);
  const [ec, setEc] = useState(0);

  return (
    
  <div className='flex flex-col gap-3   mx-auto max-w-8xl  sm:px-6  max-sm:justify-center  lg:px-8  mb-64'>
  <p className='text-4xl font-bold mt-20 my-3 ml-3'>{deviceid.slice(-4)}</p>
  
  <div className='flex flex-row max-sm:flex-col gap-3'>
    <div className='flex flex-row max-sm:flex-col max-sm:w-full gap-3  w-1/2 '>
      
    <div className='bg-white rounded-md'>
        <div className='  '>
        <div className=' flex flex-row flex-wrap gap-5  '>
            <div className=' w-full h-full'>
            <div className='flex flex-row w-full justify-between p-3'>
                <div>
                <p className='text-lg font-bold '>Son Durum</p>
                <p>Ölçüm tarihi: {formatDate(lastData?.time)}</p>
                </div>
                <p className='font-bold text-green-500'>online</p>
            </div>
            <div className='cubukcontainer flex flex-row justify-evenly mb-4'>
                <div
                className='w-32 mr-3  h-20 max-sm:w-32
            bg-contain bg-ocak bg-no-repeat bg-center  ml-4'
                ></div>
                <div>
                <div className='mr-3'>
                    <div className='flex flex-row justify-between font-bold text-slate-500 gap-5'>
                    <p>Wind</p>
                    <p>*Humidity</p>
                    <p>*Temp</p>
                    </div>

                    <div className='flex flex-row justify-between font-bold text-3xl '>
                    <p className='flex flex-row gap-1'>
                        - <p className='text-sm  self-center '> km/h</p>
                    </p>
                    <p className='flex flex-row gap-1'>
                        {Number(lastData?.am)} <p className='text-sm  self-center '> %</p>
                    </p>
                    <p className='flex flex-row gap-1'>
                        {Number(lastData?.at)} <p className='text-sm  self-center '> °C</p>
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    <div className=' '>
    <ReactWeather
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    data={data}
                    lang='en'
                    locationLabel={fieldTown}
                    unitsLabels={{ temperature: '°', windSpeed: 'Km/h' }}
                    showForecast={false}
                    />
    </div>
    </div>
    {/* {full ? (
      <div className=' w-1/2 max-sm:w-full  bg-white'>
        <p className='relative z-20 text-lg font-bold  p-3 '>
          Humidity (%){' '}
        </p>
        {DrawStackedTSSM(fieldData.tsdata)}
      </div>
    ) : (
      <div className=' flex flex-row flex-wrap gap-5  bg-white w-1/2 '>
        <div className='bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10'></div>
      </div>
    )} */}
  </div>
  {full ? (
    <div className='flex flex-row max-sm:flex-col gap-3 h-1/2'>
      <div className=' w-1/2 max-sm:w-full  bg-white'>
      <p className='relative z-20 text-lg font-bold  p-3 '>
          Humidity (%){' '}
        </p>
        {DrawStackedTSSM(fieldData.tsdata)}
      </div>
      <div className=' w-1/2 max-sm:w-full  bg-white'>
        <p className='relative z-20 text-lg font-bold  p-3 '>
          Air Temperature (°C){' '}
        </p>
        {DrawStackedTSAT(fieldData.tsdata)}
      </div>
    </div>
  ) : (
    <div className=' flex flex-row flex-wrap gap-5 bg-white h-72 w-1/2 '>
      <div className='bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10'></div>
    </div>
  )}
</div>
  );
}

export default SelectedOcak;
function StackedLine(rawdatas, names) {
  let option = {
    // title: {
    //   show: false,
    //   text: ' asd',
    //   display: 'none',
    // },

    tooltip: {
      trigger: 'axis',
    },
    legend: {
      type: 'scroll',
      top: '0%',
    },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: false, readOnly: false },
        saveAsImage: {},
      },
      top: '12%',
      z: 100000,
    },
    grid: [
      {
        left: '3%',
        right: '5%',
        bottom: '8%',
        top: '10%',
        height: '75%',
        containLabel: true,
      },
    ],
    axisLine: {
      show: false,
    },
    xAxis: {
      type: 'time',
    },
    yAxis: {
      splitLine: { show: false },
      splitArea: {
        show: true,
      },
      axisLine: { show: true },
      type: 'value',
      max: function (value) {
        return value.max + 10;
      },
      min: function (value) {
        return value.min - 0;
      },
    },
    dataZoom: [
      {
        type: 'slider',
        show: true,
        brushSelect: false,
        handleStyle: {
          borderColor: 'rgba(0, 0, 0, 1)',
        },
        handleIcon: 'roundRect',
        handleSize: '61%',
      },
    ],
    series: [],
    seriesCnt: '6',
    backgroundColor: 'rgba(255,255,255,1)',
    titleColor: '#000000',
    subtitleColor: '#505050',
    textColorShow: false,
    textColor: '#333',
    markTextColor: '#ffffff',
    color: [
      '#0015FF',
      '#9900FF',
      '#00BBFF',
      '#FF8400',
      '#FF00BF',
      '#FFF700',
      '#00E0A5',
      '#9983A7',
      '#CC0000',
    ],
    borderColor: '#ccc',
    borderWidth: '0',
    visualMapColor: ['#8a7ca8', '#e098c7', '#cceffa'],
    legendTextColor: '#000000',
    kColor: '#e098c7',
    kColor0: 'transparent',
    kBorderColor: '#e098c7',
    kBorderColor0: '#8fd3e8',
    kBorderWidth: '2',
    lineWidth: '1',
    symbolSize: '0',
    symbol: 'emptyRoundRect',
    symbolBorderWidth: '0',
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: '#aaaaaa',
    mapLabelColor: '#000',
    mapLabelColorE: '#ffffff',
    mapBorderColor: '#444',
    mapBorderColorE: '#444',
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: '#eee',
    mapAreaColorE: '#e098c7',
    axes: [
      {
        type: 'all',
        name: '通用坐标轴',
        axisLineShow: true,
        axisLineColor: '#000000',
        axisTickShow: false,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#000000',
        splitLineShow: false,
        splitLineColor: ['#eeeeee', '#333333'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
      {
        type: 'category',
        name: '类目坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: false,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'value',
        name: '数值坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'log',
        name: '对数坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'time',
        name: '时间坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: '#4100ff',
    toolboxEmphasisColor: '#000000',
    tooltipAxisColor: '#0013ff',
    tooltipAxisWidth: '3',
    timelineLineColor: '#8fd3e8',
    timelineLineWidth: 1,
    timelineItemColor: '#ff0000',
    timelineItemColorE: '#8fd3e8',
    timelineCheckColor: '#8fd3e8',
    timelineCheckBorderColor: '#8a7ca8',
    timelineItemBorderWidth: 1,
    timelineControlColor: '#8fd3e8',
    timelineControlBorderColor: '#8fd3e8',
    timelineControlBorderWidth: 0.5,
    timelineLabelColor: '#8fd3e8',
    datazoomBackgroundColor: 'rgba(0,0,0,0)',
    datazoomDataColor: 'rgba(255,255,255,0.3)',
    datazoomFillColor: 'rgba(167,183,204,0.4)',
    datazoomHandleColor: '#a7b7cc',
    datazoomHandleWidth: '100',
    datazoomLabelColor: '#333',
  };
  rawdatas.map((e, index) => {
    option.series.push({
      name: names[index],
      symbol: 'roundRect',
      type: 'line',
      data: e,
      lineStyle: {
        width: 1,
      },
    });
  });
  return <ReactEcharts option={option} className=' z-10' />;
}
