import React, { useState } from "react";

import { Outlet, Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import HamburgerButton from "../components/HamburgerButton";
const Layout = () => {
  const [scrollTop, setScrollTop] = useState("");

  const handleScroll = (event) => {
    const element = document.getElementById("myElement13");
      let pos = element.getBoundingClientRect().y ;
      if(pos == 0){
        {
          element.classList.add("stickyHeader");
        }
      }else if(element.classList.contains("stickyHeader")){
        element.classList.remove("stickyHeader");
      }
    // console.log("scroll", scrollTop);
  };
  

  
  return (
    <div className="relative select-none w-screen h-screen overflow-clip ">
      <div className="fixed z-20 left-10">
        <HamburgerButton />
      </div>
      <div className="relative z-0 w-screen ">
        <Navbar />
      </div>
      <div
        onClick={() => {
          var navi = document.getElementById("mobilBody");

          if (navi.classList.contains("mobilSmallBody")) {
            navi.classList.remove("mobilSmallBody");
          }
        }}
        className="relative max-sm:bg-[#F8F9FA] lg:bg-none  w-screen h-screen overflow-y-scroll z-10 lg:w-[calc(100%_-_15rem)]  lg:ml-[15rem]  "
        id="mobilBody"
        // onScroll={handleScroll}
      >
        {/* <div className='topspace fixed top-0  w-14 h-10 bg-[#F8F9FA]   z-40'></div> */}
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
