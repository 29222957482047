import React, { useState, useContext, useEffect } from "react";
import SetupFieldImg from "../assets/setupFieldImg.png";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../events/Account";
import Scanner from "../components/BarcodeScanner";
import QrCodeScanner from "../components/QrCodeScanner";
import { QrReader } from "react-qr-reader";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  Popup,
  Polygon,
  useMapEvent,
  FeatureGroup,
  Circle,
  useMapEvents,
  AttributionControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { map } from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { v4 as uuidv4 } from "uuid";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

const markerIcon = new L.Icon({
  iconUrl: require("../assets/marker-w.png"),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});
var popup = new L.popup();

function SetupDevice() {
  const { t, i18n } = useTranslation();
  function LocationMarker() {
    const [position, setPosition] = useState(null);
    const map = useMapEvents({
      click(e) {
        map.locate();
        console.log(e);
        setQRLatLng({ lat: e.latlng.lat, lon: e.latlng.lng });
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>You are here</Popup>
      </Marker>
    );
  }
  const { apiKey, userMail, user_id } = useContext(AccountContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [userACL, setUserACL] = useState([]);
  const [ACLFields, setACLFields] = useState([]);
  const [step, setStep] = useState(2);
  const [field, setField] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceId, setDeviceId] = useState();

  const [direkNo, setDirekNo] = useState();
  const [cikisAdi, setCikisAdi] = useState();
  const [sebekeUnsuru, setSebekeUnsuru] = useState();
  const [iletkenKesiti, setIletkenKesiti] = useState();
  const [direkTipi, setDirekTipi] = useState();
  const [userNote, setUserNote] = useState();

  const [fieldPosition, setFieldPosition] = useState([]);
  const [selectedField, setSelectedField] = useState(0);
  const [selectedFieldGeometryType, setSelectedFieldGeometryType] = useState();

  const [QRData, setQRData] = useState();
  const [QRLatLng, setQRLatLng] = useState({ lat: 38, lon: 32 });
  const [QRstatus, setQRstatus] = useState();

  useEffect(() => {
    if (window?.navigator?.userAgentData?.mobile) {
      setIsMobile(true);
    } else if (!window?.navigator?.userAgentData) {
      setIsMobile(true);
    }
    alert("Please enable your GPS position feature.");
  }, []);

  const readLocation = () => {
    var minAccuracy;

    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        function (position) {
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;
          var accuracy = position.coords.accuracy;
          console.log("readlocation");
          setQRLatLng((prev) => {
            return {
              ...prev,
              lat: latitude ? latitude : 38,
              lon: longitude ? longitude : 32,
            };
          });
          var capa = document.getElementById("capa");
          if (capa) {
            capa.innerHTML =
              "latitude: " +
              latitude +
              ", longitude: " +
              longitude +
              ", accuracy: " +
              accuracy.toFixed(2);
          }
        },
        function error(err) {
          console.error(`ERROR(${err.code}): ${err.message}`);
        },
        { maximumAge: 100000, timeout: 1000, enableHighAccuracy: true }
      );
    } else {
      alert("Geolocation API is not supported in your browser.");
    }

    return;
  };
  const qrScanned = (res) => {
    // alert(res)
    setQRData(res);
    readLocation();
    setStep(3);

  };
  const deviceSubmit = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();

    var arr = {
      konum: QRLatLng,
      kuran: userMail,
      kurulum_tarihi: day + "." + month + "." + year,
      direk_no: direkNo,
      cikis_adi: cikisAdi,
      sebeke_unsuru: sebekeUnsuru,
      iletken_kesiti: iletkenKesiti,
      direk_tipi: direkTipi,
      operator_notu: userNote,
      kaldıran: "",
      kaldırma_tarihi: "",
    };

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(arr),
    };

    fetch(
      `https://x7el29cfwg.execute-api.eu-west-1.amazonaws.com/v1/izci/${QRData}`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Geçersiz Kare Kod");
      })
      .then((result, code) => {
        console.log(
          "Device Request :",
          result.body,
          "ownerid. :",
          result.body.owner_id
        );
        if (
          result.body.owner_id &&
          result.body.owner_id !== "" &&
          result.body.owner_id !== user_id
        ) {
          throw new Error("Bu cihaza yetkiniz bulunmamakta");
        }
        setStep(5);
        setTimeout(() => navigate("/"), 3000);
      })
      .catch((error) => {
        console.log("Device Request error", error.message);
        setQRstatus(error.message);
        setStep(66);
      });
    console.log("allfield after operation :", 1);
  };
  function selectFieldFunc(e) {
    setSelectedField(e);
    setLoading(false);
  }
  return (
    <div className="relative max-sm:z-[51] h-screen bg-white  flex items-center justify-center">
      {step == 2 &&
        (isMobile ? (
          <div className=" flex  flex-col justify-center items-center bg-white ">
            <div className="flex flex-col justify-center items-center  h-screen  w-screen">
              <div className={`w-full h-full bg-red-300 ${QRData ? "" : ""}`}>
                {/* <Scanner onResult={(res) => qrScanned(res)} /> */}
                {/* <QrReader
                  constraints={{
                    facingMode: { exact: "environment" },
                    autoGainControl: { exact: true },
                    noiseSuppression: { exact: true },
                    sampleRate:{exact:"max"}
                  }}
                  onResult={(result, error) => {
                    if (!!result) {
                      qrScanned(result?.text);
                    }

                    if (!!error) {
                      console.info(error);
                    }
                  }}
                  style={{ width: "100%" }}
                /> */}


                  <QrCodeScanner onResult={(res) => qrScanned(res)} />
                  {/* <p>qrdata: {QRData}</p> */}

              </div>
              <button
                className="text-sm m-5 bg-blue-700 text-white rounded-md p-3 fixed bottom-20"
                onClick={() => setIsMobile(false)}
              >
                {t("setupSetUpDeviceNo")}
              </button>
              <button
                id="capa"
                className="text-sm m-5 bg-white  fixed top-5"
                onClick={() => readLocation()}
              ></button>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col justify-center items-center bg-white h-screen gap-6">
              <div className="flex flex-col justify-center items-center ">
                <img src={SetupFieldImg} className="w-20" />
                <p className="text-xl w-[40rem] max-sm:w-11/12 text-center mb-7">
                  {t("setupUseMobileApp")}
                </p>
                <p className="text-3xl w-[40rem] max-sm:w-11/12 text-center">
                  {t("setupContinueDeviceNo")}
                </p>
              </div>
              <input
                value={deviceId}
                onChange={(event) => setQRData(event.target.value)}
                className="max-sm:w-10/12 w-80 border-b-black h-16 bg-neutral-200 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                type="text"
                placeholder={`XXXYYY111222333`}
              />
              <div
                className="ml-auto   mr-10 absolute right-0 bottom-10  p-3 hover:cursor-pointer"
                onClick={() => {
                  readLocation();
                  setStep(3);
                }}
              >
                <p className="text-xl text-green-600">{t("next")}</p>
                <></>
              </div>
            </div>
          </>
        ))}
      {step == 3 && (
        <div className="flex  flex-col justify-center items-center bg-white w-full ">
          <div
            className={`flex flex-col justify-center items-center ${
              isMobile ? "pt-0" : "pt-16"
            } h-screen bg-oran w-full`}
          >
            <MapContainer
              center={QRLatLng}
              zoom={18}
              className="w-full h-full  z-50"
              scrollWheelZoom={isMobile ? false : true}
              dragging={isMobile ? false : true}
              zoomControl={false}
              attributionControl={false}
            >
              <TileLayer url="https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}" />
              <AttributionControl position="bottomright" prefix={"Leaflet"} />
              {selectedFieldGeometryType == "polygon" && (
                <Polygon positions={fieldPosition} />
              )}
              {selectedFieldGeometryType == "circle" && (
                <Circle
                  center={fieldPosition?.center}
                  radius={fieldPosition?.radius}
                />
              )}
              <Marker position={QRLatLng} icon={markerIcon}>
                <Popup autoClose={false}>Suyabakan</Popup>
              </Marker>
              <ResetCenterView selectPosition={QRLatLng} />
              <LocationMarker />
            </MapContainer>

            <div className="absolute z-50   top-8  py-2 w-full text-center bg-white opacity-80">
              <p className="text-lg text-blue-500 ">
                Haritadan işaretleyebilir veya Konumunu alabilirsin
              </p>
            </div>

            <div
              className="absolute z-50   bottom-14 max-sm:bottom-16 py-2 px-14 bg-white"
              // onClick={() => deviceSubmit()}
              onClick={() => setStep(4)}
            >
              <p className="text-xl text-green-600  ">{t("next")}</p>
            </div>
            <div
              className="absolute text-3xl  flex items-center justify-center z-50 w-10 h-10 right-10   bottom-14 max-sm:bottom-16 rounded-full bg-white"
              onClick={() => readLocation()}
            >
              <ion-icon name="locate"></ion-icon>
            </div>
          </div>
        </div>
      )}
      {step == 4 && (
        <div className="flex  flex-col justify-center items-center bg-white  ">
          <div className="flex flex-col justify-center gap-2  h-screen  w-full">
            <div className=" z-50  m-0   bottom-32  py-1    ">
              <p>İzolatörün bağlı olduğu direk numarası</p>
              <input
                className="w-full h-10 pl-2 py-5 mt-2 border border-blue-400 "
                onChange={(e) => setDirekNo((prev) => e.target.value)}
              />
            </div>
            <div className=" z-50  m-0   bottom-32  py-1    ">
              <p>Direğin bağlı olduğu çıkış adı</p>
              <input
                className="w-full h-10 pl-2 py-5 mt-2 border border-blue-400 "
                onChange={(e) => setCikisAdi((prev) => e.target.value)}
              />
            </div>
            <div className=" z-50  m-0   bottom-32  py-1    ">
              <p>Şebeke unsuru</p>
              <input
                className="w-full h-10 pl-2 py-5 mt-2 border border-blue-400 "
                onChange={(e) => setSebekeUnsuru((prev) => e.target.value)}
              />
            </div>
            <div className=" z-50  m-0   bottom-32  py-1    ">
              <p>İzolatörün üzerindeki iletkenin kesiti</p>
              <input
                className="w-full h-10 pl-2 py-5 mt-2 border border-blue-400 "
                onChange={(e) => setIletkenKesiti((prev) => e.target.value)}
              />
            </div>
            <div className=" z-50  m-0   bottom-32  py-1    ">
              <p>Direğin Tipi (Taşıyıcı, durdurucu, beton)</p>
              <input
                className="w-full h-10 pl-2 py-5 mt-2 border border-blue-400 "
                onChange={(e) => setDirekTipi((prev) => e.target.value)}
              />
            </div>
            <div className=" z-50  m-0   bottom-32  py-1    ">
              <p>Not ekle</p>
              <input
                className="w-full h-10 pl-2 py-5 mt-2 border border-blue-400 "
                onChange={(e) => setUserNote((prev) => e.target.value)}
              />
            </div>
            <div
              className=" z-50 text-end    py-2 px-6 "
              onClick={() => deviceSubmit()}
            >
              <p className="text-xl text-green-600  ">{t("next")}</p>
            </div>
          </div>
        </div>
      )}
      {step == 5 && (
        <div className="flex  flex-col justify-center items-center bg-white  ">
          <div className="flex flex-col justify-center items-center  h-screen  w-full">
            <img src={SetupFieldImg} className="w-20 mb-4" />
            <p className="text-2xl bg-green-700 p-12 text-white rounded-lg">
              Cihaz Başarıyla Kuruldu
            </p>
          </div>
        </div>
      )}
      {step == 20 && (
        <div className="flex  flex-col justify-center items-center bg-white  ">
          <div className="flex flex-col justify-center items-center  h-screen  w-full">
            <img src={SetupFieldImg} className="w-20 mb-4" />
            <p className="text-2xl bg-green-700 p-12 text-white rounded-lg">
              {t("setupDeviceAdded")} ;)
            </p>
          </div>
        </div>
      )}
      {step == 66 && (
        <div className="flex  flex-col justify-center items-center bg-white  ">
          <div className="flex flex-col justify-center items-center  h-screen  w-full">
            <p className="pt-3">{QRstatus} </p>
            <button
              className="text-2xl bg-blue-700 p-3 text-white rounded-sm"
              onClick={() => setStep(2)}
            >
              {t("setupTryAgain")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SetupDevice;
function ResetCenterView(props) {
  const { selectPosition } = props;
  const map = useMap();

  if (selectPosition) {
    map.setView(
      L.latLng(selectPosition?.lat, selectPosition?.lon),
      map.getZoom(),
      {
        animate: true,
      }
    );
  }

  return null;
}
