import React, { useContext, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Box, Button, MenuItem, Modal, Select, TextField } from "@mui/material";
import Prepare from "../assets/prepare.svg";
import Irrigate from "../assets/irrigate.svg";
import { AccountContext } from "../events/Account";
import tof from "../assets/tofWhite.svg";
import { useTranslation } from "react-i18next";

function DeviceTofCard({ data }) {
  const {t,i18n}= useTranslation()
  const { apiKey, user_id } = useContext(AccountContext);
  const navigate = useNavigate();
  const [lastData, setLastData] = useState();
  const [tsdata, settsdata] = useState();
  const [loading, setLoading] = useState(true);
  const [vanaStatus, setVanaStatus] = useState(false);
  const [HWNo, seyHWNo] = useState("02");
  const [SancakPacketVersion, setSancakPacketVersion] = useState("001");
  const [SancakPacketVersionOFF, setSancakPacketVersionOFF] = useState("002");
  const [GRDPacketVersion, setGRDPacketVersion] = useState("");
  const [GRDMeasureCount, setGRDMeasureCount] = useState(0);
  const [GRDSleepTimeH, setGRDSleepTimeH] = useState(3);
  const [GRDSleepTimeM, setGRDSleepTimeM] = useState(30);
  const [isCommandModal, setIsCommandModal] = useState(false);
  const [commandLoading, setCommandLoading] = useState(false);
  const [wateringTime, setWateringTime] = useState(false);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device?deviceId=${data.id}`,
      requestOptionsGET
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        const resultfilter = result.body;
        console.log("VANADATA : ", resultfilter);
        setLastData(resultfilter);
      })
      .then(() =>
        fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=tof`,
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            if (result?.body?.[0]?.status) {
              var lastd = lastData;
              lastd.status = result.body[0].status;
              setLastData(lastd);
            }
            const resultfilter = result.body;
            var obj = [];
            result.body.map((e) => {
              obj.push(JSON.parse(e));
            });
            settsdata(obj);

            // yaz(resultfilter.at);

            // console.log('funcyaz: ', yaz(resultfilter.at));
            // settsdata(resultfilter);
          })
          .then(() => setLoading(false))
          .catch((err) => {
            console.log("DeviceTofCard err :", err);
            setLoading(false);
          })
      )
      .catch((err) => {
        console.log("lasdatafetcherr : ", err);
        setLoading(false);
      });

    const interval = setInterval(() => {
      console.log("This will run every 10 second!");
      fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device?deviceId=${data.id}`,
        requestOptionsGET
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          const resultfilter = result.body;
          console.log("last measure data : ", resultfilter);
          setLastData(resultfilter);
        })
        .catch((err) => {
          console.log("lasdatafetcherr : ", err);
        });
      fetch(
        `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=tof`,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result?.body?.[0]?.status) {
            var lastd = lastData;
            lastd.status = result.body[0].status;
            setLastData(lastd);
          }
          const resultfilter = result.body;
          var obj = [];
          result.body.map((e) => {
            obj.push(JSON.parse(e));
          });
          settsdata(obj);

          // yaz(resultfilter.at);

          // console.log('funcyaz: ', yaz(resultfilter.at));
          // settsdata(resultfilter);
        })
        .then(() => setLoading(false))
        .catch((err) => {
          console.log("DeviceTofCard err :", err);
        });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  function formatDate(date) {
    var mdate = new Date(date);
    mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
    return `${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    } ,${mdate.getDate()}.${mdate.getMonth() + 1}`;
  }
  const VanaCommand = (status) => {
    setVanaStatus(status);
  };

  const StatusFunction = () => {
    if (lastData && lastData.status) {
      if (lastData.status == "on") {
        return <p className="text-green-500">ON</p>;
      } else if (lastData.status == "off") {
        return <p className="text-gray-500">OFF</p>;
      } else if (lastData.status == "pending") {
        return <p className="text-blue-500">pending</p>;
      }
    } else {
      return <p>noData</p>;
    }
  };

  const CommandFunctionOpenValve = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var tempCont = Number(GRDMeasureCount).toString(16);
    if (tempCont.length == 1) {
      tempCont = `0${tempCont}`;
    }
    var tempSleep = Number(GRDSleepTimeH * 60 + GRDSleepTimeM).toString(16);

    if (tempSleep.length == 1) {
      tempSleep = `000${tempSleep}`;
    } else if (tempSleep.length == 2) {
      tempSleep = `00${tempSleep}`;
    } else if (tempSleep.length == 3) {
      tempSleep = `0${tempSleep}`;
    }
    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: Number(SancakPacketVersion),
      g: Number(HWNo),
      i: data.id,
      d: `${tempSleep.toUpperCase()}`,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    console.log(body);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${data.id}&type=tof`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setCommandLoading(false);
      })
      .then(() => setIsCommandModal(false))
      .catch((error) => console.log("error", error));
  };
  const CommandFunctionCloseValve = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: Number(SancakPacketVersionOFF),
      g: Number(HWNo),
      i: data.id,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    console.log(body);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${data.id}&type=tof`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setCommandLoading(false);
      })
      .then(() => setIsCommandModal(false))
      .catch((error) => console.log("error", error));
  };
  function timestampToDate(timestamp) {
    var saat = new Date(timestamp).getHours();
    var dakika = new Date(timestamp).getMinutes();
    return `${saat}:${dakika <= 9 ? `0${dakika}` : dakika}`;
  }
  return (
    <div className=" min-w-[20rem]  lg:w-1/4  md:w-1/2   max-sm:p-0  max-sm:w-full mb-8">
      {loading ? (
        <div className=" w-full flex max-sm:flex-col flex-row gap-4">
          <div className="w-full ">
          <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fff">
              <Skeleton width={"60%"} height={30} />
              <Skeleton width={"100%"} height={200} />
            </SkeletonTheme>
          </div>
        </div>
      ) : (
        // <>
        //   <p className="text-2xl font-medium mt-5 mb-2 ml-2 max-sm:ml-4">
        //     {data.id.slice(-4)}
        //   </p>
        //   <div className="bg-white rounded-md h-44">
        //     <div className="  ">
        //       <div className=" flex flex-row flex-wrap gap-5 ">
        //         <div
        //           className=" w-full h-full"
        //           // onClick={() => {
        //           //   navigate(`${data.id.slice(-4)}`, {
        //           //     state: { ...data },
        //           //   });
        //           // }}
        //         >
        //           <div className="flex flex-row w-full justify-between p-3">
        //             <div className="w-full">
        //               <div>
        //                 <p className="text-lg font-medium ">Son Durum</p>
        //                 <p className="flex flex-row font-medium ">
        //                   Ölçüm tarihi:{" "}
        //                   <p className="text-blue-700 ml-1">
        //                     {tsdata?.[0].time
        //                       ? formatDate(tsdata?.[0].time)
        //                       : "nodata"}
        //                   </p>
        //                 </p>
        //               </div>
        //               <div className="flex flex-row  w-full">
        //                 <div className="w-24  mt-3 h-20  bg-tof bg-center   bg-no-repeat bg-contain  "></div>
        //                 <div className="flex flex-col  ml-3 w-8/12">
        //                   <p className=" ml-3 text-5xl font-medium flex flex-row items-end ">
        //                     {tsdata?.[0].max_dist}{" "}
        //                     <p className="ml-1 text-lg text-gray-500">cm</p>{" "}
        //                   </p>
        //                   <div className="flex flex-col font-medium ">
        //                     <div className="flex flex-row bg-slate-300  ">
        //                       <p className="text-blue-700 ml-1  w-28 ">
        //                         {tsdata?.[1].time
        //                           ? formatDate(tsdata?.[1].time)
        //                           : "nodata"}
        //                       </p>
        //                       <p className="   font-medium flex flex-row ">
        //                         {tsdata?.[1].max_dist}{" "}
        //                       </p>
        //                     </div>
        //                     <div className="flex flex-row bg-slate-200">
        //                       <p className="text-blue-700 ml-1  w-28 ">
        //                         {tsdata?.[1].time
        //                           ? formatDate(tsdata?.[2].time)
        //                           : "nodata"}
        //                       </p>
        //                       <p className="   font-medium flex flex-row  ">
        //                         {tsdata?.[2].max_dist}{" "}
        //                       </p>
        //                     </div>
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        //   <Modal
        //     open={isCommandModal}
        //     onClose={() => setIsCommandModal(false)}
        //     aria-labelledby="modal-modal-title"
        //     aria-describedby="modal-modal-description"
        //   >
        //     <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 max-sm:w-full bg-white rounded-md  shadow-24 p-4  flex flex-col">
        //       <p className="text-2xl w-full  text-center pt- pb-4">
        //         Set Valve {data.id.slice(-4)}
        //       </p>
        //       <div className="flex flex-col gap-2">
        //         {/* <TextField
        //           id="outlined-basic"
        //           label="Ocak Id"
        //           value={tsdata?.[0]?.ocak_id}
        //           variant="standard"
        //         />
        //         <TextField
        //           id="outlined-basic"
        //           label="Izci Id"
        //           value={data.id}
        //           variant="standard"
        //         />
        //         <TextField
        //           id="outlined-basic"
        //           label="Sancak Packet Version"
        //           variant="standard"
        //           value={SancakPacketVersion}
        //           onChange={(event) => {
        //             setSancakPacketVersion(event.target.value.toString());
        //           }}
        //         /> */}
        //         {/* <p className="pt-3 font-bold">GRD Commands </p> */}
        //         <div className="flex flex-row whitespace-nowrap  pl-4 gap-2 justify-start items-end">
        //           <p className="">Irrigate for</p>

        //           <Select
        //             variant="filled"
        //             id="demo-simple-select"
        //             value={GRDSleepTimeH}
        //             sx={{ width: "4rem" }}
        //             onChange={(event) => {
        //               setGRDSleepTimeH(event.target.value);
        //             }}
        //           >
        //             <MenuItem value={0}>0</MenuItem>
        //             <MenuItem value={1}>1</MenuItem>
        //             <MenuItem value={2}>2</MenuItem>
        //             <MenuItem value={3}>3</MenuItem>
        //             <MenuItem value={4}>4</MenuItem>
        //             <MenuItem value={5}>5</MenuItem>
        //             <MenuItem value={6}>6</MenuItem>
        //             <MenuItem value={7}>7</MenuItem>
        //             <MenuItem value={8}>8</MenuItem>
        //             <MenuItem value={9}>9</MenuItem>
        //             <MenuItem value={10}>10</MenuItem>
        //             <MenuItem value={11}>11</MenuItem>
        //             <MenuItem value={12}>12</MenuItem>
        //             {/* <MenuItem value={13}>13</MenuItem>
        //           <MenuItem value={14}>14</MenuItem>
        //           <MenuItem value={15}>15</MenuItem>
        //           <MenuItem value={16}>16</MenuItem>
        //           <MenuItem value={17}>17</MenuItem>
        //           <MenuItem value={18}>18</MenuItem>
        //           <MenuItem value={19}>19</MenuItem>
        //           <MenuItem value={20}>20</MenuItem>
        //           <MenuItem value={21}>21</MenuItem>
        //           <MenuItem value={22}>22</MenuItem>
        //           <MenuItem value={23}>23</MenuItem>
        //           <MenuItem value={24}>24</MenuItem> */}
        //           </Select>
        //           <p>hours</p>
        //           <Select
        //             variant="filled"
        //             id="demo-simple-select"
        //             value={GRDSleepTimeM}
        //             sx={{ width: "4rem" }}
        //             onChange={(event) => {
        //               setGRDSleepTimeM(event.target.value);
        //             }}
        //           >
        //             <MenuItem value={0}>0</MenuItem>
        //             {/* <MenuItem value={1}>1</MenuItem>
        //             <MenuItem value={2}>2</MenuItem>
        //             <MenuItem value={3}>3</MenuItem>
        //             <MenuItem value={4}>4</MenuItem> */}
        //             <MenuItem value={5}>5</MenuItem>
        //             {/* <MenuItem value={6}>6</MenuItem>
        //             <MenuItem value={7}>7</MenuItem>
        //             <MenuItem value={8}>8</MenuItem>
        //             <MenuItem value={9}>9</MenuItem> */}
        //             <MenuItem value={10}>10</MenuItem>
        //             {/* <MenuItem value={11}>11</MenuItem>
        //             <MenuItem value={12}>12</MenuItem>
        //             <MenuItem value={13}>13</MenuItem>
        //             <MenuItem value={14}>14</MenuItem> */}
        //             <MenuItem value={15}>15</MenuItem>
        //             {/* <MenuItem value={16}>16</MenuItem>
        //             <MenuItem value={17}>17</MenuItem>
        //             <MenuItem value={18}>18</MenuItem>
        //             <MenuItem value={19}>19</MenuItem> */}
        //             <MenuItem value={20}>20</MenuItem>
        //             {/* <MenuItem value={21}>21</MenuItem>
        //             <MenuItem value={22}>22</MenuItem>
        //             <MenuItem value={23}>23</MenuItem>
        //             <MenuItem value={24}>24</MenuItem> */}
        //             <MenuItem value={25}>25</MenuItem>
        //             {/* <MenuItem value={26}>26</MenuItem>
        //             <MenuItem value={27}>27</MenuItem>
        //             <MenuItem value={28}>28</MenuItem>
        //             <MenuItem value={29}>29</MenuItem> */}
        //             <MenuItem value={30}>30</MenuItem>
        //             {/* <MenuItem value={31}>31</MenuItem>
        //             <MenuItem value={32}>32</MenuItem>
        //             <MenuItem value={33}>33</MenuItem>
        //             <MenuItem value={34}>34</MenuItem> */}
        //             <MenuItem value={35}>35</MenuItem>
        //             {/* <MenuItem value={36}>36</MenuItem>
        //             <MenuItem value={37}>37</MenuItem>
        //             <MenuItem value={38}>38</MenuItem>
        //             <MenuItem value={39}>39</MenuItem> */}
        //             <MenuItem value={40}>40</MenuItem>
        //             {/* <MenuItem value={41}>41</MenuItem>
        //             <MenuItem value={42}>42</MenuItem>
        //             <MenuItem value={43}>43</MenuItem>
        //             <MenuItem value={44}>44</MenuItem> */}
        //             <MenuItem value={45}>45</MenuItem>
        //             {/* <MenuItem value={46}>46</MenuItem>
        //             <MenuItem value={47}>47</MenuItem>
        //             <MenuItem value={48}>48</MenuItem>
        //             <MenuItem value={49}>49</MenuItem> */}
        //             <MenuItem value={50}>50</MenuItem>
        //             {/* <MenuItem value={51}>51</MenuItem>
        //             <MenuItem value={52}>52</MenuItem>
        //             <MenuItem value={53}>53</MenuItem>
        //             <MenuItem value={54}>54</MenuItem> */}
        //             <MenuItem value={55}>55</MenuItem>
        //             {/* <MenuItem value={56}>56</MenuItem>
        //             <MenuItem value={57}>57</MenuItem>
        //             <MenuItem value={58}>58</MenuItem>
        //             <MenuItem value={59}>59</MenuItem> */}
        //           </Select>
        //           <p>minutes.</p>
        //         </div>
        //       </div>
        //       <div className=" p-4 w-full flex flex-row gap-2 justify-end">
        //         <Button
        //           //export all rows, including from the next page, (still respects filtering and sorting)
        //           onClick={() => setIsCommandModal(false)}
        //           variant="text"
        //         >
        //           Cancel
        //         </Button>
        //         <Button
        //           disabled={
        //             SancakPacketVersion === "" ||
        //             GRDSleepTimeM === "" ||
        //             GRDSleepTimeH === ""
        //           }
        //           //export all rows, including from the next page, (still respects filtering and sorting)
        //           onClick={() => {
        //             CommandFunctionOpenValve();
        //             setCommandLoading(true);
        //           }}
        //           startIcon={commandLoading ? "" : "^"}
        //           variant="contained"
        //         >
        //           {commandLoading ? (
        //             <div className=" flex flex-row flex-wrap">
        //               <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
        //             </div>
        //           ) : (
        //             "Apply"
        //           )}
        //         </Button>
        //       </div>
        //     </Box>
        //   </Modal>
        // </>
        <>
          <div
            className=" w-full h-full flex flex-row flex-wrap bg-white shadow-myShadow1 p-5 rounded-2xl "
            // onClick={() => {
            //   navigate(`${data.id.slice(-4)}`, {
            //     state: { ...data, tsdata },
            //   });
            // }}
          >
            <div className="w-5/12">
              <p className="font-semibold text-blue-400">{t("active")}</p>

              <img src={tof} className="h-16 my-2 " />

              <p className="text-lg font-semibold text-textDark ">
                {data.id.slice(-4)}
              </p>
              <div>
                <p className=" flex flex-col text-sm text-textLight">
                  {t('fieldsLastMeasurement')}
                  <p className="">
                    {tsdata?.[0]?.time && formatDate(tsdata?.[0]?.time)}
                  </p>
                </p>
              </div>
            </div>
            <div className="w-7/12  flex flex-col justify-center  ">
              <p className="w-full text-textLight">{t('tofDepth')}</p>
              <p className="text-3xl font-semibold text-textDark w-full text-center  mb-2">{tsdata?.[0].max_dist} cm</p>
              <div className="flex flex-row justify-between">
                <p className="text-textLight">{formatDate(tsdata?.[1].time)}</p>
                <p className="text-textDark font-semibold">{tsdata?.[1].max_dist} cm</p>
              </div>
              <div className="flex flex-row justify-between">
                <p className="text-textLight">{formatDate(tsdata?.[2].time)}</p>
                <p className="text-textDark font-semibold">{tsdata?.[2].max_dist} cm</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DeviceTofCard;
