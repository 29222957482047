import React, { useState, useEffect, useRef, useContext } from "react";

import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  Popup,
  useMapEvent,
  FeatureGroup,
  Circle,
  Polygon,
  Tooltip,
  AttributionControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { map } from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { AccountContext } from "../events/Account";
import { v4 as uuidv4 } from "uuid";
import SetupFieldImg from "../assets/setupFieldImg.png";
import ReactJoyride from "react-joyride";
import DevicesSvg from "..//assets/Izci2.svg";
import MapIcon from "..//assets/mapIcon.svg";
import NoInstalled from "..//assets/noinstalleddevice.svg";
import OcakPng from "..//assets/ocak.png";
import ListSvg from "..//assets/list.svg";
import PompaSvg from "..//assets/pompa.svg";
import VanaSvg from "..//assets/vana.svg";
const markerIzciIcon = new L.Icon({
  iconUrl: require("../assets/marker-w.png"),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});
const markerOcakIcon = new L.Icon({
  iconUrl: require("../assets/marker.png"),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

function FieldSetting() {
  const { state } = useLocation();
  const [stateF, setStateF] = useState(state);
  console.log("seleced basd : ", stateF);
  const { apiKey, user_id } = useContext(AccountContext);
  const navigate = useNavigate();
  const [FieldName, setFieldName] = useState(stateF?.name ? stateF?.name : "");
  const [position, setPosition] = useState([51.505, -0.09]);
  const [devices, setDevices] = useState([]);
  const [isMap, setIsMap] = useState(66);
  const [isModal, setIsModal] = useState(false);
  const [isDeviceModal, setIsDeviceModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState();
  const [status, setStatus] = useState(null);
  const [updatedDevice, setUpdatedDevice] = useState();
  const steps = [
    {
      target: ".my-first-step",
      disableBeacon: true,
      content: (
        <div className="bg-white h-[40vh]   p-6">
          <img
            className="w-full h-full"
            src="https://media.tenor.com/rJn6n_aR6WMAAAAd/giff.gif"
          />
        </div>
      ),
    },
  ];
  const [fieldGeometry, setFieldGeometry] = useState(
    stateF?.geometry ? stateF?.geometry : []
  );

  useEffect(() => {
    var tempDevicesIds = [];
    var tempDevices = [];
    console.log("stateF", stateF);
    stateF?.devices?.map((d) => {
      tempDevices.push(d);
      tempDevicesIds.push(d.id);
    });

    setDevices(tempDevices);
  }, [stateF]);

  const [fieldGeometryCenter, setFieldGeometryCenter] = useState({});
  const [fieldZoom, setFieldZoom] = useState(stateF?.zoom);
  var geometry = [];
  var geometryCenter = {};
  var leafletId;
  var zoom;
  const _onEdited = (e) => {
    console.log(`_onEdited : `, e);
    console.log(`leafletid : `, leafletId);
    console.log("geometri before edit: ", geometry);
    var editedDevicesCoord = [];
    var editedObj = Object.values(e.layers._layers);
    editedObj.map((obj) => {
      if (obj.editing._poly) {
        console.log(
          `_onEdited : `,
          Object.values(e.layers._layers)[0]._latlngs[0]
        );
        geometryCenter = Object.values(e.layers._layers)[0].getCenter();
        zoom = e.sourceTarget._zoom;
        setFieldGeometry(Object.values(e.layers._layers)[0]._latlngs[0]);
        setFieldGeometryCenter(Object.values(e.layers._layers)[0].getCenter());
        setFieldZoom(e.sourceTarget._zoom);
        stateF.geometry = Object.values(e.layers._layers)[0]._latlngs[0];
        stateF.center = Object.values(e.layers._layers)[0].getCenter();
        stateF.zoom = e.sourceTarget._zoom;
        stateF.role ? stateF.owner_id = stateF.ACL[0].ownerid : stateF.owner_id = user_id
        GeometrySubmit();
      }
      if (obj.editing._marker) {
        editedDevicesCoord.push({
          id: obj.options.id,
          type: obj.options.deviceType,
          coords: {
            lat: obj._latlng.lat,
            lon: obj._latlng.lng,
          },
        });
        var tempdevices = stateF.devices;
        tempdevices.find((f, i) => {
          console.log("find", f, i);
          if (f.id == obj.options.id) {
            tempdevices[i].coords = {
              lat: obj._latlng.lat,
              lon: obj._latlng.lng,
            };
            console.log("find method :", f, "index : ", i);
            stateF.devices[i].coords = {
              lat: obj._latlng.lat,
              lon: obj._latlng.lng,
            };
          }
        });
        setUpdatedDevice(editedDevicesCoord);
      }
      console.log("editedDevicesCoords", editedDevicesCoord);
    });
    if (editedDevicesCoord.length > 0) {
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var tempdevices = stateF.devices;
      editedDevicesCoord.map((editedDevice) => {
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(editedDevice),
        };
        //
        //
        //stateF güncellemesi tam olmuyor bir tane eksik device günvelliyor ona bi bak
        //asyncfıfonksitona cevirelecek önce apidan gelen veirnşn deggerekten yazıldığı bakılcacak sonrasında işse fgele verilere bakııpa iselen yapılacak
        fetch(
          ` https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device/${editedDevice.id}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => console.log("editedDeviceApiResult", result))
          .then(() => {
            console.log("curreneuapsd", stateF);
            tempdevices.find((f, i) => {
              console.log("find", f, i);
              if (f.id == editedDevice.id) {
                tempdevices[i].coords = editedDevice.coords;
                console.log("find method :", f, "index : ", i);
                stateF.devices[i].coords = editedDevice.coords;
              }
            });
            setDevices(tempdevices);
          })
          .catch((error) => console.log("editedDeviceApiError", error));
      });
    }
  };
  const _onDrawStop = (e) => {
    console.log(`_onEdited : `, e);
    console.log(`leafletid : `, leafletId);
    console.log("geometri before edit: ", geometry);
    // var editedDevicesCoord=[]
    // var editedObj = Object.values(e.layers._layers)
    // editedObj.map((obj)=>{
    //   if(obj.editing._poly){
    //     console.log(`_onEdited : `, Object.values(e.layers._layers)[0]._latlngs[0]);
    //     geometryCenter = Object.values(e.layers._layers)[0].getCenter();
    //     zoom = e.sourceTarget._zoom;
    //     setFieldGeometry(Object.values(e.layers._layers)[0]._latlngs[0]);
    //     setFieldGeometryCenter(Object.values(e.layers._layers)[0].getCenter());
    //     setFieldZoom(e.sourceTarget._zoom);
    //     stateF.geometry = Object.values(e.layers._layers)[0]._latlngs[0];
    //     stateF.center = Object.values(e.layers._layers)[0].getCenter();
    //     stateF.zoom = e.sourceTarget._zoom;

    //     GeometrySubmit()
    //    }
    //    if(obj.editing._marker){

    //     editedDevicesCoord.push(
    //       {
    //         id:obj.options.id,
    //         type:obj.options.deviceType,
    //         coords: {
    //           lat: obj._latlng.lat,
    //           lon: obj._latlng.lng
    //         }
    //       }
    //       )
    //       var tempdevices = stateF.devices
    //       tempdevices.find((f, i) => {
    //         console.log("find",f,i);
    //         if (f.id == obj.options.id) {
    //           tempdevices[i].coords={
    //           lat: obj._latlng.lat,
    //           lon: obj._latlng.lng
    //         }
    //           console.log("find method :", f, "index : ", i);
    //           stateF.devices[i].coords = {
    //           lat: obj._latlng.lat,
    //           lon: obj._latlng.lng
    //         }

    //         }
    //       });
    //      setUpdatedDevice(editedDevicesCoord)
    //    }
    //    console.log("editedDevicesCoords",editedDevicesCoord);

    // })
    // if(editedDevicesCoord.length >0){
    //   var myHeaders = new Headers();
    //   myHeaders.append("apikey", apiKey);
    //   myHeaders.append("userid", user_id);
    //   var tempdevices = stateF.devices
    //   editedDevicesCoord.map((editedDevice)=>{

    //       var requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: JSON.stringify(editedDevice),
    //       };
    //       //
    //       //
    //       //stateF güncellemesi tam olmuyor bir tane eksik device günvelliyor ona bi bak
    //       //asyncfıfonksitona cevirelecek önce apidan gelen veirnşn deggerekten yazıldığı bakılcacak sonrasında işse fgele verilere bakııpa iselen yapılacak
    //       fetch(
    //         ` https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device/${editedDevice.id}`,
    //         requestOptions
    //       )
    //         .then((response) => response.json())
    //         .then((result) => console.log("editedDeviceApiResult",result))
    //         .then(()=>{

    //          console.log("curreneuapsd",stateF);
    //           tempdevices.find((f, i) => {
    //             console.log("find",f,i);
    //             if (f.id == editedDevice.id) {
    //               tempdevices[i].coords=editedDevice.coords
    //               console.log("find method :", f, "index : ", i);
    //               stateF.devices[i].coords = editedDevice.coords

    //             }
    //           });
    //           setDevices(tempdevices)
    //         })
    //         .catch((error) => console.log("editedDeviceApiError", error));

    //   })

    // }
  };

  const _onDeleted = (e) => {
    console.log("_onDeleted : ", e);
  };
  const GeometrySubmit = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var allfield = [];
    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
      requestOptionsGET
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("neymişbu:", result);
        allfield = result.body.fields ? result.body.fields : [];
        postField(allfield);
      })
      .catch((error) => console.log("error", error));
    // postField(allfield);
    function postField(allfield) {
      // allfield.find((f, i) => {
      //   if (f.id == stateF.id) {
      //     console.log("find method :", f, "index : ", i);
      //     delete stateF.devices;
      //     allfield[i] = stateF;
      //   }
      // });
      // var arr = { fields: allfield };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(stateF),
      };

      fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/field`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    }
  };
  const DeleteField = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var allfield = [];
    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };

    devices.map((device) => {
      device.deleted_at = Date.now();
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);

      var id = device.id;

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: JSON.stringify(device),
      };

      fetch(
        ` https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device/${device.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      //////////////////////////////////////////////////////////////
    });

    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
      requestOptionsGET
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        allfield = result.body.fields ? result.body.fields : [];
        postField(allfield);
      })
      .catch((error) => console.log("error", error));
    // postField(allfield);
    function postField(allfield) {
      allfield.find((f, i) => {
        if (f.id == stateF.id) {
          stateF.deleted_at = Date.now();
          allfield[i] = stateF;
        }
      });
      var arr = { fields: allfield };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(arr),
      };
      console.log(arr);
      fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/field`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setStatus(true);
          setDeleteLoading(false);
          navigate("/");
        })
        .catch((error) => {
          console.log(error);
          setStatus(false);
          setDeleteLoading(false);
        });
    }
  };

  const DeviceSubmit = (device) => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var id = device.id;
    device.fieldid = stateF.id;
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify(device),
    };

    fetch(
      ` https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device/${device.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    //////////////////////////////////////////////////////////////

    var allfield = [];
    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
      requestOptionsGET
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        allfield = result.body.fields ? result.body.fields : [];
        // postField(allfield);
      })
      .catch((error) => console.log("error", error));
    // postField(allfield);
    function postField(allfield) {
      allfield.find((f, i) => {
        if (f.id == stateF.id) {
          stateF.deleted_at = Date.now();
          allfield[i].devices = allfield[i].devices.filter(
            (id) => id != device.id
          );
        }
      });
      var arr = { fields: allfield };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(arr),
      };
      console.log("post öncesi kontorl:", arr);
      fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/field`,
        requestOptions
      )
        .then((response) => response.json())
        .then(setDeleteLoading(false))
        .then((result) => {
          console.log(result);
          fetch(
            `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
            requestOptionsGET
          )
            .then((response) => response.json())
            .then((result) => {
              console.log(result);
              var tempstateF = result.body.fields.find(
                (e) => e.id == stateF.id
              );
              console.log("tempstateF", tempstateF);
              setStateF(tempstateF);
              setIsDeviceModal(false);
            })
            .catch((error) => console.log("error", error));
        })
        .catch((error) => console.log("error", error));
    }

    console.log(device);
  };

  function DeleteModal() {
    return (
      <div
        className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
        id="modal-id"
      >
        <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
        <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
          <div className="">
            <div className="text-center p-5 flex-auto justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 -m-1 flex items-center text-red-500 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-16 h-16 flex items-center text-red-500 mx-auto"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <h2 className="text-xl font-bold py-4 ">Are you sure?</h2>
              <p className="text-sm text-gray-500 px-8">
                Do you really want to delete your {FieldName}?All active sensors
                will become unassigned. This process cannot be undone.
              </p>
            </div>

            {deleteLoading ? (
              <div className="   w-full h-6 ">
                <div className="m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-700 border-4 h-6 w-6"></div>
              </div>
            ) : status == false ? (
              <p>Somethings went wrong.</p>
            ) : (
              <div className="p-3  mt-2 text-center space-x-4 md:block">
                <button
                  onClick={() => setIsModal(false)}
                  className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm  font-medium tracking-wider border text-black rounded-sm  hover:bg-gray-100"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setDeleteLoading(true);
                    DeleteField();
                  }}
                  className="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-sm hover:shadow-lg hover:bg-red-600"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  function DeleteDeviceModal({ device }) {
    return (
      <div
        className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
        id="modal-id"
      >
        <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
        <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
          <div className="">
            <div className="text-center p-5 flex-auto justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 -m-1 flex items-center text-red-500 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-16 h-16 flex items-center text-red-500 mx-auto"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <h2 className="text-xl font-bold py-4 ">Are you sure?</h2>
              <p className="text-sm text-gray-500 px-8">
                Do you really want to delete your device? Sensor will become
                unassigned. This process cannot be undone.
              </p>
            </div>

            {deleteLoading ? (
              <div className="   w-full h-6 ">
                <div className="m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-700 border-4 h-6 w-6"></div>
              </div>
            ) : status == false ? (
              <p>Somethings went wrong.</p>
            ) : (
              <div className="p-3  mt-2 text-center space-x-4 md:block">
                <button
                  onClick={() => setIsDeviceModal(false)}
                  className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm  font-medium tracking-wider border text-black rounded-sm  hover:bg-gray-100"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setDeleteLoading(true);
                    DeviceSubmit(device);
                  }}
                  className="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-sm hover:shadow-lg hover:bg-red-600"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="flex flex-col gap-3 pt-16 max-sm:pt-0 mx-auto    max-sm:justify-center   ">
        {isMap == true ? (
          <label
            onClick={() => setIsMap(false)}
            className="z-50 fixed top-2 rounded-full left-1/2 -translate-x-1/2 bg-white  inline-flex items-center p-2  cursor-pointer text-black"
          >
            <div className="w-9 h-9  text-3xl  rounded-sm flex flex-row items-center justify-center">
              <img src={ListSvg} className="w-full p-1" />
            </div>
          </label>
        ) : (
          <label
            onClick={() => setIsMap(true)}
            className="fixed top-2 right-4  inline-flex items-center p-2 rounded-md cursor-pointer text-white"
          >
            <div className="w-20 h-10 bg-blue-700 rounded-sm flex flex-row items-center justify-center">
              <div className="fill-white  h-7 flex flex-row w-7">
                <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                  <path d="M800 416a288 288 0 1 0-576 0c0 118.144 94.528 272.128 288 456.576C705.472 688.128 800 534.144 800 416zM512 960C277.312 746.688 160 565.312 160 416a352 352 0 0 1 704 0c0 149.312-117.312 330.688-352 544z" />
                  <path d="M512 448a64 64 0 1 0 0-128 64 64 0 0 0 0 128zm0 64a128 128 0 1 1 0-256 128 128 0 0 1 0 256zm345.6 192L960 960H672v-64H352v64H64l102.4-256h691.2zm-68.928 0H235.328l-76.8 192h706.944l-76.8-192z" />
                </svg>
              </div>
              Map
            </div>
          </label>
        )}
        <div className="w-full hfull  ">
          {isMap == true ? (
            stateF?.center?.lat ? (
              <div className="map bg-purple-600 w-full h-screen  ">
                {/* <ReactJoyride
              steps={steps}
              continuous={true}
              styles={{
                options: {
                  arrowColor: "rgba(255, 255, 255, 0.7)",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  overlayColor: "rgba(0, 0, 0, 0.5)",
                  primaryColor: "#000",
                  textColor: "#004a14",
                  width: "undefined",
                  zIndex: 1000,
                },
              }}
            /> */}
                <MapContainer
                  center={[stateF.center.lat, stateF.center.lng]}
                  zoom={fieldZoom}
                  scrollWheelZoom={true}
                  className="w-full h-full z-0 my-first-step"
                  attributionControl={false}
                >
                  <FeatureGroup>
                    <EditControl
                      position="topright"
                      onEdited={_onEdited}
                      onDeleted={_onDeleted}
                      onDrawStop={_onDrawStop}
                      edit={{ remove: false }}
                      draw={{
                        rectangle: false,
                        circlemarker: false,
                        polyline: false,
                        circle: false,
                        polygon: false,
                        marker: false,
                      }}
                    />
                    <AttributionControl
                      position="bottomright"
                      prefix={"Leaflet"}
                    />
                    {stateF?.geometry_type == "polygon" && (
                      <Polygon
                        draw={"edited"}
                        positions={stateF.geometry}
                        eventHandlers={{
                          click: (e) => {
                            console.log("marker clicked", e);
                          },
                        }}
                      />
                    )}
                    {stateF?.geometry_type == "circle" && (
                      <Circle
                        draw={"edited"}
                        center={stateF.center}
                        radius={stateF.radius}
                        eventHandlers={{
                          click: (e) => {
                            console.log("marker clicked", e);
                          },
                        }}
                      />
                    )}
                    {devices
                      ? devices.map((e) => {
                          console.log("stateF.devices?.map((da)", e);
                          return e?.coords?.lat ? (
                            <Marker
                              key={e.id}
                              id={e.id}
                              deviceType={e.type}
                              position={[e.coords.lat, e.coords.lon]}
                              icon={
                                e.type == "izci"
                                  ? markerIzciIcon
                                  : markerOcakIcon
                              }
                            >
                              <Tooltip
                                direction="top"
                                offset={[0, -45]}
                                opacity={0.8}
                                permanent
                                className="my-other-step"
                              >
                                {e.id.slice(-4)}
                              </Tooltip>
                            </Marker>
                          ) : (
                            ""
                          );
                        })
                      : ""}
                  </FeatureGroup>
                  <TileLayer url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}" />
                </MapContainer>
              </div>
            ) : (
              <div className="map bg-purple-600 w-full h-screen  ">
                {/* <ReactJoyride
                steps={steps}
                continuous={true}
                styles={{
                  options: {
                    arrowColor: "rgba(255, 255, 255, 0.7)",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    overlayColor: "rgba(0, 0, 0, 0.5)",
                    primaryColor: "#000",
                    textColor: "#004a14",
                    width: "undefined",
                    zIndex: 1000,
                  },
                }}
              /> */}
                <MapContainer
                  center={[39.794305, 32.806588]}
                  zoom={7}
                  scrollWheelZoom={true}
                  className="w-full h-full z-0 my-first-step"
                  attributionControl={false}
                >
                  <FeatureGroup>
                    <EditControl
                      position="topright"
                      onEdited={_onEdited}
                      onDeleted={_onDeleted}
                      onDrawStop={_onDrawStop}
                      edit={{ remove: false }}
                      draw={{
                        rectangle: false,
                        circlemarker: false,
                        polyline: false,
                        circle: false,
                        polygon: false,
                        marker: false,
                      }}
                    />
                    <AttributionControl
                      position="bottomright"
                      prefix={"Leaflet"}
                    />
                    {stateF?.geometry_type == "polygon" && (
                      <Polygon
                        draw={"edited"}
                        positions={stateF.geometry}
                        eventHandlers={{
                          click: (e) => {
                            console.log("marker clicked", e);
                          },
                        }}
                      />
                    )}
                    {stateF?.geometry_type == "circle" && (
                      <Circle
                        draw={"edited"}
                        center={stateF.center}
                        radius={stateF.radius}
                        eventHandlers={{
                          click: (e) => {
                            console.log("marker clicked", e);
                          },
                        }}
                      />
                    )}
                    {devices
                      ? devices.map((e) => {
                          console.log("stateF.devices?.map((da)", e);
                          return e?.coords?.lat ? (
                            <Marker
                              key={e.id}
                              id={e.id}
                              deviceType={e.type}
                              position={[e.coords.lat, e.coords.lon]}
                              icon={
                                e.type == "izci"
                                  ? markerIzciIcon
                                  : markerOcakIcon
                              }
                            >
                              <Tooltip
                                direction="top"
                                offset={[0, -45]}
                                opacity={0.8}
                                permanent
                                className="my-other-step"
                              >
                                {e.id.slice(-4)}
                              </Tooltip>
                            </Marker>
                          ) : (
                            ""
                          );
                        })
                      : ""}
                  </FeatureGroup>
                  <TileLayer url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}" />
                </MapContainer>
              </div>
            )
          ) : isMap == false ? (
            <div className="panel bg-white w-full  px-16 pt-10 max-sm:px-3 max-sm:pt-5 flex flex-col  gap-5 center h-[calc(100vh-4rem)] overflow-y-auto ">
              <div className="flex flex-col  bg-white w-full ">
                <div className="flex flex-row pb-6  ">
                  {/* <img src={SetupFieldImg} className='w-20' /> */}
                  <p className="text-3xl ">Field Name</p>
                </div>
                <input
                  value={FieldName}
                  onChange={(event) => {
                    setFieldName(event.target.value);
                    stateF.name = event.target.value;
                  }}
                  className="max-sm:mx-auto max-sm:w-11/12 w-full border-b-black h-16 bg-gray-100 px-3 py-2 text-xl leading-tight text-gray-700 border rounded-sm  "
                  type="text"
                  placeholder={`Yonca Tarlası..`}
                />
              </div>
              <p className="text-3xl pt-8">Devices</p>
              <div className="w-full  max-sm:items-center max-sm:justify-center flex flex-row flex-wrap gap-3 content-start">
                {devices.length > 0 ? (
                  devices?.map((device) => {
                    console.log("devices", devices);
                    return device?.id ? (
                      <div
                        key={device.id}
                        className="bg-gray-100 flex flex-col rounded-sm w-[calc((100%/4)-0.75rem)] max-w-[10rem] min-w-[6rem] h-48  p-3   "
                      >
                        <p className="text-black font-bold self-start">
                          {device.id.slice(-4)}
                        </p>
                        <img
                          className="w-20 h-36 self-center "
                          src={
                            device.type == "izci"
                              ? DevicesSvg
                              : device.type == "ocak"
                              ? OcakPng
                              : ""
                          }
                        />
                        <button
                          className="bg-gray-900 w-24 h-12 text-white rounded-sm self-center"
                          onClick={() => DeviceSubmit(device)}
                        >
                          Kaldır
                        </button>
                      </div>
                    ) : (
                      ""
                    );
                  })
                ) : (
                  <div className="flex flex-row  justify-center items-center p-3 ">
                    <img src={NoInstalled} className="w-16 mr-5" />
                    <p className="text-lg font-medium ">
                      No installed Devices
                      <p className="text-xs font-medium ">
                        Install device with qr code using mobile app or register
                      </p>
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-row items-center justify-center gap-2">
                <button
                  className="my-other-step flex justify-center rounded-sm hover:shadow-lg   px-6 py-2 bg-gray-900 text-white font-bold self-center "
                  onClick={() => {
                    GeometrySubmit();
                  }}
                >
                  Update
                </button>
                <button
                  className="flex justify-center rounded-sm   p-2 hover:shadow-lg border border-red-700 text-red-700  self-center "
                  onClick={() => setIsModal(true)}
                >
                  Delete Field
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-row max-sm:flex-col ">
              <div className="panel bg-white w-full  px-16 pt-10 max-sm:px-3 max-sm:pt-5 flex flex-col  gap-5 center h-[calc(100vh-4rem)] overflow-y-auto ">
                <div className="flex flex-col  bg-white w-full ">
                  <div className="flex flex-row pb-6  ">
                    {/* <img src={SetupFieldImg} className='w-20' /> */}
                    <p className="text-3xl ">Field Name</p>
                  </div>
                  <input
                    value={FieldName}
                    onChange={(event) => {
                      setFieldName(event.target.value);
                      stateF.name = event.target.value;
                    }}
                    className="max-sm:mx-auto max-sm:w-11/12 w-full border-b-black h-16 bg-gray-100 px-3 py-2 text-xl leading-tight text-gray-700 border rounded-sm  "
                    type="text"
                    placeholder={`Yonca Tarlası..`}
                  />
                </div>
                <p className="text-3xl pt-8">Devices</p>
                <div className="w-full max-sm:items-center max-sm:justify-center flex flex-row flex-wrap gap-3 content-start">
                  {devices.length > 0 ? (
                    devices?.map((device) => {
                      console.log("devices", devices);
                      return device?.id ? (
                        <div
                          key={device.id}
                          className="bg-gray-100 flex flex-col rounded-sm w-[calc((100%/4)-0.75rem)] max-w-[10rem] min-w-[6rem] h-48  p-3   "
                        >
                          <p className="text-black font-bold self-start">
                            {device.id.slice(-4)}
                          </p>
                          <img
                            className="w-20 h-36 self-center "
                            src={
                              device.type == "izci"
                                ? DevicesSvg
                                : device.type == "ocak"
                                ? OcakPng
                                : device.type == "vana"
                                ? VanaSvg
                                : device.type == "pompa"
                                ? PompaSvg
                                : ""
                            }
                          />
                          <button
                            className="bg-gray-900 w-24 h-12 text-white rounded-sm self-center"
                            onClick={() => {
                              setSelectedDevice(device);
                              setIsDeviceModal(true);
                            }}
                          >
                            Kaldır
                          </button>
                        </div>
                      ) : (
                        ""
                      );
                    })
                  ) : (
                    <div className="flex flex-row  justify-center items-center p-3 ">
                      <img src={NoInstalled} className="w-16 mr-5" />
                      <p className="text-lg font-medium ">
                        No installed Devices
                        <p className="text-xs font-medium ">
                          Install device with qr code using mobile app or
                          register
                        </p>
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex flex-row items-center justify-center gap-2">
                  <button
                    className="my-other-step flex justify-center rounded-sm hover:shadow-lg   px-6 py-2 bg-gray-900 text-white font-bold self-center "
                    onClick={() => {
                      GeometrySubmit();
                    }}
                  >
                    Update
                  </button>
                  <button
                    className="flex justify-center rounded-sm   p-2 hover:shadow-lg border border-red-700 text-red-700  self-center "
                    onClick={() => setIsModal(true)}
                  >
                    Delete Field
                  </button>
                </div>
              </div>
              {stateF?.center?.lat ? (
                <div className="map bg-purple-600 w-full  ">
                  {/* <ReactJoyride
                  steps={steps}
                  continuous={true}
                  styles={{
                    options: {
                      arrowColor: "rgba(255, 255, 255, 0.7)",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      overlayColor: "rgba(0, 0, 0, 0.5)",
                      primaryColor: "#000",
                      textColor: "#004a14",
                      width: "undefined",
                      zIndex: 1000,
                    },
                  }}
                /> */}
                  <MapContainer
                    center={[stateF?.center?.lat, stateF?.center?.lng]}
                    zoom={fieldZoom}
                    scrollWheelZoom={true}
                    className="w-full h-full z-0 my-first-step"
                    attributionControl={false}
                  >
                    <FeatureGroup>
                      <EditControl
                        position="topright"
                        onEdited={_onEdited}
                        onDeleted={_onDeleted}
                        edit={{ remove: false }}
                        draw={{
                          rectangle: false,
                          circlemarker: false,
                          polyline: false,
                          circle: false,
                          polygon: false,
                          marker: false,
                        }}
                      />
                      <AttributionControl
                        position="bottomright"
                        prefix={"Leaflet"}
                      />
                      {stateF?.geometry_type == "polygon" && (
                        <Polygon
                          draw={"edited"}
                          positions={stateF.geometry}
                          eventHandlers={{
                            click: (e) => {
                              console.log("marker clicked", e);
                            },
                          }}
                        />
                      )}
                      {stateF?.geometry_type == "circle" && (
                        <Circle
                          draw={"edited"}
                          center={stateF.center}
                          radius={stateF.radius}
                          eventHandlers={{
                            click: (e) => {
                              console.log("marker clicked", e);
                            },
                          }}
                        />
                      )}
                      {devices
                        ? devices.map((e) => {
                            console.log("stateF.devices?.map((da)", e);
                            return e?.coords?.lat ? (
                              <Marker
                                key={e.id}
                                position={[e.coords.lat, e.coords.lon]}
                                id={e.id}
                                deviceType={e.type}
                                icon={
                                  e.type == "izci"
                                    ? markerIzciIcon
                                    : markerOcakIcon
                                }
                              >
                                <Tooltip
                                  direction="top"
                                  offset={[0, -45]}
                                  opacity={0.8}
                                  permanent
                                  className="my-other-step"
                                >
                                  {e.id.slice(-4)}
                                </Tooltip>
                              </Marker>
                            ) : (
                              ""
                            );
                          })
                        : ""}
                    </FeatureGroup>
                    <TileLayer url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}" />
                  </MapContainer>
                </div>
              ) : (
                <div className="map bg-purple-600 w-full  ">
                  {/* <ReactJoyride
                steps={steps}
                continuous={true}
                styles={{
                  options: {
                    arrowColor: "rgba(255, 255, 255, 0.7)",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    overlayColor: "rgba(0, 0, 0, 0.5)",
                    primaryColor: "#000",
                    textColor: "#004a14",
                    width: "undefined",
                    zIndex: 1000,
                  },
                }}
              /> */}
                  <MapContainer
                    center={[39.794305, 32.806588]}
                    zoom={7}
                    scrollWheelZoom={true}
                    className="w-full h-full z-0 my-first-step"
                    attributionControl={false}
                  >
                    <FeatureGroup>
                      <EditControl
                        position="topright"
                        onEdited={_onEdited}
                        onDeleted={_onDeleted}
                        edit={{ remove: false }}
                        draw={{
                          rectangle: false,
                          circlemarker: false,
                          polyline: false,
                          circle: false,
                          polygon: false,
                          marker: false,
                        }}
                      />
                      <AttributionControl
                        position="bottomright"
                        prefix={"Leaflet"}
                      />
                      {stateF?.geometry_type == "polygon" && (
                        <Polygon
                          draw={"edited"}
                          positions={stateF.geometry}
                          eventHandlers={{
                            click: (e) => {
                              console.log("marker clicked", e);
                            },
                          }}
                        />
                      )}
                      {stateF?.geometry_type == "circle" && (
                        <Circle
                          draw={"edited"}
                          center={stateF.center}
                          radius={stateF.radius}
                          eventHandlers={{
                            click: (e) => {
                              console.log("marker clicked", e);
                            },
                          }}
                        />
                      )}
                      {devices
                        ? devices.map((e) => {
                            console.log("stateF.devices?.map((da)", e);
                            return e?.coords?.lat ? (
                              <Marker
                                key={e.id}
                                position={[e.coords.lat, e.coords.lon]}
                                id={e.id}
                                deviceType={e.type}
                                icon={
                                  e.type == "izci"
                                    ? markerIzciIcon
                                    : markerOcakIcon
                                }
                              >
                                <Tooltip
                                  direction="top"
                                  offset={[0, -45]}
                                  opacity={0.8}
                                  permanent
                                  className="my-other-step"
                                >
                                  {e.id.slice(-4)}
                                </Tooltip>
                              </Marker>
                            ) : (
                              ""
                            );
                          })
                        : ""}
                    </FeatureGroup>
                    <TileLayer url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}" />
                  </MapContainer>
                </div>
              )}
            </div>
          )}
          {isModal ? <DeleteModal /> : ""}
          {isDeviceModal ? <DeleteDeviceModal device={selectedDevice} /> : ""}
        </div>
      </div>
    </>
  );
}

export default FieldSetting;
