import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccountContext } from "../events/Account";

function Profile() {
  const { t, i18n } = useTranslation();
  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  const { apiKey,userMail, user_id } = useContext(AccountContext);

  const [field, setField] = useState([]);
  const [userACL, setUserACL] = useState([]);
  const [selectedField, setSelectedField] = useState(0);
  const [selectedRole, setSelectedRole] = useState(0);
  const [targetUser, setTargerUser] = useState(0);
  
  console.log("selectedField :", selectedField);
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("dynamo userapi get: ", result);
        var filteredFields =
          result.body.fields &&
          result.body.fields.filter(
            (field) =>
              field.deleted_at == undefined && field.isDevice == undefined
          );
        setField(filteredFields ? filteredFields : []);
        console.log("acl",result.body.ACL);
        setUserACL(result.body.ACL && result.body.ACL)
      })
      .catch((error) => console.log("error", error));
  }, []);
  const AttachRoleFunction = ()=>{
    var myHeaders = new Headers();
    myHeaders.append('apikey', apiKey);
    myHeaders.append('userid', user_id);

    var arr = {
      "AC":{
        "ownerid":user_id,
        "ownermail":userMail,
        "fieldid":selectedField,
        "targetid":targetUser,
        "targetmail":targetUser,
        "role":selectedRole
      }
    };
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(arr),
    };
    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/field/${selectedField}`,
      requestOptions
    )
      .then((response) => {
        
          return response.json();
        
      
      })
      .then((result, code) => {
        console.log(result
        );
        
      })
      .catch((error) => {
        console.log(' Request error', error.message);
        
      });


  }
  const DeleteRoleFunction = (a)=>{
    var myHeaders = new Headers();
    myHeaders.append('apikey', apiKey);
    myHeaders.append('userid', user_id);

    var arr = {
      "AC":a
    };
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: JSON.stringify(arr),
    };
    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/field/${a.fieldid}`,
      requestOptions
    )
      .then((response) => {
        
          return response.json();
        
      
      })
      .then((result, code) => {
        console.log(result
        );
        
      })
      .catch((error) => {
        console.log(' Request error', error.message);
        
      });


  }
  const GetUserACL = ()=>{
    var myHeaders = new Headers();
    myHeaders.append('apikey', apiKey);
    myHeaders.append('userid', user_id);

    var arr = {
      "ACL":userACL
    };
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(arr),
    };
    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/acl`,
      requestOptions
    )
      .then((response) => {
        
          return response.json();
        
      
      })
      .then((result, code) => {
        console.log(result
        );
        
      })
      .catch((error) => {
        console.log(' Request error', error.message);
        
      });


  }
  return (
    <div className="p-5">
      <div
        id="myElement13"
        className="myElement13 sticky top-0 w-screen lg:w-full lg:bg-none  px-5 -ml-5 z-10 max-sm:bg-[#f8f9fa] pt-10 pb-4 transition-all duration-300  ease-in-out delay-75"
      >
        <p className="text-4xl text-textDark font-medium ">{t("navProfile")}</p>
        <div id="bigselam">
          <p className="text-textLight selam" id="selam">
            {t("monitor")}
          </p>
        </div>
      </div>
      <div className="flex flex-col">
        <select
          className="block  mr-4 text-slate-400 bg-white/0"
          onChange={(e) => changeLanguageHandler(e.target.value)}
          defaultValue={i18n.language}
        >
          <option value="tr-TR">TR</option>
          <option value="en-EN">EN</option>
        </select>
        <p className="text-md text-textDark font-semibold mb-2">
          Roles & Permissions
        </p>
        <select onChange={(e)=>setSelectedField(e.target.value)} className="block  mr-4 text-slate-400 bg-white/0">
          {field?.map((e)=>{
            return <option value={e.id}>{e.name}</option>
          })}
        </select>
        <input
          className="text-sm p-2 w-full"
          type="email"
          placeholder="user mail"
          onChange={(e)=>setTargerUser(e.target.value)}
        />
        <select onChange={(e)=>setSelectedRole(e.target.value)} lassName="block  mr-4 text-slate-400 bg-white/0" >
          <option value="operator">Operator</option>
          <option value="viewer">Viewer</option>
        </select>
        <button onClick={()=>AttachRoleFunction()}>Attach Role</button>
        <br/>
        <h2>{user_id}</h2>
        <h2>{userMail}</h2>
        <h2>{selectedField}</h2>
        <h2>{selectedRole}</h2>
        <h2>{targetUser}</h2>
        <br/>
          {field.map((e)=>{
            return <div className="mb-3 bg-slate-300">
              <p>{e.name}</p>
              {e.ACL?.map((a)=>{
                return <><p>{a.targetmail}-{a.role}-{a.deleted_at && "deletedRole"}</p><button onClick={()=>DeleteRoleFunction(a)}>Delete Role</button></>
              })}
              
              </div>
          })}
      </div>
      <div>
        <button onClick={()=>{GetUserACL()}}>getACLfields</button>
      </div>
    </div>
  );
}

export default Profile;
