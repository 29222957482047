import React, { useContext, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./DeviceVanaCard.css";
import { Box, Button, MenuItem, Modal, Select, TextField } from "@mui/material";
import Prepare from "../assets/prepare.svg";
import Irrigate from "../assets/irrigate.svg";
import allertVana from "../assets/alertVana.png";
import vanaBlack from "../assets/vanaBlack.svg";
import { AccountContext } from "../events/Account";
import { useTranslation } from "react-i18next";
function DeviceVanaCard({ data,fieldData }) {
  const {t,i18n} = useTranslation()
  const { apiKey, user_id } = useContext(AccountContext);
  const navigate = useNavigate();
  const [lastData, setLastData] = useState();
  const [tsdata, settsdata] = useState();
  const [loading, setLoading] = useState(true);
  const [vanaStatus, setVanaStatus] = useState(false);
  const [HWNo, seyHWNo] = useState("02");
  const [SancakPacketVersion, setSancakPacketVersion] = useState("001");
  const [SancakPacketVersionOFF, setSancakPacketVersionOFF] = useState("002");
  const [GRDPacketVersion, setGRDPacketVersion] = useState("");
  const [GRDMeasureCount, setGRDMeasureCount] = useState(0);
  const [GRDSleepTimeH, setGRDSleepTimeH] = useState(0);
  const [GRDSleepTimeM, setGRDSleepTimeM] = useState(5);
  const [isCommandModal, setIsCommandModal] = useState(false);
  const [commandLoading, setCommandLoading] = useState(false);
  const [wateringTime, setWateringTime] = useState(false);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device?deviceId=${data.id}`,
      requestOptionsGET
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        const resultfilter = result.body;
        console.log("VANADATA : ", resultfilter);
        setLastData(resultfilter);
      })
      .then(() =>
        fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=vana`,
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            if (result?.body?.[0]?.status) {
              var lastd = lastData;
              lastd.status = result.body[0].status;
              setLastData(lastd);
            }
            const resultfilter = result.body;
            var obj = [];
            result.body.map((e) => {
              obj.push(JSON.parse(e));
            });
            settsdata(obj);

            // yaz(resultfilter.at);

            // console.log('funcyaz: ', yaz(resultfilter.at));
            // settsdata(resultfilter);
          })
          .then(() => setLoading(false))
          .catch((err) => {
            console.log("DeviceVanaCard err :", err);
            setLoading(false);
          })
      )
      .catch((err) => {
        console.log("lasdatafetcherr : ", err);
        setLoading(false);
      });

    const interval = setInterval(() => {
      console.log("This will run every 10 second!");
      fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device?deviceId=${data.id}`,
        requestOptionsGET
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          const resultfilter = result.body;
          console.log("last measure data : ", resultfilter);
          setLastData(resultfilter);
        })
        .catch((err) => {
          console.log("lasdatafetcherr : ", err);
        });
      fetch(
        `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=vana`,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result?.body?.[0]?.status) {
            var lastd = lastData;
            lastd.status = result.body[0].status;
            setLastData(lastd);
          }
          const resultfilter = result.body;
          var obj = [];
          result.body.map((e) => {
            obj.push(JSON.parse(e));
          });
          settsdata(obj);

          // yaz(resultfilter.at);

          // console.log('funcyaz: ', yaz(resultfilter.at));
          // settsdata(resultfilter);
        })
        .then(() => setLoading(false))
        .catch((err) => {
          console.log("DeviceVanaCard err :", err);
        });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  function formatDate(date) {
    var mdate = new Date(date);
    mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
    return `       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }
     ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    `;
  }
  const VanaCommand = (status) => {
    setVanaStatus(status);
  };

  const StatusFunction = () => {
    if (lastData && lastData.status) {
      if (lastData.status == "on") {
        return <p className="text-green-500">ON</p>;
      } else if (lastData.status == "off") {
        return <p className="text-gray-500">OFF</p>;
      } else if (lastData.status == "pending") {
        return <p className="text-blue-500">pending</p>;
      }
    } else {
      return <p>noData</p>;
    }
  };

  const CommandFunctionOpenValve = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var tempCont = Number(GRDMeasureCount).toString(16);
    if (tempCont.length == 1) {
      tempCont = `0${tempCont}`;
    }
    var tempSleep = Number(GRDSleepTimeH * 60 + GRDSleepTimeM).toString(16);

    if (tempSleep.length == 1) {
      tempSleep = `000${tempSleep}`;
    } else if (tempSleep.length == 2) {
      tempSleep = `00${tempSleep}`;
    } else if (tempSleep.length == 3) {
      tempSleep = `0${tempSleep}`;
    }
    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: Number(SancakPacketVersion),
      g: Number(HWNo),
      i: data.id,
      d: `${tempSleep.toUpperCase()}`,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    console.log(body);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${data.id}&type=vana`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setCommandLoading(false);
      })
      .then(() => setIsCommandModal(false))
      .catch((error) => console.log("error", error));
  };
  const CommandFunctionCloseValve = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: Number(SancakPacketVersionOFF),
      g: Number(HWNo),
      i: data.id,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    console.log(body);
    // fetch(
    //   `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${data.id}&type=vana`,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log(result);
    //     setCommandLoading(false);
    //   })
    //   .then(() => setIsCommandModal(false))
    //   .catch((error) => console.log("error", error));
  };
  function timestampToDate(timestamp) {
    var saat = new Date(timestamp).getHours();
    var dakika = new Date(timestamp).getMinutes();
    return `${saat}:${dakika <= 9 ? `0${dakika}` : dakika}`;
  }
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    if (lastData?.status != "off") {
      setCommandLoading(true);
      CommandFunctionCloseValve();
    } else {
      setIsCommandModal(true);
    }
    setIsChecked(!isChecked);
  };
  return (
    // <div className="   lg:w-1/4  md:w-1/2  p-2 max-sm:p-0  max-sm:w-full">
    //   {loading || loading || !tsdata?.[0]?.ocak_id ? (
    //     <div className=" w-full flex max-sm:flex-col flex-row gap-4">
    //       <div className="w-full ">
    //         <SkeletonTheme baseColor="#f5f5fb" highlightColor="#fff">
    //           <Skeleton width={"60%"} height={30} />
    //           <Skeleton width={"100%"} height={450} />
    //         </SkeletonTheme>
    //       </div>
    //     </div>
    //   ) : (
    //     <>
    //       <p className="text-2xl font-medium mt-5 mb-2 ml-2 max-sm:ml-4">
    //         {data.id.slice(-4)}
    //       </p>
    //       <div className="bg-white rounded-md h-44">
    //         <div className="  ">
    //           <div className=" flex flex-row flex-wrap gap-5 ">
    //             <div
    //               className=" w-full h-full"
    //               // onClick={() => {
    //               //   navigate(`${data.id.slice(-4)}`, {
    //               //     state: { ...data },
    //               //   });
    //               // }}
    //             >
    //               <div className="flex flex-row w-full justify-between p-3">
    //                 <div>
    //                   <p className="text-lg font-medium ">Status</p>
    //                   <p className="flex flex-row  text-2xl font-semibold text-gray-400 ">
    //                     {StatusFunction()}
    //                     <p className="text-blue-700 ml-1">
    //                       {lastData?.time ? formatDate(lastData.time) : ""}
    //                     </p>
    //                   </p>
    //                   <div className="w-24  h-20  bg-vana bg-center   bg-no-repeat bg-contain  "></div>
    //                 </div>
    //                 <div className=" flex flex-col  w-full items-center ">
    //                 {lastData?.status == "on" ? (
    //                     <div className=" w-full flex flex-row justify-between items-center h-12">
    //                       <div className="text-center ">
    //                         <p className="text-sm text-gray-500">
    //                           Start Watering
    //                         </p>
    //                         <p className="text-2xl font-semibold">
    //                           {timestampToDate(Number(lastData?.event_date))}
    //                         </p>
    //                       </div>
    //                       <div className="text-center ">
    //                         <p className="text-sm text-gray-500">
    //                           End Watering
    //                         </p>
    //                         <p className="text-2xl font-semibold">
    //                           {timestampToDate(
    //                             Number(lastData?.event_date) +
    //                               Number(lastData?.watering) * 60000
    //                           )}
    //                         </p>
    //                       </div>
    //                     </div>
    //                   ) : (
    //                     ""
    //                   )}
    //                   {lastData?.status == "pending" ? (
    //                     <div className=" w-full flex flex-row justify-center items-center h-12 text-center">
    //                       <img src={Prepare} className="" />
    //                       <p className="font-medium text-sm">
    //                         Preparing the device
    //                       </p>
    //                     </div>
    //                   ) : (
    //                     ""
    //                   )}
    //                   {lastData?.status == "off" || lastData?.status == null ? (
    //                     <div className=" w-full flex flex-row justify-center items-center h-12 ">
    //                       <img src={Irrigate} className="" />
    //                       <p className="font-medium text-sm">
    //                         Set the perfect irrigation duration for crops.
    //                       </p>
    //                     </div>
    //                   ) : (
    //                     ""
    //                   )}
    //                   <p
    //                     className={`${
    //                       lastData?.status == "on" ? "block" : "invisible"
    //                     } text-sm font-semibold text-center`}
    //                   >
    //                     Watering{" "}
    //                     {lastData?.watering
    //                       ? Math.floor(lastData.watering / 60)
    //                       : "0"}{" "}
    //                     hours{" "}
    //                     {lastData?.watering ? lastData.watering % 60 : "1"} mins
    //                   </p>
    //                   <div
    //                     onClick={(event) => event.stopPropagation()}
    //                     className="w-full flex  flex-col  items-center gap-1"
    //                   >
    //                     <Button
    //                       variant="contained"
    //                       onClick={() => setIsCommandModal(true)}
    //                       sx={{
    //                         backgroundColor: "#0F52BA",
    //                         fontFamily: "Poppins",
    //                         padding: "0.3rem",
    //                         margin: "0.1rem",
    //                         width: "70%",
    //                       }}
    //                     >
    //                       ON
    //                     </Button>
    //                     <Button
    //                       onClick={() => {
    //                         setCommandLoading(true);
    //                         CommandFunctionCloseValve();
    //                       }}
    //                       variant="contained"
    //                       sx={{
    //                         backgroundColor: "#DCDCDC",
    //                         fontFamily: "Poppins",
    //                         padding: "0.3rem",
    //                         margin: "0.1rem",
    //                         width: "70%",
    //                         "&:hover": {
    //                           backgroundColor: "#C8C8C8",
    //                         },
    //                       }}
    //                     >
    //                       {commandLoading ? (
    //                         <div className=" flex flex-row flex-wrap">
    //                           <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
    //                         </div>
    //                       ) : (
    //                         "OFF"
    //                       )}
    //                     </Button>
    //                   </div>
    //                   {/* <div className="w-full h-full bg-blue-400  flex flex-col items-center justify-center">
    //                   <label
    //                     className="  w-max  flex items-center relative  cursor-pointer select-none"
    //                     onClick={(event) => event.stopPropagation()}
    //                   >
    //                     <div
    //                       onClick={() => VanaCommand(!vanaStatus)}
    //                       className={`${
    //                         vanaStatus ? `bg-green-500` : `bg-gray-500`
    //                       } items-center appearance-none transition-colors cursor-pointer w-24 h-10 rounded-md  `}
    //                     >
    //                       <span className="absolute font-medium text-xl uppercase right-2 top-1/2 -translate-y-1/2  text-white">
    //                         {" "}
    //                         OFF{" "}
    //                       </span>
    //                       <span className=" absolute font-medium text-xl uppercase left-3  top-1/2 -translate-y-1/2 text-white">
    //                         {" "}
    //                         ON{" "}
    //                       </span>
    //                       <span
    //                         className={`${
    //                           vanaStatus ? `translate-x-12` : ``
    //                         } w-12 h-10 right-12 absolute rounded-md transform transition-transform bg-gray-200`}
    //                       />
    //                     </div>
    //                   </label>
    //                 </div> */}
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <Modal
    //         open={isCommandModal}
    //         onClose={() => setIsCommandModal(false)}
    //         aria-labelledby="modal-modal-title"
    //         aria-describedby="modal-modal-description"
    //       >
    //         <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 max-sm:w-full bg-white rounded-md  shadow-24 p-4  flex flex-col">
    //           <p className="text-2xl w-full  text-center pt- pb-4">
    //             Set Valve {data.id.slice(-4)}
    //           </p>
    //           <div className="flex flex-col gap-2">
    //             {/* <TextField
    //               id="outlined-basic"
    //               label="Ocak Id"
    //               value={tsdata?.[0]?.ocak_id}
    //               variant="standard"
    //             />
    //             <TextField
    //               id="outlined-basic"
    //               label="Izci Id"
    //               value={data.id}
    //               variant="standard"
    //             />
    //             <TextField
    //               id="outlined-basic"
    //               label="Sancak Packet Version"
    //               variant="standard"
    //               value={SancakPacketVersion}
    //               onChange={(event) => {
    //                 setSancakPacketVersion(event.target.value.toString());
    //               }}
    //             /> */}
    //             {/* <p className="pt-3 font-bold">GRD Commands </p> */}
    //             <div className="flex flex-row whitespace-nowrap  pl-4 gap-2 justify-start items-end">
    //               <p className="">Irrigate for</p>

    //               <Select
    //                 variant="filled"
    //                 id="demo-simple-select"
    //                 value={GRDSleepTimeH}
    //                 sx={{ width: "4rem" }}
    //                 onChange={(event) => {
    //                   setGRDSleepTimeH(event.target.value);
    //                 }}
    //               >
    //                 <MenuItem value={0}>0</MenuItem>
    //                 <MenuItem value={1}>1</MenuItem>
    //                 <MenuItem value={2}>2</MenuItem>
    //                 <MenuItem value={3}>3</MenuItem>
    //                 <MenuItem value={4}>4</MenuItem>
    //                 <MenuItem value={5}>5</MenuItem>
    //                 <MenuItem value={6}>6</MenuItem>
    //                 <MenuItem value={7}>7</MenuItem>
    //                 <MenuItem value={8}>8</MenuItem>
    //                 <MenuItem value={9}>9</MenuItem>
    //                 <MenuItem value={10}>10</MenuItem>
    //                 <MenuItem value={11}>11</MenuItem>
    //                 <MenuItem value={12}>12</MenuItem>
    //                 {/* <MenuItem value={13}>13</MenuItem>
    //               <MenuItem value={14}>14</MenuItem>
    //               <MenuItem value={15}>15</MenuItem>
    //               <MenuItem value={16}>16</MenuItem>
    //               <MenuItem value={17}>17</MenuItem>
    //               <MenuItem value={18}>18</MenuItem>
    //               <MenuItem value={19}>19</MenuItem>
    //               <MenuItem value={20}>20</MenuItem>
    //               <MenuItem value={21}>21</MenuItem>
    //               <MenuItem value={22}>22</MenuItem>
    //               <MenuItem value={23}>23</MenuItem>
    //               <MenuItem value={24}>24</MenuItem> */}
    //               </Select>
    //               <p>hours</p>
    //               <Select
    //                 variant="filled"
    //                 id="demo-simple-select"
    //                 value={GRDSleepTimeM}
    //                 sx={{ width: "4rem" }}
    //                 onChange={(event) => {
    //                   setGRDSleepTimeM(event.target.value);
    //                 }}
    //               >
    //                 <MenuItem value={0}>0</MenuItem>
    //                 {/* <MenuItem value={1}>1</MenuItem>
    //                 <MenuItem value={2}>2</MenuItem>
    //                 <MenuItem value={3}>3</MenuItem>
    //                 <MenuItem value={4}>4</MenuItem> */}
    //                 <MenuItem value={5}>5</MenuItem>
    //                 {/* <MenuItem value={6}>6</MenuItem>
    //                 <MenuItem value={7}>7</MenuItem>
    //                 <MenuItem value={8}>8</MenuItem>
    //                 <MenuItem value={9}>9</MenuItem> */}
    //                 <MenuItem value={10}>10</MenuItem>
    //                 {/* <MenuItem value={11}>11</MenuItem>
    //                 <MenuItem value={12}>12</MenuItem>
    //                 <MenuItem value={13}>13</MenuItem>
    //                 <MenuItem value={14}>14</MenuItem> */}
    //                 <MenuItem value={15}>15</MenuItem>
    //                 {/* <MenuItem value={16}>16</MenuItem>
    //                 <MenuItem value={17}>17</MenuItem>
    //                 <MenuItem value={18}>18</MenuItem>
    //                 <MenuItem value={19}>19</MenuItem> */}
    //                 <MenuItem value={20}>20</MenuItem>
    //                 {/* <MenuItem value={21}>21</MenuItem>
    //                 <MenuItem value={22}>22</MenuItem>
    //                 <MenuItem value={23}>23</MenuItem>
    //                 <MenuItem value={24}>24</MenuItem> */}
    //                 <MenuItem value={25}>25</MenuItem>
    //                 {/* <MenuItem value={26}>26</MenuItem>
    //                 <MenuItem value={27}>27</MenuItem>
    //                 <MenuItem value={28}>28</MenuItem>
    //                 <MenuItem value={29}>29</MenuItem> */}
    //                 <MenuItem value={30}>30</MenuItem>
    //                 {/* <MenuItem value={31}>31</MenuItem>
    //                 <MenuItem value={32}>32</MenuItem>
    //                 <MenuItem value={33}>33</MenuItem>
    //                 <MenuItem value={34}>34</MenuItem> */}
    //                 <MenuItem value={35}>35</MenuItem>
    //                 {/* <MenuItem value={36}>36</MenuItem>
    //                 <MenuItem value={37}>37</MenuItem>
    //                 <MenuItem value={38}>38</MenuItem>
    //                 <MenuItem value={39}>39</MenuItem> */}
    //                 <MenuItem value={40}>40</MenuItem>
    //                 {/* <MenuItem value={41}>41</MenuItem>
    //                 <MenuItem value={42}>42</MenuItem>
    //                 <MenuItem value={43}>43</MenuItem>
    //                 <MenuItem value={44}>44</MenuItem> */}
    //                 <MenuItem value={45}>45</MenuItem>
    //                 {/* <MenuItem value={46}>46</MenuItem>
    //                 <MenuItem value={47}>47</MenuItem>
    //                 <MenuItem value={48}>48</MenuItem>
    //                 <MenuItem value={49}>49</MenuItem> */}
    //                 <MenuItem value={50}>50</MenuItem>
    //                 {/* <MenuItem value={51}>51</MenuItem>
    //                 <MenuItem value={52}>52</MenuItem>
    //                 <MenuItem value={53}>53</MenuItem>
    //                 <MenuItem value={54}>54</MenuItem> */}
    //                 <MenuItem value={55}>55</MenuItem>
    //                 {/* <MenuItem value={56}>56</MenuItem>
    //                 <MenuItem value={57}>57</MenuItem>
    //                 <MenuItem value={58}>58</MenuItem>
    //                 <MenuItem value={59}>59</MenuItem> */}
    //               </Select>
    //               <p>minutes.</p>
    //             </div>
    //           </div>
    //           <div className=" p-4 w-full flex flex-row gap-2 justify-end">
    //             <Button
    //               //export all rows, including from the next page, (still respects filtering and sorting)
    //               onClick={() => setIsCommandModal(false)}
    //               variant="text"
    //             >
    //               Cancel
    //             </Button>
    //             <Button
    //               disabled={
    //                 SancakPacketVersion === "" ||
    //                 GRDSleepTimeM === "" ||
    //                 GRDSleepTimeH === ""
    //               }
    //               //export all rows, including from the next page, (still respects filtering and sorting)
    //               onClick={() => {
    //                 CommandFunctionOpenValve();
    //                 setCommandLoading(true);
    //               }}
    //               startIcon={commandLoading ? "" : "^"}
    //               variant="contained"
    //             >
    //               {commandLoading ? (
    //                 <div className=" flex flex-row flex-wrap">
    //                   <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
    //                 </div>
    //               ) : (
    //                 "Apply"
    //               )}
    //             </Button>
    //           </div>
    //         </Box>
    //       </Modal>
    //     </>
    //   )}
    // </div>
    <div className="w-48     max-sm:p-0  max-sm:w-[calc(50%-0.5rem)] ">
      {loading || loading || !tsdata?.[0]?.ocak_id ? (
        <div className=" w-full flex max-sm:flex-col flex-row gap-4">
          <div className="w-full ">
            <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fff">
              <Skeleton width={"60%"} height={30} />
              <Skeleton width={"100%"} height={200} />
            </SkeletonTheme>
          </div>
        </div>
      ) : (
        <div className="w-full ">
          <div
            className={`${
              lastData?.status == "on" || isChecked
                ? "bg-gradient-to-br from-[#62CBF8] to-[#3157F5] "
                : "bg-white shadow-myShadow1"
            }  rounded-xl p-5 transition duration-300 `}
          >
            <div className="w-full  flex flex-row justify-between">
              <p
                className={`${
                  lastData?.status == "on" || isChecked
                    ? "text-white "
                    : "text-textLight"
                }  capitalize `}
              >
                {t(`${lastData?.status}`)}
              </p>
              {/* <div className="w-12 h-6 rounded-full bg-slate-300"></div> */}
              {fieldData.role !== "viewer" &&<div className="">
                <label className="flex cursor-pointer select-none items-center">
                  <div className="relative">
                    <input
                      type="checkbox"
                      checked={lastData?.status == "on"}
                      onChange={handleCheckboxChange}
                      className="sr-only"
                    />
                    <div
                      className={`box block h-6 w-10 rounded-full ${
                        lastData?.status == "on" || isChecked
                          ? "bg-textDark"
                          : "bg-gray-300"
                      }`}
                    ></div>
                    <div
                      className={`absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-white transition ${
                        lastData?.status == "on" || isChecked
                          ? "translate-x-full"
                          : ""
                      }`}
                    ></div>
                  </div>
                </label>
              </div>}
            </div>
            <img className="w-2/3 py-2 max-h-20" src={vanaBlack} />
            <p
              className={`${
                lastData?.status == "on" || isChecked
                  ? "text-white "
                  : "text-textDark"
              }  text-lg w-full break-words  font-semibold  `}
            >
              {data.id.slice(-4)}
            </p>
            <p
              className={`${
                lastData?.status == "on" || isChecked
                  ? "text-white "
                  : "text-textLight "
              }  text-sm  h-10`}
            >
              {lastData?.status == "on"
                ? `${timestampToDate(
                    Number(lastData?.event_date)
                  )} - ${timestampToDate(
                    Number(lastData?.event_date) +
                      Number(lastData?.watering) * 60000
                  )}`
                : t('vanaSetDevice')}
            </p>
          </div>
        </div>
      )}

      <Modal
        open={isCommandModal}
        onClose={() => {
          setIsCommandModal(false);
          setIsChecked(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mx-auto translate-y-1/2 w-1/3 max-sm:w-5/6 bg-white/90 rounded-2xl  backdrop-blur-md  shadow-24 p-5  flex flex-col">
          <p className="text-xl w-full text-textDark text-center pt- pb-4">
            {data.id.slice(-4)}
          </p>
          <div className="flex flex-col gap-2">
            {/* <TextField
                  id="outlined-basic"
                  label="Ocak Id"
                  value={tsdata?.[0]?.ocak_id}
                  variant="standard"
                />
                <TextField
                  id="outlined-basic"
                  label="Izci Id"
                  value={data.id}
                  variant="standard"
                />
                <TextField
                  id="outlined-basic"
                  label="Sancak Packet Version"
                  variant="standard"
                  value={SancakPacketVersion}
                  onChange={(event) => {
                    setSancakPacketVersion(event.target.value.toString());
                  }}
                /> */}
            {/* <p className="pt-3 font-bold">GRD Commands </p> */}
            <div className="w-full p-4">
              <img src={vanaBlack} className="w-32 mx-auto" />
            </div>
            <div className="text-sm flex flex-row flex-wrap gap-2 justify-start items-end text-textLight">
              <p className="">{t('irrigate')}</p>

              <Select
                variant="standard"
                id="demo-simple-select"
                value={GRDSleepTimeH}
                sx={{
                  width: "3rem",
                  fontWeight: "600",
                  color: "rgb(69 74 115)",
                }}
                onChange={(event) => {
                  setGRDSleepTimeH(event.target.value);
                }}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                {/* <MenuItem value={13}>13</MenuItem>
                  <MenuItem value={14}>14</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={16}>16</MenuItem>
                  <MenuItem value={17}>17</MenuItem>
                  <MenuItem value={18}>18</MenuItem>
                  <MenuItem value={19}>19</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={21}>21</MenuItem>
                  <MenuItem value={22}>22</MenuItem>
                  <MenuItem value={23}>23</MenuItem>
                  <MenuItem value={24}>24</MenuItem> */}
              </Select>
              <p>{t('hours')}</p>
              <Select
                variant="standard"
                id="demo-simple-select"
                value={GRDSleepTimeM}
                sx={{
                  width: "3rem",
                  fontWeight: "600",
                  color: "rgb(69 74 115)",
                }}
                onChange={(event) => {
                  setGRDSleepTimeM(event.target.value);
                }}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={13}>13</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={17}>17</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={19}>19</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={21}>21</MenuItem>
                <MenuItem value={22}>22</MenuItem>
                <MenuItem value={23}>23</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={26}>26</MenuItem>
                <MenuItem value={27}>27</MenuItem>
                <MenuItem value={28}>28</MenuItem>
                <MenuItem value={29}>29</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={31}>31</MenuItem>
                <MenuItem value={32}>32</MenuItem>
                <MenuItem value={33}>33</MenuItem>
                <MenuItem value={34}>34</MenuItem>
                <MenuItem value={35}>35</MenuItem>
                <MenuItem value={36}>36</MenuItem>
                <MenuItem value={37}>37</MenuItem>
                <MenuItem value={38}>38</MenuItem>
                <MenuItem value={39}>39</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={41}>41</MenuItem>
                <MenuItem value={42}>42</MenuItem>
                <MenuItem value={43}>43</MenuItem>
                <MenuItem value={44}>44</MenuItem>
                <MenuItem value={45}>45</MenuItem>
                <MenuItem value={46}>46</MenuItem>
                <MenuItem value={47}>47</MenuItem>
                <MenuItem value={48}>48</MenuItem>
                <MenuItem value={49}>49</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={51}>51</MenuItem>
                <MenuItem value={52}>52</MenuItem>
                <MenuItem value={53}>53</MenuItem>
                <MenuItem value={54}>54</MenuItem>
                <MenuItem value={55}>55</MenuItem>
                <MenuItem value={56}>56</MenuItem>
                <MenuItem value={57}>57</MenuItem>
                <MenuItem value={58}>58</MenuItem>
                <MenuItem value={59}>59</MenuItem>
              </Select>
              <p>{t('minutes')}.</p>
            </div>
          </div>
          <div className=" p-4 w-full flex flex-row gap-2 justify-end">
            <Button
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                setIsCommandModal(false);
                setIsChecked(false);
              }}
              variant="text"
              sx={{ fontWeight: "600", color: "rgb(69 74 115)" }}
            >
              {t('cancel')}
            </Button>
            <Button
              sx={{
                fontWeight: "600",
                background:
                  "linear-gradient(146deg, #62CBF8 5.53%, #3157F5 91.6%)",
              }}
              disabled={
                SancakPacketVersion === "" ||
                GRDSleepTimeM === "" ||
                GRDSleepTimeH === ""
              }
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                CommandFunctionOpenValve();
                setCommandLoading(true);
              }}
              startIcon={commandLoading ? "" : ""}
              variant="contained"
            >
              {commandLoading ? (
                <div className=" flex flex-row flex-wrap">
                  <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
                </div>
              ) : (
                t('apply')

              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default DeviceVanaCard;
