import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import OcakPanel from "../assets/ocakpanel.png";
import { useTranslation } from "react-i18next";

function DeviceOcakCard({ data }) {
  const {t,i18n} = useTranslation() 
  const navigate = useNavigate();
  const [lastMeasureDate, setLastMeasureDate] = useState();
  const [tsdata, settsdata] = useState();
  const [loading, setLoading] = useState(true);
  const tempcurrent = [
    { mois: 67, temp: 29, cm: "05" },
    { mois: 66, temp: 29, cm: "15" },
    { mois: 56, temp: 25, cm: "25" },
    { mois: 50, temp: 24, cm: "35" },
    { mois: 49, temp: 23, cm: "45" },
    { mois: 48, temp: 23, cm: "55" },
    // { mois: 47, temp: 23, cm: '65' },
    // { mois: 45, temp: 23, cm: '75' },
    // { mois: 40, temp: 22, cm: '85' },
  ];
  useEffect(() => {
    fetch(
      `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=ocak&timeResolution=last`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result.body);
        const resultfilter = result.body;
        var obj = [];
        result.body.map((e) => {
          console.log("aha bu e:", e);
          obj.push(JSON.parse(e));
        });
        console.log("obj:", obj);
        settsdata(obj);

        settsdata(obj);
      })
      .then(() => setLoading(false));

    setInterval(() => {
      fetch(
        `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=ocak&timeResolution=last`,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          var obj = [];
          result.body.map((e) => {
            console.log("aha bu e:", e);
            obj.push(JSON.parse(e));
          });
          console.log("obj:", obj);
          settsdata(obj);
        })
        .then(() => setLoading(false));
    }, 20000);
  }, []);
   function formatDate(date) {
    var mdate = new Date(date);
    console.log("date",mdate);
    mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
    // return `       ${mdate.getHours()}:${
    //   mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    // }
    //  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    // `;
    return `${timeDifference(mdate)},       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }
     
    `;
    
  }
  function timeDifference( previous) {
    var current = new Date().getTime()
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

     if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ` ${t('minutes')} ${t('ago')}`;   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ` ${t('hours')} ${t('ago')}`;   
    }

    else if (elapsed < msPerMonth) {
        return ' ' + Math.round(elapsed/msPerDay) + ` ${t('days')} ${t('ago')}`;   
    }

    else if (elapsed < msPerYear) {
        return ' ' + Math.round(elapsed/msPerMonth) + ` ${t('months')} ${t('ago')}`;   
    }

    else {
        return ' ' + Math.round(elapsed/msPerYear ) + ` ${t('years')} ${t('ago')}`;   
    }
}
  return (
    // <div className='   lg:w-1/4  md:w-1/2  p-2 max-sm:p-0  max-sm:w-full '>
    //   {loading ? <div className=' w-full flex max-sm:flex-col flex-row gap-4'>
    //      <div className='w-full '>
    //      <SkeletonTheme baseColor="#f5f5fb" highlightColor="#fff">
    //       <Skeleton width={"60%"} height={30} />
    //       <Skeleton width={"100%"} height={150} />
    //       </SkeletonTheme>
    //       </div>
    //     </div>:<><p className='text-2xl font-medium mt-5 mb-2 ml-4'>{data.id.slice(-4)}</p>
    //   <div className='bg-white rounded-md  h-44'>
    //     <div className='  '>
    //         <div className=' flex flex-row flex-wrap gap-5  '>
    //           <div
    //             className=' w-full h-full'
    //             onClick={() => {
    //               navigate(`${data.id.slice(-4)}`, {
    //                 state: { ...data, tsdata },
    //               });
    //             }}
    //           >
    //             <div className='flex flex-row w-full justify-between p-3'>
    //               <div>
    //                 <p className='text-lg font-medium'>Son Durum</p>
    //                 <p className=' flex flex-row font-medium'> Ölçüm tarihi:<p className='ml-1 text-blue-700'>{tsdata?.[0]?.time && formatDate(tsdata?.[0]?.time)}</p></p>
    //               </div>
    //               <p className='font-bold text-green-500'>online</p>
    //             </div>
    //             <div className='cubukcontainer flex flex-row justify-evenly h-24'>
    //               <div
    //                 className='w-32 mr-3   max-sm:w-32
    //                bg-contain bg-ocak bg-no-repeat bg-center  ml-4'
    //               ></div>
    //               <div>
    //                 {/* <div className='flex flex-row center items-center  gap-10  mb-4'>
    //                   <p className='text-lg font-bold text-gray-500 '>
    //                     Ambient Temp
    //                   </p>
    //                   <p className='text-4xl'>21 °C</p>
    //                 </div> */}
    //                 <div className='mr-3'>
    //                   <div className='flex flex-row justify-between font-bold text-slate-500 gap-5'>
    //                     {/* <p>Wind</p> */}
    //                     <p>Humidity</p>
    //                     <p>Temp</p>
    //                   </div>

    //                   <div className='flex flex-row justify-between font-bold text-3xl '>
    //                     {/* <p className='flex flex-row gap-1'>
    //                       5 <p className='text-sm  self-center '> km/h</p>
    //                     </p> */}
    //                     <p className='flex flex-row gap-1'>
    //                       {tsdata?.[0]?.time && Number(tsdata?.[0]?.am)} <p className='text-sm  self-center '> %</p>
    //                     </p>
    //                     <p className='flex flex-row gap-1'>
    //                     {tsdata?.[0]?.time && Number(tsdata?.[0]?.at)} <p className='text-sm  self-center '> °C</p>
    //                     </p>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>

    //     </div>
    //   </div></>}
    // </div>
    <div className=" min-w-[20rem]  lg:w-1/4  md:w-1/2  p-2 max-sm:p-0  max-sm:w-full mb-8">
      {loading ? (
        <div className=" w-full flex max-sm:flex-col flex-row gap-4">
          <div className="w-full ">
          <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fff">
              <Skeleton width={"60%"} height={30} />
              <Skeleton width={"100%"} height={200} />
            </SkeletonTheme>
          </div>
        </div>
      ) : (
        <>
          <div
            className=" w-full h-full flex flex-row flex-wrap bg-white shadow-myShadow1 p-5 rounded-2xl "
            onClick={() => {
              navigate(`${data.id.slice(-4)}`, {
                state: { ...data, tsdata },
              });
            }}
          >
            <div className="w-5/12">
              <p className="font-semibold text-blue-500">{t("active")}</p>

              <img src={OcakPanel} className="h-24 my-2 shadow-miniMap" />

              <p className="text-lg font-semibold text-textDark ">
                {data.id.slice(-4)}
              </p>
              <div>
                <p className=" flex flex-col text-sm text-textLight">
                {t("fieldsLastMeasurement")}
                  <p className="">
                    {tsdata?.[0]?.time && formatDate(tsdata?.[0]?.time)}
                  </p>
                </p>
              </div>
            </div>
            <div className="w-7/12 pr-3 flex flex-col justify-center gap-3 ">
              <div className="flex flex-row justify-between text-textLight gap-5">
                {/* <p>Wind</p> */}
                <p>{t("humidity")}</p>
                <p>{t("temp")}</p>
              </div>

              <div className="flex flex-row justify-between font-bold text-3xl text-textDark">
                {/* <p className='flex flex-row gap-1'>
                        5 <p className='text-sm  self-center '> km/h</p>
                      </p> */}
                <p className="flex flex-row gap-1">
                  {tsdata?.[0]?.time && Number(tsdata?.[0]?.am)}{" "}
                  <p className="text-sm  self-center "> %</p>
                </p>
                <p className="flex flex-row gap-1">
                  {tsdata?.[0]?.time && Number(tsdata?.[0]?.at)}{" "}
                  <p className="text-sm  self-center "> °</p>
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default DeviceOcakCard;
