import React, { useState, useContext, useEffect } from 'react';
import SetupFieldImg from '../assets/setupFieldImg.png';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../events/Account';
import Scanner from '../components/BarcodeScanner';
import QrCodeScanner from "../components/QrCodeScanner";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  Popup,
  Polygon,
  useMapEvent,
  FeatureGroup,
  Circle,
  useMapEvents,
  AttributionControl,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { map } from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import { v4 as uuidv4 } from 'uuid';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
const markerIcon = new L.Icon({
  iconUrl: require('../assets/marker-w.png'),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});
var popup = new L.popup();

function SetupField() {
  const {t,i18n} = useTranslation()
  function LocationMarker() {
    const [position, setPosition] = useState(null);
    const map = useMapEvents({
      click(e) {
        map.locate();
        console.log(e);
        setQRLatLng({ lat: e.latlng.lat, lon: e.latlng.lng });
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>You are here</Popup>
      </Marker>
    );
  }
  const { apiKey,userMail, user_id } = useContext(AccountContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [userACL, setUserACL] = useState([]);
  const [ACLFields, setACLFields] = useState([]);
  const [step, setStep] = useState(2);
  const [field, setField] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceId, setDeviceId] = useState();
  const [fieldPosition, setFieldPosition] = useState([]);
  const [selectedField, setSelectedField] = useState(0);
  const [selectedFieldGeometryType, setSelectedFieldGeometryType] = useState();

  const [QRData, setQRData] = useState();
  const [QRLatLng, setQRLatLng] = useState({ lat: 38, lon:  32 });
  const [QRstatus, setQRstatus] = useState();

  useEffect(() => {
    if (window?.navigator?.userAgentData?.mobile) {
      setIsMobile(true);
    } else if (!window?.navigator?.userAgentData) {
      setIsMobile(true);
    }
    // alert('Please enable your GPS position feature.');
  }, [])
  
 
  const readLocation = () => {
    var minAccuracy;
    
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        function (position) {
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;
          var accuracy = position.coords.accuracy;
          console.log("readlocation");
          setQRLatLng(prev => {return{...prev , "lat": latitude ? latitude : 38, "lon": longitude ? longitude : 32 }});
          var capa = document.getElementById('capa');
          if(capa){
            capa.innerHTML  =
            'latitude: ' +
            latitude +
            ', longitude: ' +
            longitude +
            ', accuracy: ' +
            accuracy.toFixed(2);
          }
        },function error(err) {
          console.error(`ERROR(${err.code}): ${err.message}`);
        },
        { maximumAge: 100000, timeout: 1000, enableHighAccuracy: true }
      );
    } else {
      alert('Geolocation API is not supported in your browser.');
    }

    return;
  };
  const qrScanned = (res) => {
    setQRData(res);
    // alert(res)
    // readLocation();
    setStep(3)
  };
  const deviceSubmit = () => {
   
    
    var myHeaders = new Headers();
    myHeaders.append('apikey', apiKey);
    myHeaders.append('userid', user_id);
    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1 ; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();

    
    var arr = {
      kaldıran:userMail,
      kaldırma_tarihi: day + "." + month + "." + year,
    };

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(arr),
    };

    fetch(
    `https://x7el29cfwg.execute-api.eu-west-1.amazonaws.com/v1/izci/${QRData}`,
    requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Geçersiz Kare Kod');
      })
      .then((result, code) => {
        console.log(
          'Device Request :',
          result.body,
          'ownerid. :',
          result.body.owner_id
        );
        if (
          result.body.owner_id &&
          result.body.owner_id !== '' &&
          result.body.owner_id !== user_id
        ) {
          throw new Error('Bu cihaza yetkiniz bulunmamakta');
        }
        setStep(4);
        setTimeout(() => navigate('/'), 3000);
      })
      .catch((error) => {
        console.log('Device Request error', error.message);
        setQRstatus(error.message);
        setStep(66);
      });
    console.log('allfield after operation :', 1);
  
  };
  function selectFieldFunc(e) {
    setSelectedField(e);
    setLoading(false);
   
  }
  return (
    <div className='relative max-sm:z-[51] h-screen bg-white  flex items-center justify-center'>
      
     
      {step == 2 &&
        (isMobile ? (
          <div className=' flex  flex-col justify-center items-center bg-white '>
            <div className='flex flex-col justify-center items-center  h-screen  w-screen'>
              <div className={`h-full w-full ${QRData ? '' : ''}`}>
                {/* <Scanner onResult={(res) => qrScanned(res)} /> */}
                <QrCodeScanner onResult={(res) => qrScanned(res)} />

              </div>
              <button
                className='text-sm m-5 bg-blue-700 text-white rounded-md p-3 fixed bottom-20'
                onClick={() => setIsMobile(false)}
              >
                Cihaz No Girerek Kaldır
              </button>
              <button
                id='capa'
                className='text-sm m-5 bg-white  fixed top-5'
              ></button>
            </div>
          </div>
        ) : (
          <>
            <div className='flex flex-col justify-center items-center bg-white h-screen gap-6'>
              <div className='flex flex-col justify-center items-center '>
                <img src={SetupFieldImg} className='w-20' />
                <p className='text-xl w-[40rem] max-sm:w-80 text-center mb-7'>
                  Cihazı  Mobil Uygulamadan Kare Kodu okutarak kaldırabilirsiniz.
                </p>
                <p className='text-3xl w-[40rem] max-sm:w-80 text-center'>
                  Cihaz Numarasını girerek kaldır.
                </p>
              </div>
              <input
                value={deviceId}
                onChange={(event) => setQRData(event.target.value)}
                className='max-sm:w-10/12 w-80 border-b-black h-16 bg-neutral-200 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline'
                type='text'
                placeholder={`XXXYYY111222333`}
              />
              <div
                className='ml-auto   mr-10 absolute right-0 bottom-0  p-3 hover:cursor-pointer'
                onClick={() => {
                  // readLocation();
                  setStep(3);
                }}
              >
                <p className='text-xl text-green-600'>{t('next')}</p>
                <></>
              </div>
              
            </div>
          </>
        ))}
      {step == 3 && (
        <div className='flex  flex-col justify-center items-center bg-white w-full '>
          <div className={`flex flex-col justify-center items-center ${isMobile ? "pt-0" : "pt-16"} h-screen bg-oran w-full`}>
            
           Cihazı Kaldırmak İstiyor musunuz?
            
            <div
              className='absolute z-50   bottom-14 max-sm:bottom-24 py-2 px-14 bg-white'
              onClick={() => deviceSubmit()}
            >
              <p className='text-xl text-green-600  '>{t('next')}</p>
            </div>
            <div
              className='absolute z-50 w-10 h-10 right-10 text-center  bottom-14 max-sm:bottom-24 rounded-full bg-white'
              
            >
              <p className='text-xl text-green-600  '>.</p>
            </div>
          </div>
        </div>
      )}
      {step == 4 && (
        <div className='flex  flex-col justify-center items-center bg-white  '>
          <div className='flex flex-col justify-center items-center  h-screen  w-full'>
            <img src={SetupFieldImg} className='w-20 mb-4' />
            <p className='text-2xl bg-red-700 p-12 text-white rounded-lg'>
            Cihaz Kaldırıldı
            </p>
          </div>
        </div>
      )}
      {step == 20 && (
        <div className='flex  flex-col justify-center items-center bg-white  '>
          <div className='flex flex-col justify-center items-center  h-screen  w-full'>
            <img src={SetupFieldImg} className='w-20 mb-4' />
            <p className='text-2xl bg-green-700 p-12 text-white rounded-sm'>
            Cihaz Kaldırıldı
            </p>
          </div>
        </div>
      )}
      {step == 66 && (
        <div className='flex  flex-col justify-center items-center bg-white  '>
          <div className='flex flex-col justify-center items-center  h-screen  w-full'>
            <p className='pt-3'>{QRstatus} </p>
            <button
              className='text-2xl bg-blue-700 p-3 text-white rounded-sm'
              onClick={() => setStep(2)}
            >
              {t('setupTryAgain')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SetupField;
function ResetCenterView(props) {
  const { selectPosition } = props;
  const map = useMap();

  if (selectPosition) {
    map.setView(
      L.latLng(selectPosition?.lat, selectPosition?.lon),
      map.getZoom(),
      {
        animate: true,
      }
    );
  }

  return null;
}
