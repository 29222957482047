import { useState, useEffect } from "react"
import { ChevronLeft, ChevronRight } from "react-feather"
import { useTranslation } from "react-i18next"

export default function Carousel({
  children: slides,
  autoSlide = false,
  autoSlideInterval = 3000,
}) {
  const {t,i18n} =useTranslation()
  const [curr, setCurr] = useState(0)

  const prev = () =>
    setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1))
  const next = () =>
    setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1))

  useEffect(() => {
    if (!autoSlide) return
    const slideInterval = setInterval(next, autoSlideInterval)
    return () => clearInterval(slideInterval)
  }, [curr])
  return (
    <div className="relative w-full h-full">
      <button
          onClick={prev}
          className="absolute z-50 bottom-1/2 translate-y-1/2 -left-4   rounded-full  bg-white text-textLight sm:hover:bg-white/30 shadow-md "
        >
          <ChevronLeft size={30} className=" "/>
        </button>
        <button
          onClick={next}
          className="absolute z-50 bottom-1/2 translate-y-1/2   -right-4 translate-x-0 rounded-full bg-white  text-textLight sm:hover:bg-white/30 shadow-md"
        >
        <ChevronRight size={30} className=""/>
        </button>
    <div className="overflow-hidden relative w-full h-full  "  >
        <p className='font-semibold text-xs text-white rounded-md bg-rose-400 p-1 absolute right-2  top-2'>5 {t('alert')}</p>
        <p className='text-xs text-textDark  absolute right-6  bottom-3'> {t('seeall')} → </p>
      <div
        className=" flex flex-row w-full h-full transition-transform ease-out duration-500"
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {slides}
      </div>
      
    

      <div className="absolute bottom-4 right-0 left-0">
        <div className="flex items-center justify-center gap-[0.15rem]">
          {slides.map((_, i) => (
            <div
              className={`
              transition-all w-[0.23rem] h-[0.23rem] bg-textDark rounded-full
              ${curr === i ? "p-[0.14rem]" : "bg-opacity-50"}
            `}
            />
          ))}
        </div>
      </div>
    </div></div>
  )
}